// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * Avinash - 2023-06-21 - 60 -- Working on Skill sheet to show on tab
// * Avinash - 2023-06-21 - 180 -- Doing some R&D for data grid
// * Avinash - 2023-06-22 - 20 -- spring planing meeting
// * Avinash - 2023-06-212 - 250 -- Working on Skill sheet UI part

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './Skill.css';

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  QueryCellInfoEventArgs,
  Page,
  Inject,
  Resize,
} from '@syncfusion/ej2-react-grids';
// import { employeeData } from './data';
// * ------------------------------------------------------------------------------------------------------------------3.Style

// -------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
// const { REACT_APP_ENDPOINT_HOLIDAYPATH } = process.env;

// interface Props {

// }

type DataItem = {
  id: number;
  name: string;
  age: number;
  designation: string;
};

const jsonData: DataItem[] = [
  { id: 1, name: 'Communication', age: 30, designation: 'Software Engineer' },
  { id: 2, name: 'Teamwork', age: 35, designation: 'Project Manager' },
  { id: 3, name: 'Reliability', age: 40, designation: 'Team Lead' },
  { id: 3, name: 'Problem-solving', age: 40, designation: 'Team Lead' },
  {
    id: 3,
    name: 'Organization and planning',
    age: 40,
    designation: 'Team Lead',
  },
  { id: 3, name: 'Initiative', age: 40, designation: 'Team Lead' },
  { id: 3, name: 'Self-management', age: 40, designation: 'Team Lead' },
  { id: 3, name: 'Leadership', age: 40, designation: 'Team Lead' },
  { id: 3, name: 'Learning', age: 40, designation: 'Team Lead' },
  { id: 3, name: 'Technology', age: 40, designation: 'Team Lead' },
];

// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
class Skill extends React.Component<{}> {
  //   private holiday: any;
  //   private user: any;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedOptions: {},
    };
    // this.user = JSON.parse(Common.getUser() ?? '');
    // this.holiday = this.props.jsonHoliday;
  }

  // handleOptionChange(rowData, columnName, value) {
  //   this.setState((prevState) => ({
  //     selectedOptions: {
  //       ...prevState.selectedOptions,
  //       [rowData.id]: {
  //         ...prevState.selectedOptions[rowData.id],
  //         [columnName]: value,
  //       },
  //     },
  //   }));
  // }

  renderRadioButtons(args: { rowData: DataItem }) {
    return (
      <div>
        <input type='radio' name='radioBtn' className='radio-button' />
      </div>
    );
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  //   state = {
  //     jsonHoliday: null,
  //   };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  //   componentDidMount() {
  //
  //   }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * Avinash - 2023-05-26 - 200 -- Working on Attendance sheet to show on selector from the syncfusion control
  // * Avinash - 2023-05-26 - 480 -- Working on Attendance sheet header bar to show on UI from the syncfusion control

  render() {
    return (
      <>
        <div className='main-div'>
          {/* <GridComponent dataSource={jsonData}>
            cssClass='skill-sheet'
            
            <ColumnsDirective>
              <ColumnDirective
                // showInColumnChooser={s}
                field='name'
                headerText='Name'
                // width='20'
                textAlign='Left'
              />
              <ColumnDirective
                field='age'
                headerText='Poor'
                // width='20'
                textAlign='Center'
                template={this.renderRadioButtons}
              />
              <ColumnDirective
                field='designation'
                headerText='Fair'
                showInColumnChooser={true}
                // width='20'
                textAlign='Center'
                template={this.renderRadioButtons}
              />
              <ColumnDirective
                field='id'
                headerText='Good'
                // width='20'
                textAlign='Center'
                template={this.renderRadioButtons}
              />
              <ColumnDirective
                field='id'
                headerText='Excellent'
                // width='20'
                textAlign='Center'
                template={this.renderRadioButtons}
              />
            </ColumnsDirective>
          </GridComponent> */}
        </div>
      </>
    );
  }
}

export default Skill;
