// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SANDEEP - 2023-04-10 - 90 - Work Status Meeting
// * SUHAIL - 2023-06-13 - 240 - Slowness Issue on employee calendar
// * SUHAIL - 2023-06-13 - 30 - meeting with fabio on saving person and employee
// * SUHAIL - 2023-06-13 - 210 - working on create employee and person through modal dialog
// * SANDEEP - 2023-07-13 - 300 -- Checking Spreadsheet component to be implemented and it's working.
// * SANDEEP - 2023-07-15 - 60 - Work Status Meeting
// * SANDEEP - 2023-07-15 - 300 -  Checking the if we make component by basic html and then assign the evets, etc.
// * SANDEEP - 2023-07-15 - 60 -  Checking if we can use spreadsheet component to make attendance table.
// * SANDEEP - 2023-07-17 - 120 - Doing some RnD for getting yearly calender.
// * SUHAIL - 2023-08-18 - 55 - attended sprint review and retrospective meeting
// * SUHAIL - 2023-08-18 - 30 - meeting with fabio on review word component changes
// * SUHAIL - 2023-08-18 - 290 - Research on upgrading word component due to some issues
// * SUHAIL - 2023-08-21 - 80 - attended weekly status review meeting
// * SUHAIL - 2023-08-21 - 180 - Resolve issue of context menu errors in word document
// * SUHAIL - 2023-08-21 - 120 - Resolve issue of open document and save not working in word document
// * SUHAIL - 2023-08-21 - 100 - Resolve issue of print quality in word document
// * SUHAIL - 2023-08-22 - 80 - Selector list not coming when Sidebar menu has more than two levels submenu
// * SUHAIL - 2023-08-22 - 80 - Error on Employee submenu filter click when some employees have no branch assigned
// * SUHAIL - 2023-08-22 - 80 - Employee - Show dossier and contract list in descending order
// * SUHAIL - 2023-08-22 - 80 - Employee - Latest contract should be selected by default
// * SUHAIL - 2023-08-22 - 160 - Open word and pdf document on dossier list double click in employee dossier

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import { Component } from 'ionicons/dist/types/stencil-public-runtime.js';
import React, { ReactNode, useEffect } from 'react';
import XCard from './XCard';
import Accordion from './Accordion';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  GridModel,
  row,
  Inject,
  Resize,
  ContextMenuItemModel,
  EditSettingsModel,
  Filter,
  Edit,
  Sort,
  Reorder,
  ContextMenu,
  InfiniteScroll,
  PageSettingsModel,
  GroupSettingsModel,
  Group,
  Page,
  Toolbar,
  beforeOpen,
} from '@syncfusion/ej2-react-grids';
import Common from '../Util/Common';
import SelectorSearch from './Layout/SelectorSearch';
import { DialogComponent, Dialog } from '@syncfusion/ej2-react-popups';
import DocumentViewer from './Layout/DocumentViewer';
import DataGrid from './DataGrid';
//import Timeline from './Layout/Timeline';
import Attendance from './NewAttendance';
import Skill from './Skill';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  RangesDirective,
  CellStyleModel,
  RowsDirective,
  CellDirective,
  CellsDirective,
  RowDirective,
} from '@syncfusion/ej2-react-spreadsheet';
import Payslip from './Payslip';
import Contract from './Contract';
import EmployeePayHeads from './PayHeads/EmployeePayHeads';
import YearlyCalanderView from './YearTimeLine/YearlyCalanderView';
import { composer_contextmenu } from '../dummydata/xml_data_employee';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import { parseIsolatedEntityName } from 'typescript';
import {
  DropDownListComponent,
  DropDownTreeComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import PayheadList from './PayHeads/PayheadList';
import Bank from './Bank/Bank';
import EmployeeBankList from './Bank/EmployeeBankList';
import AllocationList from './Allocation/AllocationList';
import NewPayslip from './NewPayslip';
import Formats from './Formats/Formats';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import LeaveTypesList from './Leave/LeaveTypesList';
import { sort } from '@syncfusion/ej2-react-charts';
import DialogConfirm, { DialogBoxProps } from './Dialogbox/DialogConfirm';
import LeaveTimeLine from './YearTimeLine/LeaveTimeLine';

interface _CardData {
  _jObject: any;
  _xml: any;
  selectedtabname?: string;
  _json?: any;
  datagridAllowGrouping?: boolean;
  datagridWidth?: string;
  subcardxml?: any;
  selectorService?: any;
  rootmenu?: any;
  selectedmenu?: any;
  showCommonCntxMenu: boolean;
  refreshTabs?: any;
  tabinfo?: any;
}

// * ------------------------------------------------------------------------------------------------------------------3.Style

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const {
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
  REACT_APP_ENDPOINT_EMPLOYEESERVICE,
  REACT_APP_ENDPOINT_CORESERVICE,
} = process.env;

class Composer extends React.Component<_CardData> {
  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
  private user: any;
  private arrTimeline: any = [];
  private Attendance: any = [];
  private Skill: any = [];
  private startidx: number = 0;
  private arr: number[] = [];
  private _structure: any;
  private GridInstance: GridComponent | any;
  private spreadsheet: SpreadsheetComponent | any;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  private cmnu: any;
  private _fulljson: any;
  private updateflexflow: string = 'row';
  private selectedRowIndex: number;
  private _dlgHeader: string = 'New Document';
  private jsonContractType: any;
  private cfields: object = { value: 'id', text: 'itemValue' };
  private treeSettings: TreeSettingsModel = { expandOn: 'Click' };
  private iMenuItems: any;
  private iValidTab: string = '';
  private iDatacntxid: any;
  private iRelations: any;

  private iAddrMainCardid = 0;
  private iAddrSubCardid = 0;
  private token: any = null;
  public dialogObj: any;
  private _voidguid: any;

  public filterSettings: any = { type: 'Menu' };
  public toolbarOptions: any = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
  public editSettings: any = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    showAddNewRow: true,
    newRowPosition: 'Top',
  };
  public sortingOptions: Object = {
    columns: [{ field: 'itemValue', direction: 'Ascending' }],
  };

  private radRE: RadioButtonComponent | any = null;
  private radNR: RadioButtonComponent | any = null;
  private radPR: RadioButtonComponent | any = null;
  private radSE: RadioButtonComponent | any = null;
  private radDF: RadioButtonComponent | any = null;
  private radZB: RadioButtonComponent | any = null;
  private radTM: RadioButtonComponent | any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    //this._fulljson = JSON.parse(Common.getFullJson() ?? '');
    this.selectedRowIndex = 0;

    this.arrTimeline = [];

    var _result: any;
    parser.parseString(composer_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });

    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];

    this.iMenuItems = this.cntxitems;
  }

  static defaultProps = {
    showCommonCntxMenu: false,
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  // * SUHAIL - 2023-05-03 - 20
  state = {
    showword: false,
    docdata: '',
    contractid: '',
    timelinedata: [],
    contractdetail: [],
    jsonDocument: null,
    _absences: undefined,
    _addNew: false,
    _menuitems: undefined,
    showDocDialog: false,
    isvalidtab: '',
    menudatacntxid: undefined,
    lastcontract: null,
    jobfields: undefined,
    branchfields: undefined,
    deptfields: undefined,
    openperiod: undefined,
    //commonDataitems: [],
    refreshpropsvalue: 0,
    PrompotValue: '',
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler

  componentDidMount() {
    // let data: HTMLElement = document.getElementById(
    //   'divTimeline'
    // ) as HTMLElement;
    // if (data === null) {
    //   return;
    // }
    // data.addEventListener('scroll', () => {
    //   const { scrollTop, scrollHeight, clientHeight } = data;
    //   if (
    //     scrollTop + clientHeight >= scrollHeight - 50 &&
    //     this.arr.length > this.state.timelinedata.length
    //   ) {
    //     this.startidx = this.startidx + 1;
    //     //this.arrTimeline.push(<Timeline Year={this.arr[this.startidx]} />);
    //     var myear: any = [];
    //     myear.push(this.arr[this.startidx]);
    //     this.arrTimeline.push(
    //       <YearlyCalanderView
    //         _years={myear}
    //         _employee={this.props._jObject}
    //         _firstDayOfWeek={1}
    //         _showWeekSeparators={false}
    //         _forceFullWeeks={false}
    //         _cmenuitems={null}></YearlyCalanderView>
    //     );
    //     this.setState({ timelinedata: this.arrTimeline });
    //   }
    // });
    if (this.state.openperiod == undefined) {
      this.getopenperiod();
    }
    if (
      this.props.selectedtabname?.toLowerCase() === 'leave' &&
      this.state._absences == undefined
    ) {
      // this.GetAbsences();
    }

    if (this.props.selectedtabname?.toLowerCase() === 'contract') {
      if (!this.state.lastcontract) {
        this.GetContract();
      }
      if (!this.state.jobfields) {
        this.GetJob();
      }
      if (!this.state.branchfields) {
        this.GetBranch();
      }
      if (!this.state.deptfields) {
        this.GetDepartment();
      }

      /* Open contextmenu on three vertical dots */
      let ctl: any = document.querySelector(
        `#grid-tabdetails_${Common.toCamelCase(
          this.props.selectedtabname!.toString()
        )}-moreoptions`
      );
      if (ctl && this.cMenu) {
        ctl?.removeEventListener('click', (e: any) =>
          this.cMenu!.open(e.y, e.x)
        );
        ctl?.addEventListener('click', (e: any) => this.cMenu!.open(e.y, e.x));
      }
    }
    // if (this.state.commonDataitems.length <= 0) {
    //   let payload: any = {};
    //   Common.ApiCallAsync(
    //     'POST',
    //     `${REACT_APP_ENDPOINT_CORESERVICE}` + '/commondata/search',
    //     payload,
    //     Common.getToken() || '',
    //     this.user,
    //     this.token.tenant
    //   )
    //     .then((response: any) => {
    //       return response.data;
    //       //.itemType <= this._structure.CommonDataItems.MaritalStatus
    //     })
    //     .then((response: any) => {
    //       let _items: any = response.data;
    //       this.setState({
    //         commonDataitems: _items,
    //       });
    //     })
    //     .catch((error: any) => {
    //       console.error(error);
    //     })
    //     .finally(() => { });
    // }

    if (this.props.selectedtabname?.toLowerCase() === 'taxinfo') {
      if (
        this.props._jObject.residentialStatus ==
        this._structure.Residential_Status.Resident.id
      ) {
        this.radRE.checked = true;
      } else if (
        this.props._jObject.residentialStatus ==
        this._structure.Residential_Status.NonResident.id
      ) {
        this.radNR.checked = true;
      }

      if (
        this.props._jObject.typeOfEmployment ==
        this._structure.Type_Of_Employment.Primary.id
      ) {
        this.radPR.checked = true;
      } else if (
        this.props._jObject.typeOfEmployment ==
        this._structure.Type_Of_Employment.Secondary.id
      ) {
        this.radSE.checked = true;
      } else if (
        this.props._jObject.typeOfEmployment ==
        this._structure.Type_Of_Employment.DirectorsFees.id
      ) {
        this.radDF.checked = true;
      }

      if (
        this.props._jObject.location == this._structure.Location.Zanzibar.id
      ) {
        this.radZB.checked = true;
      } else if (
        this.props._jObject.location == this._structure.Location.Mainland.id
      ) {
        this.radTM.checked = true;
      }
    }
  }

  private getopenperiod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/firstperiod`,
      {
        statusId: Number(this._structure.PeriodStatusId.Open),
        lastPeriodOnNoOpenPeriod: false,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          openperiod: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetSubItems(parentId: any) {
    let submenutems: MenuItemModel[] | undefined = [];
    let _subitems: any = this.user.commondata.filter(
      (x: any) => x.itemType == parentId
    );
    for (let i = 0; i < _subitems.length; i++) {
      submenutems.push({ id: _subitems[i].id, text: _subitems[i].itemValue });
    }
    return submenutems;
  }

  onbeforeOpen = (e: any) => {
    if (this.user.commondata.length <= 0) {
      return;
    }
    var item = null;
    if (e.event) {
      item = document.elementFromPoint(e.event.clientX, e.event.clientY);
    } else {
      item = document.elementFromPoint(e.left, e.top);
    }

    let _elem: Element | null | undefined;
    let acrd_header: boolean = false;

    if ((item as any)?.id.startsWith('acrdn_header_') == false) {
      _elem = item?.closest('[data-contextmenuid]');
    } else {
      _elem = item?.closest('[data-context]');
      acrd_header = true;
    }
    let _mitems: any;
    let _isvalidtab = '';
    let _menudatacntxid: any = undefined;

    if (
      !e.event ||
      (e.event.target.id !== '100' && e.event.target.id !== '200')
    ) {
      var _result: any;
      parser.parseString(
        composer_contextmenu,
        function (err: any, result: any) {
          _result = JSON.parse(JSON.stringify(result));
        }
      );

      let tab = item
        ?.closest('.dataviewer-tabcomponent')
        ?.querySelector('.e-tab-text.tab-highlighter');

      if (tab == undefined) {
        if ((item as any).classList.contains('e-menu-item') == false) {
          e.cancel = true;
        }
        return;
      } else {
        this.iDatacntxid = '';
        this.iValidTab = '';
      }
      _isvalidtab = (tab as HTMLElement).innerText.toLowerCase();
      _menudatacntxid = (
        _elem as HTMLElement
      )?.dataset.contextmenuid?.toLowerCase();

      this.iDatacntxid = _menudatacntxid;
      this.iValidTab = _isvalidtab;

      let cntxmenuitems: MenuItemModel[] | undefined = [];

      if (
        (tab as HTMLElement).innerText.toLowerCase() === 'bio' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'composer'
      ) {
        //this.cMenu?.close();
        e.cancel = true;
        return;
      }

      this.cntxitems = Common.GetContextMenuFromXML(
        _result.ContextMenu.Item
      ) as MenuItemModel[];

      _mitems = this.cntxitems;

      this.setMenuItems(_mitems, true);

      if (
        (tab as HTMLElement).innerText.toLowerCase() === 'bio' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'bio-card'
      ) {
        let _id = this._structure.CommonDataItems.Gender;
        let _text = Object.keys(this._structure.CommonDataItems).find(
          (key) =>
            this._structure.CommonDataItems[key] ===
            this._structure.CommonDataItems.Gender
        );
        cntxmenuitems.push({
          id: _id,
          text: _text,
          items: this.GetSubItems(_id),
        });

        _id = this._structure.CommonDataItems.Religion;
        _text = Object.keys(this._structure.CommonDataItems).find(
          (key) =>
            this._structure.CommonDataItems[key] ===
            this._structure.CommonDataItems.Religion
        );
        cntxmenuitems.push({
          id: _id,
          text: _text,
          items: this.GetSubItems(_id),
        });

        _id = this._structure.CommonDataItems.MaritalStatus;
        _text = Object.keys(this._structure.CommonDataItems).find(
          (key) =>
            this._structure.CommonDataItems[key] ===
            this._structure.CommonDataItems.MaritalStatus
        );
        cntxmenuitems.push({
          id: _id,
          text: _text,
          items: this.GetSubItems(_id),
        });

        let _exists: any = [];
        if (this.props._jObject.bio !== undefined) {
          for (let i = 0; i < this.props._jObject.bio.length; i++) {
            let _bio = this.props._jObject.bio[i];
            let _items: any;
            for (let j = 0; cntxmenuitems.length; j++) {
              let _cxt = cntxmenuitems[j];
              if (_cxt == undefined) {
                break;
              }
              _items =
                _cxt.items !== undefined
                  ? _cxt.items.filter((x: any) => x.id == _bio.commonDataId)
                  : [];

              if (_items.length > 0) {
                _cxt.items = [];
                _exists.push(_cxt);
                break;
              }
            }
          }

          let _delidx = this.cntxitems.indexOf(
            this.cntxitems.filter((x) => x.text?.toLowerCase() == 'delete')[0]
          );
          if (_delidx == -1) {
            _delidx = 0;
          }

          let _delete: any = this.cntxitems.filter(
            (x: any) => x.text.toLowerCase() === 'delete'
          );

          _delete[0].items = _exists.filter(
            (x: any) => x.id !== this._structure.CommonDataItems.Gender
          );

          let _unq = cntxmenuitems
            .filter((x: any) => !_exists.includes(x))
            .concat(_exists.filter((x: any) => !cntxmenuitems!.includes(x)));

          for (let i = 0; i < _unq.length; i++) {
            //_unq[i].items = [];
            this.cntxitems[0].items?.splice(2, 0, _unq[i]);
          }
        } else {
          for (let i = 0; i < cntxmenuitems.length; i++) {
            //cntxmenuitems[i].items = [];
            this.cntxitems[0].items?.splice(2, 0, cntxmenuitems[i]);
          }
        }

        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );

        this.cntxitems[0].items?.shift();
        this.cntxitems[0].items?.shift();

        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);

        // let _objectId = Common.FindJsonByObjectValue(
        //   this._fulljson,
        //   '_bio_',
        //   this.user
        // );

        //if (_objectId !== null) {
        // let menu = Common.FindJsonByObjectId(
        //   this._fulljson,
        //   _objectId.object,
        //   this.user
        // );

        // let _relation = menu.children.filter(
        //   (x: any) => x.value === '_relation_'
        // );

        // _relation = menu.children.filter(
        //   (x: any) => x.object == _relation[0].object
        // );

        // let notrelation: any;
        // if (_relation.length > 0) {
        //   notrelation = menu.children
        //     .filter((x: any) => !_relation.includes(x))
        //     .concat(
        //       _relation.filter((x: any) => !menu.children!.includes(x))
        //     );
        // } else {
        //   notrelation = menu.children;
        // }

        // let cntxmenuitems: MenuItemModel[] | undefined =
        //   Common.GetContextMenu(
        //     (menu as any).children,
        //     this._structure,
        //     this.user
        //   );

        // let cntxmenuitems: MenuItemModel[] | undefined =
        //   Common.GetContextMenu(notrelation, this._structure, this.user);

        // let _delidx = this.cntxitems.indexOf(
        //   this.cntxitems.filter((x) => x.text?.toLowerCase() == 'delete')[0]
        // );
        // if (_delidx == -1) {
        //   _delidx = 0;
        // }

        // let _delete: any = this.cntxitems.filter(
        //   (x: any) => x.text.toLowerCase() === 'delete'
        // );
        // let _exists: any = [];

        // for (let i = 0; i < cntxmenuitems.length; i++) {
        //   cntxmenuitems[i].items = [];
        //   if (
        //     this.props._jObject.bio
        //       .map((x: any) => x.type)
        //       .includes(cntxmenuitems[i].text) == false
        //   ) {
        //     this.cntxitems[0].items?.splice(2, 0, cntxmenuitems[i]);
        //   } else {
        //     _exists.push(cntxmenuitems[i]);
        //   }
        // }
        // _delete[0].items = _exists;

        // let _exists: any = [];
        // if (this.props._jObject.bio !== undefined) {
        //   for (let i = 0; i < this.props._jObject.bio.length; i++) {
        //     let _bio = this.props._jObject.bio[i];
        //     let _items: any;
        //     for (let j = 0; cntxmenuitems.length; j++) {
        //       let _cxt = cntxmenuitems[j];
        //       _items =
        //         _cxt.items !== undefined
        //           ? _cxt.items.filter((x: any) => x.id == _bio.account.id)
        //           : [];

        //       if (_items.length > 0) {
        //         _exists.push(_cxt);
        //         break;
        //       }
        //     }
        //   }

        //   let _unq = cntxmenuitems
        //     .filter((x: any) => !_exists.includes(x))
        //     .concat(_exists.filter((x: any) => !cntxmenuitems!.includes(x)));

        //   for (let i = 0; i < _unq.length; i++) {
        //     //_unq[i].items = [];
        //     this.cntxitems[0].items?.splice(2, 0, _unq[i]);
        //   }
        // } else {
        //   for (let i = 0; i < cntxmenuitems.length; i++) {
        //     //cntxmenuitems[i].items = [];
        //     this.cntxitems[0].items?.splice(2, 0, cntxmenuitems[i]);
        //   }
        // }

        // this.cntxitems[0].text = Common.toTitleCase(
        //   (tab as HTMLElement).innerText
        // );

        // this.cntxitems[0].items?.shift();
        // this.cntxitems[0].items?.shift();

        // _mitems = this.cntxitems;

        // this.setMenuItems(_mitems, true);
        //}
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'bio' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          '_accordion'
      ) {
        let _mitem: any = this.user.commondata.filter(
          (g: any) => g.itemType == this._structure.CommonDataItems.IdentityType
        );

        let itemExists: MenuItemModel[] = [];
        for (let i = 0; i < _mitem.length; i++) {
          if (this.props._jObject.documents) {
            if (
              this.props._jObject.documents.filter(
                (d: any) => d.commonDataId == _mitem[i].id
              ).length > 0
            ) {
              itemExists.push({ id: _mitem[i].id, text: _mitem[i].itemValue });
            } else {
              cntxmenuitems.push({
                id: _mitem[i].id,
                text: _mitem[i].itemValue,
              });
            }
          } else {
            cntxmenuitems.push({ id: _mitem[i].id, text: _mitem[i].itemValue });
          }
        }

        for (let i = 0; i < cntxmenuitems.length; i++) {
          this.cntxitems[0].items?.splice(2, 0, cntxmenuitems[i]);
        }

        let _delidx = this.cntxitems.indexOf(
          this.cntxitems.filter((x) => x.text?.toLowerCase() == 'delete')[0]
        );
        if (_delidx == -1) {
          _delidx = 0;
        }

        let _delete: any = this.cntxitems.filter(
          (x: any) => x.text.toLowerCase() === 'delete'
        );

        _delete[0].items = itemExists;

        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);

        // let _objectId = Common.FindJsonByObjectValue(
        //   this._fulljson,
        //   '_document_',
        //   this.user
        // );
        // if (_objectId !== null) {
        //   let menu = Common.FindJsonByObjectId(
        //     this._fulljson,
        //     _objectId.object,
        //     this.user
        //   );

        //   let cntxmenuitems: MenuItemModel[] | undefined =
        //     Common.GetContextMenu(
        //       (menu as any).children,
        //       this._structure,
        //       this.user
        //     );

        //   for (let i = 0; i < cntxmenuitems.length; i++) {
        //     this.cntxitems[0].items?.splice(2, 0, cntxmenuitems[i]);
        //   }
        //   _mitems = this.cntxitems;

        //   this.setMenuItems(_mitems, true);
        // }
      } else if (
        ((tab as HTMLElement).innerText.toLowerCase() === 'contact' ||
          (tab as HTMLElement).innerText.toLowerCase() === 'reference' ||
          (tab as HTMLElement).innerText.toLowerCase() === 'address') &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() === 'ecard'
      ) {
        this.iAddrMainCardid = Number((_elem as HTMLElement).dataset.crd);
        this.iAddrSubCardid = Number(
          (_elem as HTMLElement)?.querySelectorAll('[data-subcrd]')
            ? (
                (_elem as HTMLElement)?.querySelectorAll(
                  '[data-subcrd]'
                )[0] as HTMLElement
              ).dataset.subcrd
            : 0
        );

        //   Common.FindJsonByObjectId(
        //   this._fulljson,
        //   this._structure.Constants.AddressItems,
        //   this.user
        // );

        let _eelm = (_elem as HTMLElement)?.querySelectorAll('[data-item]');
        let _eitems = Object.values(_eelm)
          .map((x: any) => x.dataset.item)
          .filter((y, i, a) => !y.startsWith('_') && a.indexOf(y) == i);

        let itemvalues = Object.values(this._structure.AddressItems);
        let cntxmenuitems: MenuItemModel[] | undefined = [];
        for (let i = 0; i < itemvalues.length; i++) {
          let _aitem = Object.keys(this._structure.AddressItems).find(
            (x: any) => this._structure.AddressItems[x] === itemvalues[i]
          );
          cntxmenuitems.push({
            id: itemvalues[i] as any,
            text: _aitem,
          });
        }

        for (let m = 0; m < cntxmenuitems.length; m++) {
          switch (Number(cntxmenuitems[m].id)) {
            case this._structure.AddressItems.Mobile:
              cntxmenuitems[m].iconCss = 'fa fa-mobile';
              break;
            case this._structure.AddressItems.Telephone:
              cntxmenuitems[m].iconCss = 'fa fa-phone';
              break;
            case this._structure.AddressItems.Email:
              cntxmenuitems[m].iconCss = 'fa fa-at';
              break;
            case this._structure.AddressItems.Position:
              cntxmenuitems[m].iconCss = 'fa fa-landmark-dome';
              break;
            case this._structure.AddressItems.Domicile:
              cntxmenuitems[m].iconCss = 'fa fa-house';
              break;
            case this._structure.AddressItems.Location:
              cntxmenuitems[m].iconCss = 'fa fa-location-dot';
              break;
            case this._structure.AddressItems.PoBox:
              cntxmenuitems[m].iconCss = 'fa fa-envelope';
              break;
            case this._structure.AddressItems.City:
              cntxmenuitems[m].iconCss = 'fa fa-city';
              break;
            case this._structure.AddressItems.Fax:
              cntxmenuitems[m].iconCss = 'fa fa-fax';
              break;
            case this._structure.AddressItems.Website:
              cntxmenuitems[m].iconCss = 'fab fa-internet-explorer';
              break;
          }
        }

        // let _unq = cntxmenuitems.filter((x: any) => !_eitems.includes(x.text));

        let _unq = cntxmenuitems;

        for (let i = 0; i < _unq.length; i++) {
          this.cntxitems[0].items?.splice(2, 0, _unq[i]);
        }
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );

        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'contact' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'composer'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);

        // let _objectId = Common.FindJsonByObjectValue(
        //   this._fulljson,
        //   '_relation_',
        //   this.user
        // );
        // if (_objectId !== null) {
        //   let relations = Common.FindJsonByObjectId(
        //     this._fulljson,
        //     _objectId.object,
        //     this.user
        //   );
        //   this.iRelations = relations.children.filter(
        //     (x: any) => x.field == this.user.language
        //   );
        // }

        this.iRelations = this.user.commondata.filter(
          (x: any) => x.itemType == this._structure.CommonDataItems.Relation
        );
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'experience' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() === 'ecard'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'experience' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'composer'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'education' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() === 'ecard'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'education' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'composer'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'reference' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'composer'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'address' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          'composer'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else if (
        (tab as HTMLElement).innerText.toLowerCase() === 'contracts' &&
        (_elem as HTMLElement)?.dataset.contextmenuid?.toLowerCase() ===
          '_contract_'
      ) {
        this.cntxitems[0].text = Common.toTitleCase(
          (tab as HTMLElement).innerText
        );
        _mitems = this.cntxitems;

        this.setMenuItems(_mitems, true);
      } else {
        e.cancel = true;
        return;
      }

      // if (acrd_header) {
      //   _mitems.splice(
      //     0,
      //     _mitems.indexOf(_mitems.filter((x: any) => x.text == 'Delete')[0])
      //   );
      // }

      // this.setState({
      //   _menuitems: _mitems,
      //   isvalidtab: _isvalidtab,
      //   menudatacntxid: _menudatacntxid,
      // });
    }
  };

  handleMenuClick = (e: any) => {
    // if (parseInt(e.element.id) === 101) {
    //   if (
    //     this.state.isvalidtab === 'bio' ||
    //     this.state.isvalidtab === 'education'
    //   ) {
    //     switch (this.state.isvalidtab) {
    //       case 'bio':
    //         this._dlgHeader = 'New Document';
    //         break;
    //       case 'education':
    //         this._dlgHeader = 'New Educaton';
    //         break;
    //       default:
    //         this._dlgHeader = 'New Document';
    //         break;
    //     }
    //     this.setState({ showDocDialog: true });
    //   } else if (this.state.isvalidtab === 'contracts') {
    //     this.SaveContract();
    //   }
    // } else if (parseInt(e.element.id) === 200) {
    //   if (this.state.isvalidtab === 'contracts') {
    //     this.DeleteContract();
    //   }
    // } else {
    //   if (
    //     parseInt(e.element.id) !== 100 &&
    //     e.item.properties.items.length <= 0
    //   ) {
    //     if (
    //       this.state.isvalidtab === 'bio' &&
    //       this.state.menudatacntxid === '_accordion'
    //     ) {
    //       this.AddAccordion(e.element.id, e.element.textContent);
    //     } else if (
    //       this.state.isvalidtab === 'bio' &&
    //       this.state.menudatacntxid === 'bio-card'
    //     ) {
    //       let x = Common.FindParentJsonByIdKeyChildren(
    //         this._fulljson,
    //         e.element.id,
    //         'object',
    //         'children',
    //         null
    //       );
    //       if (x) {
    //         this.SaveBioInfo(e.element.id, e.element.textContent, x);
    //       }
    //     }
    //   }
    // }

    if (parseInt(e.element.id) === 101) {
      if (
        this.iValidTab === 'bio' ||
        this.iValidTab === 'reference' ||
        this.iValidTab === 'contact' ||
        this.iValidTab === 'address' ||
        this.iValidTab === 'contracts'
      ) {
        switch (this.iValidTab) {
          case 'bio':
            //this._dlgHeader = 'New Document';
            this._dlgHeader = 'Manage Document';
            break;
          case 'reference':
            this._dlgHeader = 'New Reference';
            break;
          case 'contact':
            this._dlgHeader = 'New Contact';
            break;
          case 'address':
            this._dlgHeader = 'New Address';
            break;
          case 'contracts':
            this._dlgHeader = 'New Contract';
            break;
          default:
            this._dlgHeader = 'New Document';
            break;
        }
        this.setState({ showDocDialog: true });
      } else if (this.iValidTab === 'contracts') {
        //this.SaveContract();
      }
    } else if (parseInt(e.element.id) === 200) {
      if (this.iValidTab === 'contracts') {
        if (this.GridInstance.GridInstance.getSelectedRows().length > 0) {
          // if (
          //   new Date(
          //     this.GridInstance.GridInstance.dataSource[
          //       this.GridInstance.GridInstance.selectedRowIndex
          //     ].dateStart
          //   ).getTime() <
          //   new Date((this.state.openperiod as any).startDate).getTime()
          // ) {
          //  DialogConfirm.showDialog({
          //    content:
          //      '<p class="dialog-contain">Sorry, You cannot delete the selected contract as period is already closed.</p>',
          //  });

          //   return;
          // }

          if (
            new Date((this.state.contractdetail as any).dateStart).getTime() <
            new Date((this.state.openperiod as any).startDate).getTime()
          ) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, You cannot delete the selected contract as period is already closed.</p>',
            });

            return;
          }

          DialogConfirm.showDialog({
            isConfirm: true,
            showCloseIcon: true,
            closeOnEscape: true,
            content:
              '<p class="dialog-contain">Are you sure you want to delete the selected contract?</p>',
            okCaption: 'Yes',
            closeCaption: 'No',
            CloseEvent: this.confirmNoClick,
            OkEvent: this.DeleteContract.bind(this),
          });
        }
      }
    } else {
      if (parseInt(e.element.id) !== 100) {
        if (this.iValidTab === 'bio' && this.iDatacntxid === '_accordion') {
          let menut = this.cMenu!.items.filter((x: any) =>
            x.items.some((y: any) => y.id == e.element.id)
          );
          let _id: any;
          if (menut && menut.length > 0) {
            _id = menut[0].id;
          }
          if (_id && parseInt(_id) == 200) {
            this.VoidAccordion(e.element.id);
          } else {
            this.AddAccordion(e.element.id, e.element.textContent);
          }
        } else if (
          this.iValidTab === 'bio' &&
          this.iDatacntxid === 'bio-card'
        ) {
          let menut = this.cMenu!.items.filter((x: any) =>
            x.items.some((y: any) => y.id == parseInt(e.element.id))
          );
          let _id: any;
          if (menut && menut.length > 0) {
            _id = menut[0].id;
          }
          if (_id && parseInt(_id) == 200) {
            this.VoidBioInfo(parseInt(e.element.id));
          } else {
            this.SaveBioInfo(e.element.id, e.element.textContent);
          }
        } else if (
          (this.iValidTab === 'contact' ||
            this.iValidTab === 'address' ||
            this.iValidTab === 'reference') &&
          this.iDatacntxid === 'ecard'
        ) {
          let _mcard: any;
          switch (this.iValidTab) {
            case 'contact':
              _mcard = this.props._jObject.addresses
                .filter((x: any) => x.type == 'Emergency')[0]
                .contacts.filter((x: any) => x.id === this.iAddrMainCardid);
              // _mcard = this.props._jObject.addresses.filter(
              //   (x: any) => x.id == this.iAddrMainCardid
              // );
              break;
            case 'address':
              _mcard = this.props._jObject.addresses
                .filter((x: any) => x.type == 'Personal')[0]
                .contacts.filter((x: any) => x.id === this.iAddrMainCardid);
              // _mcard = this.props._jObject.addresses.filter(
              //   (x: any) => x.id == this.iAddrMainCardid
              // );
              break;
            case 'reference':
              _mcard = this.props._jObject.addresses
                .filter((x: any) => x.type == 'Reference')[0]
                .contacts.filter((x: any) => x.id === this.iAddrMainCardid);
              // _mcard = this.props._jObject.addresses.filter(
              //   (x: any) => x.id == this.iAddrMainCardid
              // );
              break;
          }

          if (_mcard) {
            let _subcard = _mcard[0].cards.filter(
              (x: any) => x.id == this.iAddrSubCardid
            );
            if (_subcard) {
              if (_subcard[0].items == null) {
                _subcard[0].items = [];
              }
              var arrObjIds = _subcard[0].items.map((ea: any) => {
                return ea.id;
              });
              let maxid = arrObjIds.length > 0 ? Math.max(...arrObjIds) : 0;

              let itemval: any = {
                id: maxid + 1,
                itemType: e.element.textContent,
                itemValue: '',
              };
              _subcard[0].items.push(itemval);
              this.setState({ _addNew: true });
            }
          }
        }
      }
    }
    // this.iDatacntxid = '';
    // this.iValidTab = '';
  };

  public VoidAccordion(_id: any) {
    this._voidguid = this.props._jObject.documents.filter(
      (x: any) => x.commonDataId == _id
    )[0].id;
    if (this._voidguid) {
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove this assigned document?</p></div>',
        okCaption: 'Cancel',
        OkEvent: undefined,
        // btncssClass: 'flat-button',
        closeCaption: 'Accept',
        CloseEvent: this.confirmVoidAccordion.bind(this),
      });
    }
  }

  public VoidBioInfo(_id: any) {
    this._voidguid = this.props._jObject.bio.filter(
      (x: any) => x.commonData.itemType == _id
    )[0].id;

    if (this._voidguid) {
      // console.log('first', this._voidguid);

      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Are you sure you want to remove this assigned item?</p>',
      // });
      DialogConfirm.showDialog({
        isConfirm: true,
        // onPromptInput: (value) => {
        //   console.log('Prompt input value:', value);
        // },
        content:
          '<p class="dialog-contain">Are you sure you want to remove this assigned item?</p>',
        okCaption: 'Accept',
        closeCaption: 'Cancel',
        // btncssClass: 'flat-button',
        OkEvent: this.confirmAction.bind(this),
      });
    }
  }

  confirmVoidAccordion = () => {
    if (this._voidguid) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/persons/void_document${
          !this.user.isManager ? '/ess' : ''
        }`,
        { id: this._voidguid, personId: this.props._jObject.id },
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            let data = this.props._jObject.documents;
            data = (data as any).filter((x: any) => x.id !== this._voidguid);
            if (data.length > 0) {
              this.props._jObject.documents = [];
              data.map((d: any) => {
                this.props._jObject.documents.push(d);
              });
            } else {
              this.props._jObject.documents = [];
            }
            if (
              this.user.person &&
              this.user.person.id === this.props._jObject.id
            ) {
              this.user.person = this.props._jObject;
              Common.setItem('user', this.user);
            }

            this.setState({ addnew: true });
          }
          this.dialogObj.hide();
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  confirmAction = () => {
    // console.log('first confirmation', this._voidguid);
    if (this._voidguid) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/personbioiitems/delete${
          !this.user.isManager ? '/ess' : ''
        }`,
        { id: this._voidguid, personId: this.props._jObject.id },
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            let data = this.props._jObject.bio;
            data = (data as any).filter((x: any) => x.id !== this._voidguid);
            this.props._jObject.bio = [];
            data.map((d: any) => {
              this.props._jObject.bio.push(d);
            });
            this.setState({ addnew: true });
            if (
              this.user.person &&
              this.user.person.id === this.props._jObject.id
            ) {
              this.user.person = this.props._jObject;
              Common.setItem('user', this.user);
            }
          }
          // this.dialogObj.hide();
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  public SaveBioInfo(_selc_id: any, _selc_name: any) {
    let resp: any;
    let payload: any = {
      PersonsId: this.props._jObject.id,
      CommonDataId: _selc_id,
      // person: { id: this.props._jObject.id },
      // account: {
      //   id: parseInt(_selc_id),
      //   account: {
      //     id: parseInt(_selc_prid.object),
      //   },
      // },
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/personbioiitems/add`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        resp = resopnse.data;

        if (this.props._jObject.hasOwnProperty('bio') == false) {
          let _doc: any = [];
          this.props._jObject['bio'] = _doc;
        }
        let exist = this.props._jObject['bio'].filter(
          (x: any) => x.commonDataId == _selc_id
        );
        if (exist.length <= 0) {
          this.props._jObject['bio'].push(resp);
        }

        if (
          this.user.person &&
          this.user.person.id === this.props._jObject.id
        ) {
          this.user.person = this.props._jObject;
          Common.setItem('user', this.user);
        }

        this.setState({ _addNew: true });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public SaveContract(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/add_contract`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.props._jObject['contract'] = response['contract'];
        this.props._jObject['dossier'] = response['dossier'];
        this.props._jObject['isActive'] = response['isActive'];
        this.GridInstance.refreshData();
        this.props.refreshTabs(true);
        if (
          this.user.employee &&
          this.user.employee.id === this.props._jObject.id
        ) {
          this.user.employee = this.props._jObject;
          Common.setItem('user', this.user);
        }
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          DialogConfirm.showDialog({
            content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
          });
        }
      })
      .finally(() => {});
  }
  public confirmNoClick() {}
  public DeleteContract() {
    if (this.GridInstance.GridInstance.getSelectedRows().length > 0) {
      let contractid: string = this.GridInstance.GridInstance.getRowInfo(
        this.GridInstance.GridInstance.getSelectedRows()[0]
      ).rowData.id;
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/employee/voidempcontract`,
        {
          id: contractid,
          employeeId: this.GridInstance.GridInstance.getRowInfo(
            this.GridInstance.GridInstance.getSelectedRows()[0]
          ).rowData.employeeId,
        },
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            this.props._jObject['contract'] = this.props._jObject[
              'contract'
            ].filter((x: any) => x.id !== contractid);
            this.props._jObject['dossier'] = this.props._jObject[
              'dossier'
            ].filter((x: any) => x.detailTranId !== contractid);
            this.GridInstance.refreshData();
            this.props.refreshTabs(true);
            if (
              this.user.employee &&
              this.user.employee.id === this.props._jObject.id
            ) {
              this.user.employee = this.props._jObject;
              Common.setItem('user', this.user);
            }
            // let cnt: any = this.props._jObject['contract'].filter(
            //   (x: any) => x.id === contractid
            // );
            // if (cnt.length > 0) {
            //   this.props._jObject['contract'].splice(
            //     this.props._jObject['contract'].indexOf(cnt[0]),
            //     1
            //   );
            //   this.props.refreshTabs(true);
            // }
          }
        })
        .catch((error: any) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            DialogConfirm.showDialog({
              content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
            });
          }
        })
        .finally(() => {});
    }
  }

  public AddAddress(
    section: any,
    _addressid: any,
    effdate: Date,
    contactid: any,
    nametype: any,
    relatit: any
  ) {
    let clone: boolean = false;
    if (_addressid != undefined && contactid != undefined) {
      clone = true;
    }
    let payload: any;
    switch (section) {
      case this._structure.AddressSectons.Personal:
        payload = {
          id: _addressid ? _addressid : null,
          cloneCard: clone,
          personsId: this.props._jObject.id,
          type: 'Personal',
          contacts: [
            {
              id: contactid ? contactid : 0,
              value: nametype,
              cards: [
                {
                  id: this.iAddrSubCardid,
                  date: effdate,
                },
              ],
            },
          ],
        };
        break;
      case this._structure.AddressSectons.Emergency:
        payload = {
          id: _addressid ? _addressid : null,
          cloneCard: clone,
          personsId: this.props._jObject.id,
          type: 'Emergency',
          contacts: [
            {
              id: contactid ? contactid : 0,
              value: nametype,
              commonDataId: relatit,
              cards: [
                {
                  id: this.iAddrSubCardid,
                  date: effdate,
                },
              ],
            },
          ],
        };
        break;
      case this._structure.AddressSectons.Reference:
        payload = {
          id: _addressid ? _addressid : null,
          cloneCard: clone,
          personsId: this.props._jObject.id,
          type: 'Reference',
          contacts: [
            {
              id: contactid ? contactid : 0,
              value: nametype,
              title: relatit,
              cards: [
                {
                  id: this.iAddrSubCardid,
                  date: effdate,
                },
              ],
            },
          ],
        };
        break;
    }
    if (payload != undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/address/add`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((resopnse: any) => {
          if (this.props._jObject.addresses.length <= 0) {
            this.props._jObject.addresses.push(resopnse.data);
          } else {
            const index = this.props._jObject.addresses.indexOf(
              this.props._jObject.addresses.filter(
                (x: any) => x.type == resopnse.data.type
              )[0]
            );
            if (index > -1) {
              this.props._jObject.addresses.splice(index, 1);
            }
            this.props._jObject.addresses.push(resopnse.data);
          }
          if (
            this.user.person &&
            this.user.person.id === this.props._jObject.id
          ) {
            this.user.person = this.props._jObject;
            Common.setItem('user', this.user);
          }

          this.setState({ _addNew: true });
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  public SaveAddress(
    address_section: any,
    name: any,
    relation: any,
    effdate: Date,
    _clone?: boolean,
    _addrItem?: any,
    _fldvalue?: any,
    _cloncrdid?: Number
  ) {
    //Object.entries(this._structure.AddressItems).filter((x) => x[1] == this._structure.AddressItems.Domicile)[0][0];
    // let payload: any = {
    //   MainTableId: this._structure.Cluster.Identity.Person,
    //   FieldId: this._structure.Field.Identif.Address,
    //   AddressSection: address_section,
    //   AddressItem: _addrItem,
    //   AddressEmRefNameCol: 'pname',
    //   AddressKinshipCol: 'title',
    //   AddressTitleCol: 'title',
    //   AsOnDateColumn: 'asondate',
    //   DocumentType: 'XML',
    //   MainTableOldIdColumn: 'personid',
    //   TypeColumn: '',
    //   FieldColumn: 'fldcolval',
    //   Data: [
    //     {
    //       personid: this.props._jObject.id,
    //       pname: name,
    //       title: relation,
    //       asondate: Common.formatDate(effdate, 'yyyy-MM-dd'),
    //       fldcolval: _fldvalue !== '' ? _fldvalue : 'Demo',
    //       clone: _clone ? _clone : false,
    //       subcrdid: _cloncrdid ? _cloncrdid : 0,
    //     },
    //   ],
    // };
    // Common.ApiCallAsync(
    //   'POST',
    //   `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Person/Address/Set`,
    //   payload,
    //   Common.getToken() || '',
    //   this.user
    // )
    //   .then((resopnse: any) => {
    //     if (_clone == true) {
    //       let clonedata: any;
    //       let _maxid: any;
    //       switch (this.iValidTab) {
    //         case 'address':
    //           clonedata = JSON.parse(
    //             JSON.stringify(
    //               this.props._jObject.address[0].cards.filter(
    //                 (x: any) => x.id == _cloncrdid
    //               )[0]
    //             )
    //           );
    //           var arrObjIds = this.props._jObject.address[0].cards.map(
    //             (ea: any) => {
    //               return ea.id;
    //             }
    //           );
    //           _maxid = arrObjIds.length > 0 ? Math.max(...arrObjIds) : 0;
    //           clonedata.id = _maxid + 1;
    //           clonedata.date = Common.formatDate(effdate, 'yyyy-MM-dd');
    //           this.props._jObject.address[0].cards.push(clonedata);
    //           break;
    //         case 'contact':
    //           clonedata = JSON.parse(
    //             JSON.stringify(
    //               this.props._jObject.contact[0].cards.filter(
    //                 (x: any) => x.id == _cloncrdid
    //               )[0]
    //             )
    //           );
    //           var arrObjIds = this.props._jObject.contact[0].cards.map(
    //             (ea: any) => {
    //               return ea.id;
    //             }
    //           );
    //           _maxid = arrObjIds.length > 0 ? Math.max(...arrObjIds) : 0;
    //           clonedata.id = _maxid + 1;
    //           clonedata.date = Common.formatDate(effdate, 'yyyy-MM-dd');
    //           this.props._jObject.contact[0].cards.push(clonedata);
    //           break;
    //         case 'reference':
    //           clonedata = JSON.parse(
    //             JSON.stringify(
    //               this.props._jObject.reference[0].cards.filter(
    //                 (x: any) => x.id == _cloncrdid
    //               )[0]
    //             )
    //           );
    //           var arrObjIds = this.props._jObject.reference[0].cards.map(
    //             (ea: any) => {
    //               return ea.id;
    //             }
    //           );
    //           _maxid = arrObjIds.length > 0 ? Math.max(...arrObjIds) : 0;
    //           clonedata.id = _maxid + 1;
    //           clonedata.date = Common.formatDate(effdate, 'yyyy-MM-dd');
    //           this.props._jObject.reference[0].cards.push(clonedata);
    //           break;
    //       }
    //     }
    //     this.setState({ _addNew: true });
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   })
    //   .finally(() => { });
  }

  private onradiochanged(e: any) {
    let payload: any;
    if (
      e.target.id.toLowerCase() === 'radresident' ||
      e.target.id.toLowerCase() === 'radnonresident'
    ) {
      payload = {
        id: this.props._jObject.id,
        residentialStatus: e.value,
        personId: this.props._jObject.personId,
      };
    } else if (
      e.target.id.toLowerCase() === 'radprimary' ||
      e.target.id.toLowerCase() === 'radsecondory' ||
      e.target.id.toLowerCase() === 'raddirectorsfee'
    ) {
      payload = {
        id: this.props._jObject.id,
        typeOfEmployment: e.value,
        personId: this.props._jObject.personId,
      };
    } else if (
      e.target.id.toLowerCase() === 'radzanzibar' ||
      e.target.id.toLowerCase() === 'radmainland'
    ) {
      payload = {
        id: this.props._jObject.id,
        location: e.value,
        personId: this.props._jObject.personId,
      };
    }
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/add`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (this.props._jObject) {
          if (
            this.props._jObject.typeOfEmployment !== response.typeOfEmployment
          ) {
            this.props._jObject.typeOfEmployment = response.typeOfEmployment;
            this.props._jObject.typeOfEmploymentName =
              response.typeOfEmploymentName;
          }

          if (
            this.props._jObject.residentialStatus !== response.residentialStatus
          ) {
            this.props._jObject.residentialStatus = response.residentialStatus;
            this.props._jObject.residentialStatusName =
              response.residentialStatusName;
          }

          if (this.props._jObject.location !== response.location) {
            this.props._jObject.location = response.location;
            this.props._jObject.locationName = response.locationName;
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SANDEEP - 2023-03-27 - 310 -- Working on composer for genrating line and accordian (Reading & Understanding form godwin's project)
  // * SANDEEP - 2023-03-28 - 100 -- Working on composer for genrating accordian
  // * SANDEEP - 2023-04-11 - 300 -- Working on Address XML
  // * SANDEEP - 2023-04-12 - 300 -- Working on Address XML & Data Display and properties
  // * SANDEEP - 2023-04-13 - 90 -- Working on displaying the data
  // * SANDEEP - 2023-05-04 - 200 - Working on Address List XML to check if it's workable or not.
  // * SANDEEP - 2023-05-05 - 360 - Worked on Some changes in xml and tried to read data.
  // * SANDEEP - 2023-05-08 - 300 - Trying to display data with minimum details in xml specially for array.
  // * SANDEEP - 2023-07-10 - 420 -- Display Of Selected Data from Grid to Card Along with Accordion.
  // * SANDEEP - 2023-07-11 - 420 -- Display Of Selected Data from Grid to DataGrid.
  // * SANDEEP - 2023-07-13 - 120 -- Worked on Spreadsheet component displaye the data along with css changes.
  // * SANDEEP - 2023-07-14 - 425 -- Worked on Spreadsheet component displaye the data along with css changes.

  private async GetAbsences() {
    var payLoad: any = {
      employee: this.props._jObject,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/Absences`,
      payLoad,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ _absences: resopnse.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetContract() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search${
        !this.user.isManager ? '/ess' : ''
      }`,
      {
        id: this.props._jObject.id,
        showLatestContractOnly: true,
        showOnlyActive: false,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let cntrct: any = null;
        if (
          response.data.length > 0 &&
          response.data[0]['contract'] &&
          response.data[0]['contract'].length > 0
        ) {
          cntrct = response.data[0]['contract'][0];
        }
        this.setState({
          lastcontract: cntrct,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetJob() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listjob`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({
          jobfields: {
            dataSource: response.data.filter((x: any) => x.jobId === null),
            value: 'id',
            text: 'name',
            child: 'subJob',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetBranch() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listbranch`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({
          branchfields: {
            dataSource: response.data.filter((x: any) => x.branchId === null),
            value: 'id',
            text: 'name',
            child: 'subBranchs',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetDepartment() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listdepartment`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({
          deptfields: {
            dataSource: response.data.filter(
              (x: any) => x.departmentId === null
            ),
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private setMenuItems(menuitems: any, remlastitem: boolean) {
    this.iMenuItems = menuitems;

    let s: any = this.cMenu?.items.map((x: any) => {
      return x.text;
    });
    if (s && s.length > 0) {
      s = s.slice(1);
      this.cMenu?.removeItems(s);
    }
    this.cMenu!.items[0].text = 'tempitem1';
    this.cMenu!.insertBefore(this.iMenuItems as MenuItemModel[], 'tempitem1');
    this.cMenu!.removeItems(['tempitem1']);
    if (remlastitem) {
      let mitm = this.cMenu!.items[this.cMenu!.items.length - 1];
      if (mitm.separator == true) {
        mitm.text = '_dele';
        this.cMenu!.removeItems(['_dele']);
      }
    }
  }

  private rgbToHex(value: string): string {
    var color: string;
    color = '';
    if (value.split(',').length > 1) {
      var r = value.split(',')[0] as unknown as number;
      var g = value.split(',')[1] as unknown as number;
      var b = value.split(',')[2] as unknown as number;
      color =
        '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
    } else {
      color = value;
    }
    return color;
  }

  // private ParseXML(value: string) {
  //     var XMLParser = require('react-xml-parser');
  //     var xml = new XMLParser().parseFromString(value);
  //     return xml;
  // }

  private process_Margin(_value: string) {
    var _margin = '';
    if (_value.split(',').length > 1) {
      var _mval = _value.split(',');
      for (let i = 0; i < _mval.length; i++) {
        _margin = _margin + _mval[i] + 'px ';
      }
    } else {
      _margin = _value + 'px ';
    }
    return _margin;
  }

  private process_MergeAll(item: any, xml: any) {
    var _parent = xml.getElementsByTagName('Grid')[0];
    var _mstyle: any = { attributes: {} };
    if (_parent.attributes.mtop !== undefined) {
      _mstyle.attributes['marginTop'] =
        _parent.attributes.mtop !== undefined
          ? _parent.attributes.mtop.toString() + 'px'
          : '25px';
    }
    _mstyle.attributes['display'] = 'flex';
    //_mstyle.attributes['overflow'] = 'auto';
    _mstyle.attributes['flexFlow'] = 'wrap'; //this.updateflexflow; //'row'

    //_mstyle.attributes['height'] = '100%';

    //_mstyle.attributes['flex'] = '50%';
    //_mstyle.attributes['maxWidth'] = '100%';

    return (
      <>
        {/* <div id='cdiv' style={_mstyle.attributes}> */}
        <div
          id='cdiv'
          style={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <div style={_mstyle.attributes}>{item}</div>
        </div>
      </>
    );
  }

  private generateTaxInfo() {
    let content: any;
    content = (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2' style={{ display: 'flex' }}>
            <div className='col-2 py-2'>
              <label style={{ fontSize: '13px' }}>Residential Status</label>
            </div>
            <div className='col-1 py-2'>
              <RadioButtonComponent
                id='radResident'
                ref={(c: any) => (this.radRE = c)}
                label='Resident'
                name='radrs'
                value={this._structure.Residential_Status.Resident.id}
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.residentialStatus ==
                  this._structure.Residential_Status.Resident.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
            <div className='col-3 py-2'>
              <RadioButtonComponent
                id='radNonResident'
                ref={(c: any) => (this.radNR = c)}
                label='Non Resident'
                name='radrs'
                value={this._structure.Residential_Status.NonResident.id}
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.residentialStatus ==
                  this._structure.Residential_Status.NonResident.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
          </div>
          <div className='col-12 py-2' style={{ display: 'flex' }}>
            <div className='col-2 py-2'>
              <label style={{ fontSize: '13px' }}>Tax Type</label>
            </div>
            <div className='col-1 py-2'>
              <RadioButtonComponent
                id='radPrimary'
                ref={(c: any) => (this.radPR = c)}
                label='Primary'
                name='radtaxtype'
                value={this._structure.Type_Of_Employment.Primary.id}
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.typeOfEmployment ==
                  this._structure.Type_Of_Employment.Primary.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
            <div className='col-1 py-2'>
              <RadioButtonComponent
                id='radSecondory'
                ref={(c: any) => (this.radSE = c)}
                label='Secondary'
                name='radtaxtype'
                value={this._structure.Type_Of_Employment.Secondary.id}
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.typeOfEmployment ==
                  this._structure.Type_Of_Employment.Secondary.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
            <div className='col-3 py-2'>
              <RadioButtonComponent
                id='radDirectorsFee'
                ref={(c: any) => (this.radDF = c)}
                label='Directors Fees'
                name='radtaxtype'
                value={this._structure.Type_Of_Employment.DirectorsFees.id}
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.typeOfEmployment ==
                  this._structure.Type_Of_Employment.DirectorsFees.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
          </div>
          <div className='col-12 py-2' style={{ display: 'flex' }}>
            <div className='col-2 py-2'>
              <label style={{ fontSize: '13px' }}>Location</label>
            </div>
            <div className='col-1 py-2'>
              <RadioButtonComponent
                id='radZanzibar'
                ref={(c: any) => (this.radZB = c)}
                label='Zanzibar'
                name='radlocation'
                value={this._structure.Location.Zanzibar.id}
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.location ==
                  this._structure.Location.Zanzibar.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
            <div className='col-3 py-2'>
              <RadioButtonComponent
                id='radMainLand'
                ref={(c: any) => (this.radTM = c)}
                label='Tanzania Mainland'
                value={this._structure.Location.Mainland.id}
                name='radlocation'
                onChange={this.onradiochanged.bind(this)}
                checked={
                  this.props._jObject.location ==
                  this._structure.Location.Mainland.id
                    ? true
                    : false
                }
              ></RadioButtonComponent>
            </div>
          </div>
        </div>
      </>
    );

    return content;
  }

  //TODO -- TO BE REMOVED AFTER TESTING --- START
  private ProcessAddress(xml: any) {
    var items: any;
    var _elements: any;
    for (
      let i = 0;
      i < xml.getElementsByTagName('Grid')[0].children.length;
      i++
    ) {
      var element = xml.getElementsByTagName('Grid')[0].children[i];

      var _mstyle: any = { attributes: {} };

      if (element.attributes.source !== undefined) {
        var _card = xml.getElementsByTagName('card')[0];

        if (_card.attributes.margin !== undefined) {
          _mstyle.attributes['margin'] =
            _card.attributes.margin !== undefined
              ? this.process_Margin(_card.attributes.margin)
              : 'inherit';
        }
        // if (_card.attributes.flow !== undefined) {
        //   _mstyle.attributes['float'] =
        //     _card.attributes.flow !== undefined
        //       ? _card.attributes.flow.toString()
        //       : 'none';
        // }

        _mstyle.attributes['display'] = 'flex';
        _mstyle.attributes['flexDirection'] = 'column';

        if (
          this.props._jObject.addresses.length > 0 &&
          this.props._jObject.addresses.filter(
            (x: any) => x.type == xml.attributes.type
          ).length > 0 &&
          Array.isArray(
            this.props._jObject.addresses.filter(
              (x: any) => x.type == xml.attributes.type
            )[0][element.attributes.source]
          ) === true
        ) {
          let _addrid = this.props._jObject.addresses.filter(
            (x: any) => x.type == xml.attributes.type
          )[0];
          _elements = this.props._jObject.addresses
            .filter((x: any) => x.type == xml.attributes.type)[0]
            [element.attributes.source].map((c: any) => (
              <>
                <div key='Key0' className='col-5' style={_mstyle.attributes}>
                  <div
                    key={c.id}
                    className='e-card'
                    data-contextmenuid='ecard'
                    data-crd={c.id}
                  >
                    <XCard
                      _id={_addrid.id}
                      _element={_card}
                      _data={c}
                      _selectedtabname={this.props.selectedtabname}
                      _user={this.user}
                      _maincardid={c.id}
                      _persomempid={
                        this.props._jObject.id ? this.props._jObject.id : 0
                      }
                      _addressid={_addrid.id}
                    ></XCard>
                    {/* <XCard _element={element} _data={c.cards[0]}></XCard> */}
                  </div>
                </div>
              </>
            ));
        }
      }
    }
    items = _elements;
    return items;
  }
  //TODO -- TO BE REMOVED AFTER TESTING --- END

  private Process(xml: any, jsonObject?: any) {
    var items: any;
    let _jObject: any = jsonObject ?? this.props._jObject;
    items = xml.getElementsByTagName('Grid')[0].children.map((element: any) => {
      switch (element.name.toLowerCase()) {
        case 'card':
          var _elements: any;
          var _class = 'e-card';
          var _mstyle: any = { attributes: {} };
          if (element.attributes.margin !== undefined) {
            _mstyle.attributes['margin'] =
              element.attributes.margin !== undefined
                ? this.process_Margin(element.attributes.margin)
                : 'inherit';
          }
          // if (element.attributes.flow !== undefined) {
          //   _mstyle.attributes['float'] =
          //     element.attributes.flow !== undefined
          //       ? element.attributes.flow.toString()
          //       : 'none';
          // }
          if (element.attributes.width !== undefined) {
            _mstyle.attributes['width'] =
              element.attributes.width !== undefined
                ? element.attributes.width.toString() + 'px'
                : 'inherit';
          }
          // _mstyle.attributes['display'] = 'flex';
          // _mstyle.attributes['flexDirection'] = 'column';
          // _mstyle.attributes['justifyContent'] = 'flex-start';

          if (element.attributes.source !== undefined) {
            if (Array.isArray(_jObject[element.attributes.source]) === true) {
              _elements = _jObject[element.attributes.source].map((c: any) => (
                <>
                  <div
                    key='Key0'
                    // className={
                    //   element.attributes.col !== undefined
                    //     ? ' col-' + element.attributes.col.toString()
                    //     : 'col-3'
                    // }
                    style={_mstyle.attributes}
                  >
                    <div key={c.id} className={_class}>
                      <XCard
                        _element={element}
                        _data={c}
                        _selectedtabname={this.props.selectedtabname}
                        _user={this.user}
                      ></XCard>
                      {/* <XCard _element={element} _data={c.cards[0]}></XCard> */}
                    </div>
                  </div>
                </>
              ));
            } else {
              _elements = (
                <div
                  key='Key0'
                  // className={
                  //   element.attributes.col !== undefined
                  //     ? ' col-' + element.attributes.col.toString()
                  //     : 'col-3'
                  // }
                  style={_mstyle.attributes}
                >
                  <div className={_class} style={{ display: 'flex' }}>
                    <XCard
                      _element={element}
                      _data={_jObject[element.attributes.source]}
                      _selectedtabname={this.props.selectedtabname}
                      _user={this.user}
                    ></XCard>
                  </div>
                </div>
              );
            }
          } else {
            _elements = (
              <div
                data-contextmenuid='bio-card'
                key='Key0'
                // className={
                //   element.attributes.col !== undefined
                //     ? ' col-' + element.attributes.col.toString()
                //     : 'col-3'
                // }
                style={_mstyle.attributes}
              >
                <div className={_class}>
                  <XCard
                    _element={element}
                    _data={_jObject}
                    _selectedtabname={this.props.selectedtabname}
                    _user={this.user}
                  ></XCard>
                </div>
              </div>
            );
          }
          return _elements;
        case 'line':
          var _mstyle: any = { attributes: {} };
          if (element.attributes.mode !== undefined) {
            if (element.attributes.width !== undefined) {
              _mstyle.attributes['border-width'] =
                element.attributes.width !== undefined
                  ? element.attributes.width + 'px'
                  : 'fit-content';
            }
          }
          if (element.attributes.color !== undefined) {
            _mstyle.attributes['color'] =
              element.attributes.color !== undefined
                ? this.rgbToHex(element.attributes.color)
                : 'transparent';
          }
          if (element.attributes.style !== undefined) {
            _mstyle.attributes['borderStyle'] =
              element.attributes.style !== undefined
                ? element.attributes.style
                : 'solid';
          }
          if (element.attributes.height !== undefined) {
            _mstyle.attributes['height'] =
              element.attributes.height !== undefined
                ? element.attributes.height + 'px'
                : '200px';
          }
          // if (element.attributes.flow !== undefined) {
          //   _mstyle.attributes['float'] =
          //     element.attributes.flow !== undefined
          //       ? element.attributes.flow.toString()
          //       : 'none';
          // }
          if (element.attributes.margin !== undefined) {
            _mstyle.attributes['margin'] =
              element.attributes.margin !== undefined
                ? this.process_Margin(element.attributes.margin)
                : 'inherit';
          }
          return (
            //border-left: 6px solid green;
            <div key={element.name} style={_mstyle.attributes}></div>
          );
        case 'accordion':
          var _mstyle: any = { attributes: {} };
          if (element.attributes.mtop !== undefined) {
            _mstyle.attributes['marginTop'] =
              element.attributes.mtop !== undefined
                ? element.attributes.mtop.toString() + 'px'
                : '25px';
          }
          // if (element.attributes.flow !== undefined) {
          //   _mstyle.attributes['float'] =
          //     element.attributes.flow !== undefined
          //       ? element.attributes.flow.toString()
          //       : 'none';
          // }
          if (element.attributes.margin !== undefined) {
            _mstyle.attributes['margin'] =
              element.attributes.margin !== undefined
                ? this.process_Margin(element.attributes.margin)
                : 'inherit';
          }
          return (
            <>
              <div data-contextmenuid='_accordion' style={{ width: '62%' }}>
                <div
                  key={element.name}
                  style={_mstyle.attributes}
                  // className={
                  //   element.attributes.cols !== undefined
                  //     ? ' col-' + element.attributes.cols.toString()
                  //     : 'col-8'}
                >
                  <Accordion
                    key={Math.random()}
                    _data={_jObject}
                    _element={element}
                    _user={this.user}
                  ></Accordion>
                </div>
              </div>

              {/* <div className="e-addicon" title="New Document"><i className="fa fa-circle-plus" style={{ fontSize: '50px' }}></i></div> */}
              {/* style={{ float: "right", position: "relative", opacity: '10%', alignSelf: "flex-end" }} */}
            </>
          );
        default:
          return;
      }
    });
    return items;
  }

  private AddAccordion(_doctypeid: any, _doctypename: any) {
    let resp: any;
    // let payload: any = {
    //   id: this.props._jObject.id,
    //   account: {
    //     isDeduction: false,
    //     id: _doctypeid,
    //     name: _doctypename,
    //   },
    // };
    let payload: any = {
      personsId: this.props._jObject.id,
      commonDataId: _doctypeid,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/persons/add_document${
        !this.user.isManager ? '/ess' : ''
      }`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        resp = resopnse.data;

        if (this.props._jObject.hasOwnProperty('documents') == false) {
          let _doc: any = [];
          this.props._jObject['documents'] = _doc;
        }
        let exist = this.props._jObject['documents'].filter(
          (x: any) => x.commonDataId == _doctypeid
        );
        if (exist.length <= 0) {
          this.props._jObject['documents'].push(resp);
        }
        if (
          this.user.person &&
          this.user.person.id === this.props._jObject.id
        ) {
          this.user.person = this.props._jObject;
          Common.setItem('user', this.user);
        }

        // resp = {
        //   id: resopnse.data.id,
        //   name: '',
        //   account: {
        //     isDeduction: false,
        //     id: resopnse.data.Account.id,
        //     name: resopnse.data.Account.Name,
        //   },
        // };

        // if (this.props._jObject.hasOwnProperty('document') == false) {
        //   let _doc: any = [];
        //   this.props._jObject['document'] = _doc;
        // }
        // let exist = this.props._jObject['document'].filter(
        //   (x: any) => x.account.id == _doctypeid
        // );
        // if (exist.length <= 0) {
        //   this.props._jObject['document'].push(resp);
        // }

        this.setState({ _addNew: true });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetContractType() {
    return this.user.commondata.filter(
      (g: any) => g.itemType == this._structure.CommonDataItems.ContractType
    );
  }
  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-05-05 - 60
  dialogContent() {
    return (
      <DocumentViewer
        //data={JSON.stringify(this.state.docdata)}
        //data={this.state.docdata}
        id='wordeditor-composer'
        indialogbox={true}
        //jsonDataViewer={this.props._jObject}
        jsonDataViewer={this.state.jsonDocument}
        selectorService={
          this.props.selectorService
            ? this.props.selectorService
            : this._structure.SidebarConstants.SidebarService.Employee
        }
        selectedmenu={this.props.selectedmenu}
        hideTitlebar={true}
      />
    );
  }

  private IdentityactionBegin = (e: any) => {
    if (e.requestType === 'save') {
      if (e.data.itemValue == undefined) {
        e.cancel = true;
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, Document name is mandatory information.</p>',
        });
        return;
      } else if (e.previousData == undefined) {
        var payload: any = {
          itemType: this._structure.CommonDataItems.IdentityType,
          itemValue: e.data.itemValue,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/commondata/add`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((resopnse: any) => {
            if (this.user.commondata) {
              this.user.commondata.push({
                id: resopnse.data.id,
                itemType: this._structure.CommonDataItems.IdentityType,
                itemValue: resopnse.data.itemValue,
              });
              Common.setItem('user', this.user);
              this.user = JSON.parse(Common.getUser() ?? '');
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      } else if (
        e.previousData !== undefined &&
        e.previousData.itemValue !== e.data.itemValue /* UPDATE */
      ) {
        if (e.data.itemValue == undefined || e.data.itemValue == '') {
          e.cancel = true;
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, Document name is mandatory information.</p>',
          });
          return;
        }

        var payload: any = {
          id: e.data.id,
          itemType: this._structure.CommonDataItems.IdentityType,
          itemValue: e.data.itemValue,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/commondata/update`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((resopnse: any) => {
            if (this.user.commondata) {
              let data = this.user.commondata.filter(
                (x: any) => x.id !== e.data.id
              );
              if (data) {
                data.push({
                  id: resopnse.data.id,
                  itemType: this._structure.CommonDataItems.IdentityType,
                  itemValue: resopnse.data.itemValue,
                });
              }
              this.user.commondata = data;
              Common.setItem('user', this.user);
              this.user = JSON.parse(Common.getUser() ?? '');
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    } else if (e.requestType == 'delete') {
      /*Delete*/
      var payload: any = {
        id: e.data[0].id,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/commondata/delete`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          if (this.user.commondata) {
            let data = this.user.commondata.filter(
              (x: any) => x.id !== e.data[0].id
            );
            this.user.commondata = data;
            Common.setItem('user', this.user);
            this.user = JSON.parse(Common.getUser() ?? '');
          }
        })
        .catch((error: any) => {
          console.error(error);

          let obj: any = document.getElementById('IdentityList-grid');
          if (obj) {
            let gdIdentity: GridComponent = obj.ej2_instances[0];
            gdIdentity.dataSource = this.user.commondata.filter(
              (g: any) =>
                g.itemType == this._structure.CommonDataItems.IdentityType
            );
          }
        })
        .finally(() => {});
    }
  };

  DailogContent() {
    if (this.iValidTab == 'contracts') {
      this.jsonContractType = this.GetContractType();
    }
    const handleContractChange = (e: any) => {};
    var _content: any;
    if (this.iValidTab == 'bio') {
      _content = (
        <>
          {/* <div className='row align-items-center'>
            <div className='col-2 py-2'>
              <label style={{ fontSize: '13px' }}>Name:</label>
            </div>
            <div className='col-10 py-2'>
              <input
                className='input-text-border'
                id='grp_name'
                name='grp_name'
                type='text'
                placeholder='Enter Name'
                autoComplete='off'
                style={{ fontSize: '15px' }}
                required={true}
              />
            </div>
          </div> */}

          <GridComponent
            id='IdentityList-grid'
            dataSource={this.user.commondata.filter(
              (g: any) =>
                g.itemType == this._structure.CommonDataItems.IdentityType
            )}
            height='calc(100%)'
            toolbar={this.toolbarOptions}
            selectedRowIndex={0}
            allowTextWrap={true}
            allowSorting={true}
            allowPaging={true}
            allowFiltering={true}
            filterSettings={this.filterSettings}
            sortSettings={this.sortingOptions}
            editSettings={this.editSettings}
            pageSettings={{ pageCount: 4, pageSizes: true }}
            actionBegin={this.IdentityactionBegin.bind(this)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='itemValue'
                headerText='Name'
                width='300'
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, Edit, Sort]} />
          </GridComponent>
        </>
      );
    } else if (this.iValidTab == 'address') {
      _content = (
        <>
          <div className='row align-items-center'>
            {this.iDatacntxid !== 'ecard' ? (
              <>
                <div className='col-3 py-2'>
                  <label style={{ fontSize: '13px' }}>Type:</label>
                </div>
                <div className='col-9 py-2'>
                  <input
                    className='input-text-border'
                    id='addr_type'
                    name='addr_type'
                    type='text'
                    placeholder='Enter Address Type'
                    autoComplete='off'
                    style={{ fontSize: '15px' }}
                    required={true}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div className='col-3  py-2'>
              <label style={{ fontSize: '13px' }}>Date:</label>
            </div>
            <div className='col-9  py-2'>
              <DatePickerComponent
                id='addrcdate'
                format={this.user.settings.formats.DateShort}
                value={new Date()}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                //placeholder={this.user.settings.formats.DateShort}
                placeholder='Select Date'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
        </>
      );
    } else if (this.iValidTab == 'reference') {
      _content = (
        <div>
          <div className='row align-items-center'>
            {this.iDatacntxid !== 'ecard' ? (
              <>
                <div className='col-3 py-2'>
                  <label style={{ fontSize: '13px' }}>Title:</label>
                </div>
                <div className='col-9 py-2'>
                  <input
                    className='input-text-border'
                    id='ref_title_name'
                    name='ref_title_name'
                    type='text'
                    placeholder='Enter Title'
                    autoComplete='off'
                    style={{ fontSize: '15px' }}
                    required={true}
                  />
                </div>
                <div className='col-3 py-2'>
                  <label style={{ fontSize: '13px' }}>Name:</label>
                </div>
                <div className='col-9 py-2'>
                  <input
                    className='input-text-border'
                    id='ref_person_name'
                    name='ref_person_name'
                    type='text'
                    placeholder='Enter Name'
                    autoComplete='off'
                    style={{ fontSize: '15px' }}
                    required={true}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className='col-3  py-2'>
              <label style={{ fontSize: '13px' }}>Date:</label>
            </div>
            <div className='col-9  py-2'>
              <DatePickerComponent
                id='refcdate'
                format={this.user.settings.formats.DateShort}
                value={new Date()}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                //placeholder={this.user.settings.formats.DateShort}
                placeholder='Select Date'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.iValidTab == 'contact') {
      let fields: object = { text: 'itemValue', value: 'id' };
      _content = (
        <>
          <div className='row align-items-center'>
            {this.iDatacntxid !== 'ecard' ? (
              <>
                <div className='col-3 py-2'>
                  <label style={{ fontSize: '13px' }}>Name:</label>
                </div>
                <div className='col-9 py-2'>
                  <input
                    className='input-text-border'
                    id='contact_person_name'
                    name='contact_person_name'
                    type='text'
                    placeholder='Enter Name'
                    autoComplete='off'
                    style={{ fontSize: '15px' }}
                    required={true}
                  />
                </div>
                <div className='col-3 py-2'>
                  <label style={{ fontSize: '13px' }}>Relation:</label>
                </div>
                <div className='col-9 py-2'>
                  <DropDownListComponent
                    id='relationid'
                    dataSource={this.iRelations}
                    filterBarPlaceholder='Search a Relation'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={fields}
                    // change={this.DropDownListChange.bind(this)}
                    placeholder='Select Relation'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownListComponent>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className='col-3  py-2'>
              <label style={{ fontSize: '13px' }}>Date:</label>
            </div>
            <div className='col-9  py-2'>
              <DatePickerComponent
                id='crddate'
                format={this.user.settings.formats.DateShort}
                value={new Date()}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                //placeholder={this.user.settings.formats.DateShort}
                placeholder='Select Date'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
        </>
      );
    } else if (this.iValidTab == 'contracts') {
      let fields: object = { text: 'itemValue', value: 'id' };
      _content = (
        <>
          <div className='row align-items-center'>
            {this.iDatacntxid !== 'ecard' ? (
              <>
                <div className='col-md-6  py-2'>
                  <label style={{ fontSize: '13px' }}>* Start Date:</label>
                  <DatePickerComponent
                    id='cstartdate'
                    format={this.user.settings.formats.DateShort}
                    value={
                      this.state.openperiod
                        ? new Date(this.state.openperiod!['startDate'])
                        : new Date()
                    }
                    showClearButton={false}
                    cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    showTodayButton={false}
                    //placeholder={this.user.settings.formats.DateShort}
                    placeholder='Select Date'
                    style={{ fontSize: '15px' }}
                  />
                </div>

                <div className='col-md-6  py-2'>
                  <label style={{ fontSize: '13px' }}>End Date:</label>
                  <DatePickerComponent
                    id='cenddate'
                    format={this.user.settings.formats.DateShort}
                    showClearButton={false}
                    cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    showTodayButton={false}
                    //placeholder={this.user.settings.formats.DateShort}
                    placeholder='Select Date'
                    style={{ fontSize: '15px' }}
                  />
                </div>

                <div className='col-md-6 py-2'>
                  <label style={{ fontSize: '13px' }}>* Contract Type:</label>
                  <DropDownListComponent
                    id='contracttypeid'
                    dataSource={this.jsonContractType}
                    filterBarPlaceholder='Search a Contract Type'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={this.cfields}
                    value={
                      this.state.lastcontract &&
                      this.state.lastcontract['commonDataId']
                        ? this.state.lastcontract['commonDataId']
                        : undefined
                    }
                    change={handleContractChange}
                    placeholder='Select Contract Type'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownListComponent>
                </div>
                <div className='col-md-6 py-2'>
                  <label style={{ fontSize: '13px' }}>* Salary:</label>
                  <NumericTextBoxComponent
                    id='txtnewcscale'
                    value={
                      this.state.lastcontract &&
                      this.state.lastcontract['salary']
                        ? this.state.lastcontract['salary']
                        : 0
                    }
                    decimals={
                      this.user.settings.formats.Decimal.includes('.')
                        ? this.user.settings.formats.Decimal.split('.').pop()
                            .length
                        : null
                    }
                    showSpinButton={false}
                    cssClass='numerictext-right'
                  />
                </div>

                <div className='col-md-6 py-2'>
                  <label style={{ fontSize: '13px' }}>Job:</label>
                  <DropDownTreeComponent
                    id='ddlnewcjob'
                    treeSettings={this.treeSettings}
                    fields={this.state.jobfields}
                    filterBarPlaceholder='Search a Job'
                    allowFiltering={true}
                    filterType='Contains'
                    value={
                      this.state.lastcontract &&
                      this.state.lastcontract['jobId']
                        ? [this.state.lastcontract['jobId']]
                        : undefined
                    }
                    //change={handleContractChange}
                    placeholder='Select Job'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownTreeComponent>
                </div>

                <div className='col-md-6 py-2'>
                  <label style={{ fontSize: '13px' }}>Branch:</label>
                  <DropDownTreeComponent
                    id='ddlnewcbranch'
                    treeSettings={this.treeSettings}
                    fields={this.state.branchfields}
                    filterBarPlaceholder='Search a Branch'
                    allowFiltering={true}
                    filterType='Contains'
                    value={
                      this.state.lastcontract &&
                      this.state.lastcontract['branchId']
                        ? [this.state.lastcontract['branchId']]
                        : undefined
                    }
                    //change={handleContractChange}
                    placeholder='Select Branch'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownTreeComponent>
                </div>

                <div className='col-md-6 py-2'>
                  <label style={{ fontSize: '13px' }}>Department:</label>
                  <DropDownTreeComponent
                    id='ddlnewcdepartment'
                    treeSettings={this.treeSettings}
                    fields={this.state.deptfields}
                    filterBarPlaceholder='Search a Department'
                    allowFiltering={true}
                    filterType='Contains'
                    value={
                      this.state.lastcontract &&
                      this.state.lastcontract['departmentId']
                        ? [this.state.lastcontract['departmentId']]
                        : undefined
                    }
                    //change={handleContractChange}
                    placeholder='Select Department'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownTreeComponent>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div style={{ padding: '7px' }}>
          {_content}
          <div
            style={{
              textAlign: 'right',
              display: 'flex',
              float: 'right',
              paddingTop: '10px',
            }}
          >
            {this.iValidTab !== 'bio' ? (
              <button
                className='flat-button'
                id='btnCancel'
                style={{
                  marginRight: '15px',
                }}
                onClick={this.btnHandleClick.bind(this)}
              >
                Cancel
              </button>
            ) : null}

            {this.iValidTab !== 'bio' ? (
              <button
                className='flat-button'
                id='btnAccept'
                style={{
                  marginRight: '10px',
                }}
                onClick={this.btnHandleClick.bind(this)}
              >
                Accept
              </button>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  btnHandleClick(args: any) {
    if (this.iValidTab == 'bio' && this.iDatacntxid == '_accordion') {
      if (args.target.id.toString().toLowerCase() == 'btnaccept') {
        var _input: any = document.getElementById('grp_name');

        if (_input.value.trim().length <= 0) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, Document name is mandatory information.</p>',
          });
          return;
        }

        var payload: any = {
          itemType: this._structure.CommonDataItems.IdentityType,
          itemValue: _input.value,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/commondata/add`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((resopnse: any) => {
            if (this.user.commondata) {
              this.user.commondata.push({
                id: resopnse.data.id,
                itemType: this._structure.CommonDataItems.IdentityType,
                itemValue: resopnse.data.itemValue,
              });
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    } else if (this.iValidTab == 'address' && this.iDatacntxid == 'composer') {
      if (args.target.id.toString().toLowerCase() == 'btnaccept') {
        let name: any;
        let ictl: any;
        ictl = document.getElementById('addr_type');
        if (ictl !== undefined) {
          if ((ictl as any).value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Address Mode is mandatory information.</p>',
            });
            return;
          }
          name = (ictl as any).value.toString().trim();
        }
        ictl = document.getElementById('addrcdate');
        let _xdate: any;
        if (ictl !== undefined) {
          if (ictl.value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
            });
            return;
          } else if (ictl.value.toString().trim().length > 0) {
            _xdate = new Date(ictl.value.toString());
            if (Object.prototype.toString.call(_xdate) !== '[object Date]') {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
              });
              return;
            }
          }
        }
        this.AddAddress(
          this._structure.AddressSectons.Personal,
          undefined,
          _xdate,
          undefined,
          name.toString().trim(),
          ''
        );

        // this.SaveAddress(
        //   Object.entries(this._structure.AddressSections).filter(
        //     (x) => x[1] == this._structure.AddressSections.Personal
        //   )[0][0],
        //   name.toString().trim(),
        //   '',
        //   _xdate,
        //   false,
        //   '',
        //   ''
        // );
      }
    } else if (
      this.iValidTab == 'reference' &&
      this.iDatacntxid == 'composer'
    ) {
      if (args.target.id.toString().toLowerCase() == 'btnaccept') {
        let name: any;
        let ictl: any;
        ictl = document.getElementById('ref_person_name');
        if (ictl !== undefined) {
          if ((ictl as any).value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, name is mandatory information.</p>',
            });
            return;
          }
          name = (ictl as any).value.toString().trim();
        }
        let title: any;
        ictl = document.getElementById('ref_title_name');
        if (ictl !== undefined) {
          if ((ictl as any).value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, name is mandatory information.</p>',
            });
            return;
          }
          title = (ictl as any).value.toString().trim();
        }

        ictl = document.getElementById('refcdate');
        let _xdate: any;
        if (ictl !== undefined) {
          if (ictl.value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
            });
            return;
          } else if (ictl.value.toString().trim().length > 0) {
            _xdate = new Date(ictl.value.toString());
            if (Object.prototype.toString.call(_xdate) !== '[object Date]') {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
              });
              return;
            }
          }
        }
        this.AddAddress(
          this._structure.AddressSectons.Reference,
          undefined,
          _xdate,
          undefined,
          name.toString().trim(),
          title
        );
        // this.SaveAddress(
        //   Object.entries(this._structure.AddressSections).filter(
        //     (x) => x[1] == this._structure.AddressSections.Reference
        //   )[0][0],
        //   name.toString().trim(),
        //   title,
        //   _xdate,
        //   false,
        //   '',
        //   ''
        // );
      }
    } else if (this.iValidTab == 'contact' && this.iDatacntxid == 'composer') {
      if (args.target.id.toString().toLowerCase() == 'btnaccept') {
        let name: any;
        let ictl: any;
        ictl = document.getElementById('contact_person_name');
        if (ictl !== undefined) {
          if ((ictl as any).value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, name is mandatory information.</p>',
            });
            return;
          }
          name = (ictl as any).value.toString().trim();
        }
        let sysrelation: any;
        ictl = document.getElementById('relationid');
        if (ictl !== undefined) {
          if (
            ictl.ej2_instances[0].element.value.toString().trim().length <= 0
          ) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Relation is mandatory information.</p>',
            });
            return;
          }
          sysrelation =
            ictl.ej2_instances[0].element.ej2_instances[0].itemData.id;
          // sysrelation = Common.FindJsonByObjectId(
          //   this._fulljson,
          //   ictl.ej2_instances[0].properties.dataSource.filter(
          //     (x: any) => x.value == ictl.ej2_instances[0].element.value
          //   )[0].object,
          //   this.user,
          //   this._structure.Field.Identif.SysName
          // );
        }
        ictl = document.getElementById('crddate');
        let _xdate: any;
        if (ictl !== undefined) {
          if (ictl.value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
            });
            return;
          } else if (ictl.value.toString().trim().length > 0) {
            _xdate = new Date(ictl.value.toString());
            if (Object.prototype.toString.call(_xdate) !== '[object Date]') {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
              });
              return;
            }
          }
        }

        if (sysrelation) {
          this.AddAddress(
            this._structure.AddressSectons.Emergency,
            undefined,
            _xdate,
            undefined,
            name.toString().trim(),
            sysrelation
          );
          // this.SaveAddress(
          //   Object.entries(this._structure.AddressSections).filter(
          //     (x) => x[1] == this._structure.AddressSections.Emergency
          //   )[0][0],
          //   name.toString().trim(),
          //   sysrelation.value,
          //   _xdate,
          //   false,
          //   '',
          //   ''
          // );
        }
      }
    } else if (
      this.iValidTab == 'contracts' &&
      this.iDatacntxid == '_contract_'
    ) {
      if (args.target.id.toString().toLowerCase() == 'btnaccept') {
        let ictl: any;
        let startdate: any;
        let enddate: any = null;
        let contracttypeid: string = '';
        let scale: number = 0;
        let jobid: any = null;
        let branchid: any = null;
        let departmentid: any = null;

        ictl = document.getElementById('cstartdate');
        if (ictl !== undefined) {
          if (ictl.value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Start Date is mandatory information.</p>',
            });
            return;
          } else if (ictl.value.toString().trim().length > 0) {
            startdate = new Date(ictl.value.toString());
            if (
              new Date(startdate).getTime() <
              new Date(this.state.openperiod!['startDate']).getTime()
            ) {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, Period is already closed for selected start date.</p>',
              });
              return;
            }
            if (Object.prototype.toString.call(startdate) !== '[object Date]') {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, Start Date is mandatory information.</p>',
              });
              return;
            }
          }
        }

        ictl = document.getElementById('cenddate');
        if (ictl.value.toString().trim().length > 0) {
          enddate = new Date(ictl.value.toString());
          if (new Date(startdate).getTime() > new Date(enddate).getTime()) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Start date should be less than end date.</p>',
            });
            return;
          }
        }

        ictl = document.getElementById('contracttypeid');
        if (ictl !== undefined) {
          if (
            ictl.ej2_instances[0].element.value.toString().trim().length <= 0
          ) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Contract Type is mandatory information.</p>',
            });
            return;
          }
          contracttypeid =
            ictl.ej2_instances[0].element.ej2_instances[0].itemData.id;
        }

        ictl = document.getElementById('txtnewcscale');
        if (ictl !== undefined) {
          if (
            !ictl.ej2_instances[0].value ||
            ictl.ej2_instances[0].value <= 0
          ) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please enter scale.</p>',
            });
            return;
          } else {
            scale = ictl.ej2_instances[0].value;
          }
        }

        ictl = document.getElementById('ddlnewcjob');
        if (ictl !== undefined) {
          if (
            ictl.ej2_instances[0].value &&
            ictl.ej2_instances[0].value.length > 0
          ) {
            jobid = ictl.ej2_instances[0].value[0];
          }
        }

        ictl = document.getElementById('ddlnewcbranch');
        if (ictl !== undefined) {
          if (
            ictl.ej2_instances[0].value &&
            ictl.ej2_instances[0].value.length > 0
          ) {
            branchid = ictl.ej2_instances[0].value[0];
          }
        }

        ictl = document.getElementById('ddlnewcdepartment');
        if (ictl !== undefined) {
          if (
            ictl.ej2_instances[0].value &&
            ictl.ej2_instances[0].value.length > 0
          ) {
            departmentid = ictl.ej2_instances[0].value[0];
          }
        }

        let payload: any = {
          employeeId: this.props._jObject.id,
          dateStart: Common.formatDate(startdate, 'yyyy-MM-dd'),
          dateEnd: enddate ? Common.formatDate(enddate, 'yyyy-MM-dd') : enddate,
          commonDataId: contracttypeid,
          salary: scale,
          jobId: jobid,
          branchId: branchid,
          departmentId: departmentid,
          tenantId: this.token.tenant,
        };

        this.SaveContract(payload);
      }
    } else if (
      (this.iValidTab == 'contact' ||
        this.iValidTab == 'reference' ||
        this.iValidTab == 'address') &&
      this.iDatacntxid == 'ecard'
    ) {
      if (this.iAddrMainCardid > 0 && this.iAddrSubCardid > 0) {
        let ictl: any;
        switch (this.iValidTab) {
          case 'contact':
            ictl = document.getElementById('crddate');
            break;
          case 'reference':
            ictl = document.getElementById('refcdate');
            break;
          case 'address':
            ictl = document.getElementById('addrcdate');
            break;
        }
        let _xdate: any;
        if (ictl !== undefined) {
          if (ictl.value.toString().trim().length <= 0) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
            });
            return;
          } else if (ictl.value.toString().trim().length > 0) {
            _xdate = new Date(ictl.value.toString());
            if (Object.prototype.toString.call(_xdate) !== '[object Date]') {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, Date is mandatory information.</p>',
              });
              return;
            }
          }
        }
        let maincard: any;
        switch (this.iValidTab) {
          case 'contact':
            maincard = this.props._jObject.addresses
              .filter((x: any) => x.type == 'Emergency')[0]
              .contacts.filter((x: any) => x.id === this.iAddrMainCardid);
            break;
          case 'address':
            maincard = this.props._jObject.addresses
              .filter((x: any) => x.type == 'Personal')[0]
              .contacts.filter((x: any) => x.id === this.iAddrMainCardid);
            break;
          case 'reference':
            maincard = this.props._jObject.addresses
              .filter((x: any) => x.type == 'Reference')[0]
              .contacts.filter((x: any) => x.id === this.iAddrMainCardid);
            break;
        }

        if (maincard) {
          // let _obj: any = Common.FindJsonByObjectValue(
          //   this._fulljson,
          //   maincard[0].title,
          //   this.user,
          //   Number(
          //     Object.entries(this._structure.Languages).filter(
          //       (x: any) => x[1] == this.user.language
          //     )[0][1]
          //   )
          // );
          // let kinship: any = '';
          // if (_obj) {
          //   kinship = Common.FindJsonByObjectId(
          //     this._fulljson,
          //     _obj.object,
          //     this.user,
          //     this._structure.Field.Identif.SysName
          //   );
          // }
          let _addrSection: any;
          let _addressid: any;

          switch (this.iValidTab) {
            case 'address':
              _addrSection = this._structure.AddressSectons.Personal;
              _addressid = this.props._jObject.addresses.filter(
                (x: any) => x.type == 'Personal'
              )[0].id;
              break;
            case 'contact':
              _addrSection = this._structure.AddressSectons.Emergency;
              _addressid = this.props._jObject.addresses.filter(
                (x: any) => x.type == 'Emergency'
              )[0].id;
              break;
            case 'reference':
              _addrSection = this._structure.AddressSectons.Reference;
              _addressid = this.props._jObject.addresses.filter(
                (x: any) => x.type == 'Reference'
              )[0].id;
              break;
          }
          this.AddAddress(
            _addrSection,
            _addressid,
            _xdate,
            maincard[0].id,
            null,
            null
          );
          // switch (this.iValidTab) {
          //   case 'address':
          //     _addrSection = Object.entries(
          //       this._structure.AddressSections
          //     ).filter(
          //       (x) => x[1] == this._structure.AddressSections.Personal
          //     )[0][0];
          //     _title = '';
          //     break;
          //   case 'contact':
          //     _addrSection = Object.entries(
          //       this._structure.AddressSections
          //     ).filter(
          //       (x) => x[1] == this._structure.AddressSections.Emergency
          //     )[0][0];
          //     _title = kinship.value ? kinship.value : '';
          //     break;
          //   case 'reference':
          //     _addrSection = Object.entries(
          //       this._structure.AddressSections
          //     ).filter(
          //       (x) => x[1] == this._structure.AddressSections.Reference
          //     )[0][0];
          //     _title = maincard[0].title;
          //     break;
          // }
          // this.SaveAddress(
          //   _addrSection,
          //   maincard[0].name,
          //   _title,
          //   _xdate,
          //   true,
          //   '',
          //   '',
          //   this.iAddrSubCardid
          // );
        }
      }
    }

    this.setState({ showDocDialog: false });
  }

  public GetContextMenuFromXML(
    cntxmenu: any,
    idx: number = 0
  ): ContextMenuItemModel[] {
    let cntxMenuItems: ContextMenuItemModel[] = [];
    cntxmenu = Array.isArray(cntxmenu) ? cntxmenu : [cntxmenu];
    cntxmenu.forEach((x: any) => {
      idx = idx + 1;
      cntxMenuItems.push({
        text: x.$.type === 'separator' ? '' : x.$.text,
        id: idx.toString(),
        separator: x.$.type === 'separator',
        items: x.Item ? this.GetContextMenuFromXML(x.Item, idx) : [],
        iconCss: `noicon {${x.$.columnname}}`,
      });
    });

    return cntxMenuItems;
  }

  public onCreated(): void {
    // this.spreadsheet.hideColumn(2,120,true);
    // this.spreadsheet.hideRow(8, 120, true);
    // this.spreadsheet.setColumnsWidth(350, ['A:B'])

    this.spreadsheet.merge('B1:E1');

    // var elem: any;
    // elem = document.querySelectorAll('.e-header-panel');
    // if (elem !== null && elem !== undefined) {
    //   elem[0].style.display = 'none';
    // }
    // elem = document.querySelectorAll('.e-row-header');
    // if (elem !== null && elem !== undefined) {
    //   elem[0].style.display = 'none';
    // }
    // elem = document.querySelectorAll('.e-sheet-content');
    // if (elem !== null && elem !== undefined) {
    //   elem[0].style.left = '0px';
    // }
  }

  public process_SpreadsheetValue(_jData: any, _src: any) {
    var _valueinfo = '';
    if (_src.$.ikey !== '' && Array.isArray(_jData[_src.$.ikey]) == true) {
      if (_src.$.formula !== '') {
        switch (_src.$.formula.toLowerCase()) {
          case 'sum':
            var total: number;
            total = 0;
            for (let i = 0; i < _jData[_src.$.ikey].length; i++) {
              total += _jData[_src.$.ikey][i][_src.$.source];
            }
            _valueinfo = total.toString();
            break;
        }
      }
    } else {
      if (_src.$.source.includes('.') === true) {
        var _fld: any = _src.$.source;
        if (_jData[_fld.split('.')[0]] !== undefined) {
          _valueinfo = _jData[_fld.split('.')[0]][_fld.split('.')[1]];
        } else {
          _valueinfo = '';
        }
      } else {
        _valueinfo = _jData[_src.$.source];
      }
      if (
        Common.DatesColumns(this._structure).includes(
          _src.$.source.toString().toLowerCase()
        )
      ) {
        if (_valueinfo !== '0001-01-01T00:00:00') {
          _valueinfo = Common.formatDate(
            new Date(_valueinfo),
            this.user.settings.formats.DateShort
          );
        } else {
          _valueinfo = '';
        }
      }
    }

    return _valueinfo;
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * SUHAIL - 2023-04-24 - 150
  // * SUHAIL - 2023-04-25 - 60
  // * SUHAIL - 2023-04-28 - 60
  // * SUHAIL - 2023-05-03 - 80
  // * SUHAIL - 2023-05-04 - 60
  // * SUHAIL - 2023-05-05 - 120
  // * Avinash - 2023-05-26 - 30
  // * Avinash - 2023-05-31 - 60

  render() {
    // let item = document.getElementById('dataviewer-tabcomponent');
    // if (item !== null) {
    //   let text = item?.querySelector('.e-tab-text.tab-highlighter');
    //   if (text !== null) {
    //     if ((text as HTMLElement).innerText.toLowerCase() !== 'bio') {
    //       this.updateflexflow = 'wrap';
    //     } else {
    //       this.updateflexflow = 'row';
    //     }
    //   } else {
    //     this.updateflexflow = 'row';
    //   }
    // }

    //var xml = this.ParseXML(this.props._xml);
    //var _item = this.Process(xml);
    //var _final = this.process_MergeAll(this.Process(xml), xml)
    var xml: any = null;
    var _final: any = '';
    xml = this.props._xml;
    this.arr = [];
    this.arrTimeline = [];
    if (
      // this.props.selectedtabname?.toLowerCase() === 'leave' && this.state._absences !== undefined
      this.props.selectedtabname?.toLowerCase() === 'leave'
    ) {
      let date = new Date();
      if (
        this.props._jObject.contract &&
        this.props._jObject.contract.length > 0
      ) {
        date = new Date(
          this.props._jObject.contract.sort((a: any, b: any) => {
            return (
              new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime()
            );
          })[0]['dateStart']
        );

        if (date.getFullYear() <= 2000) {
          date.setFullYear(2022, 0, 1);
        }

        // if (this.user.company.registration.commencement !== undefined) {
        //   if (
        //     date.getTime() <
        //     new Date(this.user.company.registration.commencement).getTime()
        //   ) {
        //     date = new Date(this.user.company.registration.commencement);
        //   }
        // }
        // date = new Date(
        //   this.props._jObject.contract[this.props._jObject.contract.length - 1][
        //     'dateStart'
        //   ]
        //     );
      }
      for (let i = date.getFullYear(); i <= new Date().getFullYear() + 1; i++) {
        this.arr.push(i);
      }

      let minYear = this.arr[0];
      // this.arr = this.arr.reverse();
      if (this.state.timelinedata.length <= 0) {
        for (let i = 0; i <= this.arr.length - 1; i++) {
          //     this.arrTimeline.push(<Timeline Year={this.arr[i]} />);
          var myear: any = [];
          myear.push(this.arr[i]);
          // this.arrTimeline.push(
          //   <YearlyCalanderView
          //     _years={myear}
          //     _employee={this.props._jObject}
          //     _firstDayOfWeek={1}
          //     _showWeekSeparators={false}
          //     _forceFullWeeks={false}
          //     _cmenuitems={null}
          //     _absences={this.state._absences}
          //     _minYear={minYear}
          //     _issue_calender_days={false}
          //   ></YearlyCalanderView>
          // );
          //if (i === 1) break;
        }
      } else {
        for (let i = 0; i <= this.state.timelinedata.length - 1; i++) {
          //     this.arrTimeline.push(<Timeline Year={this.arr[i]} />);
          var myear: any = [];
          myear.push(this.arr[i]);
          // this.arrTimeline.push(
          //   <YearlyCalanderView
          //     _years={myear}
          //     _employee={this.props._jObject}
          //     _firstDayOfWeek={1}
          //     _showWeekSeparators={false}
          //     _forceFullWeeks={false}
          //     _cmenuitems={null}
          //     _absences={this.state._absences}
          //     _minYear={minYear}
          //     _issue_calender_days={false}
          //   ></YearlyCalanderView>
          // );
        }
      }
    }

    // if (
    //   this.props._jObject.contract &&
    //   this.props._jObject.contract.length > 0
    // ) {
    //   this.props._jObject.contract.sort((a: any, b: any) => {
    //     return (
    //       new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
    //     );
    //   });
    // }

    let total: number = this.arr.length;
    this.startidx = 0;
    if (this.arr.length <= 2) {
      this.startidx = this.arr.length - 1;
    } else {
      this.startidx = 1;
    }
    if (this.props._xml !== null) {
      // xml = this.ParseXML(this.props._xml);
      //_final = this.process_MergeAll(this.Process(xml), xml)
      if (
        this.props.selectedtabname?.toLowerCase() == 'address' ||
        this.props.selectedtabname?.toLowerCase() == 'contact' ||
        this.props.selectedtabname?.toLowerCase() == 'reference' ||
        this.props.selectedtabname?.toLowerCase() == 'education' ||
        this.props.selectedtabname?.toLowerCase() == 'experience'
      ) {
        _final = this.process_MergeAll(this.ProcessAddress(xml), xml);
      } else {
        _final = this.process_MergeAll(this.Process(xml), xml);
      }
    } else if (
      // this.props.selectedtabname?.toLowerCase() === 'leave' && this.state._absences !== undefined
      this.props.selectedtabname?.toLowerCase() === 'leave'
    ) {
      _final = (
        <div
          id='divTimeline'
          style={{
            height: 'calc(100vh - 175px)',
            overflow: 'auto',
            // paddingTop: '35px',
            marginTop: '7x',
          }}
        >
          {/* {this.arrTimeline} */}
          {
            <LeaveTimeLine
              _years={this.arr}
              _employee={this.props._jObject}
              _firstDayOfWeek={1}
              _showWeekSeparators={false}
              _forceFullWeeks={false}
              _cmenuitems={null}
            />
          }
        </div>
      );
    } else if (this.props.selectedtabname?.toLowerCase() === 'taxinfo') {
      _final = <div>{this.generateTaxInfo()}</div>;
    } else if (this.props.selectedtabname?.toLowerCase() === 'attendance') {
      _final = <div>{<Attendance selectorData={this.props._jObject} />}</div>;
    } else if (this.props.selectedtabname?.toLowerCase() === 'payslips') {
      // _final = <div>{<Payslip selectorData={this.props._jObject} />}</div>;
      _final = <div>{<NewPayslip selectorData={this.props._jObject} />}</div>;
    } else if (this.props.selectedtabname?.toLowerCase() === 'payheads') {
      _final = (
        <div>{<EmployeePayHeads selectorData={this.props._jObject} />}</div>
      );
    } else if (this.props.selectedtabname?.toLowerCase() === 'income tax') {
      _final = (
        <div>
          {
            <PayheadList
              selectorData={this.props._jObject}
              defaultHeadTypeId={[
                this._structure.HeadType.EmployeesStatutoryDeductions.id,
              ]}
              defaultTypeOfId={[this._structure.TypeOf.Taxes.id]}
              allowGroupingHead={false}
            />
          }
        </div>
      );
    } else if (this.props.selectedtabname?.toLowerCase() === 'contribution') {
      _final = (
        <div>
          {
            <PayheadList
              selectorData={this.props._jObject}
              defaultHeadTypeId={[
                this._structure.HeadType.EmployeesStatutoryDeductions.id,
                this._structure.HeadType.EmployersStatutoryContributions.id,
              ]}
              defaultTypeOfId={[
                this._structure.TypeOf.Employee_Statutory_Contributions.id,
                this._structure.TypeOf.Employers_Statutory_Contribution.id,
              ]}
              allowGroupingHead={true}
            />
          }
        </div>
      );
    } else if (this.props.selectedtabname?.toLowerCase() === 'skill') {
      _final = <div>{<Skill />}</div>;
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'bank'
    ) {
      _final = <div>{<Bank />}</div>;
    } else if (
      this.props.rootmenu &&
      (this.props.rootmenu.id === 'employeemenu' ||
        this.props.rootmenu.id === 'homemenu') &&
      this.props.selectedtabname?.toLowerCase() === 'bank'
    ) {
      _final = (
        <div>{<EmployeeBankList jsonDataViewer={this.props._jObject} />}</div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'cost center'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'level'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'position'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'section'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'class'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'unit'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'team'
    ) {
      _final = (
        <div>
          {
            <AllocationList
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
              underTabs={true}
            />
          }
        </div>
      );
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'leavetype'
    ) {
      _final = <div>{<LeaveTypesList key={this.props.tabinfo.id} />}</div>;
    } else if (
      this.props.rootmenu &&
      this.props.rootmenu.id === 'companymenu' &&
      this.props.selectedtabname?.toLowerCase() === 'formats'
    ) {
      _final = (
        <div>
          {
            <Formats
              key={this.props.tabinfo.id}
              jsonDataViewer={this.props.tabinfo}
            />
          }
        </div>
      );
    } else if (
      Array.isArray(
        this.props._jObject[
          Common.toCamelCase(this.props.selectedtabname!.toString())
        ]
      )
    ) {
      let gridInstance: GridComponent | any;
      const icontemplate = (props: any) => {
        return props.document !== undefined ? (
          <div>
            <i className={props.column.field} />
          </div>
        ) : (
          <div></div>
        );
      };

      // const onLoad = (e: any) => {
      //     let obj: any = (document.getElementById('grid-tabdetails') as HTMLElement);
      //     if (obj !== null) {
      //         let objGrid: any = obj.ej2_instances[0];
      //         if (objGrid) {
      //             objGrid.element.addEventListener('dblclick', function (event: any) {
      //                 let obj1: any = (document.getElementById('grid-tabdetails') as HTMLElement);
      //                 if (obj1 !== null) {
      //                     let objGrid1: any = obj1.ej2_instances[0];
      //                     alert('double clicked');
      //                 }
      //             }
      //             )
      //         }
      //     }
      // };

      const onDblClick = (e: any) => {
        if (
          e.rowData.dossiertype ||
          this.props.selectedtabname?.toLowerCase() === 'contract'
        ) {
          let document: any = e.rowData;
          // if (!document) {
          //   document = this.props._jObject.dossier.filter(
          //     (x: any) => x.document && x.document.detailTranId == e.rowData.id
          //   );
          //   if (document.length > 0) {
          //     document = document[0].document;
          //   }
          // }
          // if (!document || document.detailid <= 0) {
          //   return;
          // }
          if (!e.rowData.detailTranId) {
            return;
          }
          let mailmergefields: string[] = Object.keys(
            Common.flattenJSON(document, {}, 'document.')
          );
          let mailmergevalues: string[] = Object.entries(
            Common.flattenJSON(
              document,
              {},
              '',
              this.user,
              Common.DatesColumns(this._structure)
            )
          ).map((x: any) => {
            if (x[1]) {
              let obj: any = x[1].toString();
              return obj;
            }
          });

          if (e.rowData.dossiertype.wordTemplate) {
            this.setState({
              showword: true,
              docdata: '',
              jsonDocument: document,
            });
          }

          // Common.ApiCallAsync(
          //   'POST',
          //   `${REACT_APP_ENDPOINT_CORESERVICE}/dossiertype/get`,
          //   {
          //     id: e.rowData.dossierTypeId ?? Common.blankguid,
          //     fieldData: mailmergefields,
          //     valueData: mailmergevalues,
          //     mailMergeObjectId: this.props.selectorService.Id,
          //     mailMergeObject: JSON.stringify(document),
          //   },
          //   Common.getToken() || '',
          //   this.user
          // )
          //   .then((response: any) => {
          //     return response.data;
          //   })
          //   .then((response: any) => {
          //     if (response.length > 0 && response[0].wordTemplate !== null) {
          //       this.setState({
          //         showword: true,
          //         docdata: '',
          //         jsonDocument: document,
          //       });
          //     } else {
          //       Common.ApiCallAsync(
          //         'POST',
          //         `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Sfdt/Read`,
          //         {
          //           object: document.account.id,
          //           field: this._structure.Field.Identif.Template,
          //         },
          //         Common.getToken() || '',
          //         this.user
          //       )
          //         .then((response: any) => {
          //           return response.data;
          //         })
          //         .then((response: any) => {
          //           if (response && response !== '') {
          //             this.setState({
          //               showword: true,
          //               docdata: '',
          //               jsonDocument: document,
          //             });
          //           } else {
          //             if (document !== undefined && document.attachments) {
          //               //document = e.rowData.document;
          //               let docid: number = 0;
          //               let rslt: any = document.attachments.filter(
          //                 (x: any) =>
          //                   x.field === this._structure.Field.FileType.Word ||
          //                   x.field === this._structure.Field.FileType.File
          //               );
          //               if (rslt.length > 0) {
          //                 docid = rslt[0].object;
          //               }
          //               if (dialogInstance !== null && docid > 0) {
          //                 this.setState({
          //                   showword: true,
          //                   docdata: '',
          //                   jsonDocument: document,
          //                 });

          //                 //dialogInstance.show();
          //               } else {
          //                 this.setState({ jsonDocument: document });
          //               }
          //             } else {
          //               this.setState({ jsonDocument: document });
          //             }
          //           }
          //         })
          //         .catch((error: any) => {
          //           console.error(error);
          //           // this.setState({
          //           //   showword: true,
          //           //   docdata: '',
          //           //   jsonDocument: document,
          //           // });
          //         })
          //         .finally(() => {});
          //     }
          //   })
          //   .catch((error: any) => {
          //     console.error(error);
          //   })
          //   .finally(() => {});
        }
      };

      const onRowSelected = (e: any) => {
        // this.setState({ contractdetail: e.data });
        // this.setState({ contractid: e.data.id });
        this.setState({ contractdetail: e.data, contractid: e.data.id });
        // if (this.props.subcardxml && this.state.contractid > 0) {
        //   let iddata: any = this.props._jObject.contract.filter(
        //   (x: any) => x.id === this.state.contractid
        // );
        //}
      };

      var cntxitems: ContextMenuItemModel[] = [];
      let subcontextMenuItems: ContextMenuItemModel[] = [];
      if (this.props._json && this.props._json.Grid.Contextmenu) {
        cntxitems = this.GetContextMenuFromXML(
          this.props._json.Grid.Contextmenu.Item
        );
      }

      // subcontextMenuItems.push({
      //   text: 'Account',
      //   id: '1',
      //   separator: false,
      // });
      // subcontextMenuItems.push({ text: 'Date', id: '2', separator: false });
      // cntxitems.push({
      //   text: 'Group By',
      //   id: '3',
      //   separator: false,
      //   items: subcontextMenuItems,
      // });

      const refreshContract = (allcontracts: any) => {
        // this.props._jObject[
        //   Common.toCamelCase(this.props.selectedtabname!.toString())
        // ] = allcontracts;
        let selIdx: number = this.GridInstance.GridInstance.selectedRowIndex;
        this.GridInstance.refreshData();
        this.props.refreshTabs(true);
        this.selectedRowIndex = selIdx;
      };
      const cntxmenuclick = (e: any) => {
        if (e.rowInfo !== undefined) {
          let grd: GridComponent;
          let obj: any = document.getElementById(
            'grid-tabdetails_' +
              Common.toCamelCase(this.props.selectedtabname!.toString())
          ) as Element;
          if (obj !== null) {
            grd = obj.ej2_instances[0] as GridComponent;
            grd.clearGrouping();
            grd.groupColumn(
              `${Common.getSubstringBetweenTwoChars(e.item.iconCss, '{', '}')}`
            );
          }
        }
      };

      const onGridDataBound = () => {
        if (
          this.props.selectedtabname!.toString().toLowerCase() === 'contract'
        ) {
          // console.log(
          //   this.props._jObject[
          //     Common.toCamelCase(this.props.selectedtabname!.toString())
          //   ]
          // );
          let grd: GridComponent;
          let obj: any = document.getElementById(
            'grid-tabdetails_' +
              Common.toCamelCase(this.props.selectedtabname!.toString())
          ) as Element;
          if (obj !== null) {
            grd = obj.ej2_instances[0] as GridComponent;
            grd.selectRow(this.selectedRowIndex);
          }
        }
      };

      var _subcard: any = '';
      var _sxml: any = this.props.subcardxml;
      if (this.props.subcardxml && this.state.contractid !== '') {
        let iddata: any = this.props._jObject.contract.filter(
          (x: any) => x.id === this.state.contractid
        );

        if (this.state.contractdetail !== undefined) {
          // _subcard = this.process_MergeAll(this.Process(_sxml, iddata[0]), _sxml);
          _subcard = (
            <Contract
              id={'contracts-composer'}
              key={'ssc-contractdetail_' + this.state.contractid.toString()}
              selectorData={this.props._jObject}
              contractData={this.state.contractdetail}
              refreshContract={refreshContract}
            />

            // _subcard = (
            //   <>
            //     <SpreadsheetComponent
            //       id={'ssc-contractdetail_' + this.state.contractid.toString()}
            //       key={'ssc-contractdetail_' + this.state.contractid.toString()}
            //       showRibbon={false}
            //       showFormulaBar={false}
            //       showSheetTabs={false}
            //       ref={(ssObj) => {
            //         this.spreadsheet = ssObj;
            //       }}
            //       created={this.onCreated.bind(this)}
            //       allowScrolling={false}
            //       scrollSettings={{ isFinite: true }}
            //       enableContextMenu={false}
            //       readOnly={true}
            //       // cssClass='e-hideheader e-hiderowheader e-hidesheetcontent'
            //       selectionSettings={{ mode: 'None' }}
            //       enableClipboard={true}
            //     >
            //       <SheetsDirective>
            //         {/* <SheetDirective name='Contract Details' rowCount={40} colCount={30} showGridLines={false}> */}
            //         <SheetDirective name='Contract Details' showGridLines={false}>
            //           <RowsDirective>
            //             <RowDirective height={55}>
            //               <CellsDirective>
            //                 <CellDirective
            //                   index={1}
            //                   value='Contract Details'
            //                   style={{
            //                     fontWeight: 'bold',
            //                     fontSize: '18pt',
            //                     verticalAlign: 'middle',
            //                   }}
            //                 ></CellDirective>
            //               </CellsDirective>
            //             </RowDirective>
            //             {_sxml
            //               ? _sxml.Grid.Columns.Column.map((x: any) => (
            //                   <RowDirective height={30}>
            //                     {/* {x.$.ikey == '' ?
            //                     <CellsDirective>
            //                       <CellDirective index={1} value={x.$.label} style={{ fontSize: '11pt', verticalAlign: 'middle' }}></CellDirective>
            //                       <CellDirective index={2} value={this.process_SpreadsheetValue(iddata[0], x)} style={{ fontSize: '12pt', verticalAlign: 'middle', textAlign: 'left' }}></CellDirective>
            //                     </CellsDirective>
            //                     : ''
            //                   } */}
            //                     <CellsDirective>
            //                       <CellDirective
            //                         index={1}
            //                         value={x.$.label}
            //                         style={{
            //                           fontSize: '11pt',
            //                           verticalAlign: 'middle',
            //                         }}
            //                       ></CellDirective>
            //                       <CellDirective
            //                         index={2}
            //                         value={this.process_SpreadsheetValue(
            //                           iddata[0],
            //                           x
            //                         )}
            //                         style={{
            //                           fontSize: '12pt',
            //                           verticalAlign: 'middle',
            //                           textAlign: 'left',
            //                         }}
            //                       ></CellDirective>
            //                     </CellsDirective>
            //                   </RowDirective>
            //                 ))
            //               : ''}
            //           </RowsDirective>
            //           <ColumnsDirective>
            //             <ColumnDirective index={1} width={100}></ColumnDirective>
            //             <ColumnDirective index={2} width={200}></ColumnDirective>
            //           </ColumnsDirective>
            //         </SheetDirective>
            //       </SheetsDirective>
            //     </SpreadsheetComponent>
            //   </>
          );
        }
      }
      var dialogInstance: DialogComponent | any;
      let datedesccolumn: string = '';
      if (this.props.selectedtabname?.toLowerCase() === 'dossier') {
        datedesccolumn = 'date';
      } else if (this.props.selectedtabname?.toLowerCase() === 'contract') {
        datedesccolumn = 'dateStart';
      }
      // _final = <div id="divgrid-tabdetails" style={{ marginTop: '40px', height: 'calc(100vh - 185px)', overflow: 'auto' }}>
      //     <GridComponent id="grid-tabdetails" dataSource={this.props._jObject[Common.toCamelCase(this.props.selectedtabname!.toString())]} ref={(obj) => gridInstance = obj} selectionSettings={{ persistSelection: true }} recordDoubleClick={onDblClick} allowResizing={true} gridLines={'Both'} >
      //         <ColumnsDirective>
      //             {/*<ColumnDirective field='account.name' headerText='' width='10' template={doctemplate}></ColumnDirective>
      //             <ColumnDirective field='date' headerText='Date' width='100' type='datetime' format='dd-MMM-yyyy'></ColumnDirective>
      //             <ColumnDirective field='account.name' headerText='Account' width='100'></ColumnDirective>
      //             <ColumnDirective field='note' headerText='Note' width='100'></ColumnDirective> */}
      //             {(this.props._json !== null ?
      //                 this.props._json.Grid.Columns.Column.map((x: any) =>
      //                     //<ColumnDirective key='id' field='account.name' headerText='Account' width='100'></ColumnDirective>
      //                     <ColumnDirective key='id' field={x.$.source} headerText={x.$.label} width={`${x.$.width}%`} toolTip={x.$.label}
      //                         type={x.$.type ? x.$.type : Common.datescolumns.includes(x.$.source.toString().toLowerCase()) ? 'datetime' : 'string'}
      //                         format={x.$.type === 'number' ? x.$.decimal ? `N${x.$.decimal}` : 'N2' : Common.datescolumns.includes(x.$.source.toString().toLowerCase()) ? this.user.settings[0].formats.DateShort : ''}
      //                         template={x.$.icon && x.$.icon === 'true' ? icontemplate : null}
      //                         textAlign={x.$.type === 'number' ? 'Right' : undefined}
      //                     ></ColumnDirective>

      //                 ) : null)}
      //         </ColumnsDirective>
      //         <Inject services={[Resize]} />
      //     </GridComponent>
      //     <SelectorSearch grid_id='grid-tabdetails' />
      //     {
      //         this.state.showword === true ?
      //             <DialogComponent id="defaultDialog" showCloseIcon={true} visible={this.state.showword} width={'90%'} height={'90%'} style={{ overflow: 'auto' }} target={'#root'} ref={(dialog) => dialogInstance = dialog} close={() => this.setState({ showword: false })}
      //                 content={this.dialogContent.bind(this)}
      //                 isModal={true}
      //             >
      //                 {/* {this.state.showword === true ?
      //             <DocumentViewer data={this.state.docdata} indialogbox={true} />
      //             : null} */}
      //             </DialogComponent>
      //             : null
      //     }
      // </div >
      _final = (
        <div id='composer-dossier-contract'>
          <div style={{ height: '10px' }}>&nbsp;</div>
          <DataGrid
            id={
              'grid-tabdetails_' +
              Common.toCamelCase(this.props.selectedtabname!.toString())
            }
            dataSource={
              this.props._jObject[
                Common.toCamelCase(this.props.selectedtabname!.toString())
              ]
            }
            jsonGridSettings={this.props._json}
            //marginTop="40px"
            showSearchBar={true}
            //height={`calc(100vh - ${this.props.selectedtabname!.toString().toLowerCase() === 'dossier' ? '319' : '271'}px)`}
            height={`calc(100vh - ${
              this.props.datagridAllowGrouping ? '271' : '271'
            }px)`}
            recordDoubleClick={onDblClick}
            rowSelected={onRowSelected}
            enableInfiniteScrolling={false}
            //allowGrouping={this.props.selectedtabname!.toString().toLowerCase() === 'dossier' ? true : false}
            allowGrouping={this.props.datagridAllowGrouping ?? false}
            //groupSettings={this.props.selectedtabname!.toString().toLowerCase() === 'dossier' ? { showGroupedColumn: false } : undefined}
            groupSettings={
              this.props.datagridAllowGrouping
                ? { showGroupedColumn: false, showDropArea: false }
                : undefined
            }
            //width={this.props.selectedtabname!.toString().toLowerCase() === 'contract' ? '40%' : undefined}
            width={this.props.datagridWidth ?? undefined}
            classname='datagrid-min-width'
            //contextMenuItems={cntxitems}
            //contextMenuClick={cntxmenuclick}
            dataBound={onGridDataBound}
            DateDescendingColumn={datedesccolumn}
            ref={(obj: any) => (this.GridInstance = obj)}
            dataContextmenuid={
              this.props.selectedtabname!.toLowerCase() === 'contract'
                ? '_contract_'
                : ''
            }
            showMoreOptions={
              this.props.selectedtabname!.toLowerCase() === 'contract'
            }
          />

          {this.props.subcardxml &&
          this.state.contractid !== '' &&
          this.state.contractdetail !== undefined ? (
            <div
              className='control-pane ps-2'
              style={{
                width: '73%',
                float: 'left',
                height: `calc(100vh - 201px)`,
              }}
            >
              {_subcard}
            </div>
          ) : null}
          {this.state.showword === true ? (
            <DialogComponent
              id='defaultDialog-dossier'
              showCloseIcon={true}
              visible={this.state.showword}
              width={'70%'}
              height={'90%'}
              style={{ overflow: 'auto' }}
              target={'#root'}
              ref={(dialog) => (dialogInstance = dialog)}
              close={() => this.setState({ showword: false })}
              content={this.dialogContent.bind(this)}
              isModal={true}
            >
              {/* {this.state.showword === true ?
                        <DocumentViewer data={this.state.docdata} indialogbox={true} />
                        : null} */}
            </DialogComponent>
          ) : null}
        </div>
      );
    } else {
    }

    return (
      <>
        <div
          id='composer'
          data-contextmenuid='composer'
          className='overflow-auto'
          style={{
            height: `calc(100vh - ${this.user.isManager ? '145px' : '248px'})`,
          }}
        >
          {_final != undefined ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                //overflow: 'auto',
              }}
            >
              {_final}
            </div>
          ) : (
            ''
          )}
        </div>
        <div id='cmenu'>
          {this.props.showCommonCntxMenu == true ? (
            <ContextMenuComponent
              id={Math.random().toString() + 'contextmenu'}
              ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
              // items={this.state._menuitems}
              items={this.iMenuItems}
              beforeOpen={this.onbeforeOpen}
              select={this.handleMenuClick}
              target='#composer'
            />
          ) : null}
        </div>
        <div>
          {this.state.showDocDialog === true ? (
            <DialogComponent
              id='defaultDialog1'
              showCloseIcon={true}
              visible={this.state.showDocDialog}
              width={
                this.props.selectedtabname?.toLowerCase() === 'contract' ||
                this.props.selectedtabname?.toLowerCase() === 'bio'
                  ? '40vw'
                  : '40vh'
              }
              height={'50hv'}
              close={() => this.setState({ showDocDialog: false })}
              content={this.DailogContent.bind(this)}
              isModal={true}
              header={this._dlgHeader}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
      </>
    );
  }
}

export default Composer;
