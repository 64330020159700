import * as React from 'react';
import Common from '../../Util/Common';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  DatePickerComponent,
  DateRangePickerComponent,
} from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { content } from '@syncfusion/ej2-react-grids';

interface Props {
  onSave: (response: any) => void;
  onCancel: () => void;
  leaveobject: any;
  statusid: number;
}

const { REACT_APP_ENDPOINT_LEAVESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

export default class LeaveApproval extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private dRange: DateRangePickerComponent | any;
  private sText: TextBoxComponent | any = undefined;
  private disabledDates: any = [];

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    leavetypes: [],
    isOpen: false,
    holidays: [],
    weekends: [],
  };

  componentDidMount(): void {
    if (this.state.leavetypes.length <= 0) {
      this.fetchleavetypes();
    }

    if (this.state.holidays.length <= 0) {
      this.fetchHoliday(new Date().getFullYear())
    }

    if (this.state.weekends.length <= 0) {
      this.fetchemployee();
    }
  }

  private fetchemployee() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search${!this.user.isManager ? '/ess' : ''
      }`,
      { id: this.props.leaveobject.employeeId },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let dayids: any
        dayids = response.data.employeeShift[0].shift.shiftTran.filter((s: any) => s.isWeekend === true);
        this.setState({
          weekends: dayids
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({ holidays: response.data })
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ leavetypes: resopnse.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private datechange(e: any) {
    if (this.dRange && this.dRange.value !== null) {
      let sRange = Common.getDatesInRange(
        this.dRange.value[0],
        this.dRange.value[1]
      );

      this.disabledDates = this.dRange.disabledDays;

      let uniqueElements: any = [];
      if (this.disabledDates !== undefined) {
        for (let i = 0; i < sRange.length; i++) {
          let sDate = sRange[i];
          if (this.disabledDates.filter((d: any) => new Date(d).getDate() == new Date(sDate).getDate() && new Date(d).getMonth() == new Date(sDate).getMonth()).length <= 0) {
            uniqueElements.push(sDate);
          }
        }
      }

      if (uniqueElements.length > 0) {
        sRange = uniqueElements;
      }

      (document.getElementById('lvRequested') as any).value = sRange.length;
    }
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSave = async () => {
    try {
      if (this.dRange.value == null) {
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, From Date and To Date are mandatory information.Please set correct dates to continue.</p>',
        });
        return;
      }
      let final = false;
      let ilevel = 0;
      final = this.props.leaveobject.leaveApproval
        .flatMap((approval: any) => approval.leaveApprovers)
        .filter(
          (x: any) =>
            x.leaveApplicationId == (this.props.leaveobject as any)!.id &&
            x.employeeId == this.user.employee.id
        )[0].isFinal;
      ilevel = this.props.leaveobject.leaveApproval
        .flatMap((approval: any) => approval.leaveApprovers)
        .filter(
          (x: any) =>
            x.leaveApplicationId == (this.props.leaveobject as any).id &&
            x.employeeId == this.user.employee.id
        )[0].level;

      let dateRange: any = [];
      let sRange = Common.getDatesInRange(
        this.dRange.value[0],
        this.dRange.value[1]
      );

      let uniqueElements: any = [];
      if (this.disabledDates !== undefined) {
        for (let i = 0; i < sRange.length; i++) {
          let sDate = sRange[i];
          if (this.disabledDates.filter((d: any) => new Date(d).getDate() == new Date(sDate).getDate() && new Date(d).getMonth() == new Date(sDate).getMonth()).length <= 0) {
            uniqueElements.push(sDate);
          }
        }
      }

      if (uniqueElements.length > 0) {
        sRange = uniqueElements;
      }

      for (let i = 0; i < sRange.length; i++) {
        dateRange.push({
          leaveApplicationId: (this.props.leaveobject as any)!.id,
          appliedDate: Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(new Date(sRange[i])),
            'yyyy-MM-dd'
          ),
          dayFraction: 1,
          leaveApprovalId: this.user.employee.id,
        });
      }

      if (this.props.leaveobject.leaveType.isAccrued == true) {
        if (
          parseFloat((document.getElementById('lvbalance') as any).value) <= 0
        ) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, Selected leave type does not have any balance set for the selected employee.</p>',
          });

          return;
        }

        if (
          parseFloat((document.getElementById('lvbalance') as any).value) <
          dateRange.length
        ) {
          let avail: string = (document.getElementById('lvbalance') as any)
            .value;
          let reqst: string = dateRange.length;

          DialogConfirm.showDialog({
            content:
              '<div clas="new" style="display: flex;flex-direction: column;"><p>Leave request exceeds available balance.</p><p>Available:' +
              `${avail}` +
              ' day(s). </p><p>Requested: ' +
              `${reqst}` +
              ' day(s).</p></div >',
          });

          return;
        }
      }

      let payload = {
        leaveApplicationId: (this.props.leaveobject as any)!.id,
        employeeId: this.user.employee.id,
        approvalDate: new Date(),
        approvalRemark: this.sText.value,
        approvedStartDate: Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(this.dRange.value[0]),
          'yyyy-MM-dd'
        ),
        approvedEndDate: Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(this.dRange.value[1]),
          'yyyy-MM-dd'
        ),
        approvedDays: dateRange.length,
        statusId: this.props.statusid,
        isFinal: final,
        leaveAppliedDays: dateRange,
        level: ilevel,
      };

      const response = await this.saveData(payload);
      this.props.onSave(response);
    } catch (error) {
      this.setState({ error: 'Error saving data. Please try again.' });
    }
  };

  saveData = async (data: any) => {
    const response = await Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/approveleave`,
      data,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p>Leave request exceeds available balance.</p><p>Available:' +
          //     `${error.response.data.messages.join()}` + '</p>',
          // });
        }
      })
      .finally(() => { });

    return response;
  };

  disabledDate = (e: any) => {
    if (e) {
      if (this.state.holidays.length > 0) {
        if (this.state.holidays.filter((h: any) => new Date(h.date).getTime() == e.date.getTime()).length > 0) {
          e.isDisabled = true;
        }
      }
      if (this.state.weekends.length > 0) {
        if (this.state.weekends.filter((w: any) => w.dayid == e.date.getDay()).length > 0) {
          e.isDisabled = true;
        }
      }
    }
  }

  render() {
    let balance: any = '0';
    if (
      this.props.leaveobject.leaveType.isAccrued == true &&
      this.state.leavetypes.length > 0
    ) {
      let ldata: any = this.state.leavetypes.filter(
        (x: any) => x.id == this.props.leaveobject.leaveTypesId
      );
      if (ldata !== null && ldata.length > 0) {
        if (
          ldata[0].leaveBalances != null &&
          ldata[0].leaveBalances.length > 0
        ) {
          let balances = ldata[0].leaveBalances.filter(
            (x: any) => x.employeeId == this.props.leaveobject.employeeId
          );
          if (balances !== null && balances.length > 0) {
            balance = Number(balances[0].availableToDate).toFixed(2);
          } else {
            balance = '0';
          }
        }
      }
    }

    return (
      <>
        {1 > 0 ? (
          <>
            <div className='row align-items-center'>
              <div className='col-5 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Application Number
                </label>
                <input
                  className='input-text-border'
                  id='lvformnumber'
                  name='lvformnumber'
                  type='text'
                  autoComplete='off'
                  style={{ fontSize: '15px', backgroundColor: 'transparent' }}
                  value={(this.props.leaveobject as any)!.applicationNumber}
                  disabled
                />
              </div>
              <div className='col-5 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Leave Type
                </label>
                <input
                  className='input-text-border'
                  id='lvleavetype'
                  name='lvleavetype'
                  type='text'
                  autoComplete='off'
                  style={{ fontSize: '15px', backgroundColor: 'transparent' }}
                  value={(this.props.leaveobject as any)!.leaveType.name}
                  disabled
                />
              </div>
              <div
                className={
                  this.props.leaveobject.leaveType.isAccrued == true
                    ? 'col-2 py-2'
                    : 'col-2 py-2 d-none'
                }
              >
                <label
                  style={{
                    fontSize: '14px',
                    paddingBottom: '7px',
                    fontWeight: '500',
                  }}
                >
                  Available
                </label>
                <div style={{ display: 'flex' }}>
                  <input
                    id='lvbalance'
                    name='lvbalance'
                    type='text'
                    autoComplete='off'
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      border: '0px',
                      width: '50%',
                    }}
                    readOnly
                    value={balance}
                  />
                  {/* <label style={{ fontSize: '14px', fontWeight: '500' }}>
                    Days
                  </label> */}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-10 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Employee
                </label>
                <input
                  className='input-text-border'
                  id='lvemployee'
                  name='lvemployee'
                  type='text'
                  autoComplete='off'
                  style={{ fontSize: '15px', backgroundColor: 'transparent' }}
                  value={(this.props.leaveobject as any)!.employeeName}
                  disabled
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-10 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Leave Duration
                </label>

                <DateRangePickerComponent
                  ref={(scope) => {
                    this.dRange = scope;
                  }}
                  startDate={
                    this.props.leaveobject == undefined
                      ? new Date()
                      : new Date(this.props.leaveobject.startDate)
                  }
                  endDate={
                    this.props.leaveobject == undefined
                      ? new Date()
                      : new Date(this.props.leaveobject.endDate)
                  }
                  cssClass='e-hidedate-icon'
                  showTodayButton={false}
                  allowEdit={false}
                  showClearButton={true}
                  openOnFocus={true}
                  format={this.user.settings.formats.DateShort}
                  change={this.datechange.bind(this)}
                  renderDayCell={this.disabledDate}
                />
              </div>
              <div className='col-2 py-2' id='divRequested'>
                <label
                  style={{
                    fontSize: '14px',
                    paddingBottom: '7px',
                    fontWeight: '500',
                  }}
                >
                  Requested
                </label>
                <div style={{ display: 'flex' }}>
                  <input
                    id='lvRequested'
                    name='lvRequested'
                    type='text'
                    autoComplete='off'
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      border: '0px',
                      width: '50%',
                    }}
                    readOnly
                    value={(this.props.leaveobject as any)!.appliedDays}
                  />
                  {/* <label style={{ fontSize: '14px', fontWeight: '500' }}> Days</label> */}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-5 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Request Remark
                </label>
                <TextBoxComponent
                  id='lvapprliedremark'
                  multiline={true}
                  value={(this.props.leaveobject as any)!.remark}
                  readOnly
                ></TextBoxComponent>
              </div>
              <div className='col-5 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Approval Remark (optional)
                </label>
                <TextBoxComponent
                  id='lvApprovalremark'
                  multiline={true}
                  ref={(scope) => {
                    this.sText = scope;
                  }}
                ></TextBoxComponent>
              </div>
            </div>
            <div
              className='row'
              style={{
                textAlign: 'right',
                display: 'flex',
                float: 'right',
                paddingTop: '10px',
              }}
            >
              <div>
                <button
                  id='btnICancel'
                  className='flat-button'
                  onClick={this.handleCancel.bind(this)}
                >
                  Cancel
                </button>
                <button
                  id='btnIAccept'
                  className='flat-button'
                  onClick={this.handleSave.bind(this)}
                >
                  Approve
                </button>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}