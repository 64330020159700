// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-22 - 320 - Research and Developement to show tab as per selector click
// * SANDEEP - 2023-04-10 - 420 - Worked on Tab Styles and some changes in the design provided by fabio
// * SUHAIL - 2023-04-10 - 380 - Research and Developement on preventing page refresh on selector click and deployment
// * SANDEEP - 2023-04-10 - 420 - Worked on Tab Styles and some changes in the design provided by fabio
// * SANDEEP - 2023-04-21 - 200 - Worked on changes as per the design and pager control on different project
// * SANDEEP - 2023-05-02 - 120 -- Reading newly made xml file with changes.
// * SANDEEP - 2023-05-03 - 150 -- Removed Parsing Logic from Composer & Moved to Dataviewer
// * SANDEEP - 2023-04-08 - 60 -- Meeting
// * SANDEEP - 2023-04-08 - 120 -- Removing the data repetition and trying to generate with minimum xml model
// * SANDEEP - 2023-05-17 - 180 -- Generate xml for education and experience and displayed the data.
// * SUHAIL - 2023-05-23 - 90 - meeting with fabio on local database connection and multi company login
// * SUHAIL - 2023-05-23 - 390 - Adapt login flow to the new structure 1.5.26
// * SUHAIL - 2023-05-24 - 210 - working on creating sample xml for dossier and contract to display custom columns
// * SUHAIL - 2023-05-24 - 60 - working with avinash to apply holidays when selected year changed
// * SUHAIL - 2023-05-24 - 60 - weekly status meeting
// * SUHAIL - 2023-05-24 - 30 - meeting with fabio on calling foxsystem api by using user object in header for amos project
// * SUHAIL - 2023-05-25 - 50 - sprint planning meeting
// * SUHAIL - 2023-05-25 - 40 - meeting with fabio on create employee and contract xml
// * SUHAIL - 2023-05-25 - 380 - working on creating xml for dossier to display dossier data in employee
// * SUHAIL - 2023-05-26 - 35 - sprint review meeting
// * SUHAIL - 2023-05-26 - 120 - helping sourav on image not showing on employee change in dialog component
// * SUHAIL - 2023-05-26 - 325 - working, building, deploying and testing gntz and cisp company multi user
// * SUHAIL - 2023-06-07 - 150 - meeting with fabio and saurav on task discussion
// * SUHAIL - 2023-06-07 - 50 - weekly status meeting
// * SUHAIL - 2023-06-07 - 80 - guiding sourav and avinash on aruti 2.0 code structure
// * SUHAIL - 2023-06-07 - 200 - Provide grouping column settings in xml to display dossier group by Account

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';
import React, { Component } from 'react';
import Composer from '../Composer';
import Login from '../Login/Login';
import bio_data from '../../dummydata/xml_data_bio.js';
import address_data from '../../dummydata/xml_data_address.js';
import addresslist from '../../dummydata/xml_data_addresslist.js';
import learninglist from '../../dummydata/xml_learninginfo.js';
import XCard from '../XCard';
import Common, { JsonTree } from '../../Util/Common';
import DocumentViewer from './DocumentViewer';
import LeaveProcess from '../LeaveProcess';
import {
  dossier_data,
  contract_data,
  contractcard_data,
  loan_data,
  contract_alldata,
  leave_type_data,
} from '../../dummydata/xml_data_employee';
import Attendance from '../NewAttendance';
import Dashboard from './Dashboard/Dashboard';
import WordTemplate from '../Template/WordTemplate';
import PeriodList from '../PeriodSelector/PeriodList';
import WorkScheduleList from '../WorkSchedule/WorkScheduleList';
import PayheadList from '../PayHeads/PayheadList';
import Payroll from '../Payroll/Payroll';
import AllocationList from '../Allocation/AllocationList';
import UserList from '../User/UserList';
import Reports from '../CustomReport/Reports';
import Email from '../Email/Email';
import LeaveInfo from '../Leave/LeaveInfo';
import ResourceLinkList from '../Company/ResourceLink/ResourceLinkList';
import HolidayList from '../Holidays/HolidayList';
import CompanyList from '../Company/Company/CompanyList';

// * UNCOMMENT IF XML2JS ARE REQUIRED
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * -----------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-03-31 - 15
interface tabstructure {
  id: string;
  object: string;
  name: string;
  label: string;
}
// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SANDEEP - 2023-08-10 - 05
interface Props {
  selectorService?: any;
  jsonDataViewer: any;
  rootmenu: any;
  selectedmenu: any;
  selectedtab: any;
  selectedtabindex: number;
  //jsonDocument?: any;
  //handleDocumentSave?: any;
  showganttchart: boolean;
  refreshSelector?: any;
  showdashboard?: boolean;
  showPayslip?: boolean;
  id?: string;
}

// * -------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
class DataViewer extends React.Component<Props> {
  // * ------------------------------------------------------------------------------------------------------------------2.Initialize Component
  // * SUHAIL - 2023-03-31 - 10
  private _structure: any;
  private tabset: any = null;
  private js: any = null;
  private user: any;
  private tabs: tabstructure[] = [];
  private isTabAdded: boolean;
  //private controlType: any;
  private child: Composer | any;
  private _fulljson: any;
  private _showcommon_cntxmneu: boolean = false;
  private isTabsRefresh: boolean = false;
  private tabInstance: TabComponent | any = undefined;
  private componentname: string = '';

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    //this._fulljson = JSON.parse(Common.getFullJson() ?? '');

    if (
      this.props.selectorService &&
      (this.props.selectorService.Id ===
        this._structure.SidebarConstants.SidebarService.Person.Id ||
        this.props.selectorService.Id ===
          this._structure.SidebarConstants.SidebarService.Employee.Id)
    ) {
      this._showcommon_cntxmneu = true;
    }
    //this.controlType = 0;
    if (
      this.props.selectedmenu &&
      this.props.selectedmenu.dynamicSelector &&
      this.props.selectedmenu.dynamicSelector.dynamicViewer &&
      this.props.selectedmenu.dynamicSelector.dynamicViewer.dynamicTab &&
      this.props.selectedmenu.dynamicSelector.dynamicViewer.dynamicTab.length >
        0
    ) {
      this.tabset =
        this.props.selectedmenu.dynamicSelector.dynamicViewer.dynamicTab;
    } else if (
      this.props.selectedmenu &&
      this.props.selectedmenu.dynamicViewer &&
      this.props.selectedmenu.dynamicViewer.dynamicTab &&
      this.props.selectedmenu.dynamicViewer.dynamicTab.length > 0
    ) {
      this.tabset = this.props.selectedmenu.dynamicViewer.dynamicTab;
    } else if (
      this.props.jsonDataViewer &&
      this.props.jsonDataViewer.length > 0 &&
      this.props.jsonDataViewer[0].taskData.tabs &&
      this.props.jsonDataViewer[0].taskData.tabs.length > 0
    ) {
      this.tabset = this.props.jsonDataViewer[0].taskData.tabs;
    }

    if (
      this.props.jsonDataViewer &&
      this.props.jsonDataViewer.length > 0 &&
      this.props.jsonDataViewer[0].taskData &&
      this.props.jsonDataViewer[0].taskData.componentName
    ) {
      this.componentname = this.props.jsonDataViewer[0].taskData.componentName;
    } else if (
      this.props.jsonDataViewer &&
      this.props.jsonDataViewer['componentName'] &&
      this.props.jsonDataViewer['componentName'].startsWith('_')
    ) {
      this.componentname = this.props.jsonDataViewer['componentName'];
    }

    // if (this.props.selectedmenu !== null) {
    //   this.controlType = Common.FindJsonByRelationFieldId(
    //     this.props.selectedmenu.children,
    //     this._structure.UiComponents.ControlType
    //   );
    //   this.tabset = Common.FindJsonByRelationFieldId(
    //     this.props.selectedmenu.children,
    //     this._structure.Parameters.TabsSet
    //   ); //9182 = TabSet
    // }
    this.isTabAdded = false;

    let tabst: tabstructure;
    if (this.tabset !== null) {
      const fromIndex = this.tabset.findIndex(
        (item: any) => item.id === 'taxinfo'
      );
      const toIndex =
        this.tabset.findIndex((item: any) => item.id === 'bank') + 1;

      if (fromIndex !== -1 && toIndex !== -1) {
        const item = this.tabset.splice(fromIndex, 1)[0];
        this.tabset.splice(toIndex, 0, item);
      }

      for (let idx = 0; idx < this.tabset.length; idx++) {
        tabst = {
          id: this.tabset[idx].id,
          object: this.tabset[idx].id,
          name: this.tabset[idx].name,
          label: this.tabset[idx].name,
        };
        this.tabs.push(tabst);
      }
      //   this.tabset.children.forEach((x: any) => {
      //     let idx: any = this.tabs.findIndex((p: any) => {
      //       return p.object === x.object;
      //     });
      //     if (idx < 0) {
      //       idx = this.tabs.length;
      //       tabst = {
      //         idx: this.tabs.length,
      //         object: x.object,
      //         name: ,
      //         label: '',
      //       };
      //this.tabs.push(tabst);
    }

    //     switch (x.field) {
    //       case this._structure.Field.Identif.Name:
    //         tabst = { ...this.tabs[idx], name: x.value };
    //         this.tabs[idx] = tabst;
    //         break;

    //       case this._structure.Field.Identif.Label:
    //       case this.user.language:
    //         tabst = { ...this.tabs[idx], label: x.val<ue };
    //         this.tabs[idx] = tabst;
    //         break;
    //     }
    //   });
    // }
    this.child = React.createRef();
  }

  static defaultProps = {
    id: '',
  };
  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonDataViewer: this.props.jsonDataViewer,
    _selectedtab: undefined,
    _menuitems: undefined,
    selectedtabindex: this.props.selectedtabindex,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {
    // if (this.tabInstance) {
    //   this.tabCreated();
    // }
  }

  addnew_click(evt: any) {
    switch (this.state.selectedtabindex) {
      case 0:
        this.child.current.AddAccordion();
        break;
      case 1:
        break;
      default:
        break;
    }
  }

  // * -------------------------------------------------------------------------------------------------------------------4.Api

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  private findUpTag(el: any, tag: string) {
    while (el.parentNode) {
      el = el.parentNode;
      if (el.id === tag) return el;
    }
    return null;
  }

  private ParseXML(value: string) {
    var XMLParser = require('react-xml-parser');
    var xml = new XMLParser().parseFromString(value);
    return xml;
  }

  public getSelectedSelector(data: any) {
    this.setState({ jsonDataViewer: data });
  }

  private applyStyle(args: any) {
    if (!args) return;
    for (
      var i = 0;
      i <
      document
        .getElementById(this.tabInstance.id)!
        .querySelectorAll('.tab-highlighter').length;
      i++
    ) {
      var el: any;
      el = document
        .getElementById(this.tabInstance.id)!
        .querySelectorAll('.tab-highlighter')[i];
      if (el !== null) {
        el.className = 'e-tab-text';
      }
    }

    for (
      var i = 0;
      i <
      document
        .getElementById(this.tabInstance.id)!
        .querySelectorAll('.e-addicon-hide').length;
      i++
    ) {
      var el: any;
      el = document
        .getElementById(this.tabInstance.id)!
        .querySelectorAll('.e-addicon-hide ')[i];
      if (el !== null) {
        el.className = 'e-addicon';
      }
    }

    var _id: any;
    if (args.selectedItem !== undefined && args.selectedItem.id !== null) {
      _id = args.selectedItem.id;
    } else {
      _id = args.id;
      let obj: any = document
        .getElementById(this.tabInstance.id)
        ?.querySelector('.e-toolbar-item.e-template.e-active');
      if (obj) {
        _id = obj.id;
      }
    }

    if (_id !== undefined && _id !== null) {
      var obj: any = document.getElementById(_id);
      if (obj !== null) {
        if (obj.classList.contains('e-active')) {
          for (var i = 0; i < obj.querySelectorAll('.e-tab-text').length; i++) {
            var el: any;
            el = obj.querySelectorAll('.e-tab-text')[i];
            if (el !== null) {
              el.className = 'e-tab-text tab-highlighter';
            }
          }
        }
      }
      if (this.HideButton(obj) == true) {
        for (
          var i = 0;
          i <
          document
            .getElementById(this.tabInstance.id)!
            .querySelectorAll('.e-addicon').length;
          i++
        ) {
          var el: any;
          el = document
            .getElementById(this.tabInstance.id)!
            .querySelectorAll('.e-addicon')[i];
          if (el !== null) {
            el.className = 'e-addicon-hide';
          }
        }
      }
    }
  }

  private HideButton(ele: any) {
    // let res = Boolean;

    if (
      ele.innerText !== undefined &&
      (ele.innerText as any).toLowerCase() === 'attendance'
    ) {
      return true;
    } else if (
      ele.innerText !== undefined &&
      (ele.innerText as any).toLowerCase() === 'leaves'
    ) {
      return true;
    } else if (
      ele.innerText !== undefined &&
      (ele.innerText as any).toLowerCase() === 'pay slips'
    ) {
      return true;
    }
    // else {
    //   this.props.selectedtab?.toLowerCase() !== 'attendance'{
    //     return false
    //   }
    // }
    // return res;
  }

  private tabCreated = (): void => {
    if (this.state.jsonDataViewer === null || this.componentname !== '') {
      return;
    }

    let itemsData: Object[] = [];
    let tab: string[] = [];

    if (this.tabs.length <= 0) {
      if (
        (this.state.jsonDataViewer &&
          this.state.jsonDataViewer.constructor === {}.constructor) ||
        (Array.isArray(this.state.jsonDataViewer) &&
          this.state.jsonDataViewer.length > 0)
      ) {
        //tab.push(this.props.serviceName);
        //tab.push('Bio');
        if (
          this.props.selectorService.Id ==
          this._structure.SidebarConstants.SidebarService.Person.Id
        ) {
          tab.push('Bio');
        } else {
          tab.push(this.props.selectorService.Name);
        }
      }

      this.state.jsonDataViewer &&
        Object.keys(this.state.jsonDataViewer).forEach((r: any) =>
          (Array.isArray(this.state.jsonDataViewer[r]) &&
            r.toString().toLowerCase() !== 'bio') ||
          (this.state.jsonDataViewer[r] &&
            this.state.jsonDataViewer[r].constructor === {}.constructor)
            ? tab.push(r)
            : null
        );
    } else {
      this.tabs.forEach((x: any) => {
        tab.push(x.label);
      });
      this.isTabAdded = true;
    }
    let headertext: any = [
      { text: 'Twitter', iconCss: 'e-twitter' },
      { text: 'Facebook', iconCss: 'e-facebook' },
      { text: 'WhatsApp', iconCss: 'e-whatsapp' },
    ];

    for (let i: number = 0; i < tab.length; i++) {
      // if (i == 0) {
      //     // let cntnt: any = {};
      //     // Object.keys(this.state.jsonDataViewer).forEach(r => {
      //     //     if (!(Array.isArray(this.state.jsonDataViewer[r]) || this.state.jsonDataViewer[r].constructor === ({}).constructor)) {
      //     //         cntnt[r] = this.state.jsonDataViewer[r]
      //     //     }
      //     // })
      //     let cntnt: any = this.state.jsonDataViewer[tab[i]];
      //     //itemsData.push({ header: { text: tab[i] }, content: JSON.stringify(cntnt) });
      //     itemsData.push({ header: { text: tab[i] }, content: "" });

      // }
      // else {
      //     if (tab[i].toLowerCase() == "bio") {
      //         let cntnt: any =
      //             () => {
      //                 return (<Composer _jObject={this.state.jsonDataViewer} />)
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else {
      //         let cntnt: any = this.state.jsonDataViewer[tab[i]];
      //         //itemsData.push({ header: { text: tab[i] }, content: JSON.stringify(cntnt) });
      //         itemsData.push({ header: { text: tab[i] }, content: "" });
      //     }
      // }

      var _finalxml: any;
      //     if (tab[i].toLowerCase() == "bio") {
      //         let cntnt: any =
      //             () => {
      //                 // var _idata = Common.FindJsonByFieldId(this.props.selectedmenu.children, this._structure.Field.Identif.Composer);
      //                 // if (_idata !== undefined) {
      //                 //     _finalxml = this.ParseXML(_idata.value);
      //                 //     return (<Composer _jObject={this.state.jsonDataViewer} _xml={_finalxml} selectedtabname={tab[i]} />)
      //                 // }
      //                 // else {
      //                 //     return ''
      //                 // }

      //                 _finalxml = this.ParseXML(bio_data);
      //                 return (<Composer _jObject={this.state.jsonDataViewer} _xml={_finalxml} selectedtabname={tab[i]} />)

      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     } else if (tab[i].toLowerCase() == "contact") {
      //         let cntnt: any =
      //             () => {
      //                 _finalxml = this.ParseXML(addresslist);
      //                 var _emergency = _finalxml.getElementsByTagName('Address').filter((x: any) => x.attributes.type == 'Emergency')[0];
      //                 return (<Composer _jObject={this.state.jsonDataViewer} _xml={_emergency} selectedtabname={tab[i]} />)
      //                 //_finalxml = this.ParseXML(address_data);
      //                 //return (<Composer _jObject={this.state.jsonDataViewer} _xml={_finalxml} />)
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else if (tab[i].toLowerCase() == "address") {
      //         let cntnt: any =
      //             () => {
      //                 _finalxml = this.ParseXML(addresslist);
      //                 var _personal = _finalxml.getElementsByTagName('Address').filter((x: any) => x.attributes.type == 'Personal')[0];
      //                 if (_personal !== undefined) {
      //                     return (<Composer _jObject={this.state.jsonDataViewer} _xml={_personal} selectedtabname={tab[i]} />)
      //                 }
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else if (tab[i].toLowerCase() == "reference") {
      //         let cntnt: any =
      //             () => {
      //                 _finalxml = this.ParseXML(addresslist);
      //                 var _reference = _finalxml.getElementsByTagName('Address').filter((x: any) => x.attributes.type == 'Reference')[0];
      //                 if (_reference !== undefined) {
      //                     return (<Composer _jObject={this.state.jsonDataViewer} _xml={_reference} selectedtabname={tab[i]} />)
      //                 }
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else if (tab[i].toLowerCase() == "education") {
      //         let cntnt: any =
      //             () => {
      //                 _finalxml = this.ParseXML(learninglist);
      //                 var _education = _finalxml.getElementsByTagName('DataValue').filter((x: any) => x.attributes.type == 'Education')[0];
      //                 if (_education !== undefined) {
      //                     return (<Composer _jObject={this.state.jsonDataViewer} _xml={_education} selectedtabname={tab[i]} />)
      //                 }
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else if (tab[i].toLowerCase() == "experience") {
      //         let cntnt: any =
      //             () => {
      //                 _finalxml = this.ParseXML(learninglist);
      //                 var _experience = _finalxml.getElementsByTagName('DataValue').filter((x: any) => x.attributes.type == 'Experience')[0];
      //                 if (_experience !== undefined) {
      //                     return (<Composer _jObject={this.state.jsonDataViewer} _xml={_experience} selectedtabname={tab[i]} />)
      //                 }
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else if (tab[i].toLowerCase() == "dossier") {
      //         let cntnt: any =
      //             () => {
      //                 return (<Composer _jObject={this.state.jsonDataViewer} _xml={null} selectedtabname={tab[i]} />)
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else if (tab[i].toLowerCase() == "contract") {
      //         let cntnt: any =
      //             () => {
      //                 return (<Composer _jObject={this.state.jsonDataViewer} _xml={null} selectedtabname={tab[i]} />)
      //             };
      //         itemsData.push({ header: { text: tab[i] }, content: cntnt });
      //     }
      //     else {
      //         //let cntnt: any = this.state.jsonDataViewer[tab[i].toString()];
      //         //itemsData.push({ header: { text: tab[i] }, content: JSON.stringify(cntnt) });
      //         itemsData.push({ header: { text: tab[i] }, content: '' });
      //     }
      // }

      if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'bio'
      ) {
        let cntnt: any = () => {
          // var _idata = Common.FindJsonByFieldId(this.props.selectedmenu.children, this._structure.Field.Identif.Composer);
          // if (_idata !== undefined) {
          //     _finalxml = this.ParseXML(_idata.value);
          //     return (<Composer _jObject={this.state.jsonDataViewer} _xml={_finalxml} selectedtabname={tab[i]} />)
          // }
          // else {
          //     return ''
          // }

          _finalxml = this.ParseXML(bio_data);
          return (
            <Composer
              ref={this.child}
              _jObject={this.state.jsonDataViewer}
              _xml={_finalxml}
              selectedtabname={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0].name
              }
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'contact'
      ) {
        let cntnt: any = () => {
          _finalxml = this.ParseXML(addresslist);
          var _emergency = _finalxml
            .getElementsByTagName('address')
            .filter((x: any) => x.attributes.type == 'Emergency')[0];
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={_emergency}
              selectedtabname={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0].name
              }
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.props.selectorService &&
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'address' &&
        this.props.selectorService.Id ===
          this._structure.SidebarConstants.SidebarService.Person.Id
      ) {
        let cntnt: any = () => {
          _finalxml = this.ParseXML(addresslist);
          var _personal = _finalxml
            .getElementsByTagName('address')
            .filter((x: any) => x.attributes.type == 'Personal')[0];
          if (_personal !== undefined) {
            return (
              <Composer
                _jObject={this.state.jsonDataViewer}
                _xml={_personal}
                selectedtabname={
                  this.tabs.filter(
                    (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                  )[0].name
                }
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          }
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'reference'
      ) {
        let cntnt: any = () => {
          _finalxml = this.ParseXML(addresslist);
          var _reference = _finalxml
            .getElementsByTagName('Address')
            .filter((x: any) => x.attributes.type == 'Reference')[0];
          if (_reference !== undefined) {
            return (
              <Composer
                _jObject={this.state.jsonDataViewer}
                _xml={_reference}
                selectedtabname={
                  this.tabs.filter(
                    (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                  )[0].name
                }
                showCommonCntxMenu={this._showcommon_cntxmneu}
              />
            );
          }
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'education'
      ) {
        // let cntnt: any = () => {
        //   _finalxml = this.ParseXML(learninglist);
        //   var _education = _finalxml
        //     .getElementsByTagName('DataValue')
        //     .filter((x: any) => x.attributes.type == 'Education')[0];
        //   if (_education !== undefined) {
        //     return (
        //       <Composer
        //         _jObject={this.state.jsonDataViewer}
        //         _xml={_education}
        //         selectedtabname={
        //           this.tabs.filter(
        //             (x) => x.label.toLowerCase() === tab[i].toLowerCase()
        //           )[0].name
        //         }
        //         showCommonCntxMenu={this._showcommon_cntxmneu}
        //       />
        //     );
        //   }
        // };
        // itemsData.push({ header: { text: tab[i] }, content: cntnt });
        itemsData.push({ header: { text: tab[i] }, content: '' });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'experience'
      ) {
        // let cntnt: any = () => {
        //   _finalxml = this.ParseXML(learninglist);
        //   var _experience = _finalxml
        //     .getElementsByTagName('DataValue')
        //     .filter((x: any) => x.attributes.type == 'Experience')[0];
        //   if (_experience !== undefined) {
        //     return (
        //       <Composer
        //         _jObject={this.state.jsonDataViewer}
        //         _xml={_experience}
        //         selectedtabname={
        //           this.tabs.filter(
        //             (x) => x.label.toLowerCase() === tab[i].toLowerCase()
        //           )[0].name
        //         }
        //         showCommonCntxMenu={this._showcommon_cntxmneu}
        //       />
        //     );
        //   }
        // };
        // itemsData.push({ header: { text: tab[i] }, content: cntnt });
        itemsData.push({ header: { text: tab[i] }, content: '' });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'dossier'
      ) {
        let cntnt: any = () => {
          var _result: any;
          parser.parseString(dossier_data, function (err: any, result: any) {
            _result = JSON.parse(JSON.stringify(result));
          });
          let jsondviewer: any = this.state.jsonDataViewer;

          jsondviewer[tab[i].toLowerCase()] = this.state.jsonDataViewer[
            tab[i].toLowerCase()
          ].sort((a: any, b: any) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          });

          return (
            <Composer
              //_jObject={this.state.jsonDataViewer}
              _jObject={jsondviewer}
              _xml={null}
              _json={_result}
              selectedtabname={tab[i]}
              datagridAllowGrouping={true}
              selectorService={this.props.selectorService}
              selectedmenu={this.props.selectedmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'contracts'
      ) {
        let cntnt: any = () => {
          var _result: any;
          parser.parseString(contract_data, function (err: any, result: any) {
            _result = JSON.parse(JSON.stringify(result));
          });
          let jsondviewer: any = this.state.jsonDataViewer;
          // if (this.state.jsonDataViewer[tab[i].toLowerCase()]) {
          // 	jsondviewer[tab[i].toLowerCase()] = this.state.jsonDataViewer[
          // 		tab[i].toLowerCase()
          // 	].sort((a: any, b: any) => {
          // 		return (
          // 			new Date(b.startDate).getTime() -
          // 			new Date(a.startDate).getTime()
          // 		);
          // 	});
          // } else {
          // 	jsondviewer['contract'] = this.state.jsonDataViewer[
          // 		'contract'
          // 	].sort((a: any, b: any) => {
          // 		return (
          // 			new Date(b.startDate).getTime() -
          // 			new Date(a.startDate).getTime()
          // 		);
          // 	});
          // }
          //var subcardxml: any = this.ParseXML(contractcard_data);
          var subcardxml: any;
          parser.parseString(
            contract_alldata,
            function (err: any, result: any) {
              subcardxml = JSON.parse(JSON.stringify(result));
            }
          );

          return (
            <Composer
              //_jObject={this.state.jsonDataViewer}
              _jObject={jsondviewer}
              _xml={null}
              _json={_result}
              selectedtabname={'Contract'}
              selectorService={this.props.selectorService}
              selectedmenu={this.props.selectedmenu}
              datagridWidth={_result.Grid.$.width ?? undefined}
              subcardxml={subcardxml}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              refreshTabs={this.refreshTabs}
            />
          );
        };

        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'pay heads'
      ) {
        let cntnt: any = () => {
          var _result: any;
          parser.parseString(contract_data, function (err: any, result: any) {
            _result = JSON.parse(JSON.stringify(result));
          });

          //var subcardxml: any = this.ParseXML(contractcard_data);
          var subcardxml: any;
          parser.parseString(
            contract_alldata,
            function (err: any, result: any) {
              subcardxml = JSON.parse(JSON.stringify(result));
            }
          );

          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={_result}
              selectedtabname={'PayHeads'}
              datagridWidth={_result.Grid.$.width ?? undefined}
              subcardxml={subcardxml}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };

        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'pay slips'
      ) {
        let cntnt: any = () => {
          var _result: any;
          parser.parseString(contract_data, function (err: any, result: any) {
            _result = JSON.parse(JSON.stringify(result));
          });

          //   //var subcardxml: any = this.ParseXML(contractcard_data);
          //   var subcardxml: any;
          //   parser.parseString(
          //     contract_alldata,
          //     function (err: any, result: any) {
          //       subcardxml = JSON.parse(JSON.stringify(result));
          //     }
          //   );

          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={_result}
              selectedtabname={'PaySlips'}
              datagridWidth={_result.Grid.$.width ?? undefined}
              subcardxml={null}
            />
          );
        };

        itemsData.push({ header: { text: tab[i] }, content: cntnt });
        //itemsData.push({ header: { text: tab[i] }, content: '' });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'loans'
      ) {
        // let cntnt: any = () => {
        //   var _result: any;
        //   parser.parseString(loan_data, function (err: any, result: any) {
        //     _result = JSON.parse(JSON.stringify(result));
        //   });

        //   return (
        //     <Composer
        //       _jObject={this.state.jsonDataViewer}
        //       _xml={null}
        //       _json={_result}
        //       selectedtabname={'Loan'}
        //       showCommonCntxMenu={this._showcommon_cntxmneu}
        //     />
        //   );
        // };

        // itemsData.push({ header: { text: tab[i] }, content: cntnt });
        itemsData.push({ header: { text: tab[i] }, content: '' });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'leaves'
      ) {
        let cntnt: any = () => {
          var _result: any;
          parser.parseString(leave_type_data, function (err: any, result: any) {
            _result = JSON.parse(JSON.stringify(result));
          });
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={_result}
              selectedtabname={'Leave'}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'tax info'
      ) {
        let cntnt: any = () => {
          var _result: any;
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={_result}
              selectedtabname={'taxinfo'}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      }
      // else if (
      //   this.tabs
      //     .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
      //     .name.toLowerCase() === 'attendance'
      // ) {
      //   let cntnt: any = () => {
      //     return (
      //       <Composer
      //         _jObject={this.state.jsonDataViewer}
      //         _xml={null}
      //         _json={null}
      //         selectedtabname={'Attendance'}
      //       />
      //     );
      //   };
      //   itemsData.push({ header: { text: tab[i] }, content: cntnt });
      // }
      else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'attendance'
      ) {
        // let cntnt: any = () => {
        //   return (
        //     <Composer
        //       _jObject={this.state.jsonDataViewer}
        //       _xml={null}
        //       _json={null}
        //       selectedtabname={'attendance'}
        //       showCommonCntxMenu={this._showcommon_cntxmneu}
        //     />
        //   );
        // };
        // itemsData.push({ header: { text: tab[i] }, content: cntnt });
        itemsData.push({ header: { text: tab[i] }, content: '' });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'skill'
      ) {
        // let cntnt: any = () => {
        //   return (
        //     <Composer
        //       _jObject={this.state.jsonDataViewer}
        //       _xml={null}
        //       _json={null}
        //       selectedtabname={'skill'}
        //       showCommonCntxMenu={this._showcommon_cntxmneu}
        //     />
        //   );
        // };
        // itemsData.push({ header: { text: tab[i] }, content: cntnt });
        itemsData.push({ header: { text: tab[i] }, content: '' });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'listing'
      ) {
        let cntnt: any = () => {
          return (
            <>
              <WorkScheduleList />
            </>
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'period'
      ) {
        let cntnt: any = () => {
          return <PeriodList />;
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'type'
      ) {
        let cntnt: any = () => {
          return (
            <>
              <div style={{ height: '10px' }}></div>
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  Weekly :
                </div>
                <div style={{ fontSize: '13px', marginBottom: '30px' }}>
                  An employee who is paid on a weekly basis will receive 52
                  paychecks a year. Hourly employees and part-time employees are
                  typically paid weekly. A weekly pay period is ideal for
                  employees who tend to work overtime or whose work schedules
                  change on a weekly basis. With this pay period, employees will
                  record their hours for the week and submit a timesheet at the
                  end of the workweek. They will then be paid in the following
                  week because it gives the payroll clerk time to make any
                  adjustments. Some employees enjoy a weekly pay period because
                  it’s a more consistent cash flow.
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  Biweekly :
                </div>
                <div style={{ fontSize: '13px', marginBottom: '30px' }}>
                  An employee who is paid biweekly will typically receive 26
                  paychecks a year. Employees paid biweekly can either be hourly
                  or salaried. Biweekly pay periods are more cost effective than
                  weekly payroll, but it can be confusing to process payroll for
                  months that have three pay periods.
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  Monthly :
                </div>
                <div style={{ fontSize: '13px', marginBottom: '30px' }}>
                  An employee who is paid monthly will receive 12 paychecks a
                  year. Monthly pay periods typically only work for salaried
                  employees. Employees paid on a monthly basis are usually paid
                  on either the first or last day of the month. Monthly payroll
                  is the most cost effective of the bunch and is the easiest to
                  calculate. However, some employees don’t like monthly payroll
                  because it’s a less frequent cash flow.
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '15px',
                    float: 'left',
                    marginRight: '5px',
                  }}
                >
                  Semimonthly :
                </div>
                <div style={{ fontSize: '13px', marginBottom: '30px' }}>
                  An employee who is paid semimonthly will receive 24 paychecks
                  a year. Employees paid semimonthly will typically be paid on
                  the first and 15th of each month. Semimonthly payroll works
                  best for salaried employees who have a consistent schedule.
                  Semimonthly payroll is easy for both the employer and the
                  employee. It’s easy for the employer to calculate and the
                  employee receives a more frequent cash flow. However, the
                  semimonthly pay period can be confusing for hourly workers if
                  overtime needs to be applied.
                </div>
              </div>
            </>
          );
        };
        itemsData.push({ header: { text: tab[i] }, content: cntnt });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'income tax'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Income Tax'}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'contribution'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Contribution'}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'bank'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Bank'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'cost center'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Cost Center'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'level'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Level'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'position'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Position'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'section'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Section'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'class'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Class'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'unit'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Unit'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'team'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Team'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'formats'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'Formats'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'personal'
      ) {
        let cntnt: any = () => {
          let sm: any = this.props.selectedmenu;
          sm.dynamicViewer.dynamicTab = [
            {
              id: 'bio',
              name: 'Bio',
              isgroup: false,
              order: 1,
            },
            {
              id: 'education',
              name: 'Education',
              isgroup: false,
              order: 2,
            },
            {
              id: 'experience',
              name: 'Experience',
              isgroup: false,
              order: 3,
            },
            {
              id: 'address',
              name: 'Address',
              isgroup: false,
              order: 4,
            },
            {
              id: 'contact',
              name: 'Contact',
              isgroup: false,
              order: 5,
            },
            {
              id: 'reference',
              name: 'Reference',
              isgroup: false,
              order: 6,
            },
          ];

          return (
            <DataViewer
              id='profilebio'
              jsonDataViewer={this.user.person}
              rootmenu={this.props.rootmenu}
              selectedmenu={sm}
              selectedtab={() => {}}
              selectedtabindex={0}
              showganttchart={false}
              selectorService={
                this._structure.SidebarConstants.SidebarService.Person
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs
          .filter((x) => x.label.toLowerCase() === tab[i].toLowerCase())[0]
          .name.toLowerCase() === 'time-off'
      ) {
        let cntnt: any = () => {
          return (
            <Composer
              _jObject={this.state.jsonDataViewer}
              _xml={null}
              _json={null}
              selectedtabname={'LeaveType'}
              selectedmenu={this.props.selectedmenu}
              rootmenu={this.props.rootmenu}
              showCommonCntxMenu={this._showcommon_cntxmneu}
              tabinfo={
                this.tabs.filter(
                  (x) => x.label.toLowerCase() === tab[i].toLowerCase()
                )[0]
              }
            />
          );
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else if (
        this.tabs.filter(
          (x) => x.label.toLowerCase() === tab[i].toLowerCase()
        )[0].id === 'resource_link'
      ) {
        let cntnt: any = () => {
          return <ResourceLinkList />;
        };

        itemsData.push({
          header: { text: tab[i] },
          content: cntnt,
        });
      } else {
        itemsData.push({ header: { text: tab[i] }, content: '' });
      }
    }

    var el: any = document.getElementById('tabC');
    if (el !== null) {
      el.className = 'tabline';
    }

    if (this.tabInstance === undefined) {
      let obj: any = document.getElementById(this.tabInstance.id) as Element;
      if (obj !== null) {
        this.tabInstance = obj.ej2_instances[0] as TabComponent;
      }
    }

    if (this.tabInstance !== undefined && this.tabInstance !== null) {
      this.tabInstance.items = itemsData;
      this.tabInstance.overflowMode = 'Popup';
      this.tabInstance.refresh();

      var tabidx: any = 0;
      // if (localStorage.getItem('tabdataviewer-tabcomponent') !== undefined) {
      //   var idx = Object.keys(
      //     JSON.parse(
      //       localStorage.getItem('tabdataviewer-tabcomponent')!.toString()
      //     )
      //   ).indexOf('selectedItem');
      //   tabidx = Object.values(
      //     JSON.parse(
      //       localStorage.getItem('tabdataviewer-tabcomponent')!.toString()
      //     )
      //   ).at(idx);
      // }
      if (tabidx <= 0) {
        tabidx = this.state.selectedtabindex;
      }

      this.tabInstance.select(tabidx);

      //this.applyStyle(tabInstance.tbItem[tabidx]);
      this.applyStyle(this.tabInstance.tbItem[tabidx]);

      //this.applyStyle(tabInstance.tbItem[0])>
      // this.applyStyle(
      // 	tabInstance.tbItem[
      // 		this.props.selectedtabindex == undefined
      // 			? 0
      // 			: this.props.selectedtabindex
      // 	]
      // );
    }
  };

  private refreshTabs = (bln: boolean) => {
    this.isTabsRefresh = bln;
    //this.props.refreshSelector(true);
  };

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * SANDEEP - 2023-03-23 - 450 -- Implement the changes in the main copy and working on card component under tab
  // * SUHAIL - 2023-03-31 - 120
  // * SUHAIL - 2023-04-03 - 10
  // * SANDEEP - 2023-04-06 - 300 -- Working on CSS for applying dynanically
  // * SANDEEP - 2023-04-07 - 300 -- Working on CSS for applying dynanically as per the design
  // * SUHAIL - 2023-05-04 - 60
  // * SANDEEP - 2023-06-01 - 30 -- Working on callback function to setting tab style as persistance property was removing it
  // * Avinash - 2023-05-26 - 30 -- Working on timeline year
  // * SANDEEP - 2023-08-10 - 20 -- Added Leave Process Component

  render() {
    // this._structure = JSON.parse(Common.getStructure() ?? '');
    // if (this.props.rootmenu !== null) {
    //     this.tabset = Common.FindJsonByRelationFieldId(this.props.rootmenu.children, this._structure.Parameters.TabsSet);//9182 = TabSet
    // }
    // var isTabAdded = false;
    // let tabs: tabstructure[] = [];
    // let tabst: tabstructure;
    // if (this.tabset !== null) {
    //     this.tabset.children.forEach((x: any) => {
    //         let idx: any = tabs.findIndex((p: any) => { return p.object === x.object });

    //         if (idx < 0) {
    //             idx = tabs.length;
    //             tabst = { idx: tabs.length, object: x.object, name: '', label: '' };
    //             tabs.push(tabst);
    //         }

    //         switch (x.field) {

    //             case this._structure.Field.Identif.Name:
    //                 tabst = { ...tabs[idx], name: x.value };
    //                 tabs[idx] = tabst;
    //                 break;

    //             case this._structure.Field.Identif.Label:
    //             case this.user.language:
    //                 tabst = { ...tabs[idx], label: x.value };
    //                 tabs[idx] = tabst;
    //                 break;

    //         }
    //     });

    // }
    const updateJsonDataViewer = (
      rootmenu: any,
      selectedmenu: any,
      data: any,
      resetSidebarSelection: boolean = false
    ) => {
      //this.state.jsonDataViewer['componentName'] = data;
      let rm: any = this.props.rootmenu;
      let sm: any = this.props.selectedmenu;
      let sti: any = this.state.selectedtabindex;

      rm = rootmenu;
      sm = selectedmenu;
      if (!isNaN(data.selectedtabindex)) {
        sti = data.selectedtabindex;
      }

      if (resetSidebarSelection) {
        let tv: any = document.getElementById('main-treeview');
        let trvwsidebar: TreeViewComponent = tv
          .ej2_instances[0] as TreeViewComponent;
        trvwsidebar.selectedNodes = [];
      }
      if (data.componentName) {
        this.componentname = data.componentName;
      } else {
        this.componentname = '';
      }
      if (data.tabset) {
        this.tabset = data.tabset;
        this.tabs = [];
        this.isTabsRefresh = true;
        let ss: any = this.props.selectorService;
        ss = this._structure.SidebarConstants.SidebarService.Employee;
      }
      let tabst: tabstructure;
      if (this.tabset) {
        for (let idx = 0; idx < this.tabset.length; idx++) {
          tabst = {
            id: this.tabset[idx].id,
            object: this.tabset[idx].id,
            name: this.tabset[idx].name,
            label: this.tabset[idx].name,
          };
          this.tabs.push(tabst);
        }
      }

      this.setState({ jsonDataViewer: data, selectedtabindex: sti });
    };

    const selected = (args: any) => {
      if (this.isTabsRefresh === true) {
        this.tabInstance.refresh();
        this.isTabsRefresh = false;
      }
      this.props.selectedtab(args.selectedIndex);
      this.applyStyle(args);
      this.setState({ _selectedtab: args.selectedIndex });
    };

    //let tabInstance: TabComponent | any;

    // const handleDocumentSave = (jsnAttach: Object[]) => {
    //   this.props.handleDocumentSave(jsnAttach);
    // };

    //tabCreated();
    let selectedemployeename: string = '';
    if (this.state.jsonDataViewer && this.props.selectorService) {
      switch (this.props.selectorService.Id) {
        case this._structure.SidebarConstants.SidebarService.Person.Id:
          if (this.props.id !== 'profilebio') {
            selectedemployeename = this.state.jsonDataViewer['name'];
          }
          break;

        case this._structure.SidebarConstants.SidebarService.Employee.Id:
          if (this.props.rootmenu.name.toLowerCase() === 'employees') {
            selectedemployeename =
              this.state.jsonDataViewer.employeecode +
              ' - ' +
              this.state.jsonDataViewer.personName;
          } else {
            selectedemployeename = this.state.jsonDataViewer['componentName'];
          }
          break;

        case this._structure.SidebarConstants.SidebarService.CompanyMenuSettings
          .Id:
          selectedemployeename = Array.isArray(this.state.jsonDataViewer)
            ? this.state.jsonDataViewer[0]['name']
            : this.state.jsonDataViewer['name'];
          break;

        default:
          break;
      }
    } else if (
      !this.props.selectorService &&
      !this.componentname &&
      this.state.jsonDataViewer &&
      this.state.jsonDataViewer.name
    ) {
      selectedemployeename = this.state.jsonDataViewer.name;
    }
    return (
      <div className='control-section card-control-section basic_card_layout'>
        <div className='e-card-resize-container'>
          {/* <div className='row mx-0'> */}
          <div className='row mx-0 card-layout'>
            <div className='col-12'>
              <div
                className=''
                id='basic_card'
                style={{
                  height: 'calc(100vh - 109px)',
                  justifyContent: 'start',
                  marginTop: '20px',
                  marginBottom: '0px',
                  overflow: 'hidden',
                }}
              >
                <div className='e-card-header'>
                  <div className='e-card-header-caption'>
                    <div className='row'>
                      {/* <div className='col-2 pt-2' > */}
                      <div className='col-12'>
                        {this.props.selectedmenu &&
                        (this.props.selectedmenu.dynamicSelector ||
                          this.props.selectedmenu.dynamicViewer) &&
                        this.tabset &&
                        this.tabset.length > 0 &&
                        this.componentname === '' ? (
                          //this._structure.UiComponents.Tabs ? (
                          //this.controlType && this.controlType.object === this._structure.UiComponents.Tabs ?
                          <>
                            <div
                              id='maintitle'
                              className='e-card-title tab-tilte'
                            >
                              {
                                /* {this.state.jsonDataViewer
                                  ? this.state.jsonDataViewer['name']
                                  : ''} */
                                selectedemployeename
                              }
                            </div>
                            <div
                              key={'tabC'}
                              id='tabC'
                              className='tab-control-resize-container'
                            >
                              {/* <TabComponent heightAdjustMode='Auto' cssClass='e-accent dataviewer' ref={tab => tabInstance = tab} created={tabCreated} selected={selected} selecting={selected}>
                                                        </TabComponent> */}
                              <TabComponent
                                id={`${this.props.id}dataviewer-tabcomponent`}
                                className='dataviewer-tabcomponent'
                                heightAdjustMode='Auto'
                                //cssClass='responsive-mode'
                                //width={'auto'}
                                ref={(tab) => (this.tabInstance = tab)}
                                created={this.tabCreated.bind(this)}
                                selected={selected}
                                enablePersistence={true}
                                selectedItem={this.state.selectedtabindex}
                                overflowMode='Popup'
                              ></TabComponent>
                            </div>
                            <div
                              className='e-addicon'
                              title='New'
                              style={{ display: 'none' }}
                            >
                              <i
                                className='fa fa-circle-plus'
                                style={{ fontSize: '50px' }}
                                onClick={this.addnew_click.bind(this)}
                              ></i>
                            </div>
                          </>
                        ) : this.componentname &&
                          this.componentname === 'payhead' ? (
                          <PayheadList
                            selectorData={this.state.jsonDataViewer}
                            showPageHeader={true}
                            allowGroupingHead={true}
                          />
                        ) : this.componentname &&
                          this.componentname === 'allowance' ? (
                          <PayheadList
                            selectorData={this.state.jsonDataViewer}
                            showPageHeader={true}
                            defaultHeadTypeId={[
                              this._structure.HeadType.EarningforEmployee.id,
                            ]}
                            defaultTypeOfId={[
                              this._structure.TypeOf.Allowance.id,
                            ]}
                            allowGroupingHead={true}
                          />
                        ) : this.componentname &&
                          this.componentname === '_payroll_' ? (
                          <Payroll jsonDataViewer={this.state.jsonDataViewer} />
                        ) : this.componentname &&
                          this.componentname === '_allocation_' ? (
                          <AllocationList
                            jsonDataViewer={
                              this.state.jsonDataViewer.length > 0
                                ? this.state.jsonDataViewer[0]
                                : null
                            }
                          />
                        ) : this.componentname &&
                          this.componentname === '_user_' ? (
                          <UserList
                            jsonDataViewer={
                              Array.isArray(this.state.jsonDataViewer) &&
                              this.state.jsonDataViewer.length > 0
                                ? this.state.jsonDataViewer[0]
                                : this.state.jsonDataViewer
                            }
                          />
                        ) : this.componentname &&
                          this.componentname === '_report_' ? (
                          <Reports jsonDataViewer={this.state.jsonDataViewer} />
                        ) : this.componentname &&
                          this.componentname === '_dashboard_' ? (
                          <Dashboard
                            rootmenu={this.props.rootmenu}
                            selectedmenu={this.props.selectedmenu}
                            updateJsonDataViewer={updateJsonDataViewer}
                          />
                        ) : this.componentname &&
                          this.componentname === '_leave_' ? (
                          <LeaveInfo
                            jsonDataViewer={this.state.jsonDataViewer}
                          />
                        ) : this.componentname &&
                          this.componentname === '_email_' ? (
                          <Email />
                        ) : this.componentname &&
                          this.componentname === '_holiday_' ? (
                          <HolidayList />
                        ) : this.componentname &&
                          this.componentname === '_company_' ? (
                          <CompanyList />
                        ) : this.componentname &&
                          this.componentname === '__' ? (
                          <></>
                        ) : this.state.jsonDataViewer &&
                          this.state.jsonDataViewer.length > 0 &&
                          this.state.jsonDataViewer[0].isgroup ? (
                          <></>
                        ) : this.state.jsonDataViewer &&
                          this.state.jsonDataViewer['isgroup'] ? (
                          <></>
                        ) : this.props.showganttchart == false &&
                          this.state.jsonDataViewer !== null ? (
                          <div id='dataviewerdocumentviewer'>
                            {this.props.selectorService.Id ===
                            this._structure.SidebarConstants.SidebarService
                              .Template.Id ? (
                              <WordTemplate
                                id='wordtemplate-sidebar'
                                accountid={this.state.jsonDataViewer.id}
                                name={this.state.jsonDataViewer.name}
                                showindialogbox={false}
                                jsonTemplate={this.state.jsonDataViewer}
                              />
                            ) : (
                              <DocumentViewer
                                //data={JSON.stringify(this.props.jsonDocument)}
                                //data={this.props.jsonDocument}
                                id='dataviewer'
                                jsonDataViewer={this.state.jsonDataViewer}
                                selectorService={this.props.selectorService}
                                //handleDocumentSave={handleDocumentSave}
                                selectedmenu={this.props.selectedmenu}
                                hideTitlebar={true}
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            {this.props.showganttchart == true ? (
                              <LeaveProcess />
                            ) : (
                              <>
                                {this.props.showdashboard == true ? (
                                  <Dashboard
                                    rootmenu={this.props.rootmenu}
                                    selectedmenu={this.props.selectedmenu}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {/* <div className='col-10' >
                                                    {/* <TabComponent heightAdjustMode='Auto' id='defaultTab'>
                                                        <TabItemsDirective>
                                                            <TabItemDirective header={headertext[0]}
                                                                content={() => <div>
                                                                    Twitter
                                                                </div>} />
                                                            <TabItemDirective header={headertext[1]}
                                                                content={() => <div>
                                                                    Twitter
                                                                </div>} />
                                                            <TabItemDirective header={headertext[2]}
                                                                content={() => <div>
                                                                    Twitter
                                                                </div>} />
                                                        </TabItemsDirective>
                                                    </TabComponent> 
                                                    {/* <TabComponent heightAdjustMode='Auto' cssClass='e-accent' ref={tab => tabInstance = tab} created={tabCreated} >
                                                    </TabComponent>
                                                </div> */}
                    </div>
                  </div>
                </div>
                <div className='e-card-content'></div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default DataViewer;
