import * as React from 'react';
import './Company.css';
import Common from '../../../Util/Common';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  RadioButtonComponent,
  SwitchComponent,
} from '@syncfusion/ej2-react-buttons';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import CompanyGroup from './CompanyGroup';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

interface Props {
  jsonCompanyGroup?: undefined;
  jsonCountry?: undefined;
  isAdd?: boolean;
  tenantGroupId?: string;
  identifier?: string;
  name?: string;
  email?: string;
  adminEmail?: string;
  website?: string;
  finStartDate?: Date;
  finEndDate?: Date;
  countryId?: string;
  isActive?: boolean;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}

export default class Company extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    jsonCompanyGroup: this.props.jsonCompanyGroup,
    jsonCountry: this.props.jsonCountry,
    showDialog: false,
    isAdd: this.props.isAdd,
    tenantGroupId: this.props.tenantGroupId ?? '',
    identifier: this.props.identifier ?? '',
    name: this.props.name ?? '',
    email: this.props.email ?? '',
    adminEmail: this.props.adminEmail ?? '',
    website: this.props.website ?? '',
    finStartDate: this.props.finStartDate
      ? new Date(this.props.finStartDate)
      : undefined,
    finEndDate: this.props.finEndDate
      ? new Date(this.props.finEndDate)
      : undefined,
    countryId: this.props.countryId ?? '',
    isActive: this.props.isActive ?? true,
    databaseName: '',
  };

  componentDidMount(): void {
    if (!this.state.jsonCompanyGroup) {
      this.GetList();
    }

    if (!this.state.jsonCountry) {
      this.GetCountryList();
    }
  }

  public GetList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/tenants/tenantgroup/get`,
      { addNewOption: true },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          jsonCompanyGroup: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetCountryList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/country/root/get`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          jsonCountry: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const handleOnChange = (args: any): void => {
      let key: string = args.target.name;
      let value: string = args.target.value;
      this.setState({ [key]: value });
    };

    const handleOnSyncTextChange = (args: any): void => {
      let key: string = args.target.name;
      let value: string = args.value;
      this.setState({ [key]: value });
    };

    const handleOnDateChange = (e: any): void => {
      let key: string = e.element.name;
      let value: string = e.value;
      this.setState({ [key]: value });
    };

    const handleOnCheckboxChange = (args: any): void => {
      let key: string = args.target.name;
      let value: string = args.target.checked;
      this.setState({ [key]: value });
    };

    const onCancelClick = (e: any) => {
      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };

    const onSaveClick = (e: any) => {
      if (IsValid() === false) return false;

      let dateFormat: string = 'dd-MMM-yyyy';
      let df: any = document.getElementsByName('dateFormat');

      if (df.length > 0) {
        dateFormat = df[0].ej2_instances[0].getSelectedValue();
      }

      let payload: any = {
        id: this.state.identifier,
        name: this.state.name,
        email: this.state.email,
        adminEmail: this.state.adminEmail,
        website: this.state.website,
        finStartDate: Common.RemoveTimezoneOffsetFromDate(
          new Date(this.state.finStartDate as Date)
        ),
        finEndDate: Common.RemoveTimezoneOffsetFromDate(
          new Date(this.state.finEndDate as Date)
        ),
        tenantGroupId: this.state.tenantGroupId,
        countryId: this.state.countryId,
        isActive: this.state.isActive,
        issuer: this.user.id,
        //databaseName: this.state.databaseName,
        databaseName: this.state.identifier,
        dateFormat: dateFormat,
      };

      Common.ApiCallAsync(
        this.props.isAdd ? 'POST' : 'PUT',
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/tenants`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Record saved successfully!</p>',
          });
          this.setState({
            isAdd: true,
            identifier: '',
            name: '',
            email: '',
            adminEmail: '',
            website: '',
            finStartDate: null,
            finEndDate: null,
            isActive: true,
            tenantGroupId: '',
            countryId: '',
            databaseName: '',
          });

          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const IsValid = (): boolean => {
      if (
        !this.state.tenantGroupId ||
        this.state.tenantGroupId === Common.blankguid
      ) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please select company group.</p>',
        });
        return false;
      }
      if (!this.state.identifier.trim()) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter code.</p>',
        });
        return false;
      } else if (!this.state.name.trim()) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter name.</p>',
        });
        return false;
      } else if (!this.state.adminEmail.trim()) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter admin email.</p>',
        });
        return false;
      } else if (!this.state.finStartDate) {
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Please enter financial year start date.</p>',
        });
        return false;
      } else if (!this.state.finEndDate) {
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Please enter financial year end date.</p>',
        });
        return false;
      } else if (!this.state.countryId) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please select country.</p>',
        });
        return false;
      }

      return true;
    };

    const onCompanyGroupChange = (e: any) => {
      if (e.value === Common.blankguid) {
        this.setState({ showDialog: true, tenantGroupId: e.value });
      } else {
        this.setState({ tenantGroupId: e.value });
      }
    };

    const onCountryChange = (e: any) => {
      this.setState({ countryId: e.value });
    };

    const handleCancelClick = (e: any) => {
      this.setState({ showDialog: false });
      let drp: any = document.getElementById('companygroup');
      if (drp) {
        drp.ej2_instances[0].value = null;
      }
    };

    const dlgContent = (e: any) => {
      const handleSaveClick = (data: any) => {
        this.GetList();
        setTimeout(() => {
          let drp: any = document.getElementById('companygroup');
          if (drp) {
            drp.ej2_instances[0].value = data.id;
          }
        }, 2000);
      };

      return (
        <CompanyGroup
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
        />
      );
    };

    return (
      <>
        <div id='divCompany' className='py-2'>
          <div className={`basicinfo-${this.props.isAdd ? 'add' : 'edit'}`}>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Company Group
                </label>
                <span className='mandatoryinfo'>*</span>
                <DropDownListComponent
                  id='companygroup'
                  dataSource={this.state.jsonCompanyGroup}
                  filterBarPlaceholder='Search a Company Group'
                  allowFiltering={true}
                  showClearButton={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'name' }}
                  placeholder='Select Company Group'
                  popupHeight='220px'
                  style={{ fontSize: '15px' }}
                  value={this.state.tenantGroupId}
                  onChange={onCompanyGroupChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Code
                </label>
                <span className='mandatoryinfo'>*</span>
                <TextBoxComponent
                  id='identifier'
                  name='identifier'
                  type='text'
                  placeholder={'Code'}
                  autocomplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.identifier}
                  onChange={handleOnSyncTextChange}
                  disabled={!this.props.isAdd}
                />
                {/* <input
                  className='input-text-border'
                  id='identifier'
                  name='identifier'
                  type='text'
                  placeholder={'Code'}
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.identifier}
                  onChange={handleOnChange}
                  disabled={!this.props.isAdd}
                /> */}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Name
                </label>
                <span className='mandatoryinfo'>*</span>
                <input
                  className='input-text-border'
                  id='name'
                  name='name'
                  type='text'
                  placeholder={'Name'}
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.name}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Email
                </label>

                <input
                  className='input-text-border'
                  id='email'
                  name='email'
                  type='email'
                  placeholder={'Email'}
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.email}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Admin Email
                </label>
                <span className='mandatoryinfo'>*</span>
                <TextBoxComponent
                  id='adminEmail'
                  name='adminEmail'
                  type='email'
                  placeholder={'Admin Email'}
                  autocomplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.adminEmail}
                  onChange={handleOnSyncTextChange}
                  disabled={!this.props.isAdd}
                />
                {/* <input
                  className='input-text-border'
                  id='adminEmail'
                  name='adminEmail'
                  type='email'
                  placeholder={'Admin Email'}
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.adminEmail}
                  onChange={handleOnChange}
                  disabled={!this.props.isAdd}
                  readOnly={!this.props.isAdd}
                /> */}
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Website
                </label>

                <input
                  className='input-text-border'
                  id='website'
                  name='website'
                  type='text'
                  placeholder={'Website'}
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={this.state.website}
                  onChange={handleOnChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Financial Year Start Date
                </label>
                <span className='mandatoryinfo'>*</span>
                <DatePickerComponent
                  id='finStartDate'
                  name='finStartDate'
                  format={this.user.settings.formats.DateShort}
                  showClearButton={true}
                  cssClass='e-hidedate-icon'
                  openOnFocus={true}
                  value={
                    this.state.finStartDate == undefined
                      ? undefined
                      : this.state.finStartDate
                  }
                  showTodayButton={false}
                  placeholder={this.user.settings.formats.DateShort}
                  style={{ fontSize: '13px' }}
                  strictMode={true}
                  change={handleOnDateChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Financial Year End Date
                </label>
                <span className='mandatoryinfo'>*</span>
                <DatePickerComponent
                  id='finEndDate'
                  name='finEndDate'
                  format={this.user.settings.formats.DateShort}
                  showClearButton={true}
                  cssClass='e-hidedate-icon'
                  openOnFocus={true}
                  value={
                    this.state.finEndDate == undefined
                      ? undefined
                      : this.state.finEndDate
                  }
                  showTodayButton={false}
                  placeholder={this.user.settings.formats.DateShort}
                  style={{ fontSize: '13px' }}
                  strictMode={true}
                  change={handleOnDateChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Country
                </label>
                <span className='mandatoryinfo'>*</span>
                <DropDownListComponent
                  id='country'
                  dataSource={this.state.jsonCountry}
                  filterBarPlaceholder='Search a Country'
                  allowFiltering={true}
                  showClearButton={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'name' }}
                  placeholder='Select Country'
                  popupHeight='220px'
                  style={{ fontSize: '15px' }}
                  value={this.state.countryId}
                  onChange={onCountryChange}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label
                  htmlFor='isActive'
                  style={{ padding: '10px 72px 10px 0' }}
                >
                  Active
                </label>
                <SwitchComponent
                  id='isActive'
                  name='isActive'
                  checked={this.state.isActive}
                  onChange={handleOnCheckboxChange}
                ></SwitchComponent>
              </div>
            </div>
          </div>
          {this.props.isAdd && (
            <div className='settinginfo'>
              {/* <div className='row'>
                <div className='col-12'>
                  <label style={{ fontSize: '13px', paddingBottom: '16px' }}>
                    Database Name
                  </label>
                  <span className='mandatoryinfo'>*</span>
                  <input
                    className='input-text-border'
                    id='databaseName'
                    name='databaseName'
                    type='text'
                    placeholder={'Database Name'}
                    autoComplete='off'
                    style={{ fontSize: '15px' }}
                    value={this.state.databaseName}
                    onChange={handleOnChange}
                  />
                </div>
              </div> */}
              <div className='row'>
                <div className='col-12'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Date Format
                  </label>
                  <span className='mandatoryinfo'>*</span>
                  <div className='row'>
                    <RadioButtonComponent
                      checked={true}
                      cssClass='w-100'
                      label={`dd-MMM-yyyy (${Common.formatDate(
                        new Date(),
                        'dd-MMM-yyyy'
                      )})`}
                      name='dateFormat'
                      value='dd-MMM-yyyy'
                    ></RadioButtonComponent>
                  </div>
                  <div className='row'>
                    <RadioButtonComponent
                      cssClass='w-100'
                      label={`dd/MM/yyyy (${Common.formatDate(
                        new Date(),
                        'dd/MM/yyyy'
                      )})`}
                      name='dateFormat'
                      value='dd/MM/yyyy'
                    ></RadioButtonComponent>
                  </div>
                  <div className='row'>
                    <RadioButtonComponent
                      cssClass='w-100'
                      label={`MM/dd/yyyy (${Common.formatDate(
                        new Date(),
                        'MM/dd/yyyy'
                      )})`}
                      name='dateFormat'
                      value='MM/dd/yyyy'
                    ></RadioButtonComponent>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='e-footer-content-temp'>
            <button
              id='btnCancel'
              className='e-control e-btn e-lib flat-button e-flat'
              onClick={onCancelClick}
            >
              Cancel
            </button>
            <button
              id='btnAccept'
              className='e-control e-btn e-lib flat-button e-primary e-flat'
              onClick={onSaveClick}
            >
              Accept
            </button>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-companyhange'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'25vw'}
            close={handleCancelClick}
            content={dlgContent}
            isModal={true}
            header={`Add Company Group`}
            //buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
