// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SANDEEP - 2023-03-28 - 240 -- understanding the accordian code from godwin work and syncfusion.
// * SANDEEP - 2023-03-29 - 60 -- meeting
// * SOURAV - 2023-05-29 - 60 -- meeting
//* SOURAV - 2023-05-31 - 50 -- meeting
//* SOURAV - 2023-06-05 - 50 -- meeting
//* SOURAV - 2023-06-07 - 50 -- meeting
//* SOURAV - 2023-06-07 - 145 -- personal meeting with fabio.
//* SOURAV - 2023-06-08 - 45 --  meeting
//* SOURAV - 2023-06-09 - 45 --  meeting
//* SOURAV - 2023-06-12 - 50 --  meeting
//* SOURAV - 2023-06-14 - 50 --  meeting
//* SOURAV - 2023-06-19 - 75 --  meeting
//* SOURAV - 2023-06-21 - 60 --  meeting
//* SOURAV - 2023-06-22 - 20 --  meeting
//* SOURAV - 2023-06-23 - 45 --  meeting
//* SOURAV - 2023-06-26 - 90 --  meeting
//* SOURAV - 2023-06-26 - 60 --  meeting
//* SOURAV - 2023-07-01 - 80 --  meeting

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Common from '../Util/Common';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { emitKeypressEvents } from 'readline';
import ImageEditor from './ImageEditor';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import { accordion_contextmenu } from '../dummydata/xml_data_employee';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
interface _AccordianProps {
  _element: any;
  _data: any;
  _user: any;
  _height?: any;
  _width?: any;
}

var _headers: { [key: number]: any } = {};
var _contents: { [key: number]: any } = {};
var _height: any;

// * ------------------------------------------------------------------------------------------------------------------3.Style

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;
class Accordion extends React.Component<_AccordianProps> {
  private _structure: any;
  private user: any;
  private _fulljson: any;
  private _document_type: any;
  private listdocumenttype: DropDownListComponent | any;
  //private cfields: object = { value: 'object', text: 'value' };
  private cfields: object = { value: 'id', text: 'itemValue' };
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private contextmenu: any = undefined;
  private _accrd_expanded: any = [];
  private cntxitems: MenuItemModel[] = [];

  private isCollapsed: boolean = false;
  private expandIndex: number = -1;
  private acrdnInstance: any;
  private token: any = null;

  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
  constructor(props: any) {
    super(props);
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.user = JSON.parse(Common.getUser() ?? '');
    //this._fulljson = JSON.parse(Common.getFullJson() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    var _result: any;
    parser.parseString(accordion_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    f_document: [],
    showDialog: false,
    fImage: null,
    doubleClick_id: 0,
    _menuitems: undefined,
    expandIndex: -1,
    showDocDialog: false,
    editImage: '',
    docImage: '',
    document_type: [],
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  // * SANDEEP - 2023-05-01 - 120 -- Adding Event(s) on generated jsx elements.
  // * SANDEEP - 2023-05-30 - 60 -- Working on css apply dynamically for icon show and hide
  // * SANDEEP - 2023-06-03 - 120 -- Worked on showing the calender on focus and working on css

  componentDidMount() {
    if (this.contextmenu == undefined) {
      this.GetContextMenu();
    }
    // if (this.state.document_type.length <= 0) {
    //   this.GetDocumentType();
    // }
    // if (this.menuItem.length <= 0) {
    //   this.setContextMenuItems();
    // }
  }

  handleMenuClick = (e: any) => {
    if (parseInt(e.element.id) === 101) {
      this.setState({ showDocDialog: true });
    } else if (parseInt(e.element.id) === 103) {
    } else {
      this.AddAccordion(e.element.id, e.element.textContent);
    }
  };

  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  onClicked = (e: any) => {
    console.log((e.originalEvent.target as Element).closest('.e-acrdn-header'));
    let acidx = e.item.parentObj.items.indexOf(
      e.item.parentObj.items.filter(
        (x: any) =>
          x.properties.id ==
          e.originalEvent.target.closest('.e-acrdn-header').parentElement.id
      )[0]
    );
    if (acidx !== -1) {
      //e.item.parentObj.items[acidx].expanded = true;
      e.item.expanded = true;
    }
  };
  onExpanding = (e: any) => {
    let arr = this.props._data.documents;
    let val: any;
    let id: number = 0;

    let x: any = e.element
      //.closest('.e-acrdn-item.e-select.e-item-focus')
      .querySelector('div>input[type=text]');

    if (!x || x.value === '') {
      x = e.element
        // .closest('.e-acrdn-item.e-select.e-expand-state.e-selected.e-active')
        .querySelector('.e-acrdn-header-content label');
      if (x) {
        val = x.textContent;
        let fltr: any = arr.filter((x: any) => x.idNumber == val);
        if (fltr.length > 0) {
          id = fltr[0].id;
        }
      }
    } else {
      val = x.value;
      let fltr: any = arr.filter((x: any) => x.idNumber == val);
      if (fltr.length > 0) {
        id = fltr[0].id;
      }
    }

    //this.expandIndex = this.acrdnInstance.items.indexOf(e.item);

    if (e.isExpanded == true) {
      if (this._accrd_expanded.includes(e.index) == false)
        this._accrd_expanded.push(e.index);
    } else if (e.isExpanded == false) {
      // if (this._accrd_expanded.includes(e.index) == true) {
      //   const index = this._accrd_expanded.indexOf(e.index);
      //   if (index > -1) {
      //     this._accrd_expanded.splice(index, 1);
      //   }
      // }
    }

    let arrdoc: any = this.state.f_document;
    if (arrdoc.filter((x: any) => x.id == e.element.id).length <= 0) {
      arrdoc.push({
        id: e.element.id,
        img: this.props._data.documents.filter(
          (x: any) => x.id == e.element.id
        )[0].image,
      });
      this.setState({ f_document: arrdoc });
      //   Common.ApiCallAsync(
      //     'POST',
      //     `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Image/Get`,
      //     {
      //       object: Number(e.element.id),
      //       field: this._structure.Field.FileType.Jpg,
      //     },
      //     Common.getToken() || '',
      //     this.user
      //   )
      //     .then((response: any) => {
      //       return response.data;
      //     })
      //     .then((response: any) => {
      //       if (response) {
      //         arrdoc.push({ id: e.element.id, img: response });
      //         // if (response && response.length > 0) {
      //         //   response.forEach((x: any) => {
      //         //     arrdoc.push({ id: x.object, img: x.value });
      //         //   });
      //         // }

      //         this.setState({ f_document: arrdoc });
      //         //e.item.expanded = true;
      //       } else {
      //         // if (this.state.expandIndex !== e.index) {
      //         //   this.setState({ expandIndex: e.index });
      //         // }
      //       }
      //     })
      //     .catch((error: any) => {
      //       console.error(error);
      //     })
      //     .finally(() => {});
    }
  };

  handleLabelClick = (args: any) => {
    console.log(args);
  };

  btnHandleClick(args: any) {
    if (args.target.id.toString().toLowerCase() == 'btnaccept') {
      let _objectId = Common.FindJsonByObjectValue(
        this._fulljson,
        '_document_',
        this.user
      );
      var _input: any = document.getElementById('grp_name');

      if (_objectId !== null && _input !== null) {
        var payload: any = {
          name: _input.value,
          ischild: true,
          parentid: _objectId.object,
        };
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/General/Group/Set`,
          payload,
          Common.getToken() || '',
          this.user
        )
          .then((resopnse: any) => {
            if (this.cMenu !== null) {
              this.cMenu.insertAfter(
                [
                  {
                    text: resopnse.data.Name,
                    id: resopnse.data.id,
                    separator: false,
                  },
                ],
                'New ...'
              );
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    }
    this.setState({ showDocDialog: false });
  }

  dialogContent() {
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-2 py-2'>
            <label style={{ fontSize: '13px' }}>Name:</label>
          </div>
          <div className='col-10 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='grp_name'
              floatLabelType='Auto'
              name='grp_name'
              type='text'
              placeholder='Enter Name'
              autocomplete='off'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            float: 'right',
            paddingTop: '7px',
          }}
        >
          <button
            className='flat-button'
            id='btnCancel'
            style={{
              marginRight: '15px',
            }}
            onClick={this.btnHandleClick.bind(this)}
          >
            Cancel
          </button>
          <button
            className='flat-button'
            id='btnAccept'
            style={{
              marginRight: '10px',
            }}
            onClick={this.btnHandleClick.bind(this)}
          >
            Accept
          </button>
        </div>
      </>
    );
    return <>{_content}</>;
  }

  handleTextClick = (event: any) => {
    event.target.style.borderStyle = 'solid';
    event.target.style.backgroundColor = 'white';
  };

  handleTextBlur = (event: any) => {
    event.target.style.borderStyle = 'none';
    event.target.style.backgroundColor = 'transparent';
    if (event.target.value !== '') {
      // let payload: any = [
      //   {
      //     object: Number(event.target.id),
      //     field: this._structure.Field.Identif.Name,
      //     value: event.target.value,
      //   },
      // ];
      let payload: any = {
        Id: event.target.id,
        idNumber: event.target.value,
        personsId: this.props._data.id,
      };
      this.SaveIdNumber(payload);
    }
  };

  // onFocus = (args: any) => {
  //     this.dateObj.show();
  //     //console.log(args)
  //     //.e-control-wrapper .e-input-group-icon.e-date-icon { display: none; }
  // }
  // onBlur = (args: any) => {
  //     console.log(args)
  // }

  ImageDblclicked = (e: any) => {
    let val: any;
    let x: any = e.target.closest(
      '.e-acrdn-item.e-select.e-expand-state.e-selected.e-active'
    );
    val = x.id;
    console.log(val);

    let editImg: any = this.state.f_document.filter((x) => {
      return x['id'] == val;
    });
    if (editImg.length > 0) {
      console.log(editImg[0].img);
      // this.setState({
      //   editImage: editImg[0].img,
      // });
      this.setState({
        showDialog: true,
        doubleClick_id: val,
        editImage: editImg[0].img,
      });
    }
  };

  getFinalImage = (data: any) => {
    let arrdoc: any = this.state.f_document;
    if (arrdoc.filter((x: any) => x.id == data.imageId).length <= 0) {
      arrdoc.push({ id: data.imageId, img: data.image });
    } else {
      arrdoc.filter((x: any) => x.id == data.imageId)[0]['img'] = data.image;
    }
    this.setState({
      f_document: arrdoc,
      isCropping: data.isCropping,
      showDialog: data.showDialog,
    });
  };

  SaveIdNumber = (payload: any) => {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/persons/add_document${
        !this.user.isManager ? '/ess' : ''
      }`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((resopnse: any) => {
        let resp: any = resopnse.data;

        if (this.props._data.hasOwnProperty('documents') == false) {
          let _doc: any = [];
          this.props._data['documents'] = _doc;
        }
        let exist = this.props._data['documents'].filter(
          (x: any) => x.id == payload.Id
        );
        if (exist.length <= 0) {
          this.props._data['documents'].push(resp);
        } else if (exist.length > 0) {
          exist[0].idNumber = resp.idNumber;
          let _hidel: any = null;
          _hidel = document.getElementById(payload.Id);
          if (_hidel !== null) {
            //(_hidel as HTMLElement).textContent = payload.idNumber;
          }
          if (this.user.person && this.user.person.id === this.props._data.id) {
            this.user.person = this.props._data;
            Common.setItem('user', this.user);
          }
        }
      })

      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  };

  public handleDocumentChange(e: any): void {
    if (this.listdocumenttype === null) {
      return;
    }
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SANDEEP - 2023-03-28 - 400 -- working accordian code.
  // * SANDEEP - 2023-03-29 - 300 -- Working on style and header and content of accordian based on xml
  // * SANDEEP - 2023-03-30 - 400 -- Working on rendering content logic xml
  // * SANDEEP - 2023-03-31 - 410 -- Working on generating image and date controls
  // * SANDEEP - 2023-04-04 - 240 -- working on label and header display and modify the xml for the same
  // * SANDEEP - 2023-04-05 - 300 -- Working on generating the date control on accordion and setting up style
  // * SANDEEP - 2023-04-28 - 300 -- Working on editing of accordion item (Number/date and issuer)

  private rgbToHex(value: string): string {
    var color: string;
    color = '';
    if (value.split(',').length > 1) {
      var r = value.split(',')[0] as unknown as number;
      var g = value.split(',')[1] as unknown as number;
      var b = value.split(',')[2] as unknown as number;
      color =
        '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
    } else {
      color = value;
    }
    return color;
  }

  private Process_Accordian(xeml: any) {
    _headers = {};
    _contents = {};
    _height = undefined;
    var _xlabel: any;
    for (let index = 0; index < xeml.children.length; index++) {
      if (xeml.children[index].name.toLowerCase() === 'grid') {
        var _child = xeml.children[index];
        _height = _child.attributes.height;
        var _row = 0;
        var _header: any;
        var _content: any;
        for (let idx = 0; idx < _child.children.length; idx++) {
          var _elem = _child.children[idx];
          if (_elem.name.toLowerCase() == 'label') {
            _xlabel = this.Process_Element(_elem, idx, null);
          } else if (_elem.name.toLowerCase() == 'item') {
            if (_elem.attributes.source !== undefined) {
              if (this.props._data[_elem.attributes.source] !== undefined) {
                if (
                  Array.isArray(this.props._data[_elem.attributes.source]) ===
                  true
                ) {
                  for (
                    let i = 0;
                    i < this.props._data[_elem.attributes.source].length;
                    i++
                  ) {
                    var _arrayItem: any;
                    _arrayItem = this.props._data[_elem.attributes.source][i];
                    var _ielem: any;
                    _ielem = this.ReadElement(_elem, 'Header');
                    _header = (
                      <div> {this.process_Header(_ielem[0], _arrayItem)}</div>
                    );

                    _ielem = this.ReadElement(_elem, 'Content');

                    _content = (
                      <div>{this.process_Content(_ielem[0], _arrayItem)}</div>
                    );
                    if (_header !== undefined && _content !== undefined) {
                      _headers[_row] = <div>{_header}</div>;
                      _contents[_row] = <div>{_content}</div>;
                      _row = _row + 1;
                      _header = undefined;
                      _content = undefined;
                    }
                  }
                } else {
                  _header = <div>{this.process_Header(_elem, null)}</div>;
                  _content = <div>{this.process_Content(_elem, null)}</div>;
                  if (_header !== undefined && _content !== undefined) {
                    _headers[_row] = <div>{_header}</div>;
                    _contents[_row] = <div>{_content}</div>;
                    _row = _row + 1;
                    _header = undefined;
                    _content = undefined;
                  }
                }
              }
            } else {
              //Generating Blank Accordion -- Start
            } //Generating Blank Accordion -- End
          }
        }
      }
    }
    return _xlabel;
  }

  // private process_Accordian(xeml: any) {
  //     var _final: any[] = [];
  //     for (let index = 0; index < xeml.children.length; index++) {
  //         if (xeml.children[index].name.toLowerCase() === "grid") {
  //             var _child = xeml.children[index];
  //             var _row = 0;
  //             var _header: any
  //             var _content: any
  //             for (let idx = 0; idx < _child.children.length; idx++) {
  //                 var _elem = _child.children[idx];
  //                 if (_elem.name.toLowerCase() == "label") {
  //                     //_extra.push(<div key={idx.toString()} className="row" style={{ justifyContent: "left" }}> {this.Process_Element(_elem, idx.toString())}</div>);
  //                 } else if (_elem.name.toLowerCase() == "header") {
  //                     _header = <div>{this.process_Header(_elem, null)}</div>;
  //                 } else if (_elem.name.toLowerCase() == "content") {
  //                     //_content = <div>{this.process_Content(_elem)}</div>;
  //                     _content = <div></div>;
  //                 }
  //                 if (_header !== undefined && _content !== undefined) {
  //                     _final.push(<div>{_header}</div>);
  //                     _final.push(<div>{_content}</div>);
  //                     _headers[_row] = <div>{_header}</div>;
  //                     _contents[_row] = <div>{_content}</div>;
  //                     _row = _row + 1;
  //                     _header = undefined; _content = undefined;
  //                 }
  //             }
  //         }
  //     }
  //     return _final;
  // }
  // private Process_AccordianElement(xeml: any) {
  //     var _final: any[] = [];
  //     var _rows: any[] = [];
  //     for (let index = 0; index < xeml.children.length; index++) {
  //         if (xeml.children[index].name.toLowerCase() === "grid") {
  //             var _child = xeml.children[index];
  //             _rows = this.getAttributeValues(_child, "row");
  //             for (let i = 0; i < _rows.length; i++) {
  //                 var _extra: any[] = [];
  //                 var _elements = this.getElementByAttributeValue(_child, "row", _rows[i])
  //                 for (let idx = 0; idx < _elements.length; idx++) {
  //                     if (_elements[idx].name.toLowerCase() == "header" || _elements[idx].name.toLowerCase() == "content") {
  //                         _extra.push(<AccordionItemDirective header={this.process_Header(_elements[idx], null)} content={this.process_Content(_elements[idx + 1], null)} />)

  //                     }
  //                     // else if (_elements[idx].name.toLowerCase() == "content") {
  //                     //     _extra.push(this.process_Content(_elements[idx]))
  //                     // }
  //                     else if (i <= 0) {

  //                         //
  //                         _extra.push(this.Process_Element(_elements[idx], i.toString() + idx.toString(), null))
  //                     }
  //                 }
  //                 _final.push(<div key={index.toString() + i.toString()} className="row" style={{ justifyContent: "left" }} >{_extra}</div>);
  //             }
  //         }
  //     }
  //     return _final;
  // }

  private process_Header(_element: any, _itemData: any) {
    var _extra: any[] = [];
    var _label: any;
    var _text: any;
    for (let idx = 0; idx < _element.children.length; idx++) {
      var _mChild = _element.children[idx];
      if (_mChild.name.toLowerCase() === 'label') {
        // _extra.push(this.Process_Element(_mChild, idx.toString()))
        _label = this.Process_Element(_mChild, idx.toString(), _itemData, true);
      } else if (_mChild.name.toLowerCase() === 'text') {
        _text = this.Process_Element(_mChild, idx.toString(), _itemData, true);
      }
      // else if (_mChild.name.toLowerCase() === "image") {
      //     _extra.push(this.Process_Element(_mChild, idx.toString()))
      // }
    }
    if (_label !== undefined && _text !== undefined) {
      _extra.push(
        <div>
          {_label} : {_text}
        </div>
      );
      _label = undefined;
      _text = undefined;
    } else if (_label !== undefined && _text == undefined) {
      _extra.push(<div>{_label}</div>);
      _label = undefined;
      _text = undefined;
    } else if (_label == undefined && _text !== undefined) {
      _extra.push(<div>{_label}</div>);
      _label = undefined;
      _text = undefined;
    }
    return _extra;
  }

  private process_Content(_element: any, _itemData: any) {
    var _final: any[] = [];
    var _label: any;
    var _text: any;
    for (let idx = 0; idx < _element.children.length; idx++) {
      var _mChild = _element.children[idx];
      if (_mChild.children.length > 0) {
        var _extra: any[] = [];
        for (let _rid = 0; _rid < _mChild.children.length; _rid++) {
          var _subChild = _mChild.children[_rid];
          if (_subChild.name.toLowerCase() === 'label') {
            //_extra.push(<div style={{ width: '10%' }}>{this.Process_Element(_mChild, idx.toString(), _itemData)}</div>)
            _label = (
              <div style={{ width: '38%' }}>
                {this.Process_Element(_subChild, _rid.toString(), _itemData)}
              </div>
            );
          } else if (_subChild.name.toLowerCase() === 'text') {
            //_extra.push(<div style={{ width: '30%' }}>{this.Process_Element(_mChild, idx.toString(), _itemData)}</div>)
            _text = (
              <div style={{ width: '55%' }}>
                {this.Process_Element(_subChild, _rid.toString(), _itemData)}
              </div>
            );
          } else if (_subChild.name.toLowerCase() === 'image') {
            _extra.push(
              <div>
                {this.Process_Element(_subChild, _rid.toString(), _itemData)}
              </div>
            );
          }
          if (_label !== undefined && _text !== undefined) {
            _extra.push(
              <div className='row'>
                {_label}:{_text}
              </div>
            );
            _label = undefined;
            _text = undefined;
          }
        }
        let _mstyle: any = { attributes: {} };
        _mstyle.attributes['float'] =
          _mChild.attributes.flow !== undefined
            ? _mChild.attributes.flow.toString()
            : 'left';

        if (_mChild.attributes.show !== undefined) {
          if (_mChild.attributes.show.toLowerCase() == 'false') {
            _mstyle.attributes['display'] = 'none';
          }
        }

        _final.push(
          <div
            className={
              _mChild.attributes.cols !== undefined
                ? ' col-' + _mChild.attributes.cols.toString()
                : 'col-3'
            }
            style={_mstyle.attributes}
          >
            {_extra}
          </div>
        );
      }
    }
    return _final;
  }

  private process_Margin(_value: string) {
    var _margin = '';
    if (_value.split(',').length > 1) {
      var _mval = _value.split(',');
      for (let i = 0; i < _mval.length; i++) {
        _margin = _margin + _mval[i] + 'px ';
      }
    } else {
      _margin = _value + 'px ';
    }
    return _margin;
  }

  private process_Padding(_value: string) {
    var _padding = '';
    if (_value.split(',').length > 1) {
      var _mval = _value.split(',');
      for (let i = 0; i < _mval.length; i++) {
        _padding = _padding + _mval[i] + 'px ';
      }
    } else {
      _padding = _value + 'px ';
    }
    return _padding;
  }

  private process_ValueInfo(_valu_attbrs: any, _value: any, _itemData: any) {
    var _valueinfo: any;
    if (_valu_attbrs.field !== undefined) {
      if (_valu_attbrs.source !== undefined) {
        if (_valu_attbrs.source.includes('.') === true) {
          var _fld: any = _valu_attbrs.source;
          if (_itemData[_valu_attbrs.field] !== undefined) {
            _valueinfo = _itemData[_valu_attbrs.field].filter(
              (x: any) => x.type === _fld.split('.')[0]
            )[0][_fld.split('.')[1]];
          }
        } else {
          if (_itemData[_valu_attbrs.field] !== undefined) {
            _valueinfo = _itemData[_valu_attbrs.field][_valu_attbrs.source];
          }
        }
      } else {
        if (_itemData[_valu_attbrs.field] !== undefined) {
          _valueinfo = _itemData[_valu_attbrs.field][_value];
        }
      }
    } else {
      if (_valu_attbrs.source !== undefined) {
        if (_valu_attbrs.source.includes('.') === true) {
          var _fld = _valu_attbrs.source;
          if (_itemData[_fld.split('.')[0]] !== undefined) {
            _valueinfo = _itemData[_fld.split('.')[0]][_fld.split('.')[1]];
          }
        } else {
          _valueinfo = _itemData[_valu_attbrs.source];
        }
      } else {
        if (_itemData[_value] == undefined) {
          _valueinfo = _value;
        } else {
          _valueinfo = _itemData[_value];
        }
      }
    }

    if (
      _value == 'date' &&
      new Date(_valueinfo).toString() !== 'Invalid Date'
    ) {
      _valueinfo = new Date(_valueinfo).toDateString().substring(4);
    }

    return _valueinfo;
  }

  private Process_Element(
    element: any,
    _idx: any,
    _itemData: any,
    _isHeader?: boolean
  ) {
    var _src_attbrs;
    var _prop_attbrs;
    var _fnt_attbrs;
    var _bord_attbrs;
    var _valu_attbrs;
    var _value: any;
    var _mstyle: any = { attributes: {} };
    if (this.ReadSource(element).length > 0) {
      _src_attbrs = this.Child_Element_Attributes(element, 'Source');
      _value = this.Child_Element_Value(element, 'Source');
    }

    if (this.ReadProperties(element).length > 0) {
      _prop_attbrs = this.Child_Element_Attributes(element, 'Properties');
    }

    if (this.ReadFont(element).length > 0) {
      _fnt_attbrs = this.Child_Element_Attributes(element, 'Font');
    }

    if (this.ReadBorder(element).length > 0) {
      _bord_attbrs = this.Child_Element_Attributes(element, 'Border');
    }

    if (this.ReadValue(element).length > 0) {
      _value = this.Child_Element_Value(element, 'Value');
      _valu_attbrs = this.Child_Element_Attributes(element, 'Value');
    }

    if (_prop_attbrs !== undefined) {
      if (_prop_attbrs.padding !== undefined) {
        _mstyle.attributes['padding'] =
          _prop_attbrs.padding !== undefined
            ? this.process_Padding(_prop_attbrs.padding)
            : 'inherit';
      }
      if (_prop_attbrs.width !== undefined) {
        _mstyle.attributes['width'] =
          _prop_attbrs.width !== undefined ? _prop_attbrs.width + 'px' : '300';
      }
      if (_prop_attbrs.margin !== undefined) {
        _mstyle.attributes['margin'] =
          _prop_attbrs.margin !== undefined
            ? this.process_Margin(_prop_attbrs.margin)
            : 'inherit';
      }
      if (_prop_attbrs.lineheight !== undefined) {
        _mstyle.attributes['lineHeight'] =
          _prop_attbrs.lineheight !== undefined
            ? _prop_attbrs.lineheight + 'px'
            : 'inherit';
      }
      if (_prop_attbrs.spacing !== undefined) {
        _mstyle.attributes['whiteSpace'] =
          _prop_attbrs.spacing !== undefined
            ? _prop_attbrs.spacing + 'px'
            : 'pre-wrap';
      }
      if (_prop_attbrs.height !== undefined) {
        _mstyle.attributes['height'] =
          _prop_attbrs.height !== undefined
            ? _prop_attbrs.height + 'px'
            : '200';
      }
    }

    if (_bord_attbrs !== undefined) {
      if (_bord_attbrs.width !== undefined) {
        _mstyle.attributes['borderWidth'] =
          _bord_attbrs.width !== undefined
            ? _prop_attbrs.margin + 'px'
            : 'fit-content';
      }
      if (_bord_attbrs.color !== undefined) {
        _mstyle.attributes['color'] =
          _bord_attbrs.color !== undefined
            ? this.rgbToHex(_bord_attbrs.color)
            : 'transparent';
      }
      if (_bord_attbrs.style !== undefined) {
        _mstyle.attributes['borderStyle'] =
          _bord_attbrs.style !== undefined ? _bord_attbrs.style : 'solid';
      }
    }

    if (_fnt_attbrs !== undefined) {
      if (_fnt_attbrs.family !== undefined) {
        _mstyle.attributes['fontFamily'] =
          _fnt_attbrs.family !== undefined ? _fnt_attbrs.family : 'inherit';
      }
      if (_fnt_attbrs.size !== undefined) {
        _mstyle.attributes['fontSize'] =
          _fnt_attbrs.size !== undefined ? _fnt_attbrs.size + 'px' : '1px';
      }
      if (_fnt_attbrs.color !== undefined) {
        _mstyle.attributes['color'] =
          _fnt_attbrs.color !== undefined ? _fnt_attbrs.color : 'transparent';
      }
      if (_fnt_attbrs.weight !== undefined) {
        _mstyle.attributes['fontWeight'] =
          _fnt_attbrs.weight !== undefined ? _fnt_attbrs.weight : 'inherit';
      }
      if (_fnt_attbrs.decoration !== undefined) {
        _mstyle.attributes['textDecoration'] =
          _fnt_attbrs.decoration !== undefined
            ? _fnt_attbrs.decoration
            : 'none';
      }
    }

    if (element.name.toLowerCase() === 'image') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      if (_value === '') {
        return (
          <>
            <div
              key={_idx.toString()}
              className={_class}
              style={_mstyle.attributes}
            >
              <img
                src={_value}
                id={_itemData.id}
                alt={_src_attbrs.alt}
                style={_mstyle.attributes}
                onDoubleClick={this.ImageDblclicked.bind(this)}
              ></img>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div key={_idx.toString()} className={_class}>
              <img
                src={
                  this.state.f_document &&
                  this.state.f_document.filter((x) => {
                    return x['id'] == _itemData.id;
                  }).length > 0 &&
                  this.state.f_document.filter((x) => {
                    return x['id'] == _itemData.id;
                  })[0]['img'] !== null
                    ? `data:image/png;base64,${
                        this.state.f_document.filter((x) => {
                          return x['id'] == _itemData.id;
                        })[0]['img']
                      }`
                    : _value
                }
                id={_itemData.commonData.id}
                alt={
                  _src_attbrs.alt !== undefined
                    ? _src_attbrs.alt
                    : 'No Image To Display'
                }
                style={_mstyle.attributes}
                onDoubleClick={this.ImageDblclicked.bind(this)}
              ></img>
            </div>
          </>
        );
      }
    } else if (element.name.toLowerCase() === 'label') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }
      var _valueinfo = this.process_ValueInfo(
        _valu_attbrs,
        _value,
        _itemData == null ? this.props._data : _itemData
      );
      //return <div key={_idx.toString()} className={_class}><label style={_mstyle.attributes}>{_value}</label></div>

      let el: any;
      if (_valu_attbrs.control !== undefined) {
        switch (_valu_attbrs.control.toString()) {
          case 'select':
            let _valueid: any = _itemData.commonData.id;
            let _valuename: any = _itemData.commonData.itemValue;
            el = (
              // <select
              //   className='custom-select'
              //   placeholder='Select Document Type'
              //   onChange={this.handleDocumentChange.bind(this)}>
              //   {this._document_type.map((x: any, y: any) => (
              //     <option key={x.object}>{x.value}</option>
              //   ))}
              // </select>

              <DropDownListComponent
                id='doctype'
                popupWidth={'300px'}
                dataSource={this._document_type}
                ref={(scope) => {
                  this.listdocumenttype = scope;
                }}
                filterBarPlaceholder='Search a Document Type'
                allowFiltering={true}
                filterType='Contains'
                fields={this.cfields}
                //change={this.handleDocumentChange.bind(this)}
                placeholder='Select Document Type'
                popupHeight='220px'
                value={_valueid}
                text={_valuename}
                style={{
                  fontSize: '15px',
                }}
              ></DropDownListComponent>
            );
            break;
          default:
            el = (
              <label
                id={
                  _itemData == null
                    ? 'lbldoc'
                    : 'lblHead_' + Math.random().toString()
                }
                style={_mstyle.attributes}
                onClick={this.handleLabelClick.bind(this)}
                htmlFor={_valueinfo}
              >
                {_valueinfo}
              </label>
            );
            break;
        }
      } else {
        el = (
          <label
            id={
              _itemData == null
                ? 'lbldoc'
                : 'lblHead_' + Math.random().toString()
            }
            style={_mstyle.attributes}
            onClick={this.handleLabelClick.bind(this)}
            htmlFor={_valueinfo}
          >
            {_valueinfo}
          </label>
        );
      }

      var _melem: any;
      if (_isHeader == true) {
        _melem = (
          <div
            key={_idx.toString()}
            style={{
              float: 'left',
              width: _itemData.commonData.itemValue.length > 20 ? '58%' : '36%',
            }}
          >
            {el}
          </div>
        );
      } else {
        _melem = <div key={_idx.toString()}>{el}</div>;
      }
      return _melem;
    } else if (element.name.toLowerCase() === 'text') {
      var _class = '';
      if (element.attributes.col !== undefined) {
        _class = 'offset-' + element.attributes.col.toString();
      }
      if (element.attributes.cols !== undefined) {
        _class = _class + ' col-' + element.attributes.cols.toString();
      }

      var _valueinfo = this.process_ValueInfo(
        _valu_attbrs,
        _value,
        _itemData == null ? this.props._data : _itemData
      );

      var _id = _itemData.id;
      if (_id == undefined) {
        _id = Math.random();
      }

      //return <div key={_idx.toString()} className={_class}><label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label></div>
      if (_valu_attbrs.control !== undefined) {
        switch (_valu_attbrs.control.toString()) {
          case 'date':
            return (
              <DatePickerComponent
                format={this.props._user.settings.formats.DateShort}
                value={
                  _valueinfo == undefined ? new Date() : new Date(_valueinfo)
                }
                showClearButton={false}
                cssClass='e-hidedate-icon'
                // onFocus={this.onFocus.bind(this)}
                // onBlur={this.onBlur.bind(this)}
                openOnFocus={true}
                showTodayButton={false}
                id={_id}
              />
            );

          //<label typeof="text" style={_mstyle.attributes}>{_valueinfo}</label>; //<DatePickerComponent format="dd/MM/yyyy" value={new Date(_valueinfo)} />;
          case 'select':
            return (
              <select id={_id} name='select' style={{ width: '100%' }}></select>
            );
          case 'text':
            return (
              <input
                type='text'
                id={_id}
                style={{ width: '95%' }}
                value={_valueinfo == undefined ? '' : _valueinfo}
                onClick={this.handleTextClick.bind(this)}
              />
            );
          default:
            return (
              <label
                id={_id}
                typeof='text'
                style={_mstyle.attributes}
                onClick={this.handleLabelClick.bind(this)}
              >
                {_valueinfo}
              </label>
            );
        }
      } else {
        _mstyle.attributes['borderStyle'] = 'none';
        _mstyle.attributes['background'] = 'transparent';
        _mstyle.attributes['width'] = '95%';
        return (
          <input
            id={_id}
            type='text'
            style={_mstyle.attributes}
            onClick={this.handleTextClick}
            onBlur={this.handleTextBlur}
            defaultValue={_valueinfo}
          ></input>
        );
      }
    }
  }

  private FindValFromJson(_obj: any, _key: string) {
    if (_obj[_key]) return _obj[_key];
    for (let key in _obj) {
      if (typeof _obj[key] === 'object') {
        const value: any = this.FindValFromJson(_obj[key], _key);
        if (value) return value;
      }
    }
    return false;
  }

  private getAttributeValues(element: any, name: string) {
    var matches: any[] = [];
    for (var attribute in element.attributes) {
      if (attribute.toLowerCase() === name.toLowerCase()) {
        matches.push(element.attributes[attribute]);
      }
    }
    for (let index = 0; index < element.children.length; index++) {
      matches = matches.concat(
        this.getAttributeValues(element.children[index], name)
      );
    }
    return Array.from(new Set(matches.map((item: any) => item)));
  }

  private getElementByAttributeValue(
    element: any,
    name: string,
    value: string
  ) {
    var matches: any[] = [];
    for (var attribute in element.attributes) {
      if (attribute.toLowerCase() === name.toLowerCase()) {
        if (
          value !== '' &&
          element.attributes[attribute].toLowerCase() === value.toLowerCase()
        ) {
          matches.push(element);
        } else {
          //matches.push(element);
        }
      }
    }
    for (let index = 0; index < element.children.length; index++) {
      matches = matches.concat(
        this.getElementByAttributeValue(element.children[index], name, value)
      );
    }
    return matches;
  }

  private ReadElement(Parent: any, tagname: string) {
    var matches: any[] = [];
    if (Parent.name.toLowerCase() === tagname.toLowerCase()) {
      matches.push(Parent);
    }
    Parent.children.map((child: any) => {
      matches = matches.concat(this.ReadElement(child, tagname));
    });
    return matches;
  }

  private ReadSource(Parent: any) {
    return this.ReadElement(Parent, 'Source');
  }

  private ReadProperties(Parent: any) {
    return this.ReadElement(Parent, 'Properties');
  }

  private ReadFont(Parent: any) {
    return this.ReadElement(Parent, 'Font');
  }

  private ReadBorder(Parent: any) {
    return this.ReadElement(Parent, 'Border');
  }

  private ReadValue(Parent: any) {
    return this.ReadElement(Parent, 'Value');
  }

  private IsAttributeExists(element: any, _attribute: string) {
    for (var attribute in element.attributes) {
      if (_attribute.toLowerCase() === attribute.toLowerCase()) {
        return true;
      }
    }
  }

  private Child_Element_Attributes(ParentElement: any, ChildTagName: string) {
    var attbr;
    for (let index = 0; index < ParentElement.children.length; index++) {
      if (
        ParentElement.children[index].name.toLowerCase() ===
        ChildTagName.toLowerCase()
      ) {
        attbr = ParentElement.children[index].attributes;
      }
    }
    return attbr;
  }

  private Child_Element_Value(ParentElement: any, ChildTagName: string) {
    var _value;
    for (let index = 0; index < ParentElement.children.length; index++) {
      if (
        ParentElement.children[index].name.toLowerCase() ===
        ChildTagName.toLowerCase()
      ) {
        _value =
          ParentElement.children[index].value.toString() === ''
            ? ''
            : ParentElement.children[index].value; //ParentElement.children[index].attributes;
      }
    }
    return _value;
  }

  private renderAccordionItem = (index: number) => {
    const header = () => _headers[index];
    const content = () => _contents[index];
    var expnd: boolean = false;
    if (this._accrd_expanded.length > 0) {
      if (this._accrd_expanded.includes(parseInt(index as any)) === true) {
        expnd = true;
      }
    }

    // if (this.acrdnInstance !== undefined) {
    //   this.acrdnInstance.expandItem(true, this.expandIndex);
    // }

    // if (this.state.expandIndex === parseInt(index as any)) {
    //   expnd = true;
    // }

    return (
      <AccordionItemDirective
        key={index}
        header={header}
        expanded={expnd}
        content={content == undefined ? <div></div> : content}
        id={this.props._data.documents[index].id}
      />
    );
  };

  private GetDocumentType() {
    // let payload: any = {
    //   advancedSearch: {
    //     fields: ['itemType'],
    //     keyword: this._structure.CommonDataItems.IdentityType.toString(),
    //   },
    // };
    // Common.ApiCallAsync(
    //   'POST',
    //   `${REACT_APP_ENDPOINT_CORESERVICE}` + '/commondata/search',
    //   payload,
    //   Common.getToken() || '',
    //   this.user,
    //   this.token.tenant
    // )
    //   .then((response: any) => {
    //     return response.data;
    //   })
    //   .then((response: any) => {
    //     this.setState({ document_type: response.data });
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   })
    //   .finally(() => { });

    let types = this.user.commondata.filter(
      (x: any) => x.itemType === this._structure.CommonDataItems.IdentityType
    );

    return types;

    // let _objectId = Common.FindJsonByObjectValue(
    //   this._fulljson,
    //   '_document_',
    //   this.user
    // );
    // if (_objectId !== null) {
    //   // Common.ApiCallAsync(
    //   //   'POST',
    //   //   `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/General/Group/Get?id=${_objectId.object}`,
    //   //   '',
    //   //   Common.getToken() || '',
    //   //   this.user
    //   // ).then((resopnse: any) => {
    //   //   return resopnse.data;
    //   // });
    //   let types = Common.FindJsonByObjectId(
    //     this._fulljson,
    //     _objectId.object,
    //     this.user
    //   );
    //   return types.children;
    // }
  }

  // PLEASE DO NOT REMOVE THIS METHOD IT'S BEING USED AS REFRECENCE METHOD IN DATAVIEWER COMPONENT
  private AddAccordion(_doctypeid: number, _doctypename: string) {
    let resp: any;
    let payload: any = {
      id: this.props._data.id,
      account: {
        isDeduction: false,
        id: _doctypeid,
        name: _doctypename,
      },
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/person/Document/Set`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        resp = {
          id: resopnse.data.id,
          name: '',
          account: {
            isDeduction: false,
            id: resopnse.data.Account.id,
            name: resopnse.data.Account.Name,
          },
        };

        if (this.props._data.hasOwnProperty('document') == false) {
          let _doc: any = [];
          this.props._data['document'] = _doc;
        }
        let exist = this.props._data['document'].filter(
          (x: any) => x.account.id == _doctypeid
        );
        if (exist.length <= 0) {
          this.props._data['document'].push(resp);
        }

        this.setState({ _addNew: true });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private async GetContextMenu() {
    /* THIS IS TEMP CODE TO BE REVISED ONCE WE HAVE THE MENU IN PLACE - START*/
    // let _objectId = Common.FindJsonByObjectValue(
    //   this._fulljson,
    //   '_document_',
    //   this.user
    // );
    // if (_objectId !== null) {
    //   let menu = Common.FindJsonByObjectId(
    //     this._fulljson,
    //     _objectId.object,
    //     this.user
    //   );
    //   if (menu !== undefined) {
    //     this.contextmenu = menu;
    //   }
    //   this.setState({ _menuitems: this.contextmenu });
    // }
  }

  private setContextMenuItems() {
    let payload: any = {
      advancedSearch: {
        fields: ['itemType'],
        keyword: this._structure.CommonDataItems.IdentityType.toString(),
      },
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}` + '/commondata/search',
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let menu = response.data;
        if (menu !== undefined) {
          this.contextmenu = menu;
        }
        let cntxmenuitems: MenuItemModel[] | undefined = [];
        for (let i = 0; i < menu.length; i++) {
          cntxmenuitems.push({ id: menu[i].id, text: menu[i].itemValue });
        }
        for (let i = 0; i < cntxmenuitems.length; i++) {
          this.cntxitems[0].items?.splice(1, 0, cntxmenuitems[i]);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});

    this.menuItem = this.cntxitems;

    // let _objectId = Common.FindJsonByObjectValue(
    //   this._fulljson,
    //   '_document_',
    //   this.user
    // );
    // if (_objectId !== null) {
    //   let menu = Common.FindJsonByObjectId(
    //     this._fulljson,
    //     _objectId.object,
    //     this.user
    //   );
    //   if (menu !== undefined) {
    //     this.contextmenu = menu;
    //   }
    //   let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
    //     (this.contextmenu as any).children,
    //     this._structure,
    //     this.user
    //   );
    //   for (let i = 0; i < cntxmenuitems.length; i++) {
    //     this.cntxitems[0].items?.splice(1, 0, cntxmenuitems[i]);
    //   }
    //   this.menuItem = this.cntxitems;
    // } else {
    //   this.menuItem = this.cntxitems;
    // }

    // if (this.state._menuitems !== undefined) {
    //   // let cntxmenu = Common.FindJsonByRelationFieldId(
    //   //   this.contextmenu.children,
    //   //   this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
    //   //   this.user
    //   // ); //9150 = CntxMenu
    //   // if (cntxmenu !== null) {
    //   //   let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
    //   //     cntxmenu.children,
    //   //     this._structure,
    //   //     this.user
    //   //   );
    //   //   cntxmenuitems = cntxmenuitems.filter(
    //   //     (x: any) => !x.text.includes('Re-Entry')
    //   //   );
    //   //   if (cntxmenuitems) this.menuItem = cntxmenuitems;
    //   // }
    //   let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
    //     (this.state._menuitems as any).children,
    //     this._structure,
    //     this.user
    //   );
    //   cntxmenuitems = cntxmenuitems.filter(
    //     (x: any) => !x.text.includes('Re-Entry')
    //   );
    //   let _new: any = this.cntxitems.filter(
    //     (x: any) => x.text.toLowerCase() === 'new'
    //   );
    //   this.menuItem = this.cntxitems;
    //   // this.cMenu?.items[0].text = 'tempitem1';
    //   // this.cMenu?.insertBefore(cntxmenuitems, 'Delete', true);
    //   // if (_new.length > 0) {
    //   //   if (cntxmenuitems) {
    //   //     _new[0].items = cntxmenuitems;
    //   //   }
    //   //   this.menuItem = _new;
    //   // }
  }
  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SOURAV - 2023-05-30 - 400 -- writing code and doing reserch for document editor.
  // * SOURAV - 2023-05-30 - 60 --  fixing issue related to code merging.
  // * SOURAV - 2023-05-31 - 400 -- writing code for document editors component.
  // * SOURAV - 2023-06-01 - 460 -- writing code for document editors component and fixing previous issues in image editor component.
  // * SOURAV - 2023-06-02 - 360 -- writing code for document editors component and fixing previous issues in image editor component.
  // * SOURAV - 2023-06-02 - 90 -- writing code for document editors component.
  // * SOURAV - 2023-06-03 - 470 -- fixing previous issues in image editor component and also in document editor.
  // * SOURAV - 2023-06-05 - 400 -- fixing previous issues in image editor component and also in document editor.
  // * SOURAV - 2023-06-06 - 300 -- fixing document issues in image editor component and also in document editor.
  // * SOURAV - 2023-06-06 - 150 -- fixing image issues (centre of div) in image editor component.
  // * SOURAV - 2023-06-07 - 270  -- implementing changes as said by fabio in image editor and document editor component.
  // * SOURAV - 2023-06-08 - 470 -- implementing some changes in image and document editor component and also working with suhail for image saving in database.
  // * SOURAV - 2023-06-22 - 450 -- working on document saving features and also working to get it from the database and also doing some research on it.
  // * SOURAV - 2023-06-23 - 360 -- working on document saving features and also working to get it from the database and able to complete it.
  // * SOURAV - 2023-06-29 - 300 -- implemented changes in documented editor(fixes issues like silhoutte shadowing issue,direct cropping issue, saving issue)
  // * SOURAV - 2023-10-05 - 120 -- working on to fix error in document editor on double clicking.
  // * SOURAV - 2023-10-19 - 280 -- working on to fix document updating and document saving issue.

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    // if (this.menuItem.length <= 0) {
    //   this.setContextMenuItems();
    // }

    if (this._document_type == undefined) {
      this._document_type = this.GetDocumentType();
    }

    //var _xlabel = this.Process_Accordian(this.props._element);
    var _mStyle: any = { attributes: {} };
    _mStyle.attributes['margin'] = '25px 0';
    _mStyle.attributes['overflow'] = 'auto';
    if (this.props._height == undefined) {
      _mStyle.attributes['height'] = 'calc(100vh - 313px)';
    } else {
      _mStyle.attributes['height'] = this.props._height + 'vh';
    }
    if (this.props._width == undefined) {
      // _mStyle.attributes['width'] = '770px';
      _mStyle.attributes['width'] = '100%';
    } else {
      _mStyle.attributes['width'] = this.props._width;
    }
    return (
      <>
        {this._document_type !== undefined ? (
          <>
            <div id='_accordion'>
              <div>{this.Process_Accordian(this.props._element)}</div>
              <div
                data-context='_accordion_header'
                className='control Accordion-sample'
                style={_mStyle.attributes}
              >
                {this.isEmpty(_headers) == false ? (
                  <>
                    <AccordionComponent
                      expanding={this.onExpanding.bind(this)}
                      //clicked={this.onClicked.bind(this)}
                    >
                      <AccordionItemsDirective>
                        {Object.keys(_headers).map((item: any) =>
                          this.renderAccordionItem(item)
                        )}
                      </AccordionItemsDirective>
                    </AccordionComponent>
                  </>
                ) : null}
              </div>
              <div>
                {this.state.showDialog === true ? (
                  <ImageEditor
                    key={Math.random()}
                    showImage={this.state.showDialog}
                    _data={this.props._data}
                    getFinalImage={this.getFinalImage}
                    isImageEditor={false}
                    doubleClickedId={this.state.doubleClick_id}
                    editImage={this.state.editImage}
                  />
                ) : null}
              </div>
            </div>
            <div id='cmenu'>
              {/* <ContextMenuComponent
            id={Math.random().toString() + 'contextmenu'}
            ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
            items={this.menuItem}
            select={this.handleMenuClick}
            target='#_accordion'
          /> */}
            </div>
            <div>
              {this.state.showDocDialog === true ? (
                <DialogComponent
                  id='defaultDialog1'
                  showCloseIcon={true}
                  visible={this.state.showDocDialog}
                  width={'40vh'}
                  height={'50hv'}
                  close={() => this.setState({ showDocDialog: false })}
                  content={this.dialogContent.bind(this)}
                  isModal={true}
                  header={'New Document'}
                  statelessTemplates={[]}
                ></DialogComponent>
              ) : null}
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default Accordion;
