import * as React from 'react';
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
//import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Regwizard from '../GSignup/Regwizard';
import Common from '../../../Util/Common';
import { googleLogout, GoogleLogin } from '@react-oauth/google';
import './Signup.css';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { AppContext } from '../AppState';
import { RegistrationAction } from '../RegistrationReducer';
interface props1 {
  //nextStep: any;
  //   handleFormData: (
  //     input: string
  //   ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  // values: {
  //   userName: string;
  //   password: string;
  //   cpassword: string;
  // };
  // gName: any;
  // exitStep: any;
}

const Identity1 = (props1: props1, ref: any) => {
  const { state, dispatch } = useContext(AppContext);
  let { username, password, cpassword, step_no, error } = state.registration;

  //const [error, setError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [credit, setCredit] = useState({ name: '', email: '' });
  const [showModal, setShowModal] = useState(true);
  const [details, setDetails] = useState(null);

  // const submitFormData = (e: any) => {
  //   e.preventDefault();

  //   if (props1.values.password !== props1.values.cpassword) {
  //     setError(true);
  //   } else {
  //     props1.nextStep();
  //   }
  // };

  // const responseGoogle = (response: any) => {
  //   if (response.error) {
  //     console.log(response.error);
  //   } else {
  //     const credential = response.credential;

  //     try {
  //       let cred = Common.parseJwt(credential);
  //       setCredit(cred);
  //     } catch (error) {
  //       console.log('Error decoding token:', error);
  //     }
  //   }
  // };
  const handleArutiTosClick = () => {
    setIsDialogOpen(true);
    // props1.nextStep();
  };

  // const exitHandler = () => {
  //   props1.exitStep();
  // };

  //--------------------------------------30-11-23------------------------------
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      // props1.nextStep();
      // console.log(tokenResponse);
      try {
        const res = await axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json',
            },
          })
          .then((response: any) => {
            setDetails(response.data);

            //props1.gName(response.data);
            //props1.nextStep();
            dispatch({
              type: 'registration-field',
              fieldName: 'username',
              payload: response.data.email,
            });
            dispatch({
              type: 'registration-field',
              fieldName: 'fullname',
              payload: response.data.name,
            });
            dispatch({ type: 'registration-activestep', stepno: step_no + 1 });
          })
          .catch((err: any) => {
            console.log('error');
          });
      } catch (error) {
        console.log('Something went wrong');
      }
    },
  });
  return (
    <>
      <div className='row ' id='Identity'>
        <div className='col-12 '>
          <form>
            <div
              className='row my-3'
              // style={{
              //   height: '268px',
              // }}
            >
              <div className='col-md-8 '>
                <div className='row   '>
                  <div className='col-md-10 my-2'>
                    <input
                      type='email'
                      className='identity-input form-control'
                      id='username'
                      name='username'
                      //value={props1.values.username}
                      value={username}
                      //onChange={props1.handleFormData('username')}
                      onChange={(e) =>
                        dispatch({
                          type: 'registration-field',
                          fieldName: 'username',
                          payload: e.currentTarget.value,
                        })
                      }
                      placeholder='User email*'
                      autoComplete='off'
                      required
                    />
                  </div>
                </div>
                <div className='row   '>
                  <div className='col-md-10 my-2'>
                    <input
                      type='password'
                      className='identity-input form-control'
                      id='password'
                      placeholder='Password*'
                      name='password'
                      autoComplete='off'
                      //value={props1.values.password}
                      value={password}
                      //onChange={props1.handleFormData('password')}
                      onChange={(e) =>
                        dispatch({
                          type: 'registration-field',
                          fieldName: 'password',
                          payload: e.currentTarget.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
                <div className='row   '>
                  <div className='col-md-10 my-2'>
                    <input
                      type='password'
                      className='identity-input form-control'
                      id='cpassword'
                      placeholder='Verify Password*'
                      name='cpassword'
                      //value={props1.values.c_password}
                      value={cpassword}
                      //onChange={props1.handleFormData('cpassword')}
                      onChange={(e) =>
                        dispatch({
                          type: 'registration-field',
                          fieldName: 'cpassword',
                          payload: e.currentTarget.value,
                        })
                      }
                      autoComplete='off'
                      required
                    />
                  </div>
                </div>
              </div>
              <div className='identity-summary col-md-4'>
                <div className='row '>
                  <div className='col-md-12'>
                    <p>
                      By Entering your Data or 'Continue with Google', you agree
                      to the{' '}
                      <span
                        className='aruti-tos mx-1'
                        //onClick={handleArutiTosClick}
                        data-bs-toggle='offcanvas'
                        data-bs-target={`#arutitos-offcanvasRight`}
                        aria-controls='arutitos-offcanvasRight'
                      >
                        Aruti TOS
                      </span>{' '}
                      and
                      <span
                        className='aruti-tos mx-1'
                        //onClick={handleArutiTosClick}
                        data-bs-toggle='offcanvas'
                        data-bs-target={`#prvpolicy-offcanvasRight`}
                        aria-controls='prvpolicy-offcanvasRight'
                      >
                        Privacy Policy
                      </span>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row my-3 '>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-md-4'>
                    <button
                      type='button'
                      onClick={() => login()}
                      id='google-btn'
                      style={{
                        width: '100%',
                        minHeight: '40px',
                        height: 'auto',
                      }}
                    >
                      <img
                        src='../images/google-logo.png'
                        style={{
                          width: '1.25rem',
                          height: '1.25rem',
                          marginRight: '7px',
                        }}
                      />
                      Sign up with Google
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='row' style={{ marginTop: '30px' }}>
              <div className='nextbtn col-12'>
                <button
                  className='flat-button fs-6'
                  type='button'
                  data-bs-dismiss='modal'
                  onClick={exitHandler}
                >
                  Exit
                </button>
                <button type='submit' className='flat-button mx-1 fs-6'>
                  Next
                </button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      <div>
        {/* {isDialogOpen ? (
          <DialogComponent
            id='contract-dialog'
            showCloseIcon={true}
            visible={isDialogOpen}
            width={'25vw'}
            height='auto'
            close={() => setIsDialogOpen(false)}
            isModal={true}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null} */}
        <div
          className='offcanvas offcanvas-end'
          tabIndex={-1}
          id={`arutitos-offcanvasRight`}
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='offcanvas-header'>
            <h5 className='offcanvas-title' id='offcanvasRightLabel'>
              {/* Select Field to Insert */}
              Aruti TOS
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
            ></button>
          </div>
          <div className='offcanvas-body'>
            <div
              id='arutitos-body'
              //className='d-none'
            ></div>
          </div>
        </div>
      </div>
      <div>
        <div
          className='offcanvas offcanvas-end'
          tabIndex={-1}
          id={`prvpolicy-offcanvasRight`}
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='offcanvas-header'>
            <h5 className='offcanvas-title' id='offcanvasRightLabel'>
              {/* Select Field to Insert */}
              Privacy Policy
            </h5>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
            ></button>
          </div>
          <div className='offcanvas-body'>
            <div
              id='prvpolicy-body'
              //className='d-none'
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Identity1;
