// import { useEffect, useState } from 'react';

// interface props4 {
//   nextStep: () => void;
//   prevStep: () => void;
//   handleFormData: (
//     input: string
//   ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
//   values: {
//     userName: string;
//     password: string;
//     name: string;
//     birthday: string;
//     gender: string;
//     phone: string;
//     company: string;
//   };
// }

// const Message1 = (props4: any) => {
//   const [data, setData] = useState({
//     //  id: 1,
//     //  name: '',
//     //  birthdate: '',
//     //  gender: '',
//     //  phone: '',
//     //  company: '',
//   });

//   let u_id = Math.floor(Math.random() * 100);
//   useEffect(() => {
//     setData({
//       id: u_id,
//       userName: props4.values.username,
//       password: props4.values.password,
//       name: props4.values.name,
//       birthdate: props4.values.birthday,
//       gender: props4.values.gender,
//       phone: props4.values.phone,
//       company: props4.values.company,
//     });
//   }, []);

//   if (data) console.log(data);

//   const handlePrevious = () => {
//     props4.prevStep();
//   };

//   return (
//     <>
//       <div className='row mx-5' style={{ height: '275px' }}>
//         <div className='col-12'>
//           <div
//             className='row mt-3'
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//             }}
//           >
//             <div
//               className='col-6'
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 fontSize: '14px',
//               }}
//             >
//               <div>You have sucessfully registered with</div>
//             </div>
//           </div>
//           <div
//             className='row my-4'
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//             }}
//           >
//             <div
//               className='col-6 fs-5'
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <div>{props4.values.company}</div>
//             </div>
//           </div>
//           <div
//             className='row'
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//             }}
//           >
//             <div
//               className='col-6'
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 fontSize: '14px',
//               }}
//             >
//               <div>{props4.values.name}</div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='row mx-4'>
//         <div
//           className='col-12'
//           style={{
//             display: 'flex',
//             justifyContent: 'right',
//             alignItems: 'center',
//           }}
//         >
//           <button className='flat-button fs-6'>Exit</button>
//           <button className='flat-button mx-1 fs-6' onClick={handlePrevious}>
//             Back
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Message1;

import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import './Signup.css';
import { AppContext } from '../AppState';
interface props4 {
  // email: string;
  // nextStep: () => void;
  // prevStep: () => void;
  // handleFormData: (
  //   input: string
  // ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  // values: {
  //   name: string;
  //   birthday: string;
  //   gender: string;
  //   phone: string;
  //   company: string;
  // };
  // exitStep: any;
}

const Message1 = (props4: props4) => {
  const { state, dispatch } = useContext(AppContext);
  let { username, fullname, birthdate, phoneno, companycode, error } =
    state.registration;
  const [data, setData] = useState({
    //  id: 1,
    //  name: '',
    //  birthdate: '',
    //  gender: '',
    //  phone: '',
    //  company: '',
  });

  let u_id = Math.floor(Math.random() * 100);

  useEffect(() => {
    setData({
      id: u_id,
      // name: props4.values.name,
      // email: props4.email,
      // birthdate: props4.values.birthday,
      // gender: props4.values.gender,
      // phone: props4.values.phone,
      // company: props4.values.company,
      name: fullname,
      email: username,
      birthdate: birthdate,
      gender: '',
      phone: phoneno,
      company: companycode,
    });
  }, []);

  // const handlePrevious = () => {
  //   props4.prevStep();
  // };
  // const exitHandler = () => {
  //   props4.exitStep();
  // };

  return (
    <>
      <div className='message-center row my-5'>
        <div className='col-12'>
          <div className='message-center row'>
            <div className='message-center col-6 fs-6'>
              <p>You have sucessfully registered with</p>
            </div>
          </div>
          <div className='message-center row '>
            <div className='message-center col-6 '>
              {/* <h3>{props4.values.company}</h3> */}
              <h3>{companycode}</h3>
            </div>
          </div>
          <div className='message-center row'>
            <div className='message-center col-6 fs-6'>
              {/* <p>{props4.values.name}</p> */}
              <p>{fullname}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row mx-5' style={{ marginTop: '110px' }}>
        <div className='message-btn  col-12'>
          <button
            className='flat-button fs-6'
            data-bs-dismiss='modal'
            onClick={exitHandler}
          >
            Exit
          </button>
          <button className='flat-button mx-1 fs-6' onClick={handlePrevious}>
            Back
          </button>
        </div>
      </div> */}
    </>
  );
};
export default Message1;
