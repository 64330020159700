import React, { Dispatch } from 'react';

/*
---------------------------------------------------------------------------------------------------------------------------2.Initialize
*/
// * SUHAIL - 2023-02-23 - 30
type InitialStateType = {
  loginState: LoginState;
  shoppingCart: number;
};

export interface IContextProps {
  state: LoginState;
  dispatch: Dispatch<LoginAction>;
  // state: InitialStateType;
  // dispatch: React.Dispatch<any>;
}

// export const LoginContext = React.createContext({} as IContextProps);

// export function useLoginContext() {
//   return React.useContext(LoginContext);
// }

export interface LoginState {
  password: string;
  username: string;
  isLoading: boolean;
  error: string;
  isLoggedIn: boolean;
}

export const initialStateLogin: LoginState = {
  password: '',
  username: '',
  isLoading: false,
  error: '',
  isLoggedIn: false,
};

export type LoginAction =
  | { type: 'login' | 'success' | 'error' | 'logout' }
  | { type: 'field'; fieldName: string; payload: string | boolean };

/*
----------------------------------------------------------------------------------------CLASS
*/
// * SUHAIL - 2023-02-23 - 30
export const loginReducer = (
  state: LoginState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case 'field': {
      return {
        ...state,
        error: '',
        [action.fieldName]: action.payload,
      };
    }
    case 'login': {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }
    case 'success': {
      return { ...state, error: '', isLoading: false, isLoggedIn: true };
    }
    case 'error': {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        username: '',
        password: '',
        error: 'Incorrect username or password!',
      };
    }
    case 'logout': {
      return {
        ...state,
        isLoggedIn: false,
      };
    }
    default:
      return state;
  }
};
