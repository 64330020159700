// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import './EmployeeBank.css';
import Common from '../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  GridComponent,
  IEditCell,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { Dialog } from '@syncfusion/ej2-react-popups';
import {
  DropDownListComponent,
  DropDownTreeComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_PAYROLLSERVICE } = process.env;

// interface empbankmodel {
//   isAdd?: boolean;
//   id?: string;
//   jsonDataViewer?: any;
//   employeeId?: string;
//   modeId?: string;
//   paymentTypeId?: string;
//   startDate?: Date;
//   endDate?: Date;
//   percentage?: number;
//   employeeBankId?: string;
//   distribution?: any;
//   jsonEmployee?: any;
//   jsonPaymentMode?: any;
//   jsonPaymentType?: any;
//   jsonDistribution?: any;
//   jsonBank?: any;
//   jsonAccountType?: any;
//   defaultEmployeeId?: string;
// }

interface Props {
  jsonDataViewer: any;
  jsonEmployee: any;
  jsonPaymentMode: any;
  jsonPaymentType?: any;
  //jsonDistribution?: any;
  jsonBank?: any;
  jsonAccountType?: any;
  defaultEmployeeId?: string;
  bfields?: any;
  employeeBankId?: string;
  startDate?: any;
  modeId?: any;
  paymentTypeId?: any;
  openperiod?: any;
}

export default class EmployeeBank extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private grdBank: GridComponent | any = undefined;
  private ddlEmployee: DropDownListComponent | any;
  private ddlMode: DropDownListComponent | any;
  private ddlPaymentType: DropDownListComponent | any;
  private ddlAccountType: DropDownListComponent | any;
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private bankId: any = null;
  private bankName: any = null;
  private accounTypeId: any = null;
  private accounTypeName: any = null;
  private treeSettings: TreeSettingsModel = { expandOn: 'Click' };
  private dtpStart: DatePickerComponent | any = undefined;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    //this.state = Object.assign({}, props);
  }

  static defaultProps = {
    defaultEmployeeId: '',
    distribution: [],
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    employeeId: this.props.defaultEmployeeId,
    paymentTypeId: this.props.paymentTypeId ?? undefined,
    modeId: this.props.modeId ?? undefined,
    startDate: this.props.startDate
      ? this.props.startDate
      : this.props.openperiod
      ? new Date(this.props.openperiod!['startDate'])
      : new Date(),
    bankId: undefined,
    accountTypeId: undefined,
    accountNo: undefined,
    mobileNo: undefined,
    percentage: 100,
    openperiod: this.props.openperiod ?? undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {
    // if (!data.jsonDistribution) {
    //   this.GetDistribution();
    // }

    if (this.state.openperiod == undefined) {
      this.getopenperiod();
    }

    if (!this.props.jsonBank) {
      this.GetBank();
    }

    if (!this.props.jsonAccountType) {
      this.GetAccountType();
    }
  }

  private getopenperiod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/firstperiod`,
      {
        statusId: Number(this._structure.PeriodStatusId.Open),
        lastPeriodOnNoOpenPeriod: false,
      },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (!this.props.startDate) {
          this.dtpStart.value = new Date(response.startDate);
          this.setState({
            startDate: new Date(response.startDate),
            openperiod: response,
          });
        } else {
          this.setState({
            openperiod: response,
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetState() {
    return this.state;
  }
  public GetDistribution() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/listdistribution`,
      { employeeBankId: Common.blankguid },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonDistribution: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetBank() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/list`,
      { isgroup: false },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        // this.setState({
        //   jsonBank: response,
        // });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetAccountType() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/accounttype/list`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        // this.setState({
        //   jsonAccountType: response,
        // });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  onChange(args: any): void {
    let key: string = args.target.name;
    let value: string = args.target.value;
    this.setState({ [key]: value });
  }

  public EndEdit() {
    return !this.grdBank.element.ej2_instances[0].isEdit;
  }

  render() {
    let cnfDlg: Dialog;
    const handleDropDownTreeChange = (e: any) => {
      this.setState({ bankId: e.value });
    };

    const openDropdownListOnFocus = (e: any) => {
      if (e.event.type === 'focus' && e.event.sourceCapabilities !== null) {
        let spn: HTMLElement = e.event.target.ej2_instances[0].element;
        let ddl: any = spn.querySelector('input');
        if (ddl) {
          ddl.ej2_instances[0].showPopup();
        }
      }
    };

    return (
      <>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Employee
            </label>
            <DropDownListComponent
              id='employeeId'
              name='employeeId'
              dataSource={this.props.jsonEmployee}
              ref={(obj) => {
                this.ddlEmployee = obj;
              }}
              filterBarPlaceholder='Search Employee'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'personName' }}
              placeholder='Select Employee'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              value={this.props.defaultEmployeeId ?? undefined}
              change={(e: any) => {
                this.setState({ employeeId: e.value });
              }}
              enabled={false}
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Payment Mode
            </label>
            <DropDownListComponent
              id='modeId'
              name='modeId'
              dataSource={this.props.jsonPaymentMode}
              ref={(obj) => {
                this.ddlMode = obj;
              }}
              filterBarPlaceholder='Search Mode'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'Id', text: 'Name' }}
              placeholder='Select Mode'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              change={(e: any) => {
                this.setState({ modeId: e.value });
              }}
              created={() => {
                this.ddlMode.value = 2;
              }}
              //value={2}
              enabled={false}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          {/* <div className='col-6 py-2'>
            <span className=''></span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              End Date
            </label>
            <DatePickerComponent
              id='endDate'
              name='endDate'
              format={this.user.settings.formats.DateShort}
              value={data.isAdd ? undefined : data.endDate}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              style={{ fontSize: '15px' }}
            />
          </div> */}
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Payment Type
            </label>
            <DropDownListComponent
              id='paymentTypeId'
              name='paymentTypeId'
              dataSource={this.props.jsonPaymentType}
              ref={(obj) => {
                this.ddlPaymentType = obj;
              }}
              filterBarPlaceholder='Search Payment Type'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'Id', text: 'Name' }}
              change={(e: any) => {
                this.setState({ paymentTypeId: e.value });
              }}
              placeholder='Select Payment Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              created={() => {
                this.ddlPaymentType.value = 1;
              }}
              enabled={false}
            />
          </div>
          {/* <input
            type='hidden'
            id='distribution'
            name='distribution'
            value={
              data.distribution ? JSON.stringify(data.distribution) : 'hdn'
            }
          /> */}
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Start Date
            </label>
            <DatePickerComponent
              id='startDate'
              name='startDate'
              format={this.user.settings.formats.DateShort}
              value={
                this.props.startDate
                  ? this.props.startDate
                  : this.state.openperiod
                  ? new Date(this.state.openperiod!['startDate'])
                  : new Date()
              }
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              style={{ fontSize: '15px' }}
              onChange={this.onChange.bind(this)}
              //enabled={!this.props.employeeBankId}
              ref={(obj: any) => (this.dtpStart = obj)}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Bank
            </label>
            <DropDownTreeComponent
              id='ddtBank'
              name='ddtBank'
              treeSettings={this.treeSettings}
              fields={this.props.bfields}
              filterBarPlaceholder='Search a Branch'
              allowFiltering={true}
              filterType='Contains'
              change={handleDropDownTreeChange}
              //blur={handleDropDownOnBlur}
              placeholder='Select Branch'
              popupHeight='220px'
              style={{ fontSize: '15px' }}
            ></DropDownTreeComponent>
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Account Type
            </label>
            <DropDownListComponent
              id='accountTypeId'
              name='accountTypeId'
              dataSource={this.props.jsonAccountType}
              ref={(obj) => {
                this.ddlAccountType = obj;
              }}
              filterBarPlaceholder='Search Account Type'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Account Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              //   //value={data.typeOfId}
              //index={1}
              created={() => {
                this.ddlAccountType.index = 1;
              }}
              change={(e: any) => {
                this.setState({
                  accountTypeId: e.itemData ? e.itemData.id : undefined,
                });
              }}
              focus={openDropdownListOnFocus}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            {this.state.paymentTypeId ===
            this._structure.Bank.PaymentType.MobileTransfer.Id ? (
              <>
                <span className='mandatoryinfo'>*</span>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Mobile No.
                </label>
                <input
                  className='input-text-border'
                  id='mobileNo'
                  name='mobileNo'
                  type='text'
                  placeholder='Enter Mobile No.'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  required={true}
                  maxLength={10}
                  onChange={this.onChange.bind(this)}
                />
              </>
            ) : (
              <>
                <span className='mandatoryinfo'>*</span>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Account No.
                </label>
                <input
                  className='input-text-border'
                  id='accountNo'
                  name='accountNo'
                  type='text'
                  placeholder='Enter Account No.'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  required={true}
                  maxLength={50}
                  onChange={this.onChange.bind(this)}
                />
              </>
            )}
          </div>
          <div className='col-6 py-2'>
            {this.state.modeId === this._structure.Bank.Mode.Amount.Id ? (
              <>
                <span className='mandatoryinfo'>*</span>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Amount
                </label>
                <NumericTextBoxComponent
                  id='amount'
                  name='amount'
                  cssClass='text-right'
                  placeholder='Enter Amount'
                  decimals={
                    this.user.settings.formats.Decimal.includes('.')
                      ? this.user.settings.formats.Decimal.split('.').pop()
                          .length
                      : null
                  }
                  // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
                  // format={`c${
                  //   this.user.settings.formats.Decimal.includes('.')
                  //     ? this.user.settings.formats.Decimal.split('.').pop()
                  //         .length
                  //     : 2
                  // }`}
                  format={`${this.user.currencySign} 0.${
                    this.user.settings.formats.Decimal.includes('.')
                      ? '0'.repeat(
                          this.user.settings.formats.Decimal.split('.').pop()
                            .length
                        )
                      : '0'.repeat(2)
                  }`}
                  showSpinButton={false}
                  onChange={this.onChange.bind(this)}
                />
              </>
            ) : (
              <>
                <span className='mandatoryinfo'>*</span>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Percentage
                </label>
                <NumericTextBoxComponent
                  id='percentage'
                  name='percentage'
                  cssClass='text-right'
                  placeholder='Enter Percentage'
                  decimals={
                    this.user.settings.formats.Decimal.includes('.')
                      ? this.user.settings.formats.Decimal.split('.').pop()
                          .length
                      : null
                  }
                  showSpinButton={false}
                  max={100}
                  value={100}
                  onChange={this.onChange.bind(this)}
                />
              </>
            )}
          </div>
        </div>
        {/* <div className='row align-items-center'></div>
        <div id='divempbank-grid'>
          <GridComponent
            id={'grid-employeebank'}
            dataSource={data.jsonDistribution}
            height='calc(100%)'
            width={'700'}
            //childGrid={childGrid}
            //detailDataBound={detailDataBound}
            editSettings={editOptions}
            toolbar={[
              ...this.toolbarOptions,
              {
                text: 'Save',
                tooltipText: 'Save',
                prefixIcon: 'e-update',
                id: `grid-employeebank_update`,
              },
              'Cancel',
            ]}
            actionBegin={actionBegin}
            actionComplete={actionComplete}
            ref={(obj: any) => (this.grdBank = obj)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field='bank.name'
                headerText='Bank'
                width='100'
                editType='dropdownedit'
                //edit={countryParams}
                editTemplate={templateBank}
              />
              <ColumnDirective
                field='accountType.name'
                headerText='Account Type'
                width='100'
                editType='dropdownedit'
                editTemplate={templateAccountType}
              />
              <ColumnDirective
                field='accountNo'
                headerText='Account No'
                width='100'
                validationRules={{ required: true }}
              />
              <ColumnDirective
                field='percentage'
                headerText='Percentage'
                width='100'
                editType='numericedit'
                editTemplate={editTemplateAmount}
              />
            </ColumnsDirective>
            <Inject services={[Toolbar, Edit]} />
          </GridComponent>
        </div> */}
      </>
    );
  }
}
