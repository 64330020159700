import * as React from 'react';
import './CustomReportList.css';
import Common from '../../Util/Common';
import CustomReport from './CustomReport';
import LinkList from '../LinkList/LinkList';
import { customreportlist_contextmenu } from '../../dummydata/xml_data_employee';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import CustomReportPreview from './CustomReportPreview';
import {
  Dialog,
  DialogComponent,
  DialogUtility,
} from '@syncfusion/ej2-react-popups';

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface Props {
  jsonDataViewer: any;
}

export default class CustomReportList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  private selectedReportId: string = '';

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    var _result: any;
    parser.parseString(
      customreportlist_contextmenu,
      function (err: any, result: any) {
        _result = JSON.parse(JSON.stringify(result));
      }
    );

    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
  }

  state = {
    jsonReportGroup: undefined,
    jsonReportList: undefined,
    showAddEdit: false,
    reportId: undefined,
    report: undefined,
    showPreview: false,
    periods: undefined,
  };

  componentDidMount(): void {
    if (!this.state.periods) {
      this.GetPeriods();
    }

    if (!this.state.jsonReportList) {
      this.GetList();
    }
  }

  private GetPeriods() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/listperiods`,
      { addNewOption: false },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let date = new Date();
        let currPeriod: any = null;
        let empPeriod: any = response;
        empPeriod = empPeriod.filter(
          (x: any) =>
            Common.formatDate(new Date(x.startDate), 'dd-MM-yyyy') <=
              Common.formatDate(new Date(), 'dd-MM-yyyy') ||
            x.id == Common.blankguid
        );

        empPeriod.filter((x: any) => {
          if (date >= new Date(x.startDate) && date <= new Date(x.endDate)) {
            currPeriod = x;
          }
        });

        if (currPeriod === null) {
          currPeriod = empPeriod.slice(-1)[0];
        }

        let lastSelectedPeriod: any =
          localStorage.getItem('lastSelectedPeriod');

        if (
          lastSelectedPeriod &&
          lastSelectedPeriod !== 'undefined' &&
          lastSelectedPeriod !== 'null'
        ) {
          lastSelectedPeriod = JSON.parse(lastSelectedPeriod);

          if (
            empPeriod.length > 0 &&
            new Date(lastSelectedPeriod.startDate).getTime() <
              new Date(currPeriod.startDate).getTime() &&
            new Date(lastSelectedPeriod.startDate).getTime() >=
              new Date(empPeriod[0].startDate).getTime()
          ) {
            currPeriod = lastSelectedPeriod;
          }
        }

        this.setState({
          periods: currPeriod ?? undefined,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetList() {
    let payload: any = {
      id: null,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/customreport/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let grp: any = Array.from(
          new Set(
            data.map((x: any) => {
              return x.reportGroupId;
            })
          )
        ).sort((a: any, b: any) => {
          return a > b ? 1 : -1;
        });
        grp = grp.map((x: any) => {
          return {
            id: x,
            name: this._structure.ReportsGroupList.filter(
              (g: any) => g.Id === x
            )[0].Text,
          };
        });

        this.setState({ jsonReportList: data, jsonReportGroup: grp });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Close'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showPreview === true) {
          this.setState({ showPreview: false });
          setTimeout(() => {
            let prd: any = document.querySelectorAll(
              '#customreport-periodctrls'
            );
            if (prd.length > 0) {
              prd.forEach((p: any) => {
                p.remove();
              });
            }
          }, 1000);
        } else {
        }
      },
    },
    // {
    //   buttonModel: {
    //     content: Common.toTitleCase('Accept'),
    //     cssClass: 'flat-button d-none',
    //     isPrimary: true,
    //   },
    //   click: () => {
    //     return;
    //   },
    // },
  ];

  render() {
    let cnfDlg: Dialog;

    const handleAddEditClick = (e: any) => {
      this.setState({
        showAddEdit: true,
        reportId: undefined,
        report: undefined,
      });
    };

    const showAddEditReport = (e: any) => {
      if (e === false) {
        this.GetList();
      }
      this.setState({ showAddEdit: e });
    };

    const onLinkItemClick = (e: any) => {
      if (e.id) {
        let report: any = (this.state.jsonReportList as any).filter(
          (x: any) => x.id === e.id
        );

        if (report.length > 0) {
          report = report[0];
        } else {
          report = null;
        }

        // if (report) {
        //   this.setState({ showAddEdit: true, reportId: e.id, report: report });
        // }
        report.columns.forEach((c: any) => {
          c.category = c.columnId.split('|')[0];

          let col: any = null;
          if (c.category === 'employee_info') {
            col =
              this._structure.CustomReportCategory.EmployeeInfo.Columns.filter(
                (x: any) => x.Id === c.columnId.split('|')[1]
              );
          } else if (c.category === 'payroll_info') {
            col =
              this._structure.CustomReportCategory.PayrollInfo.Columns.filter(
                (x: any) => x.Id === c.columnId.split('|')[1]
              );
          } else if (c.category === 'leave_info') {
            col = this._structure.CustomReportCategory.LeaveInfo.Columns.filter(
              (x: any) => x.Id === c.columnId.split('|')[1]
            );
          }
          if (col.length > 0) {
            c.isNumber = col[0].IsNumber;
            c.isDate = col[0].IsDate;
          }
        });
        if (report) {
          this.setState({
            showPreview: true,
            report: report,
          });
        }
      }
    };

    const onLinkItemOptionClick = (e: any) => {
      this.selectedReportId = e.target.closest('.linkitem').dataset.id;
      this.cMenu?.open(e.clientY, e.clientX);
    };

    const handleMenuClick = (e: any) => {
      if (this.selectedReportId) {
        if (e.item.id === '101') {
          let report: any = (this.state.jsonReportList as any).filter(
            (x: any) => x.id === this.selectedReportId
          );

          if (report.length > 0) {
            report = report[0];
          } else {
            report = null;
          }
          report.columns.forEach((c: any) => {
            c.category = c.columnId.split('|')[0];

            let col: any = null;
            if (c.category === 'employee_info') {
              col =
                this._structure.CustomReportCategory.EmployeeInfo.Columns.filter(
                  (x: any) => x.Id === c.columnId.split('|')[1]
                );
            } else if (c.category === 'payroll_info') {
              col =
                this._structure.CustomReportCategory.PayrollInfo.Columns.filter(
                  (x: any) => x.Id === c.columnId.split('|')[1]
                );
            } else if (c.category === 'leave_info') {
              col =
                this._structure.CustomReportCategory.LeaveInfo.Columns.filter(
                  (x: any) => x.Id === c.columnId.split('|')[1]
                );
            }
            if (col.length > 0) {
              c.isNumber = col[0].IsNumber;
              c.isDate = col[0].IsDate;
            }
          });
          if (report) {
            this.setState({
              showPreview: true,
              report: report,
            });
          }
        } else if (e.item.id === '201') {
          let report: any = (this.state.jsonReportList as any).filter(
            (x: any) => x.id === this.selectedReportId
          );

          if (report.length > 0) {
            report = report[0];
          } else {
            report = null;
          }

          if (report) {
            this.setState({
              showAddEdit: true,
              reportId: this.selectedReportId,
              report: report,
            });
          }
        } else if (e.item.id === '301') {
          cnfDlg = DialogUtility.confirm({
            animationSettings: { effect: 'SlideTop' },
            showCloseIcon: true,
            closeOnEscape: true,
            cssClass: 'deleteallocation',
            title: ` Aruti `,
            content: ` Do you want to delete the selected custom report?`,
            cancelButton: {
              text: 'No',
            },
            okButton: {
              text: 'Yes',
              click: confirmYesClick,
            },
          });
        }
      }
    };

    const onCmenuCreated = (e: any) => {
      let mnu: any = document.getElementById('customreportlist-contextmenu');
      if (mnu) {
        mnu.addEventListener('mouseover', () => {
          let li: any = document.querySelector(
            `.linkitem[data-id="${this.selectedReportId}"]`
          );
          if (li) {
            li.style.backgroundColor = 'lightgray';
          }
        });
      }
    };

    const handleMenuCloseClick = (e: any) => {
      let li: any = document.querySelector(
        `.linkitem[data-id="${this.selectedReportId}"]`
      );
      if (li) {
        li.style.backgroundColor = '';
      }
    };

    const dialogContent = (e: any) => {
      return (
        <CustomReportPreview
          //reportId={(this.state.report as any).id}
          periodId={
            this.state.periods
              ? (this.state.periods as any).id
              : Common.blankguid
          }
          asOnStartDate={
            this.state.periods
              ? (this.state.periods as any).startDate
              : new Date()
          }
          asOnEndDate={
            this.state.periods
              ? (this.state.periods as any).endDate
              : new Date()
          }
          reportType={(this.state.report as any).reportType}
          reportName={(this.state.report as any).reportName}
          reportCategory={(this.state.report as any).reportCategory}
          columns={(this.state.report as any).columns}
          orientation={(this.state.report as any).orientation}
        />
      );
    };

    const confirmYesClick = (e: any) => {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/customreport/delete`,
        { id: this.selectedReportId },
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response) {
            cnfDlg.hide();
            this.GetList();
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    return (
      <>
        {!this.state.showAddEdit ? (
          <>
            <div id='divcustomreportlist'>
              <div className='footer'>
                {/* <div className='save' onClick={handleAddEditClick}>
                <span className='w-100'>Create New</span>
              </div> */}
                <div
                  className='save'
                  title='Create New Custom Report'
                  onClick={handleAddEditClick}
                >
                  <i
                    className='fa fa-circle-plus'
                    style={{ fontSize: '40px' }}
                  ></i>
                </div>
              </div>
              {/* <div className='row'>
              <div className='col-md-12 '>
                <div className='createnew'>
                  <span className='w-100' onClick={handleCreateNewClick}>
                    Create New
                  </span>
                </div>
              </div>
            </div> */}
              <div className='row mt-4'>
                {this.state.jsonReportGroup &&
                  (this.state.jsonReportGroup as any).map((x: any) => {
                    return (
                      <div className='col-md-2 ' key={Math.random()}>
                        <label className=''>{x.name}</label>
                        <div className='row mt-3'>
                          <div className='col-md-12 px-0'>
                            <div id='teamreportlist-employee'>
                              <LinkList
                                key={`customreportlinklist-custom-${x.id}`}
                                id={`customreportlinklist-custom-${x.id}`}
                                jsonDataViewer={this.props.jsonDataViewer}
                                dataSource={(
                                  this.state.jsonReportList as any
                                ).filter((r: any) => r.reportGroupId === x.id)}
                                fields={{
                                  id: 'id',
                                  text: 'reportName',
                                  iconCss: 'iconCss',
                                }}
                                isCustomReport={true}
                                onLinkItemClick={(e: any) => onLinkItemClick(e)}
                                linkItemOptionClick={(e: any) =>
                                  onLinkItemOptionClick(e)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className='col-md-12 '>
                <div id='customreportlist-customreport'>
                  <LinkList
                    key={Math.random()}
                    id='customreportlinklist-custom'
                    jsonDataViewer={this.props.jsonDataViewer}
                    dataSource={this.state.jsonReportList}
                    fields={{
                      id: 'Id',
                      text: 'reportName',
                      iconCss: 'IconCss',
                    }}
                    isCustomReport={true}
                    onLinkItemClick={(e: any) => onLinkItemClick(e)}
                  />
                </div>
              </div> */}
              </div>
              {this.state.jsonReportList && (
                <ContextMenuComponent
                  id={'customreportlist-contextmenu'}
                  ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
                  items={this.cntxitems}
                  select={handleMenuClick}
                  target='.linkitemoptions'
                  onClose={handleMenuCloseClick}
                  created={onCmenuCreated}
                />
              )}
            </div>

            {this.state.showPreview && (
              <DialogComponent
                id='defaultDialog-custlistrepopreview'
                showCloseIcon={true}
                visible={this.state.showPreview}
                width={'90vw'}
                height={'90vh'}
                close={() => {
                  this.setState({ showPreview: false });
                  setTimeout(() => {
                    let prd: any = document.querySelectorAll(
                      '#customreport-periodctrls'
                    );
                    if (prd.length > 0) {
                      prd.forEach((p: any) => {
                        p.remove();
                      });
                    }
                  }, 1000);
                }}
                content={dialogContent}
                isModal={true}
                header={(this.state.report as any).reportName}
                buttons={this.DialogButtonModel}
                statelessTemplates={[]}
              ></DialogComponent>
            )}
          </>
        ) : (
          <div id='div-customreport'>
            <CustomReport
              jsonDataViewer={this.props.jsonDataViewer}
              reportId={this.state.reportId}
              report={this.state.report}
              showAddEditReport={(e: any) => showAddEditReport(e)}
            />
          </div>
        )}
      </>
    );
  }
}
