// import * as React from 'react';
// import { useState } from 'react';

// import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

// interface props3 {
//   nextStep: () => void;
//   prevStep: () => void;
//   handleFormData: (
//     input: string
//   ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
//   values: {
//     userName: string;
//     password: string;
//     name: string;
//     birthday: string;
//     gender: string;
//     phone: string;
//     company: string;
//     access_code: string;
//   };
// }

// const Confirm1 = (props3: any) => {
// const [showDetails, setShowDetails] = useState(false);

//   const submitFormData = (e: any) => {
//     e.preventDefault();

//     props3.nextStep();
//   };

//   const handlePrevious = () => {
//     props3.prevStep();
//   };
//    const handleChecked = (e: any) => {
//      if (e.value === 'Yes') {
//        setShowDetails(true);
//      } else {
//        setShowDetails(false);
//      }
//    };
//   return (
//     <>
//       <div className='row mx-5' id='Identity'>
//         <div className='col-12'>
//           <form onSubmit={submitFormData}>
//             <div className='row my-2' style={{ height: '270px' }}>
//               <div
//                 className='col-5'
//                 style={{
//                   margin: 'auto',
//                 }}
//               >
//                 <p>
//                   <div
//                     className='col-12'
//                     style={{
//                       margin: 'auto',
//                     }}
//                   >
//                     <p>
//                       if you are a member of a company <br /> registered with
//                       Aruti,you can request for an <br /> Access Code that allow
//                       you to Access the
//                       <br /> system according to your Company's <br />
//                       Policy and your Access Privilege.
//                     </p>
//                   </div>
//                 </p>
//               </div>
//               <div
//                 className='col-7'
//                 style={{
//                   margin: 'auto',
//                 }}
//               >
//                 <div>
//                   <span style={{ fontSize: '14px' }}>
//                     Are you member of a Company registered with Aruti?
//                   </span>
//                 </div>
//                 <div className='my-2 form-group row'>
//                   <label htmlFor='company' className='col-5  col-form-label'>
//                     Name of the Company
//                   </label>
//                   <div className='col-6'>
//                     <input
//                       type='text'
//                       className='form-control'
//                       id='company'
//                       name='company'
//                       defaultValue={props3.values.company}
//                       onChange={props3.handleFormData('company')}
//                       required
//                       style={{
//                         border: 'none',
//                         borderBottom: '1px solid black',
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <div className='my-2'>Please enter your</div>

//                 <div className='row my-2'>
//                   <label htmlFor='access_code' className='col-2 col-form-label'>
//                     Access Code
//                   </label>
//                   <div className='col-4 mr-2'>
//                     <input
//                       type='text'
//                       className='form-control'
//                       id='access_code'
//                       name='access_code'
//                       placeholder='Access Code'
//                       value={props3.values.access_code}
//                       onChange={props3.handleFormData('access_code')}
//                       style={{
//                         border: '1px solid black',
//                       }}
//                     />
//                   </div>{' '}
//                   <div
//                     className='col-1'
//                     style={
//                       {
//                         // display: 'flex',
//                         // alignItems: 'center',
//                         // justifyContent: 'center',
//                       }
//                     }
//                   >
//                     <p>or</p>
//                   </div>
//                   <div className='col-5'>
//                     <button
//                       style={{
//                         backgroundColor: 'rgb(237,125,49)',
//                         color: '#fff',
//                         border: '1px solid black',
//                       }}
//                     >
//                       Request Access Code
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className='row mt-4'>
//               <div
//                 className='col-12'
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'right',
//                   alignItems: 'center',
//                 }}
//               >
//                 <button className='flat-button fs-6'>Exit</button>
//                 <button
//                   className='flat-button mx-1 fs-6'
//                   onClick={handlePrevious}
//                 >
//                   Back
//                 </button>
//                 <button type='submit' className='flat-button fs-6'>
//                   Next
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Confirm1;
import * as React from 'react';
import { useContext, useState } from 'react';

import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

import './Signup.css';
import { AppContext } from '../AppState';
interface props3 {
  // email: string;
  // nextStep: () => void;
  // prevStep: () => void;
  // handleFormData: (
  //   input: string
  // ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  // values: {
  //   name: string;
  //   birthday: string;
  //   gender: string;
  //   phone: string;
  //   company: string;
  //   access_code: string;
  // };
  // exitStep: any;
}

const Confirm1 = (props3: props3) => {
  const { state, dispatch } = useContext(AppContext);
  let { companyregistered, companycode, accesscode, error } =
    state.registration;
  //const [showDetails, setShowDetails] = useState(false);

  // const submitFormData = (e: any) => {
  //   e.preventDefault();
  //   props3.nextStep();
  // };

  // const handlePrevious = () => {
  //   props3.prevStep();
  // };
  // const handleNext = () => {
  //   props3.nextStep();
  // };
  const handleChecked = (e: any) => {
    if (e.value === 'Yes') {
      //setShowDetails(true);
      dispatch({
        type: 'registration-field',
        fieldName: 'companyregistered',
        payload: true,
      });
    } else {
      //setShowDetails(false);
      dispatch({
        type: 'registration-field',
        fieldName: 'companyregistered',
        payload: false,
      });
    }
  };
  // const exitHandler = () => {
  //   props3.exitStep();
  // };
  return (
    <>
      <div className='row  my-3'>
        <div className='col-md-12'>
          <form>
            <div className='row'>
              <div className='col-md-4' id='confirm1-msg'>
                <div className='row  my-3'>
                  <div className='col-12'>
                    if you are a member of a company registered with Aruti,you
                    can request for an Access Code that allow you to Access the
                    system according to your Company's Policy and your Access
                    Privilege.
                  </div>
                </div>
              </div>
              <div className='col-md-8'>
                <div className='row mt-4 mx-3'>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-8' style={{ fontSize: '14px' }}>
                        Are you member of a Company registered with Aruti?
                      </div>
                      <div className='col-md-4'>
                        <div className='radiobutton-control row'>
                          <div className='col-md-6'>
                            <RadioButtonComponent
                              label='Yes'
                              name='isCompanyEmployee'
                              value='Yes'
                              change={handleChecked}
                              checked={companyregistered}
                            ></RadioButtonComponent>
                          </div>
                          <div className='col-md-6'>
                            <RadioButtonComponent
                              label='No'
                              name='isCompanyEmployee'
                              value='false'
                              change={handleChecked}
                              checked={!companyregistered}
                            ></RadioButtonComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {companyregistered && (
                  <div className='row my-4 mx-3'>
                    <div className='col-md-4'>
                      <label htmlFor='company' className='  col-form-label'>
                        Company Code*
                      </label>
                    </div>
                    <div className='col-md-7'>
                      <input
                        type='text'
                        className='form-control'
                        id='company'
                        name='company'
                        //defaultValue={props3.values.company}
                        value={companycode}
                        //onChange={props3.handleFormData('company')}
                        onChange={(e) =>
                          dispatch({
                            type: 'registration-field',
                            fieldName: 'companycode',
                            payload: e.currentTarget.value,
                          })
                        }
                        required
                        style={{
                          border: 'none',
                          borderBottom: '1px solid black',
                        }}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                )}
                {companyregistered && (
                  <div className='row mt-4 mx-3'>
                    <div className='col-md-4' style={{ fontSize: '14px' }}>
                      Please enter your Access Code*
                    </div>
                    <div className='col-md-4' style={{ fontSize: '14px' }}>
                      <input
                        type='text'
                        className='form-control'
                        id='access_code'
                        placeholder='Access Code'
                        name='access_code'
                        value={accesscode}
                        //onChange={props3.handleFormData('access_code')}
                        onChange={(e) =>
                          dispatch({
                            type: 'registration-field',
                            fieldName: 'accesscode',
                            payload: e.currentTarget.value,
                          })
                        }
                        autoComplete='off'
                      />
                    </div>
                    <div className='col-md-4' style={{ fontSize: '14px' }}>
                      <button
                        type='button'
                        style={{
                          backgroundColor: 'rgb(237,125,49)',
                          color: '#fff',
                          border: '1px solid black',
                          height: '100%',
                          width: '100%',
                        }}
                        //onClick={(e: any) => alert('hi')}
                      >
                        Request Access Code
                      </button>
                    </div>
                  </div>
                )}
                {/* {companyregistered && (
                  <div className='row mt-1 mx-3'>
                    <div className='col-md-12'>
                      <div className='row'>
                        <div className='confirm-centre1 col-3'>Access Code</div>
                        <div className='col-md-4'>
                          <button
                            style={{
                              backgroundColor: 'rgb(237,125,49)',
                              color: '#fff',
                              border: '1px solid black',
                              height: '100%',
                              width: '100%',
                            }}
                          >
                            Request Access Code
                          </button>
                        </div>
                        <div className='col-md-3'>
                          <input
                            type='text'
                            className='form-control'
                            id='access_code'
                            placeholder='Access Code'
                            name='access_code'
                            value={accesscode}
                            //onChange={props3.handleFormData('access_code')}
                            onChange={(e) =>
                              dispatch({
                                type: 'registration-field',
                                fieldName: 'accesscode',
                                payload: e.currentTarget.value,
                              })
                            }
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>

            {/* <div className='row' style={{ marginTop: '65px' }}>
              <div
                className='col-md-12'
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}
              >
                <button
                  className='flat-button fs-6'
                  data-bs-dismiss='modal'
                  onClick={exitHandler}
                >
                  Exit
                </button>
                <button
                  className='flat-button fs-6 mx-1'
                  onClick={handlePrevious}
                >
                  Back
                </button>
                <button type='submit' className='flat-button fs-6'>
                  Next
                </button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};
export default Confirm1;
