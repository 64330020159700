// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-28 - 10
// * SUHAIL - 2023-03-01 - 10
// * SUHAIL - 2023-02-28 - 20

import React, { Component } from 'react';

import * as AllIonicons from 'react-ionicons';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import Common from '../Util/Common';

// * ---------------------------------------------------------------------------------------------------------------3.Style

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const {
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_SUBSCRIBER,
  REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  REACT_APP_ENDPOINT_XMLPATH,
  REACT_APP_ENDPOINT_SIDEBARPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
} = process.env;

interface Item {
  title: string;
  iconCss: string;
  href: string;
}

interface Items {
  item: Item[];
  open: boolean;
  mainIcon: string;
}

class DPButton extends React.Component<
  Items,
  { dopen: boolean; mainIcon: string }
> {
  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
  constructor(props: any) {
    super(props);

    // * ------------------------------------------------------------------------------------------------------------------5.State
    this.state = {
      dopen: false,
      mainIcon: '',
    };

    this.handleClick = this.handleClick.bind(this);
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-06 - 90
  createItems() {
    return this.props.item.map(function (item, index) {
      return {
        text: item.title,
        iconCss: item.iconCss,
        url: item.href,
      };
    });
  }

  // * --------------------------------------------------------------------------------------------------------------------Event Handler
  // * SUHAIL - 2023-03-06 - 30
  handleClick() {
    //this.setState({ dopen: !this.state.dopen })
    this.setState({
      dopen: Boolean(
        document
          .getElementsByClassName('userdropdowntoggle')[0]
          .getAttribute('aria-expanded')
      ),
    });
  }

  // * ------------------------------------------------------------------------------------------------------------------HTML
  // * SUHAIL - 2023-02-28 - 180
  // * SUHAIL - 2023-03-01 - 30
  // * SUHAIL - 2023-03-06 - 180
  // * FABIO  - 2023-03-16 - 270
  // * DIMITRI - 2023-03-16 - 270
  // * SUHAIL - 2023-03-30 - 120
  // * SUHAIL - 2023-04-28 - 60
  render() {
    var items = this.createItems();
    let ddb: any;
    //var cMainIcon: any = (<AllIonicons['PersonCircleSharp'] />)
    /*const cMainIcon= ({ name }) => {
      const renderedIcon = AllIonicons[name];
      if (!renderedIcon) return <p>Icon not found!</p>;
      return <renderedIcon />;
    };*/

    const onSelect = (args: any) => {
      if (args.item.text === 'Logout') {
        Common.removeUserSession();
        window.location.href = '/';
      }
    };

    const onOpen = (args: any) => {
      const elem: any = args.element.parentElement;
      elem.style.left =
        ddb.element.getBoundingClientRect().right - elem.offsetWidth + 'px';
      //elem.style.right = '100px';
    };

    return (
      <DropDownButtonComponent
        items={items}
        ref={(scope) => {
          ddb = scope;
        }}
        select={onSelect}
        open={onOpen}
        cssClass='mx-2'
      >
        {/* <AllIonicons.PersonCircleSharp /> */}
        <i className={this.props.mainIcon} style={{ fontSize: '24px' }} />
      </DropDownButtonComponent>
    );
  }
}

export default DPButton;
