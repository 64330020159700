// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import Common from '../../Util/Common';
import {
  DropDownListComponent,
  Mention,
  MentionComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { ButtonPropsModel } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import DialogConfirm from '../Dialogbox/DialogConfirm';
// * ------------------------------------------------------------------------------------------------------------------3.Style

interface payheadmodel {
  isAdd?: boolean;
  code?: string;
  name?: string;
  headTypeId?: string;
  typeOfId?: string;
  calcTypeId?: string;
  isDefault?: boolean;
  isTaxable?: boolean;
  isTaxRelief?: boolean;
  isNonCashBenefit?: boolean;
  isTaxableIncomeReducer?: boolean;
  isProrated?: boolean;
  isRecurrent?: boolean;
  payHeadTran?: any;
  effectiveDate?: Date;
  amount?: number;
  formula?: string;
  editPayHead?: any;
  jsonPayHead?: any;
  jsonFilteredPayheads?: any;
  tableid?: string;
  id?: string;
  formulaid?: string;
  defaultHeadTypeId?: string[];
  defaultTypeOfId?: string[];
}

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const { REACT_APP_ENDPOINT_PAYROLLSERVICE } = process.env;

export default class PayHeads extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private headtype: any = [];
  private typeof: any = [];
  private calctype: any = [];
  private alltypeof: any = [];
  private allcalctype: any = [];
  private lstHeadType: DropDownListComponent | any;
  private lstTypeOf: DropDownListComponent | any;
  private lstCalcType: DropDownListComponent | any;
  private allpayheads: any;
  private fitereddata: any;
  private iselcheadtypeid: number = 0;
  private iselctypeofid: number = 0;
  private iselccalctypeid: number = 0;
  private itemselect: any = {
    id: 0,
    text: 'Select',
  };
  private DefaultHeadCalcType: any = [];
  private PPACalcType: any = [];
  private NetPayRoundingAdjustmentCalcType: any = [];
  private LoanCalcType: any = [];

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.state = Object.assign({}, props);
    this.DefaultHeadCalcType.push(
      this._structure.CalcType.DefinedSalary.id,
      this._structure.CalcType.OnAttendance.id,
      this._structure.CalcType.NetPay.id,
      this._structure.CalcType.TotalEarning.id,
      this._structure.CalcType.TotalDeduction.id,
      this._structure.CalcType.Taxable_Earning_Total.id,
      this._structure.CalcType.Employer_Contribution_Payable.id,
      this._structure.CalcType.Rounding_Adjustment.id,
      this._structure.CalcType.Non_Taxable_Earning_Total.id,
      this._structure.CalcType.Non_Cash_Benefit_Total.id,
      this._structure.CalcType.Net_Pay_Rounding_Adjustment.id,
      this._structure.CalcType.Total_Taxable_Income_Reducer.id,
      this._structure.CalcType.Total_Tax_Relief.id
    );

    this.NetPayRoundingAdjustmentCalcType.push(
      this._structure.CalcType.Net_Pay_Rounding_Adjustment.id
    );

    this.fillCalcType(0);
    this.fillHeadType(0);
    this.fillTypeOf(0);
    this.FetchPeriod();
  }

  static defaultProps = {
    //jsonPayHead: null,
    //jsonFilteredPayheads: undefined,
    defaultHeadTypeId: [],
    defaultTypeOfId: [],
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  // state = {
  //   jsonFilteredPayheads: undefined,
  // };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler

  componentDidMount(): void {
    let data: payheadmodel = this.state;
    if (!data.jsonPayHead) {
      this.fillPayHeads();
    } else {
      this.allpayheads = data.jsonPayHead;
      let fpayheads: any = null;
      if (this.allpayheads) {
        fpayheads = this.allpayheads.filter((x: any) =>
          data.editPayHead
            ? x.id === data.editPayHead.id
            : true && x.id !== Common.blankguid
        );

        setTimeout(() => {
          //this.lstHeadType.value = data.headTypeId;
          this.setState({ jsonFilteredPayheads: fpayheads });
        }, 1000);
      }
    }
  }

  onChange(args: any): void {
    let key: string = args.target.name;
    let value: string = args.target.value;
    this.setState({ [key]: value });
  }

  onDivBlur(args: any): void {
    let key: string = args.target.id;
    let value: string = args.target.textContent;
    //this.setState({ [key]: value });
    (document.getElementById('ph_formulavalue') as HTMLInputElement).value =
      value;
  }

  onCheckboxChange(args: any): void {
    let ctrl: HTMLElement = args.event.target.parentElement;
    let ctrlid: any = ctrl.getElementsByTagName('input');
    if (!ctrlid) return;
    ctrlid = 'ph_' + ctrlid[0].id;

    let chk: any = (document.getElementsByClassName(ctrlid) as any)[0]
      .ej2_instances[0];

    let key: string = chk.id;
    let value: string = chk.checked;
    (document.getElementById(ctrlid) as HTMLInputElement).value = value;
  }

  private fillHeadType(iheadtypeid: any) {
    let data: payheadmodel = this.state;
    let item: any;
    for (let i = 0; i < Object.entries(this._structure.HeadType).length; i++) {
      item = Object.entries(this._structure.HeadType)[i][1];

      if (item) {
        if (iheadtypeid > 0) {
          if (item.id == iheadtypeid) {
            if (
              data.defaultHeadTypeId!.length <= 0 ||
              data.defaultHeadTypeId?.includes(item.id)
            ) {
              this.headtype.push(item);
            }
          }
        } else {
          if (
            data.defaultHeadTypeId!.length <= 0 ||
            data.defaultHeadTypeId?.includes(item.id)
          ) {
            this.headtype.push(item);
          }
        }
      }
    }
    this.headtype.unshift(this.itemselect);
  }

  private fillTypeOf(itypeofid: any) {
    let item: any;
    for (let i = 0; i < Object.entries(this._structure.TypeOf).length; i++) {
      item = Object.entries(this._structure.TypeOf)[i][1];
      if (item) {
        if (itypeofid > 0) {
          if ((item.id = itypeofid)) {
            this.typeof.push(item);
          }
        } else {
          this.typeof.push(item);
        }
      }
    }
    this.typeof.unshift(this.itemselect);
    this.alltypeof = this.typeof;
  }

  private fillCalcType(icalctypeid: any) {
    let item: any;
    for (let i = 0; i < Object.entries(this._structure.CalcType).length; i++) {
      item = Object.entries(this._structure.CalcType)[i][1];
      if (item) {
        if (icalctypeid > 0) {
          if (item.OthId == icalctypeid) {
            this.calctype.push(item);
          }
        } else {
          this.calctype.push(item);
        }
      }
    }
    this.calctype.unshift(this.itemselect);
    this.allcalctype = this.calctype;
    this.calctype = this.allcalctype.filter(
      (x: any) =>
        !this.DefaultHeadCalcType.includes(x.id) &&
        x.id !== this._structure.CalcType.ShortHours.id &&
        x.id !== this._structure.CalcType.OverTimeHours.id
    );
  }

  // private filterpayhead() {
  //   if (this.fitereddata == undefined) {
  //     this.fitereddata = this.allpayheads;
  //   }

  //   if (this.iselcheadtypeid > 0) {
  //     this.fitereddata = this.allpayheads.filter(
  //       (x: any) =>
  //         x.headTypeId == this.iselcheadtypeid ||
  //         x.id == '00000000-0000-0000-0000-000000000000'
  //     );
  //   } else {
  //   }

  //   if (this.iselctypeofid > 0) {
  //     this.fitereddata = this.allpayheads.filter(
  //       (x: any) =>
  //         x.typeOfId == this.iselctypeofid ||
  //         x.id == '00000000-0000-0000-0000-000000000000'
  //     );
  //   } else {
  //   }

  //   if (this.iselccalctypeid > 0) {
  //     this.fitereddata = this.allpayheads.filter(
  //       (x: any) =>
  //         x.calcTypeId == this.iselccalctypeid ||
  //         x.id == '00000000-0000-0000-0000-000000000000'
  //     );
  //   } else {
  //   }

  //   this.setState({ payheads: this.fitereddata });
  // }

  private hndlheadtypechg(e: any) {
    let data: payheadmodel = this.state;
    if (e.itemData != null) {
      if (e.itemData.id > 0) {
        this.iselcheadtypeid = e.itemData.id;
        // if (e.element.id.toLowerCase() !== 'ph_htype') {
        //   this.filterpayhead();
        // }
        let chkNonCashBenefit: any = document.getElementById(
          'isNonCashBenefit'
        ) as any;
        let chkTaxRelief: any = document.getElementById('isTaxRelief') as any;
        if (e.itemData.id == this._structure.HeadType.Informational.id) {
          chkNonCashBenefit.ej2_instances[0].disabled = false;
          chkTaxRelief.ej2_instances[0].disabled = false;
          this.typeof = this.alltypeof.filter(
            (x: any) =>
              x.OthId == e.itemData.id ||
              x.id == this._structure.TypeOf.Salary.id
          );
        } else {
          chkNonCashBenefit.ej2_instances[0].checked = false;
          chkNonCashBenefit.ej2_instances[0].disabled = true;
          chkTaxRelief.ej2_instances[0].checked = false;
          chkTaxRelief.ej2_instances[0].disabled = true;

          if (data.defaultTypeOfId!.length > 0) {
            this.typeof = this.alltypeof.filter(
              (x: any) =>
                data.defaultTypeOfId?.includes(x.id) && x.OthId == e.itemData.id
            );
          } else {
            this.typeof = this.alltypeof.filter(
              (x: any) => x.OthId == e.itemData.id
            );
          }
        }

        let chkTaxable: any = document.getElementById('isTaxable') as any;
        if (e.itemData.id == this._structure.HeadType.EarningforEmployee.id) {
          chkTaxable.ej2_instances[0].disabled = false;
        } else {
          chkTaxable.ej2_instances[0].checked = false;
          chkTaxable.ej2_instances[0].disabled = true;
        }

        let chkTaxableIncomeReducer: any = document.getElementById(
          'isTaxableIncomeReducer'
        ) as any;
        if (
          e.itemData.id == this._structure.HeadType.DeductionFromEmployee.id ||
          e.itemData.id ==
            this._structure.HeadType.EmployeesStatutoryDeductions.id ||
          e.itemData.id == this._structure.HeadType.Informational.id
        ) {
          chkTaxableIncomeReducer.ej2_instances[0].disabled = false;
        } else {
          chkTaxableIncomeReducer.ej2_instances[0].checked = false;
          chkTaxableIncomeReducer.ej2_instances[0].disabled = true;
        }
      } else {
        this.typeof = this.alltypeof;
      }
      this.lstTypeOf.dataSource = this.typeof;
      this.lstTypeOf.value = 0;
      this.lstTypeOf.text = '';
      this.lstTypeOf.refresh();
    } else {
      this.iselcheadtypeid = 0;
      this.typeof = this.alltypeof;
    }

    this.lstTypeOf.value = data.typeOfId;
    if (data.isAdd && data.defaultTypeOfId?.length === 1) {
      this.lstTypeOf.value = data.defaultTypeOfId[0];
    }
  }

  private hndltypeofchg(e: any) {
    if (e) {
      let data: payheadmodel = this.state;
      if (e.itemData != null) {
        if (e.itemData.id > 0) {
          this.iselctypeofid = e.itemData.id;
          // if (e.element.id.toLowerCase() !== 'ph_typof') {
          //   this.filterpayhead();
          // }
          let _filter: any = [];
          if (e.itemData.id > 1) {
            // NOT Type of - Salary
            this.calctype = this.allcalctype.filter(
              (x: any) => x.OthId <= e.itemData.id
            );

            if (data.isAdd) {
              this.DefaultHeadCalcType.map((x: any) => {
                _filter.push(x);
              });
              this.PPACalcType.map((x: any) => {
                _filter.push(x);
              });
              this.NetPayRoundingAdjustmentCalcType.map((x: any) => {
                _filter.push(x);
              });
              this.LoanCalcType.map((x: any) => {
                _filter.push(x);
              });

              if (e.itemData.id == this._structure.TypeOf.Other_Earnings.id) {
                _filter.push(this._structure.CalcType.ShortHours.id);
              } else if (
                e.itemData.id == this._structure.TypeOf.Other_Deductions_Emp.id
              ) {
                _filter.push(this._structure.CalcType.OverTimeHours.id);
              } else if (
                e.itemData.id == this._structure.TypeOf.Informational.id
              ) {
                _filter.push(
                  this._structure.CalcType.OverTimeHours.id,
                  this._structure.CalcType.OverTimeHours.id
                );
              } else if (
                e.itemData.id ==
                this._structure.TypeOf.NET_PAY_ROUNDING_ADJUSTMENT.id
              ) {
                _filter.push(
                  this._structure.CalcType.OverTimeHours.id,
                  this._structure.CalcType.OverTimeHours.id
                );
              } else if (e.itemData.id == this._structure.TypeOf.LOAN.id) {
                _filter.push(
                  this._structure.CalcType.OverTimeHours.id,
                  this._structure.CalcType.OverTimeHours.id
                );
              } else {
                _filter.push(
                  this._structure.CalcType.ShortHours.id,
                  this._structure.CalcType.OverTimeHours.id
                );
              }
            }

            this.calctype = this.calctype.filter(
              (x: any) => !_filter.includes(x.id)
            );
          } else {
            //Type of - Salary

            if (!this.DefaultHeadCalcType.includes(data.calcTypeId)) {
              _filter.push(
                this._structure.TypeOf.Salary.id,
                this._structure.TypeOf.Allowance.id
              );

              this.calctype = this.allcalctype.filter((x: any) =>
                _filter.includes(x.OthId)
              );

              let _calid_noa: any = [
                this._structure.CalcType.DefinedSalary.id,
                this._structure.CalcType.OnAttendance.id,
              ];
              let _calid_nob: any = [
                this._structure.CalcType.ShortHours.id,
                this._structure.CalcType.OverTimeHours.id,
              ];

              this.DefaultHeadCalcType.map((x: any) => {
                _calid_nob.push(x);
              });
              this.PPACalcType.map((x: any) => {
                _calid_nob.push(x);
              });
              this.NetPayRoundingAdjustmentCalcType.map((x: any) => {
                _calid_nob.push(x);
              });
              this.LoanCalcType.map((x: any) => {
                _calid_nob.push(x);
              });

              if (
                this.iselcheadtypeid ==
                  this._structure.HeadType.Informational.id &&
                e.itemData.id == this._structure.TypeOf.Salary.id
              ) {
                this.calctype = this.calctype.filter(
                  (x: any) => x.id == this._structure.CalcType.FlatRateOthers.id
                );
              } else if (
                this.iselcheadtypeid !==
                  this._structure.HeadType.Informational.id &&
                e.itemData.id == this._structure.TypeOf.Salary.id
              ) {
                this.calctype = this.calctype.filter(
                  (x: any) =>
                    (x.OthId == this._structure.TypeOf.Salary.id &&
                      !_calid_noa.includes(x.id)) ||
                    (x.OthId == this._structure.TypeOf.Allowance.id &&
                      !_calid_nob.includes(x.id))
                );
              }
            } else {
              this.calctype = this.allcalctype;
            }
          }
        } else {
          this.calctype = this.allcalctype;
        }
        this.lstCalcType.dataSource = this.calctype;
        this.lstCalcType.value = 0;
        this.lstCalcType.text = '';
        this.lstCalcType.refresh();
      } else {
        this.iselctypeofid = 0;
        this.calctype = this.allcalctype;
      }
      this.lstCalcType.value = data.calcTypeId;
    }
  }

  private hndlcalctypechg(e: any) {
    if (e) {
      let data: payheadmodel = this.state;
      if (e.itemData != null) {
        let chkNonCashBenefit: any = document.getElementById(
          'isNonCashBenefit'
        ) as any;
        let chkTaxRelief: any = document.getElementById('isTaxRelief') as any;

        if (
          this.DefaultHeadCalcType.includes(e.itemData.id) === true &&
          e.itemData.id != this._structure.CalcType.DefinedSalary.id
        ) {
          chkNonCashBenefit.ej2_instances[0].checked = false;
          chkNonCashBenefit.ej2_instances[0].disabled = true;
          chkTaxRelief.ej2_instances[0].checked = false;
          chkTaxRelief.ej2_instances[0].disabled = true;
        }
        this.iselccalctypeid = e.itemData.id;
        // if (e.element.id.toLowerCase() !== 'ph_calcid') {
        //   this.filterpayhead();
        // }
        if (e.element.id.toLowerCase() == 'ph_calcid') {
          let chkProrated: any = document.getElementById('isProrated') as any;
          let chkRecurrent: any = document.getElementById('isRecurrent') as any;
          switch (e.itemData.id) {
            case this._structure.CalcType.FlatRateOthers.id:
              chkProrated.ej2_instances[0].disabled = false;
              chkRecurrent.ej2_instances[0].disabled = false;

              if (document.getElementById('ph_formula') !== undefined) {
                setTimeout(() => {
                  (document.getElementById('ph_formula') as any).setAttribute(
                    'contenteditable',
                    false
                  );
                }, 1000);

                (document.getElementById('ph_formula') as any).textContent = '';
                (document.getElementById('ph_formulavalue') as any).value = '';
              }
              if (document.getElementById('ph_amount') !== undefined) {
                (
                  document.getElementById('ph_amount') as any
                ).ej2_instances[0].enabled = true;
              }

              break;
            default:
              chkProrated.ej2_instances[0].checked = false;
              chkProrated.ej2_instances[0].disabled = true;
              chkRecurrent.ej2_instances[0].checked = false;
              chkRecurrent.ej2_instances[0].disabled = true;

              if (document.getElementById('ph_amount') !== undefined) {
                (
                  document.getElementById('ph_amount') as any
                ).ej2_instances[0].enabled = false;
                (
                  document.getElementById('ph_amount') as any
                ).ej2_instances[0].value = '';
              }
              if (document.getElementById('ph_formula') !== undefined) {
                (document.getElementById('ph_formula') as any).setAttribute(
                  'contenteditable',
                  true
                );
              }
              break;
          }
        }
      } else {
        this.iselccalctypeid = 0;
      }
    }
  }

  private FetchPeriod() {
    let data: payheadmodel = this.state;
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/currentperiod`,
      {
        date:
          data.payHeadTran !== undefined && data.payHeadTran.length > 0
            ? data.payHeadTran[0].effectiveDate
            : null,
        endDate: null,
        statusId: null,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let periodlist = response;

        if (periodlist !== undefined && periodlist !== '') {
          if (periodlist.statusId == 2) {
            (
              document.getElementById('ph_Htype') as any
            ).ej2_instances[0].enabled = false;
            (
              document.getElementById('ph_TypOf') as any
            ).ej2_instances[0].enabled = false;
            (
              document.getElementById('ph_calcid') as any
            ).ej2_instances[0].enabled = false;
          }
        } else {
          (
            document.getElementById('ph_Htype') as any
          ).ej2_instances[0].enabled = false;
          (
            document.getElementById('ph_TypOf') as any
          ).ej2_instances[0].enabled = false;
          (
            document.getElementById('ph_calcid') as any
          ).ej2_instances[0].enabled = false;
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private fillPayHeads() {
    let data: payheadmodel = this.state;
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/listheads`,
      {
        addNewOption: true,
        startDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
        endDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
      },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.allpayheads = response;
        let fpayheads: any = null;
        if (this.allpayheads) {
          fpayheads = this.allpayheads.filter((x: any) =>
            data.editPayHead ? x.id === data.editPayHead.id : true
          );
          //this.setState({ jsonFilteredPayheads: fpayheads });
        }
        this.setState({ jsonFilteredPayheads: fpayheads });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public async HeadAdd() {
    let iHeadTypeId: any = 0;
    let iTypeOfId: any = 0;
    let iCalcTypeId: any = 0;

    if (
      document.getElementById('code') !== undefined &&
      (document.getElementById('code') as any).value == ''
    ) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please enter code to continue.</p>',
      });
      return;
    }
    if (
      document.getElementById('name') !== undefined &&
      (document.getElementById('name') as any).value == ''
    ) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please enter name to continue.</p>',
      });
      return;
    }
    if (
      document.getElementById('ph_Htype') !== undefined &&
      Number((document.getElementById('ph_Htype') as any).value) <= 0
    ) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select head type to continue.</p>',
      });
      return;
    }
    if (
      document.getElementById('ph_TypOf') !== undefined &&
      Number((document.getElementById('ph_TypOf') as any).value) <= 0
    ) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select type of to continue.</p>',
      });
      return;
    }
    if (
      document.getElementById('ph_calcid') !== undefined &&
      Number((document.getElementById('ph_calcid') as any).value) <= 0
    ) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select calculation type to continue.</p>',
      });
      return;
    }

    iHeadTypeId = (document.getElementById('ph_Htype') as any).ej2_instances[0]
      .itemData.id;
    iTypeOfId = (document.getElementById('ph_TypOf') as any).ej2_instances[0]
      .itemData.id;
    iCalcTypeId = (document.getElementById('ph_calcid') as any).ej2_instances[0]
      .itemData.id;

    if (
      (Number(iCalcTypeId) ==
        Number(this._structure.CalcType.FlatRateOthers.id) ||
        Number(iCalcTypeId) ==
          Number(this._structure.CalcType.ComputedValue.id) ||
        Number(iCalcTypeId) ==
          Number(
            this._structure.CalcType.AsComputedOnWithINEXCESSOFTaxSlab.id
          )) &&
      document.getElementById('dtpEffective') !== undefined &&
      isNaN(
        Number(new Date((document.getElementById('dtpEffective') as any).value))
      )
    ) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select effective date.</p>',
      });
      return;
    }

    switch (iCalcTypeId) {
      case this._structure.CalcType.FlatRateOthers.id:
        // if (
        //   document.getElementById('ph_amount') !== undefined &&
        //   Number(
        //     (document.getElementById('ph_amount') as any).ej2_instances[0].value
        //   ) == 0
        // ) {
        //   DialogConfirm.showDialog({
        //     content: '<p class="dialog-contain">Please enter amount to continue.</p>'
        // });
        //   return;
        // }
        // if (
        //   isNaN(
        //     (document.getElementById('ph_amount') as any).ej2_instances[0].value
        //   )
        // ) {
        //   DialogConfirm.showDialog({
        //     content: '<p class="dialog-contain">Please enter valid value to continue.</p>'
        // });
        //   return;
        // }
        break;

      case this._structure.CalcType.ComputedValue.id:
      case this._structure.CalcType.AsComputedOnWithINEXCESSOFTaxSlab.id:
        if (
          document.getElementById('ph_formulavalue') !== undefined &&
          (document.getElementById('ph_formulavalue') as any).value == ''
        ) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter formula to continue.</p>',
          });
          return;
        }

        //let _formula = (document.getElementById('ph_formula') as any).innerHTML;
        let _formula = (document.getElementById('ph_formulavalue') as any)
          .value;
        //let _pattern = /(#.*?#)/g;
        //let _pattern = /<span (.*?)>(.*?)<\/span>/g;
        let _pattern = /\[(.*?)\]/g;
        let new_formula = _formula
          .replaceAll('&nbsp;', ' ')
          .replaceAll('ph[', '[ph#')
          .replace(_pattern, '1');

        try {
          eval(new_formula);
        } catch {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter correct formula to continue.</p>',
          });
          return;
        }

        //let isvalid: boolean
        //isvalid = this.ValidateFormula((document.getElementById('ph_formula') as any).value)

        break;
    }

    let data: payheadmodel = this.state;
    if (data.tableid === 'formula') {
      let payload: any = [
        {
          id: data.isAdd ? null : data.formulaid,
          payHeadId: data.id,
          effectiveDate: Common.formatDate(
            new Date((document.getElementById('dtpEffective') as any).value),
            'yyyy-MM-dd'
          ),
          amount:
            Number(
              (document.getElementById('ph_amount') as any).ej2_instances[0]
                .value
            ) == 0
              ? 0
              : (document.getElementById('ph_amount') as any).ej2_instances[0]
                  .value,
          formula: (document.getElementById('ph_formulavalue') as any).value,
        },
      ];

      return await this.SavePayHead(
        payload,
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/addformulaamount`
      );
    }

    let payload: any = {
      id: data.isAdd ? null : data.id,
      code: (document.getElementById('code') as any).value,
      name: (document.getElementById('name') as any).value,
      headTypeId: iHeadTypeId,
      typeOfId: iTypeOfId,
      calcTypeId: iCalcTypeId,
      isTaxable:
        (document.getElementById('ph_isTaxable') as any).value == 'true'
          ? true
          : false,
      isTaxRelief:
        (document.getElementById('ph_isTaxRelief') as any).value == 'true'
          ? true
          : false,
      isNonCashBenefit:
        (document.getElementById('ph_isNonCashBenefit') as any).value == 'true'
          ? true
          : false,
      isTaxableIncomeReducer:
        (document.getElementById('ph_isTaxableIncomeReducer') as any).value ==
        'true'
          ? true
          : false,
      isProrated:
        (document.getElementById('ph_isProrated') as any).value == 'true'
          ? true
          : false,
      isRecurrent:
        (document.getElementById('ph_isRecurrent') as any).value == 'true'
          ? true
          : false,
      isDefault:
        (document.getElementById('ph_isDefault') as any).value == 'true'
          ? true
          : false,
      payHeadTran:
        iCalcTypeId == this._structure.CalcType.FlatRateOthers.id ||
        iCalcTypeId == this._structure.CalcType.ComputedValue.id ||
        iCalcTypeId ==
          this._structure.CalcType.AsComputedOnWithINEXCESSOFTaxSlab.id
          ? [
              {
                id: data.isAdd
                  ? null
                  : data.payHeadTran && data.payHeadTran.length > 0
                  ? data.payHeadTran[0].id
                  : null,
                effectiveDate: Common.formatDate(
                  new Date(
                    (document.getElementById('dtpEffective') as any).value
                  ),
                  'yyyy-MM-dd'
                ),
                amount:
                  Number(
                    (document.getElementById('ph_amount') as any)
                      .ej2_instances[0].value
                  ) == 0
                    ? 0
                    : (document.getElementById('ph_amount') as any)
                        .ej2_instances[0].value,
                formula: (document.getElementById('ph_formulavalue') as any)
                  .value,
              },
            ]
          : null,
    };

    return await this.SavePayHead(
      payload,
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/add`
    );
  }

  private async SavePayHead(payload: any, url: string) {
    let finalresponse: any = null;

    await Common.ApiCallAsync(
      'POST',
      url,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        finalresponse = response;
        //return response;
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          DialogConfirm.showDialog({
               content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
          });
          finalresponse = error;
        }
      })
      .finally(() => {});

    if (finalresponse) {
      return finalresponse;
    }
  }

  render() {
    let data: payheadmodel = this.state;
    const commonTarget = '#ph_formula';
    const payheadFields = { text: 'name', value: 'id' };
    const payheadItemTemplate = (data: any) => {
      return (
        <span>
          <span>{data.code}</span>
          <span className='absolute-right'>{data.name}</span>
        </span>
      );
    };
    const payheadDisplayTemplate = (data: any) => {
      return <>{`[${data.code}:${data.name}]`}</>;
    };

    const wholewordfomula = (formula: string): string => {
      let htmlformula = formula.replaceAll(
        '[ph#',
        '<span contenteditable="false" class="e-mention-chip">ph['
      );
      htmlformula = htmlformula.replaceAll(']', ']</span>');

      let f: HTMLElement = document.getElementById('ph_formula') as HTMLElement;
      if (f) {
        f.innerHTML = htmlformula;
      }
      return formula;
    };

    return (
      <>
        <div className='row align-items-center'>
          <div className='col-4 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Code
            </label>
            <input
              className='input-text-border'
              id='code'
              name='code'
              type='text'
              placeholder='Enter Code'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              required={true}
              maxLength={10}
              value={data.code!}
              onChange={
                data.tableid === 'payhead'
                  ? this.onChange.bind(this)
                  : undefined
              }
              readOnly={data.tableid !== 'payhead'}
            />
          </div>
          <div className='col-8 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Name
            </label>
            <input
              className='input-text-border'
              id='name'
              name='name'
              type='text'
              placeholder='Enter Name'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              required={true}
              value={data.name}
              onChange={
                data.tableid === 'payhead'
                  ? this.onChange.bind(this)
                  : undefined
              }
              readOnly={data.tableid !== 'payhead'}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Head Type
            </label>
            <DropDownListComponent
              id='ph_Htype'
              name='ph_Htype'
              dataSource={this.headtype}
              ref={(scope) => {
                this.lstHeadType = scope;
              }}
              filterBarPlaceholder='Search Head Type'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'text' }}
              change={this.hndlheadtypechg.bind(this)}
              placeholder='Select Head Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              //value={data.headTypeId}
              created={() => {
                this.lstHeadType.value = data.headTypeId;
                if (data.isAdd && data.defaultHeadTypeId?.length === 1) {
                  this.lstHeadType.value = data.defaultHeadTypeId[0];
                }
              }}
              enabled={
                data.tableid === 'payhead' &&
                (data.defaultHeadTypeId?.length === 0 ||
                  data.defaultHeadTypeId!.length > 1) &&
                !this.DefaultHeadCalcType.includes(data.calcTypeId)
              }
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Type Of
            </label>
            <DropDownListComponent
              id='ph_TypOf'
              name='ph_TypOf'
              dataSource={this.typeof}
              ref={(scope) => {
                this.lstTypeOf = scope;
              }}
              filterBarPlaceholder='Search Type Of'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'text' }}
              change={this.hndltypeofchg.bind(this)}
              placeholder='Select Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              //value={data.typeOfId}
              created={() => {
                this.lstTypeOf.value = data.typeOfId;
                if (data.isAdd && data.defaultTypeOfId) {
                  // setTimeout(() => {
                  //   this.lstTypeOf.value = data.defaultTypeOfId;
                  // }, 1000);
                }
              }}
              enabled={
                data.tableid === 'payhead' &&
                (data.defaultTypeOfId?.length === 0 ||
                  data.defaultTypeOfId!.length > 1) &&
                !this.DefaultHeadCalcType.includes(data.calcTypeId)
              }
            />
          </div>
        </div>
        <div className='row align-items-top'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Calculation Type
            </label>
            <DropDownListComponent
              id='ph_calcid'
              name='ph_calcid'
              dataSource={this.calctype}
              ref={(scope) => {
                this.lstCalcType = scope;
              }}
              filterBarPlaceholder='Search Calculation Type'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'text' }}
              change={this.hndlcalctypechg.bind(this)}
              placeholder='Select Calculation Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              //value={data.calcTypeId}
              created={() => {
                this.lstCalcType.value = data.calcTypeId;
              }}
              enabled={
                data.tableid === 'payhead' &&
                !this.DefaultHeadCalcType.includes(data.calcTypeId)
              }
            />
          </div>
          <div className='col-6 py-2 ps-3'>
            <div className='row'>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isDefault'
                  name='isDefault'
                  className='ph_isDefault'
                  checked={data.isDefault}
                  label={'Default'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead'}
                />
                <input
                  className='input-text-border'
                  id='ph_isDefault'
                  name='ph_isDefault'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={data.isDefault ? data.isDefault!.toString() : 'false'}
                />
              </div>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isTaxable'
                  name='isTaxable'
                  className='ph_isTaxable'
                  checked={data.isTaxable}
                  label={'Taxable'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead'}
                />
                <input
                  className='input-text-border'
                  id='ph_isTaxable'
                  name='ph_isTaxable'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={data.isTaxable ? data.isTaxable!.toString() : 'false'}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isTaxRelief'
                  name='isTaxRelief'
                  className='ph_isTaxRelief'
                  checked={data.isTaxRelief}
                  label={'Tax Relief'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead'}
                />
                <input
                  className='input-text-border'
                  id='ph_isTaxRelief'
                  name='ph_isTaxRelief'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={
                    data.isTaxRelief ? data.isTaxRelief!.toString() : 'false'
                  }
                />
              </div>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isNonCashBenefit'
                  name='isNonCashBenefit'
                  className='ph_isNonCashBenefit'
                  checked={data.isNonCashBenefit}
                  label={'Non-Cash Benefit'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead'}
                />
                <input
                  className='input-text-border'
                  id='ph_isNonCashBenefit'
                  name='ph_isNonCashBenefit'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={
                    data.isNonCashBenefit
                      ? data.isNonCashBenefit!.toString()
                      : 'false'
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isTaxableIncomeReducer'
                  name='isTaxableIncomeReducer'
                  className='ph_isTaxableIncomeReducer'
                  checked={data.isTaxableIncomeReducer}
                  label={'Taxable Income Reducer'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead'}
                />
                <input
                  className='input-text-border'
                  id='ph_isTaxableIncomeReducer'
                  name='ph_isTaxableIncomeReducer'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={
                    data.isTaxableIncomeReducer
                      ? data.isTaxableIncomeReducer!.toString()
                      : 'false'
                  }
                />
              </div>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isProrated'
                  name='isProrated'
                  className='ph_isProrated'
                  checked={data.isProrated}
                  label={'Prorated Flat Rate'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead' || data.isAdd}
                />
                <input
                  className='input-text-border'
                  id='ph_isProrated'
                  name='ph_isProrated'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={
                    data.isProrated ? data.isProrated!.toString() : 'false'
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6 py-2 ps-3'>
                <CheckBoxComponent
                  id='isRecurrent'
                  name='isRecurrent'
                  className='ph_isRecurrent'
                  checked={data.isRecurrent}
                  label={'Recurrent'}
                  change={this.onCheckboxChange.bind(this)}
                  disabled={data.tableid !== 'payhead'}
                />
                <input
                  className='input-text-border'
                  id='ph_isRecurrent'
                  name='ph_isRecurrent'
                  type='hidden'
                  placeholder='Type Formula'
                  autoComplete='off'
                  style={{ fontSize: '15px' }}
                  value={
                    data.isRecurrent ? data.isRecurrent!.toString() : 'false'
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Effective Date
            </label>
            <DatePickerComponent
              id='dtpEffective'
              name='effectiveDate'
              format={this.user.settings.formats.DateShort}
              value={
                data.isAdd
                  ? undefined
                  : data.formulaid && data.formulaid !== ''
                  ? data.effectiveDate
                  : data.payHeadTran && data.payHeadTran.length > 0
                  ? new Date(data.payHeadTran[0].effectiveDate)
                  : undefined
              }
              showClearButton={false}
              cssClass='e-hidedate-icon'
              //close={handleDropDownOnBlur}
              openOnFocus={true}
              //onChange={handleDatePickerOnChange}
              showTodayButton={false}
              style={{ fontSize: '15px' }}
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Amount
            </label>
            {/* <input
              className='input-text-border'
              id='ph_amount'
              name='ph_amount'
              type='number'
              placeholder='Enter Amount'
              autoComplete='off'
              onChange={this.onChange.bind(this)}
              style={{ fontSize: '15px', textAlign: 'right' }}
              value={Common.FormatNumber(
                data.ph_amount ?? 0,
                this.user.settings.formats.Decimal
              )}
            /> */}
            <NumericTextBoxComponent
              id='ph_amount'
              name='amount'
              cssClass='text-right'
              placeholder='Enter Amount'
              value={
                data.isAdd
                  ? undefined
                  : data.formulaid && data.formulaid !== ''
                  ? data.amount
                  : data.payHeadTran && data.payHeadTran.length > 0
                  ? data.payHeadTran[0].amount
                  : undefined
              }
              decimals={
                this.user.settings.formats.Decimal.includes('.')
                  ? this.user.settings.formats.Decimal.split('.').pop().length
                  : null
              }
              // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
              // format={`c${
              //   this.user.settings.formats.Decimal.includes('.')
              //     ? this.user.settings.formats.Decimal.split('.').pop().length
              //     : 2
              // }`}
              format={`${this.user.currencySign} 0.${
                this.user.settings.formats.Decimal.includes('.')
                  ? '0'.repeat(
                      this.user.settings.formats.Decimal.split('.').pop().length
                    )
                  : '0'.repeat(2)
              }`}
              showSpinButton={false}
              enabled={data.isAdd}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <div id='mention_multiplelist'>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className='mandatoryinfo'>*</span>
                      <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                        Formula{' '}
                        <b>
                          (Start typing <code className='font-13'>ph</code> for
                          payhead, <code className='font-13'>lv</code> for
                          leave, <code className='font-13'>ln</code> for loan or{' '}
                          <code className='font-13'>sv</code> for saving to
                          select the respective values)
                        </b>
                      </label>
                      <div
                        id='ph_formula'
                        className='mentionlist'
                        placeholder='Type Formula'
                        //onChange={this.onChange.bind(this)}
                        onBlur={this.onDivBlur.bind(this)}
                      >
                        {/* {data.isAdd
                          ? ''
                          : data.formulaid && data.formulaid !== ''
                          ? wholewordfomula(data.formula!)
                          : data.payHeadTran && data.payHeadTran.length > 0
                          ? wholewordfomula(data.payHeadTran[0].formula)
                          : ''} */}
                      </div>
                      <input
                        className='input-text-border'
                        id='ph_formulavalue'
                        name='ph_formulavalue'
                        type='hidden'
                        placeholder='Type Formula'
                        autoComplete='off'
                        //onChange={this.onpersonemp_namechange.bind(this)}
                        style={{ fontSize: '15px' }}
                        value={
                          data.isAdd
                            ? ''
                            : data.formulaid && data.formulaid !== ''
                            ? wholewordfomula(data.formula!)
                            : data.payHeadTran && data.payHeadTran.length > 0
                            ? wholewordfomula(data.payHeadTran[0].formula)
                            : ''
                        }
                      />
                      <input
                        className='input-text-border'
                        id='ph_orignalformulavalue'
                        name='ph_orignalformulavalue'
                        type='hidden'
                        autoComplete='off'
                        //onChange={this.onpersonemp_namechange.bind(this)}
                        style={{ fontSize: '15px' }}
                        value={
                          data.isAdd
                            ? ''
                            : data.formulaid && data.formulaid !== ''
                            ? wholewordfomula(data.formula!)
                            : data.payHeadTran && data.payHeadTran.length > 0
                            ? wholewordfomula(data.payHeadTran[0].formula)
                            : ''
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {data.jsonFilteredPayheads && (
                <MentionComponent
                  dataSource={data.jsonFilteredPayheads}
                  mentionChar={'ph'}
                  target={commonTarget}
                  fields={payheadFields}
                  popupWidth={250}
                  allowSpaces={true}
                  showMentionChar={true}
                  minLength={0}
                  sortOrder='Ascending'
                  suffixText={'&nbsp;'}
                  itemTemplate={payheadItemTemplate}
                  displayTemplate={payheadDisplayTemplate}
                ></MentionComponent>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
