import * as React from 'react';
import Common from '../../Util/Common';
import {
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import {
  ColumnsDirective,
  ColumnDirective,
  VirtualScroll,
  Toolbar,
  RowDD,
  EditSettingsModel,
  GridComponent,
  Edit,
  Inject,
  DialogEditEventArgs,
} from '@syncfusion/ej2-react-grids';
import SelectorSearch from '../Layout/SelectorSearch';
import LeaveType from './LeaveType';
import LeaveTypeDetail from './LeaveTypeDetail';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import './Leave.css';

const { REACT_APP_ENDPOINT_LEAVESERVICE } = process.env;

export default class LeaveTypesList extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  public toolbarOptions: any = ['Add', 'Delete'];
  private grid: GridComponent | null;
  private selectedrowindex = -1;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.grid = null;
  }

  state = {
    leavetypes: [],
    deldata: [],
    leavetypedetail: undefined,
  };

  componentDidMount(): void {
    if (this.state.leavetypes.length <= 0) {
      this.fetchleavetypes();
    }
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        this.setState({ leavetypes: resopnse.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  groupOptions: any = {
    columns: [''],
    captionTemplate: '<div className="grouphead" >${key}</div>',
    showDropArea: false,
  };

  colorTemplate(data: any): any {
    return (
      <div
        id='color'
        style={{
          backgroundColor: data.color,
          width: '18px',
          height: '18px',
          borderRadius: '50%',
        }}
      ></div>
    );
  }

  private AddLeaveType(eData: any) {
    if (eData.txtCode !== undefined && eData.txtCode == '') {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Sorry, Code is mandatory information. Please provide code to continue.</p>',
      });
      return;
    }

    if (eData.txtName !== undefined && eData.txtName == '') {
      DialogConfirm.showDialog({
        content:
          '<p class="dialog-contain">Sorry, Name is mandatory information. Please provide name to continue.</p>',
      });
      return;
    }

    // if (eData.colorpicker !== undefined && eData.colorpicker == '') {
    // DialogConfirm.showDialog({
    //   content : '<p class="dialog-contain">Sorry, Color is mandatory information. Please select color code to continue.</p>'
    // })
    //   return;
    // }

    //'#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')

    // let payload: any = {
    //   code: eData.txtCode,
    //   name: eData.txtName,
    //   color: eData.colorpicker,
    //   isPaid: eData.chkPaidLeave,
    //   isAccrued: eData.chkAccruedLeave,
    //   isShortLeave: eData.chkShortLeave,
    //   issueOnHoliday: eData.chkIssueOnHoliday,
    //   issueOnWeekend: eData.chkIssueOnWeekend,
    //   isEndDateOptional: eData.chkEndDateOptional,
    //   minDays: eData.mindays,
    //   maxDays: eData.maxdays,
    //   deductFromLeaveId: eData.leaveid,
    //   isExcuseLeave: false,
    // };

    let payload: any = {
      code: eData.txtCode,
      name: eData.txtName,
      color:
        '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0'),
      isPaid: eData.chkPaidLeave,
      unitId: eData.unitid,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/add`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        //let data = this.state.leavetypes;
        //(data as any).push(response);
        //this.setState({ leavetypes: data, leavetypedetail: undefined })
        //this.grid?.refresh();
        this.fetchleavetypes();
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          DialogConfirm.showDialog({
               content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
          });
        }
      })
      .finally(() => { });
  }

  private cellSave(e: any) {
    if (e.requestType === 'save') {
      if (e.data.id !== undefined) {

      } else {
        if (e.data !== undefined) {
          this.AddLeaveType(e.data);
        }
      }
    } else if (e.requestType === 'delete') {
      this.setState({ deldata: e.data[0] });
      e.data = [];
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove selected leave type?</p></div>',
        okCaption: 'Yes',
        closeCaption: 'No',
        OkEvent: this.confirmAction.bind(this),
      });
    } else if (e.requestType === 'beginEdit') {

    }
  }

  confirmAction = () => {
    if (this.state.deldata !== undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/deleteleavetype`,
        { id: (this.state.deldata as any).id },
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          this.fetchleavetypes()
        })
        .catch((error: any) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            DialogConfirm.showDialog({
                 content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
            });
          }
        })
        .finally(() => { });
    }
  };

  private actionComplete = (args: DialogEditEventArgs): void => {
    const dialog: Dialog = args.dialog as Dialog;
    if (args.requestType === 'add') {
      dialog.header = 'Add Leave Type';
      this.SetDialogCSS(dialog);
    }
    else if (args.requestType === 'beginEdit') {
      dialog.header = 'Edit Leave Type';
      this.SetDialogCSS(dialog);
    }
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  render() {
    const dialogTemplate = (props: any) => {
      return (
        <>
          <DialogFormTemplate
            {...props}
            {...this.state}
          />
        </>
      );
    };

    const editOptions: EditSettingsModel = {
      allowEditing: false,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: false,
      template: dialogTemplate,
    };

    const onRowSelected = (e: any) => {
      this.selectedrowindex = e.rowIndex;
      this.setState({ leavetypedetail: e.rowData });
    }

    const refreshLeaveType = (e: any) => {
      if (e !== null) {
        if (this.grid) {
          this.grid.refresh();
          this.setState({ leavetypedetail: undefined })
          this.grid.selectRow(this.selectedrowindex)
        }
      }
    }

    return (
      <>
        <div id='divleavetypes' className='py-2'>
          {/* <label
            className='pb-1'
            style={{ fontSize: '20px', fontWeight: '500' }}>
            Leave Types List
          </label> */}
          <div className='control-pane'>
            <div className='control-section'>
              <div className='control_wrapper' style={{ display: "flex" }}>
                <div className='col-3' style={{ paddingRight: '20px' }}>
                  <div id='leftpane'>
                    <div className='control-pane'>
                      <div className='control-section'>
                        <GridComponent
                          id='grid-lvleavetypes'
                          ref={(g) => (this.grid = g)}
                          allowTextWrap={true}
                          allowGrouping={false}
                          showColumnChooser={false}
                          dataSource={this.state.leavetypes}
                          recordClick={onRowSelected}
                          // groupSettings={this.groupOptions}
                          height='calc(100vh - 315px)'
                          editSettings={editOptions}
                          toolbar={this.toolbarOptions}
                          actionBegin={this.cellSave.bind(this)}
                          actionComplete={this.actionComplete.bind(this)}
                        >
                          <ColumnsDirective>
                            <ColumnDirective
                              template={this.colorTemplate}
                              width='25'
                            />
                            <ColumnDirective
                              field='code'
                              headerText='Code'
                              width='30'
                              visible={false}
                            />
                            <ColumnDirective
                              field='name'
                              headerText='Name'
                              width='120'
                            />
                          </ColumnsDirective>
                          <Inject services={[Toolbar, Edit]} />
                        </GridComponent>
                      </div>
                      <SelectorSearch grid_id='grid-lvleavetypes' />
                    </div>
                  </div>
                </div>
                <div className='col-8 py-2'>
                  <div id='rightpane'>
                    <div className='control-pane'>
                      <div className='control-section'>
                        {this.state.leavetypedetail !== undefined ?
                          <>
                            <LeaveTypeDetail
                              key={Math.random()}
                              leavedata={this.state.leavetypedetail}
                              leavetypes={this.state.leavetypes}
                              refreshLeavedata={refreshLeaveType}
                            />
                          </> : <></>}
                      </div>
                    </div>
                  </div></div>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

export class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private child: LeaveType | any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.child = React.createRef();
  }

  componentDidMount(): void { }

  render() {
    let data: leavetypemodel = this.props;
    return (
      <>
        <LeaveType
          {...data}
          ref={this.child}
        />
      </>
    );
  }

}

export interface leavetypemodel {
  isAdd?: boolean;
  code?: string;
  name?: string;
  color?: string;
  deductFromLeaveId?: string;
  genderId?: string;
  isAccrued?: boolean;
  isEndDateOptional?: boolean;
  isExcuseLeave?: boolean;
  isPaid?: boolean;
  isShortLeave?: boolean;
  issueOnHoliday?: boolean;
  leavetypes?: any;
  minDays?: number;
  maxDays?: number;
  unitId?: number;
}