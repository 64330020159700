import { render } from '@testing-library/react';
import ResetPassword from '../Components/ResetPassword';
import { useNavigate } from 'react-router-dom';

export default function ResetPasswordOuter(props: any) {
  const navigate = useNavigate();
  const handleDataFromReset = (resetData: string) => {
    if (resetData === 'success') {
      navigate('/login');
    }
  };
  return (
    <>
      <div className='row'>
        <div className='col-4'></div>
        <div className='col-3'>
          <ResetPassword sendDataToForgot={handleDataFromReset}></ResetPassword>
        </div>
        <div className='col-5'></div>
      </div>
    </>
  );
}
