import React, { createContext, useReducer, useContext } from 'react';
import {
  LoginAction,
  LoginState,
  initialStateLogin,
  loginReducer,
} from './LoginReducer';
import {
  RegistrationAction,
  RegistrationState,
  initialStateRegistration,
  registrationReducer,
} from './RegistrationReducer';

export interface InitialStateType {
  login: LoginState;
  registration: RegistrationState;
}

const initialState = {
  login: initialStateLogin,
  registration: initialStateRegistration,
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<LoginAction | RegistrationAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { login, registration }: InitialStateType,
  action: LoginAction | RegistrationAction
) => ({
  login: loginReducer(login, action as LoginAction),
  registration: registrationReducer(registration, action as RegistrationAction),
});

const AppProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
