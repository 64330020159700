import React from 'react';

import axios from 'axios';
import {
  ContextMenuComponent,
  MenuItemModel,
  MenuEventArgs,
} from '@syncfusion/ej2-react-navigations';
import Common from '../../Util/Common';
import './PeriodSelector.css';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
interface props {
  id: string;
  handleInputChange: any;
  _jObject: any;
  showEmployeePeriodsOnly: boolean;
  showLastPeriod: boolean;
  alttext?: any;
  showcheckbox?: boolean;
  checked?: boolean;
  handleCheckChange?: any;
  showNewPeriodOption?: boolean;
  showAsOnDateIfNoPeriod?: boolean;
  handleAsOnDateChange?: any;
  handleDtpCheckChange?: any;
}

const {
  REACT_APP_ENDPOINT_FOXSYSTEMSERVICE,
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
} = process.env;

class PeriodSelector extends React.Component<props> {
  private user: any;
  private token: any = null;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private pageloaded: boolean = false;

  constructor(props: props) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  static defaultProps = {
    showNewPeriodOption: true,
    showAsOnDateIfNoPeriod: false,
  };

  state = {
    Periods: [],
    currPeriodId: undefined,
    curr_period: null,
    periodName: '',
    menuItem: [],
    chkchecked: false,
    asondate: new Date(),
    showAsOnDateIfNoPeriod: false,
  };

  componentDidMount(): void {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/listperiods`,
      { addNewOption: this.props.showNewPeriodOption },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let date = new Date();
        let currPeriod: any = null;
        let empPeriod: any = response;
        if (this.props.showLastPeriod === false) {
          empPeriod = empPeriod.filter(
            (x: any) =>
              Common.formatDate(new Date(x.endDate), 'dd-MM-yyyy') <=
                Common.formatDate(new Date(), 'dd-MM-yyyy') ||
              x.id == Common.blankguid
          );
          // empPeriod.length = empPeriod.length - 1;
        } else {
          empPeriod = empPeriod.filter(
            (x: any) =>
              Common.formatDate(new Date(x.startDate), 'dd-MM-yyyy') <=
                Common.formatDate(new Date(), 'dd-MM-yyyy') ||
              x.id == Common.blankguid
          );
        }

        if (this.props.showEmployeePeriodsOnly) {
          let appointDate = new Date();
          if (
            this.props._jObject.contract &&
            this.props._jObject.contract.length > 0
          ) {
            appointDate = new Date(
              this.props._jObject.contract.sort((a: any, b: any) => {
                return (
                  new Date(a.dateStart).getTime() -
                  new Date(b.dateEnd).getTime()
                );
              })[0]['dateStart']
            );
          }

          empPeriod = empPeriod.filter(
            (x: any) =>
              new Date(x.startDate) >= appointDate || x.id == Common.blankguid
          );
        }
        empPeriod.filter((x: any) => {
          if (date >= new Date(x.startDate) && date <= new Date(x.endDate)) {
            currPeriod = x;
          }
        });

        if (currPeriod === null) {
          currPeriod = empPeriod.slice(-1)[0];
        }

        let lastSelectedPeriod: any =
          localStorage.getItem('lastSelectedPeriod');

        if (
          lastSelectedPeriod &&
          lastSelectedPeriod !== 'undefined' &&
          lastSelectedPeriod !== 'null'
        ) {
          lastSelectedPeriod = JSON.parse(lastSelectedPeriod);

          if (
            empPeriod.length > 0 &&
            new Date(lastSelectedPeriod.startDate).getTime() <
              new Date(currPeriod.startDate).getTime() &&
            new Date(lastSelectedPeriod.startDate).getTime() >=
              new Date(empPeriod[0].startDate).getTime()
          ) {
            currPeriod = lastSelectedPeriod;
          }
        }
        let menuItem: MenuItemModel[] = this.getYearData(empPeriod);

        this.setState({
          Periods: empPeriod,
          currPeriodId: (currPeriod && currPeriod.id) ?? 0,
          curr_period: currPeriod ?? '',
          periodName: (currPeriod && currPeriod.name) ?? '',
          menuItem: menuItem,
          chkchecked: this.props.checked ? this.props.checked : false,
          showAsOnDateIfNoPeriod: this.props.showAsOnDateIfNoPeriod,
        });
        localStorage.setItem(this.props.id, JSON.stringify(empPeriod));
        if (currPeriod && currPeriod.id !== Common.blankguid) {
          this.processPeriodClick(currPeriod.id);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.pageloaded = true;
      });

    // if (this.props.checked !== undefined && this.props.checked == true) {
    //   this.setState({ chkchecked: true });
    // }
  }

  handleContextMenu = (e: any) => {
    if (this.cMenu) {
      let obj1: HTMLElement = document.getElementById(
        this.props.id
      ) as HTMLElement;

      let c_area: any = obj1.getBoundingClientRect();

      this.cMenu.open(c_area.top + c_area.height, c_area.left + 220);
    }
  };

  private getYearData(Periods: any) {
    let jsnPeriods: MenuItemModel[] = [];

    const years: number[] = Periods.forEach((period: any) => {
      let list: any = jsnPeriods.filter(
        (x: any) =>
          x.text == new Date(period.startDate).getFullYear() &&
          x.id !== Common.blankguid
      );

      if (list.length <= 0) {
        if (period.id !== Common.blankguid) {
          jsnPeriods.push({
            text: new Date(period.startDate).getFullYear().toString(),
            id: period.id,
            items: [
              {
                text: period.name,
                id: period.id,
              },
            ],
          });
        } else {
          jsnPeriods.unshift({
            text: period.name,
            id: period.id,
          });
          if (Periods.length > 1) {
            jsnPeriods.push({ text: '', id: '00', separator: true });
          }
        }
      } else {
        list[0].items.push({
          text: period.name,
          id: period.id,
        });
      }
    });

    return jsnPeriods;
  }

  processPeriodChange = (periodData: any) => {
    this.props.handleInputChange(periodData);
  };

  processPeriodClick(id: string) {
    let itms: any = JSON.parse(localStorage.getItem(this.props.id) ?? '');
    let itm: any = itms.filter((x: any) => x.id == id);

    if (id !== Common.blankguid) {
      localStorage.setItem('lastSelectedPeriod', JSON.stringify(itm[0]));
    }
    if (itm !== undefined && itm.length > 0) {
      this.setState(
        {
          periodName: itm[0].name,
          curr_period: itm[0],
        },
        () => {
          this.processPeriodChange(itm[0]);
        }
      );
    }
  }

  handlePeriodClick = (e: any) => {
    this.processPeriodClick(e.element.id);
    e.cancel = true;
  };

  Checkchange = (e: any) => {
    this.setState({ chkchecked: e.target.checked });
    this.props.handleCheckChange(e.target.checked);
  };

  DtpCheckchange = (e: any) => {
    let dtp: any = document.getElementById(this.props.id);
    if (dtp) {
      dtp.ej2_instances[0].enabled = e.target.checked;
    }
    this.setState({ chkchecked: e.target.checked });
    this.props.handleDtpCheckChange(e);
  };

  //previous button handler
  handleLastPeriod = (e: any) => {
    let periodData: any[] = this.state.Periods.filter(
      (x: any) => x.id !== Common.blankguid
    );

    let lastPeriod: any;
    if (periodData) {
      lastPeriod = periodData.slice(0, 1)[0];
    }
    if (lastPeriod) {
      this.setState({
        periodName: lastPeriod.name,
        curr_period: lastPeriod,
      });
      this.processPeriodClick(lastPeriod.id);
    }
  };

  //handle ascending

  handlePreviousPeriod = () => {
    let cp: any = this.state.curr_period;
    let periodData: any[] = this.state.Periods.filter(
      (x: any) => x.id !== Common.blankguid
    );
    let newPeriod: any;
    let currMonth: any;
    let ascSort: any = periodData.sort((a: any, b: any) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });

    currMonth = ascSort.filter((x: any) => x.endDate < cp.startDate);

    if (currMonth.length > 0) {
      newPeriod = currMonth.slice(-1)[0];

      this.setState({
        periodName: newPeriod.name,
        curr_period: newPeriod,
      });

      this.processPeriodClick(newPeriod.id);
    }
  };

  handleNextPeriod = () => {
    let cp: any = this.state.curr_period;
    let periodData: any[] = this.state.Periods;

    let currMonth: any[];
    let ascSort: any = periodData.sort((a: any, b: any) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
    });
    currMonth = ascSort.filter((x: any) => x.startDate > cp.endDate);

    let newPeriod: any;
    if (currMonth.length > 0) {
      newPeriod = currMonth.slice(0, 1)[0];
      this.setState({
        periodName: newPeriod.name,
        curr_period: newPeriod,
      });

      this.processPeriodClick(newPeriod.id);
    }
  };

  handleFirstPeriod = () => {
    let firstPeriod: any[] = this.state.Periods;

    let fp: any;
    if (firstPeriod) {
      fp = firstPeriod.slice(-1)[0];
    }
    this.setState({
      periodName: fp.name,
      curr_period: fp,
    });
    this.processPeriodClick(fp.id);
  };
  render() {
    var disableclass = '';
    var disableInputclass = 'periodSelector-input';
    if (
      this.props.showcheckbox !== undefined &&
      this.props.showcheckbox == true
    ) {
      if (this.props.checked !== undefined) {
        // A2X-2080 : Custom report - Do not grey out the period ID when you uncheck the "show active" checkbox
        // disableclass =
        //   this.state.chkchecked == false ? 'period-selector-disabled' : '';
        // disableInputclass =
        //   this.state.chkchecked == false
        //     ? disableInputclass + ' period-selector-disabled'
        //     : disableInputclass;
      }
    }

    const onAsonDateChange = (e: any) => {
      this.setState({ asondate: e.value });
      this.props.handleAsOnDateChange(e);
    };

    return (
      <>
        {this.state.curr_period ? (
          <>
            <div className='row'>
              {this.props.showcheckbox == false ||
              this.props.showcheckbox == undefined ? null : (
                <div style={{ paddingTop: '11px', width: '3%' }}>
                  <input
                    type='checkbox'
                    className='larger'
                    defaultChecked={this.props.checked}
                    onClick={this.Checkchange.bind(this)}
                    style={{ marginRight: '10px' }}
                    title='Show Active Employee Only'
                  ></input>
                </div>
              )}

              <div
                style={{ width: '90%' }}
                id='periodctrl'
                className={disableclass}
              >
                <div className='period-selector'>
                  <i
                    className='periodSelector-icons fa-solid fa-backward-step fs-6'
                    title='First'
                    onClick={this.handleLastPeriod.bind(this)}
                  ></i>
                  <i
                    className='periodSelector-icons fa-solid fa-caret-left fs-6 mx-2'
                    title='Previous'
                    onClick={this.handlePreviousPeriod.bind(this)}
                  ></i>
                  <input
                    id={this.props.id}
                    onClick={this.handleContextMenu.bind(this)}
                    value={
                      this.state.curr_period &&
                      this.state.curr_period!['id'] !== Common.blankguid
                        ? `Period ${
                            this.state.periodName
                          } - From ${Common.formatDate(
                            new Date(this.state.curr_period!['startDate']),
                            'dd MMM yyyy'
                          )} to ${Common.formatDate(
                            new Date(this.state.curr_period!['endDate']),
                            'dd MMM yyyy'
                          )}`
                        : 'No Period Defined'
                    }
                    className={disableInputclass}
                    readOnly
                    data-periodid={(this.state.curr_period as any).id}
                    data-startdate={(this.state.curr_period as any).startDate}
                    data-enddate={(this.state.curr_period as any).endDate}
                  />
                  <i
                    className='periodSelector-dropdownIcons fa-solid fa-angle-down'
                    onClick={this.handleContextMenu.bind(this)}
                  ></i>
                  <i
                    className='periodSelector-icons fa-solid fa-caret-right mx-2 fs-6'
                    title='Next'
                    onClick={this.handleNextPeriod.bind(this)}
                  ></i>

                  <i
                    className='periodSelector-icons fa-solid fa-forward-step fs-6'
                    title='Last'
                    onClick={this.handleFirstPeriod.bind(this)}
                  ></i>
                </div>
              </div>
            </div>
            <div>
              <ContextMenuComponent
                id={this.props.id + 'contextmenu'}
                ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
                items={this.state.menuItem}
                select={this.handlePeriodClick}
              />
            </div>
          </>
        ) : this.state.showAsOnDateIfNoPeriod ? (
          <>
            {this.props.showcheckbox == false ||
            this.props.showcheckbox == undefined ? null : (
              <div style={{ paddingTop: '4px', paddingLeft: '5px' }}>
                <input
                  id={this.props.id + 'chkasondate'}
                  type='checkbox'
                  className='larger'
                  defaultChecked={this.props.checked}
                  onClick={this.DtpCheckchange}
                  style={{ marginRight: '10px' }}
                ></input>
              </div>
            )}
            <span className='periodsel_lblasondate pe-2'>As On Date</span>
            <DatePickerComponent
              id={this.props.id}
              name={this.props.id + 'asondate'}
              format={this.user.settings.formats.DateShort}
              value={this.state.asondate}
              showClearButton={true}
              //cssClass='e-hidedate-icon'
              cssClass='periodsel_dtpasondate'
              openOnFocus={true}
              showTodayButton={false}
              style={{ fontSize: '15px' }}
              width={130}
              onChange={onAsonDateChange}
              data-periodid={Common.blankguid}
              data-startdate={this.state.asondate}
              data-enddate={this.state.asondate}
            />
          </>
        ) : this.props.alttext ? (
          <div style={{ fontSize: '17px', padding: '10px' }}>
            {this.props.alttext}
          </div>
        ) : null}
      </>
    );
  }
}

export default PeriodSelector;
