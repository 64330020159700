// import * as React from 'react';
// import { useState } from 'react';
// import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
// import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

// interface props2 {
//   nextStep: () => void;
//   prevStep: () => void;
//   handleFormData: (
//     input: string
//   ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
//   values: {
//     userName: string;
//     password: string;
//     name: string;
//     birthday: string;
//     gender: string;
//     phone: string;
//   };
// }

// const UserDetails = (props2: any) => {
//   const [error, setError] = useState(false);
//   const gender: string[] = ['Male', 'Female'];

//   const submitFormData = (e: any) => {
//     e.preventDefault();

//     // if (
//     //   props2.values.birthday === '' ||
//     //   props2.values.gender === '' ||
//     //   props2.values.phone === ''
//     // ) {
//     //   setError(true);
//     // } else {
//     //   props2.nextStep();
//     // }

//     // new condition
//     if (props2.values.phone === '') {
//       setError(true);
//     } else {
//       props2.nextStep();
//     }
//   };

//   const handlePrevious = () => {
//     props2.prevStep();
//   };

//   return (
//     <>
//       <div className='row mx-5' id='Identity'>
//         <div className='col-12'>
//           <form onSubmit={submitFormData}>
//             <div
//               className='row my-2'
//               style={{
//                 // display: 'flex',
//                 // justifyContent: 'space-evenly',
//                 // alignItems: 'center',
//                 height: '270px',
//               }}
//             >
//               <div className='col-6' style={{ margin: 'auto' }}>
//                 <div className='my-2'>
//                   <input
//                     type='text'
//                     className='form-control'
//                     id='full-name'
//                     placeholder='Full Name'
//                     name='name'
//                     value={props2.values.name}
//                     onChange={props2.handleFormData('name')}
//                     style={{
//                       border: 'none',
//                       borderBottom: '1px solid rgb(237,125,49)',
//                       outline: 'none',
//                       fontSize: '14px',
//                       fontWeight: 'lighter',
//                     }}
//                   />
//                 </div>
//                 <div className='my-2'>
//                   <DatePickerComponent
//                     showClearButton={false}
//                     cssClass='e-custom e-hidedate-icon'
//                     openOnFocus={true}
//                     showTodayButton={false}
//                     name='birthday'
//                     placeholder='Birth Date'
//                     value={props2.values.birthday}
//                     onChange={props2.handleFormData('birthday')}
//                   />
//                 </div>
//                 <div className='my-2'>
//                   <DropDownListComponent
//                     id='dropdownelement'
//                     cssClass='e-custom-gender'
//                     dataSource={gender}
//                     popupHeight='200px'
//                     popupWidth='250px'
//                     placeholder='Gender'
//                     name='gender'
//                     value={props2.values.gender}
//                     onChange={props2.handleFormData('gender')}
//                   />
//                 </div>
//                 <div className='my-2'>
//                   <input
//                     type='text'
//                     className='form-control'
//                     id='phone'
//                     name='phone'
//                     value={props2.values.phone}
//                     placeholder='Phone'
//                     onChange={props2.handleFormData('phone')}
//                     style={{
//                       border: 'none',
//                       borderBottom: '1px solid rgb(237,125,49)',
//                       fontSize: '14px',
//                     }}
//                     required
//                   />
//                 </div>
//               </div>
//               <div
//                 className='col-4'
//                 style={{
//                   //display: 'flex',
//                   // justifyContent: 'right',
//                   // alignItems: 'center',
//                   // fontSize: '14px',
//                   margin: 'auto',
//                 }}
//               >
//                 <div>
//                   By Entering your Data or 'Continue <br /> with Google', you
//                   agree to the <a href='#'>Aruti TOS</a> <br /> and
//                   <a href='#' className='mx-1'>
//                     Privacy Policy
//                   </a>
//                   .
//                 </div>
//               </div>
//             </div>
//             <div className='row'>
//               <div
//                 className='col-12'
//                 style={{
//                   display: 'flex',
//                   justifyContent: 'right',
//                   alignItems: 'center',
//                 }}
//               >
//                 <button className='flat-button fs-6'>Exit</button>
//                 <button
//                   className='flat-button mx-1 fs-6'
//                   onClick={handlePrevious}
//                 >
//                   Back
//                 </button>
//                 <button type='submit' className='flat-button fs-6'>
//                   Next
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };
// export default UserDetails;

import * as React from 'react';
import { useContext, useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
// import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
//import { DialogComponent } from '@syncfusion/ej2-react-popups';

import './Signup.css';
import { AppContext } from '../AppState';

interface _AccordianProps {
  _user: any;
}
interface props2 {
  // nextStep: any;
  // prevStep: any;
  // handleFormData: any;
  // values: {
  //   name: string;
  //   birthday: string;
  //   gender: string;
  //   phone: string;
  // };
  // gName: string;
  // fullName: any;
  // exitStep: any;
}

const UserDetails = (props2: props2, _AccordianProps: any) => {
  const { state, dispatch } = useContext(AppContext);
  let { employeeid, fullname, birthdate, phoneno, error } = state.registration;

  //const [error, setError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const submitFormData = (e: any) => {
  //   e.preventDefault();
  //   props2.nextStep();
  // };

  // const handlePrevious = () => {
  //     prevStep()
  // }

  // const handleExit = () => {
  //   Props.exitHandler()
  // }

  // const handlePrevious = () => {
  //   props2.prevStep();
  // };
  // const handleNext = () => {
  //   props2.nextStep();
  // };
  const handleArutiTosClick = () => {
    setIsDialogOpen(true);
  };
  // const exitHandler = () => {
  //   props2.exitStep();
  // };
  return (
    <>
      <div className='row mt-3'>
        <div className='col-12'>
          <form>
            <div
              className='row'
              style={{
                height: '230px',
              }}
            >
              <div className='col-md-8 '>
                <div className='my-3 '>
                  <input
                    type='text'
                    className='form-control'
                    id='employeeId'
                    name='employeeId'
                    //value={props2.values.birthday}
                    value={employeeid}
                    placeholder='EmployeeId*'
                    //onChange={props2.handleFormData('employeeId')}
                    onChange={(e) =>
                      dispatch({
                        type: 'registration-field',
                        fieldName: 'employeeid',
                        payload: e.currentTarget.value,
                      })
                    }
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                      fontSize: '14px',
                    }}
                    autoComplete='off'
                    required
                    tabIndex={0}
                  />
                </div>
                <div className='form-input my-3 '>
                  <input
                    type='text'
                    className='form-control'
                    id='full-name'
                    placeholder='Full Name*'
                    name='name'
                    //value={props2.fullName}
                    value={fullname}
                    //onChange={props2.handleFormData('name')}
                    onChange={(e) =>
                      dispatch({
                        type: 'registration-field',
                        fieldName: 'fullname',
                        payload: e.currentTarget.value,
                      })
                    }
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                      outline: 'none',
                      fontSize: '14px',
                    }}
                    autoComplete='off'
                    tabIndex={1}
                  />
                </div>
                <div className='my-3 '>
                  {/* <DatePickerComponent
                    showClearButton={false}
                    cssClass='e-custom-date e-hidedate-icon'
                    //cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    showTodayButton={false}
                    name='birthday'
                    format='dd MMMM yyyy'
                    placeholder='Birth Date'
                    value={props2.values.birthday}
                    onChange={props2.handleFormData('birthday')}
                  /> */}
                  <input
                    type='text'
                    className='form-control'
                    id='date'
                    name='birthday'
                    //value={props2.values.birthday}
                    value={birthdate}
                    placeholder='Birth Date*'
                    //onChange={props2.handleFormData('birthday')}
                    onChange={(e) =>
                      dispatch({
                        type: 'registration-field',
                        fieldName: 'birthdate',
                        payload: e.currentTarget.value,
                      })
                    }
                    onFocus={(e: any) => {
                      e.target.type = 'date';
                      e.target.showPicker();
                    }}
                    onBlur={(e: any) => {
                      e.target.type = e.target.value ? 'date' : 'text';
                    }}
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                      fontSize: '14px',
                    }}
                    autoComplete='off'
                    required
                    tabIndex={2}
                  />
                </div>
                {/* <div className='my-3 mx-5'>
                  <DropDownListComponent
                    id='dropdownelement'
                    cssClass='e-custom-gender'
                    dataSource={gender}
                    popupHeight='200px'
                    popupWidth='250px'
                    placeholder='Gender'
                    name='gender'
                    value={props2.values.gender}
                    onChange={props2.handleFormData('gender')}
                  />
                  <input
                    type='text'
                    className='form-control'
                    id='gender'
                    name='gender'
                    value={props2.values.gender}
                    placeholder='Gender'
                    onChange={props2.handleFormData('gender')}
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                      fontSize: '14px',
                    }}
                    autoComplete='off'
                    required
                  />
                </div> */}
                <div className='my-3 '>
                  <input
                    type='text'
                    className='form-control'
                    id='phone'
                    name='phone'
                    //value={props2.values.phone}
                    value={phoneno}
                    placeholder='Phone*'
                    //onChange={props2.handleFormData('phone')}
                    onChange={(e) =>
                      dispatch({
                        type: 'registration-field',
                        fieldName: 'phoneno',
                        payload: e.currentTarget.value,
                      })
                    }
                    style={{
                      border: 'none',
                      borderBottom: '1px solid rgb(237,125,49)',
                      fontSize: '14px',
                    }}
                    autoComplete='off'
                    required
                    tabIndex={3}
                  />
                </div>
              </div>
              {/* <div className='col-md-4 detail-center1'>
                <p className='mx-4'>
                  By Entering your Data or 'Continue <br /> with Google', you
                  agree to <br /> the{' '}
                  <span
                    className='aruti-tos mx-1'
                    onClick={handleArutiTosClick}
                  >
                    Aruti TOS
                  </span>{' '}
                  and
                  <span
                    className='aruti-tos mx-1'
                    onClick={handleArutiTosClick}
                  >
                    Privacy Policy
                  </span>
                  .
                </p>
              </div> */}
            </div>
            {/* <div className='row' style={{ marginTop: '45px' }}>
              <div
                className='col-12'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  className='flat-button fs-6'
                  data-bs-dismiss='modal'
                  onClick={exitHandler}
                >
                  Exit
                </button>
                
                <button
                  className='flat-button mx-1 fs-6'
                  onClick={handlePrevious}
                >
                  Back
                </button>
                <button
                  type='submit'
                  className='flat-button fs-6 mx-1'
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      <div>
        <div>
          {/* {isDialogOpen ? (
            <DialogComponent
              id='contract-dialog'
              showCloseIcon={true}
              visible={isDialogOpen}
              width={'25vw'}
              height='auto'
              close={() => setIsDialogOpen(false)}
              isModal={true}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null} */}
        </div>
      </div>
    </>
  );
};
export default UserDetails;
