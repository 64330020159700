// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * Sandeep - 2023-08-24 - 30 -- Working on making new component and giving options to generated range selector
// * Sandeep - 2023-08-25 - 60 -- Working on making new component and giving options to generated range selector

// * -----------------------------------------------------------------------------------------------------------------2.Initialize

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Common from '../../Util/Common';
import { enableRipple } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  MajorGridLinesModel,
  showDialog,
} from '@syncfusion/ej2-react-spreadsheet';
import {
  RangeNavigatorComponent,
  ChartTheme,
  DateTime,
  Inject,
  IChangedEventArgs,
  IRangeLoadedEventArgs,
  StyleSettingsModel,
  RangeValueType,
  RangeIntervalType,
  AreaSeries,
  RangeTooltip,
  MajorTickLinesModel,
  FontModel,
  RangeTooltipSettingsModel,
  AxisPosition,
  RangeLabelIntersectAction,
} from '@syncfusion/ej2-react-charts';
import { emit } from 'process';

// * ------------------------------------------------------------------------------------------------------------------3.Style

// -------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
interface Props {
  _id: string;
  _datasourse?: any;
  _xaxisname?: string;
  _yaxisname?: string;
  _valueType?: RangeValueType | undefined;
  _intervalType?: RangeIntervalType | undefined;
  _labelformat?: string;
  _value?: number[] | Date[] | undefined;
  _immediateRender?: boolean;
  _width?: string;
  _heigh?: string;
  _changed?: any;
  _navigatorstyle?: StyleSettingsModel | undefined;
  _interval?: number | undefined;
  _allowIntervalData?: boolean;
  _majorGridLines?: MajorGridLinesModel | undefined;
  _majorTickLines?: MajorTickLinesModel | undefined;
  _labelstyle?: FontModel | undefined;
  _maximum?: number | Date | undefined;
  _minimum?: number | Date | undefined;
  _tooltip?: RangeTooltipSettingsModel | undefined;
  _labelintersetaction?: RangeLabelIntersectAction | undefined;
  _labelposition?: AxisPosition | undefined;
  _enableDeferredUpdate?: boolean;
  _enablePersistence?: boolean;
}

// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
class RangeNavigation extends React.Component<Props> {
  state = {};

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {}

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    return (
      <div className='control-pane'>
        <div className='control-section'>
          <RangeNavigatorComponent
            id={this.props._id}
            immediateRender={this.props._immediateRender ?? false}
            interval={this.props._interval}
            valueType={this.props._valueType}
            intervalType={this.props._intervalType}
            labelFormat={this.props._labelformat}
            height={this.props._heigh}
            navigatorStyleSettings={this.props._navigatorstyle}
            width={this.props._width}
            value={this.props._value}
            dataSource={this.props._datasourse}
            xName={this.props._xaxisname}
            yName={this.props._yaxisname}
            changed={this.props._changed}
            majorGridLines={this.props._majorGridLines}
            majorTickLines={this.props._majorTickLines}
            labelStyle={this.props._labelstyle}
            maximum={this.props._maximum}
            minimum={this.props._minimum}
            tooltip={this.props._tooltip}
            labelIntersectAction={this.props._labelintersetaction}
            labelPosition={this.props._labelposition}
            enableDeferredUpdate={this.props._enableDeferredUpdate}
            enablePersistence={this.props._enablePersistence}
            allowIntervalData={this.props._allowIntervalData ?? false}>
            <Inject services={[AreaSeries, DateTime, RangeTooltip]} />
          </RangeNavigatorComponent>
        </div>
      </div>
    );
  }
}

export default RangeNavigation;
