// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * Avinash - 2023-05-11 - 30 -- spring planing meeting
// * Avinash - 2023-05-11 - 200 - Calendar Layout - Redesign of Calendar layout structure
// * Avinash - 2023-05-11 - 100 - RnD on google for issues
// * Avinash - 2023-05-11 - 90 - working on calendar date background color
// * Avinash - 2023-05-11 - 60 -- spring review & restrospctive meating
// * Avinash - 2023-05-11 - 200 - Calendar Layout background color & Tree Grid layout structure
// * Avinash - 2023-05-11 - 90 - working on calendar date background color
// * Avinash - 2023-05-17 - 150 - working on calendar UI part
// * Avinash - 2023-05-17 - 90 - Weekly Catchup meating
// * Avinash - 2023-05-17 - 60 - Resurch on UI clander part
// * Avinash - 2023-05-18 - 100 - work on UI clander part
// * Avinash - 2023-05-18 - 60 - Resurch on Holiday Event Part
// * Avinash - 2023-05-18 - 400 - Resurch on Holiday Event Part data comming from the api
// * Avinash - 2023-05-22 - 350 - Today not working much more on task becouse of server is not working
// * Avinash - 2023-05-23 - 100 - Working on Holiday Event Part
// * Avinash - 2023-05-24 - 140 - Read API and Reander on Calendar UI part
// * Avinash - 2023-05-24 - 60 - Weekly Catchup meating
// * Avinash - 2023-05-25 - 60 - Sprint Planing meating
// * Avinash - 2023-05-11 - 140 - RnD on google for tool teap and for other issue

// * -----------------------------------------------------------------------------------------------------------------2.Initialize

import React from 'react';
import '../App.css';
import {
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  TimelineYear,
  Inject,
  Year as YearView,
  Resize,
  EventRenderedArgs,
  ResourcesDirective,
  ResourceDirective,
  Agenda,
  RenderCellEventArgs,
} from '@syncfusion/ej2-react-schedule';
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  VirtualScroll,
  Toolbar,
  RowDD,
} from '@syncfusion/ej2-react-treegrid';
import Common from '../Util/Common';
import axios from 'axios';
// import { holidayJson } from "./holiday";

import { extend } from '@syncfusion/ej2-base';

// * ------------------------------------------------------------------------------------------------------------------3.Style
// * Avinash - 2023-05-18 - 120 - Style the Calendar UI part

/*
-------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
*/
const { REACT_APP_ENDPOINT_HOLIDAYPATH } = process.env;

// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
class Calendar extends React.Component<{}> {
  private holiday: any;

  private user: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonHoliday: null,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  // * Avinash - 2023-05-12 - 60  -- Made Events (For database)
  // * Avinash - 2023-05-23 - 100  -- Made Events (For API)

  componentDidMount() {
    if (this.state.jsonHoliday === null) {
      this.GetHoliday(
        new Date().getFullYear().toString() + '-01-01',
        new Date().getFullYear().toString() + '-12-31'
      );
    }
  }

  // * Avinash - 2023-05-12 - 60  -- Made Events (clicked)

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * Avinash - 2023-05-12 - 100 -- working on methods for read data and Implement on UI
  // * Avinash - 2023-05-23 - 100 -- working on methods for read API data and Implement on UI
  // * Avinash - 2023-05-24 - 100 -- working on methods for read API data and Implement on UI
  // * Avinash - 2023-05-24 - 200 -- working on methods to show event on calendar and also implement tool teep

  public async GetHoliday(dateStart: string, dateEnd: string) {
    var payLoad: any = {
      country: { id: this.user.company.registration.country.id },
      language: this.user.language,
      period: {
        dateStart: dateStart,
        dateEnd: dateEnd,
      },
      redis: 1,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_HOLIDAYPATH}`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        return resopnse.data;
      })
      .then((resopnse: any) => {
        this.holiday = resopnse;

        this.ProcessHoliday();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }
  public ProcessHoliday() {
    let holidays: any = Object.entries(
      this.holiday[Object.keys(this.holiday)[0]]
    );

    const obj: any = holidays.map((ele: any, idx: any) => {
      return {
        Id: idx + 1,
        Subject: ele[0],
        Location: 'surat',
        StartTime: ele[1],
        EndTime: ele[1] + 60,
        eventColor: 'red',
        IsAllDay: false,
        Date: ele[1],
        Name: ele[0],
        TaskId: idx + 1,
      };
    });
    this.setState({ jsonHoliday: obj });
    this.HolidayChange();
  }

  public HolidayChange() {
    Object.entries(this.holiday[Object.keys(this.holiday)[0]]).map(
      (ele: any, idx: any) => {
        let date = new Date(ele[1].toString());
        let date1 = new Date(ele.date);
        // console.log(this.holiday);

        let cell: any = document.querySelectorAll(
          `.e-cell.e-work-cells:not(.e-other-month)[data-date='${date.getTime()}']`
        );

        if (cell !== null) {
          cell[0].children[0].style.color = 'red';
          cell[0].children[0].style.fontWeight = 'bold';
          let haldy: any = this.holiday[Object.keys(this.holiday)[0]];
          let samedayholidays: any = Object.entries(haldy).filter(
            (x) =>
              new Date(x[1] as string).toDateString() === date.toDateString()
          );

          if (samedayholidays.length > 1) {
            let samedayholidaysstring: [] = samedayholidays.map(
              (x: any) => x[0]
            );
            (cell[0].children[0] as HTMLElement).title =
              samedayholidaysstring.join('\n');
          } else {
            (cell[0].children[0] as HTMLElement).title = ele[0];
          }
        }
      }
    );
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * Avinash - 2023-05-12 - 60 -- Coding and Implementing (Tree Grid)
  // * Avinash - 2023-05-17 - 100 -- Coding and Implementing (Holiday Event Part)
  // * Avinash - 2023-05-18 - 120 -- Working on Calendar holiday Evnet to render on UI
  // * Avinash - 2023-05-23 - 100 -- Working on Calendar holiday and Tree Grid Ui part to change data based on API
  // * Avinash - 2023-05-24 - 100 -- Working on Calendar holiday Ui part to change data based on API

  render() {
    const onNavigate = (args: any) => {
      if (
        args.action === 'date' &&
        Math.abs(args.currentDate - args.previousDate) >= 365
      ) {
        let datestart: string = args.currentDate.getFullYear() + '-01-01';
        let dateend: string = args.currentDate.getFullYear() + '-12-31';
        this.GetHoliday(datestart, dateend);
      }
    };

    let data: Object[] = extend(
      [],
      this.state.jsonHoliday!,
      undefined,
      true
    ) as Object[];

    const OnrenderCell = (args: RenderCellEventArgs): void => {
      if (args.date) {
        let date1 = new Date(args.date);
        Object.entries(this.holiday[Object.keys(this.holiday)[0]]).map(
          (ele: any, idx: any) => {
            let date2 = new Date(ele[1].toString());
            var day = date2.getDate();
            if (
              date1.getFullYear() === date2.getFullYear() &&
              date1.getMonth() === date2.getMonth() &&
              date1.getDate() === date2.getDate()
            ) {
              if (args.element.classList.contains('e-cell')) {
                (args.element.children[0] as HTMLElement).style.color = 'red';
                (args.element.children[0] as HTMLElement).style.fontWeight =
                  'bold';

                let hldy: any = this.holiday[Object.keys(this.holiday)[0]];
                let samedayholidays: any = Object.entries(hldy).filter(
                  (x) =>
                    new Date(x[1] as string).toDateString() ===
                    date2.toDateString()
                );
                if (samedayholidays.length > 1) {
                  let samedayholidaysstring: [] = samedayholidays.map(
                    (x: any) => x[0]
                  );
                  (args.element.children[0] as HTMLElement).title =
                    samedayholidaysstring.join('\n');
                } else {
                  (args.element.children[0] as HTMLElement).title = ele[0];
                }
              }
            }
          }
        );
      }
    };

    return (
      <>
        {this.state.jsonHoliday && (
          <div className='container'>
            <ScheduleComponent
              className='ScheduleComponent-container'
              firstDayOfWeek={1}
              width='100%'
              height='675px'
              eventSettings={{ dataSource: this.state.jsonHoliday! }}
              renderCell={OnrenderCell}
              navigating={onNavigate}>
              <ResourcesDirective>
                <ResourceDirective
                  field='TaskId'
                  title='Category'
                  name='Categories'
                  allowMultiple={true}
                  textField='text'
                  idField='id'
                  colorField='color'></ResourceDirective>
              </ResourcesDirective>
              <ViewsDirective>
                <ViewDirective option='Year' isSelected={true} />
              </ViewsDirective>
              <Inject services={[TimelineYear, YearView, Resize, Agenda]} />
            </ScheduleComponent>

            <div className='holiday'>
              <TreeGridComponent
                dataSource={
                  this.state.jsonHoliday !== null
                    ? this.state.jsonHoliday
                    : undefined
                }>
                <ColumnsDirective>
                  <ColumnDirective
                    field='Date'
                    // textAlign='Center'
                    type='datetime'
                    format={'dd-MMM-yyyy'}></ColumnDirective>

                  <ColumnDirective
                    field='Name'
                    // textAlign='Center'
                    isPrimaryKey={true}></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[VirtualScroll, Toolbar, RowDD]} />
              </TreeGridComponent>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Calendar;
