// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * Avinash - 2023-07-18 - 30 -- Weekly cathup meating
// * Avinash - 2023-07-20 - 75 -- Sprint planing meating
// * Avinash - 2023-07-20 - 400 -- Working on Attendance sheet to display data in plain table formate
// * Avinash - 2023-07-31 - 40 -- spring review & restrospctive meating
// * Avinash - 2023-07-20 - 200 -- Working on Attendance sheet to display data in plain table formate and done
// * Avinash - 2023-07-24 - 120 -- Working on Attendance sheet UI Part
// * Avinash - 2023-07-24 - 60 -- Status review meating
// * Avinash - 2023-07-24 - 300 -- Working on period selector context menu try to bind data but yet not completed
// * Avinash - 2023-07-25 - 480 -- Working on period selector context menu almost done
// * Avinash - 2023-07-26 - 120 -- Working on period selector context menu now only need to bind the data
// * Avinash - 2023-07-26 - 60 -- Weekly cathup meating
// * Avinash - 2023-07-26 - 300 -- Working on Attendance sheet UI Structure
// * Avinash - 2023-07-26 - 300 -- Working on Attendance sheet UI Structure its completed
// * Avinash - 2023-07-26 - 180 -- Working on Attendance sheet period selector try to bind data but not completed
// * Avinash - 2023-07-28 - 180 -- Working on Attendance sheet period selector
// * Avinash - 2023-07-28 - 150 -- Working on Attendance sheet UI Structure
// * Avinash - 2023-07-28 - 150 -- Working on Attendance sheet call the API for the Hiliday
// * Avinash - 2023-07-31 - 200 -- Working on Attendance to calculate overtime and salaryperMinute
// * Avinash - 2023-07-31 - 190 -- Working on Attendance UI structure
// * Avinash - 2023-07-31 - 90 -- Status review meating
// * Avinash - 2023-08-01 - 480 -- Working on Attendance sheet call the API for the Hiliday and also print
// * Avinash - 2023-08-01 - 480 -- Working on Attendance sheet call the API for the Hiliday and also print
// * Avinash - 2023-08-02 - 40 -- Weekly cathup meating
// * Avinash - 2023-08-02 - 440 -- Working on Attendance sheet render Hiliday name and also render name based on date marge the row to print holiday name
// * Avinash - 2023-08-03 - 480 -- Working on Attendance UI structure
// * Avinash - 2023-08-04 - 45 -- spring review & restrospctive meating
// * Avinash - 2023-08-04 - 420 -- Working on Attendance sheet context menu to bind data now its completed
// * Avinash - 2023-08-08 - 420 -- Working on Attendance sheet to render data on blank sheet if data is not in API
// * Avinash - 2023-08-09 - 240 -- Working on Attendance sheet to gross all ammount in summary
// * Avinash - 2023-08-09 - 240 -- Working on Attendance sheet Ui
// * Avinash - 2023-08-10 - 240 -- Working on Attendance sheet UI struture and make smiler blank and real sheet create a saprate css file
// * Avinash - 2023-08-11 - 300 -- Marge Sunday and Saturday cells based on condition
// * Avinash - 2023-08-11 - 150 -- RnD on google for Edit Event on Cells
// * Avinash - 2023-08-14 - 60 -- Status review meating
// * Avinash - 2023-08-14 - 300 -- Marge Sunday and Saturday cells based on condition
// * Avinash - 2023-08-14 - 100 -- Working on Attendance UI structure
// * Avinash - 2023-08-16 - 400 -- Working on Attendance sheet Check Annual Leave and absence of employee
// * Avinash - 2023-08-16 - 40 -- Weekly cathup meating
// * Avinash - 2023-08-17 - 90 -- spring review & restrospctive meating
// * Avinash - 2023-08-17 - 200 -- Working on Attendance sheet render Annual Leave and absence of employee its done
// * Avinash - 2023-08-17 - 170 -- Make a function to check all parametor like Anual leave holiday and weekend till now not done
// * Avinash - 2023-08-21 - 75 -- Status review meating
// * Avinash - 2023-08-21 - 300 -- Working on Attendance sheet check all parametor like Anual leave holiday and weekend
// * Avinash - 2023-08-21 - 100 -- Done some changes on UI and also
// * Avinash - 2023-08-23 - 60 -- Status review meating
// * Avinash - 2023-08-23 - 200 -- Working on Attendance sheet Sovae error if data is not there for particular employe now its solve
// * Avinash - 2023-08-23 - 200 -- Done some changes on UI and also try to fix header and body alignment
// * Avinash - 2023-08-24 - 300 -- Done some changes on UI and also try to fix header and body alignment but not done try many ways
// * Avinash - 2023-08-24 - 150 -- Done some R&D for this alignment issue
// * Sandeep - 2023-08-18 - 450 -- Doing RnD on Time Picker comopnent like analog watch to free hand time selection
// * Sandeep - 2023-08-21 - 450 -- Doing RnD on Range Selector comopnent time range selection
// * Sandeep - 2023-08-22 - 450 -- Doing RnD on Range Selector comopnent time range selection
// * Sandeep - 2023-08-21 - 450 -- Doing RnD on Range Selector comopnent time range selection
// * Sandeep - 2023-08-23 - 430 -- Working on Changes given for showing poppup and range selector implementation.
// * Sandeep - 2023-08-28 - 430 -- Working Displaying the scale and adding change event and based on changes need to update the values.
// * Sandeep - 2023-08-29 - 430 -- Working on Displaying data based on range selected on click and fixing issues coming on id and navigtion.
// * Sandeep - 2023-08-30 - 420 -- Worked on code again as it was updating top row data everytime when we edit anyother row.
// * Sandeep - 2023-08-31 - 420 -- Worked on RnD for fixing the header and tried different css/functions and other things to get to closer.
// * Sandeep - 2023-09-01 - 300 -- Worked on RnD for fixing the header and tried different css/functions and other things to get to closer.
// * Sandeep - 2023-09-02 - 400 -- Worked on fixing the header and tried different css and done.
// * Sandeep - 2023-09-03 - 180 -- Fixed some css and height along with icons to be placed in right based on designed shared.
// * Sandeep - 2023-09-04 - 450 -- Working on importing file and showing data on the popup.
// * Sandeep - 2023-09-05 - 450 -- Working on importing file and showing data on the popup.
// * Sandeep - 2023-09-06 - 450 -- Allowing editing of data imported on dobule click and reflecting the change in the table displayed before import.
// * Sandeep - 2023-09-07 - 450 -- Allowing editing of data imported on dobule click and reflecting the change in the table displayed before import.
// * Sandeep - 2023-09-08 - 450 -- Removed the popup and editing and started to save data directly from file and generated the payload function and working to code changes.
// * Sandeep - 2023-09-09 - 300 -- Removed the popup and editing and started to save data directly from file and generated the payload function and working to code changes.
// * Sandeep - 2023-09-10 - 390 -- Removed the popup and editing and started to save data directly from file and generated the payload function and working to code changes.
// * Sandeep - 2023-09-11 - 450 -- Working on comments shared after testing on attendance tab for alignment and others
// * Sandeep - 2023-09-12 - 450 -- Working on comments shared after testing on attendance tab for alignment and others
// * Sandeep - 2023-09-13 - 450 -- Worked on removing unwanted code and bug fixes along with trying to change the scale bar when time is changed.
// * Sandeep - 2023-09-14 - 200 -- Worked on new changes suggested as per fabio or alignments.
// * Sandeep - 2023-09-26 - 240 -- Worked on the alignment issues on table for chrome and moving the last div to bottom.
// * Sandeep - 2023-09-27 - 400 -- Worked on the alignment issues on table for chrome and moving the last div to bottom and checking the api and trying to change the scale bar when time is changed
// * Sandeep - 2023-09-28 - 420 -- Worked on dialog changes for adding employee and person and css
// * Sandeep - 2023-09-29 - 420 -- Worked on dialog changes for adding employee and person and css and making space all around the controls and div css for chrome
// * Sandeep - 2023-10-03 - 240 -- Solving issue with changes sent and doing code modifications
// * Sandeep - 2023-10-04 - 240 -- Solving issue with changes sent and doing code modifications
// * Sandeep - 2023-10-05 - 240 -- Solving issue on after doing code changes attendance import and showing actual minutes

// * -----------------------------------------------------------------------------------------------------------------2.Initialize

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './NewAttendance.css';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Common from '../Util/Common';
import axios from 'axios';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import PeriodSelector from './PeriodSelector/PeriodSelector';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { showDialog } from '@syncfusion/ej2-react-spreadsheet';
import RangeNavigation from './Layout/RangeNavigation';
import { time } from 'console';
import { DisplayMode } from '@syncfusion/ej2-react-pdfviewer';
import { firstToLowerCase, IChangedEventArgs } from '@syncfusion/ej2-react-charts';
import { leave_contextmenu } from '../dummydata/xml_data_employee';

// * ------------------------------------------------------------------------------------------------------------------3.Style
// * Avinash - 2023-07-18 - 85 -- Working on Attendance header part to do some same styling like PPT

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// -------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION
enableRipple(true);
const {
  REACT_APP_ENDPOINT_FOXSYSTEMSERVICE,
  REACT_APP_ENDPOINT_HOLIDAYPATH,
  REACT_APP_ENDPOINT_EMPLOYEESERVICE,
  REACT_APP_ENDPOINT_ATTENDANCESERVICE,
} = process.env;
interface Props {
  // _years: number[];
  selectorData: any;
  [country: string]: {
    [holidayName: string]: string;
  };
}

// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
class Attendance extends React.Component<Props> {
  private absences: any = undefined;
  private user: any;
  private totalGross: number = 0;
  private totalOvertime: number = 0;
  private _enddatetime: any;
  private _fulljson: any;
  private _structure: any;

  private dragevt: any = false;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private contextmenu: any = undefined;
  private _objectId: any;
  private cntxitems: MenuItemModel[] = [];

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._fulljson = JSON.parse(Common.getFullJson() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');

    var _result: any;
    parser.parseString(leave_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
  }

  state = {
    Attendance: undefined,
    Holidays: {} as Props,
    Periods: [],
    currPeriodId: undefined,
    menuItem: [],
    loading: true,
    _startDate: undefined,
    _endDate: undefined,
    _absences: {},
    showDialog: false,
    _clickedrow: undefined,
    _dlgWidth: '50vw',
    _dlgHeight: '53vh',
    _isEditing: false,
    _periodId: 0,
    _dlgInvoker: '',
    selectedRange: [,],
    selectingRange: [,],
    _menuitems: undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  // * Avinash - 2023-07-18 - 200 -- Working on to Get data from the payload and set on state
  componentDidMount() {
    //this.holidayData();
    if (this.contextmenu == undefined) {
      this.GetContextMenu();
    }
  }

  Import_Attendance = (content: any) => {
    var payload: any = {
      id: 0,
      employees: [
        {
          id: this.props.selectorData.id,
        },
      ],
      period: {
        id: this.state._periodId,
        // datestart: this.state._startDate,
        // dateend: this.state._endDate,
      },
      Attendancesource: {
        file: content,
        fix: false,
        lunchbreak: false,
      },
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Attendance/Import`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.fetchPayslipData(
          this.state._periodId,
          this.state._startDate,
          this.state._endDate
        );
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  };

  // GetAbsences = () => {
  //   var payLoad: any = {
  //     employeeid: this.props.selectorData.id,
  //     accountid: 13002,
  //     period: {
  //       datestart: this.state._startDate,
  //       dateend: this.state._endDate,
  //     },
  //     redis: 1,
  //   };

  //   Common.ApiCallAsync(
  //     'POST',
  //     `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/Absences`,
  //     payLoad,
  //     Common.getToken() || '',
  //     this.user
  //   )
  //     .then((resopnse: any) => {
  //       this.absences = resopnse.data;
  //       this.setState({ _absences: this.absences, loading: false });
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //     })
  //     .finally(() => {});
  // };

  private async GetContextMenu() {
    /* THIS IS TEMP CODE TO BE REVISED ONCE WE HAVE THE MENU IN PLACE - START*/
    let x = Common.FindJsonByObjectValue(
      this._fulljson,
      'LeaveCalendarContxMenu',
      this.user
    );
    let objectid = 0;
    if (x !== undefined) {
      objectid = x.object;
    }

    let menu = Common.FindJsonByObjectId(this._fulljson, objectid, this.user);

    this._objectId = objectid;
    this.contextmenu = menu;
    this.setState({ _menuitems: this.contextmenu });

    /* THIS IS TEMP CODE TO BE REVISED ONCE WE HAVE THE MENU IN PLACE - END*/

    // Common.ApiCallAsync(
    //   'GET',
    //   `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/JsonTree?objectid=${objectid}`,
    //   null,
    //   Common.getToken() || '',
    //   this.user
    // )
    //   .then((resopnse: any) => {
    //     if (this.isEmpty(resopnse.data) == false) {
    //       this._objectId = resopnse.data[0].object;
    //       this.contextmenu = resopnse.data[1];
    //       this.setState({ _menuitems: this.contextmenu });
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.error(error);
    //   })
    //   .finally(() => {});
  }

  holidayData = () => {
    var payLoadH: any = {
      country: { id: this.user.company.registration.country.id },
      language: this.user.language,
      period: {
        dateStart: this.state._startDate,
        dateEnd: this.state._endDate,
      },
      redis: 0,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_HOLIDAYPATH}`,
      payLoadH,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ Holidays: resopnse.data, loading: false });
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({ loading: false });
      })
      .finally(() => { });
  };

  private generateRuler(_series: any, interval: number) {
    var _element: any = [];
    for (var i = 0; i < _series.length; i = Math.round((i + 0.1) * 10) / 10) {
      var classes = [];
      var showData: boolean = false;
      classes.push('marks');

      var _medium = interval / 2;
      switch (i % interval) {
        case 0:
          classes.push('long');
          showData = true;
          break;
        // case 0.5:
        case _medium:
          showData = false;
          classes.push('medium');
          break;
      }
      _element.push(
        <div className={classes.join(' ')}>
          {showData ? (_series[i] as any).y : ''}
        </div>
      );
    }

    return (
      <div key={Math.random()} id='ruler'>
        {_element}
      </div>
    );
  }

  private setContextMenuItems() {
    // if (this.state._menuitems !== undefined) {
    //   let cntxmenu = Common.FindJsonByRelationFieldId(
    //     this.contextmenu.children,
    //     this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
    //     this.user
    //   ); //9150 = CntxMenu
    //   if (cntxmenu !== null) {
    //     let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
    //       cntxmenu.children,
    //       this._structure,
    //       this.user
    //     );
    //     cntxmenuitems = cntxmenuitems.filter(
    //       (x: any) => !x.text.includes('Re-Entry')
    //     );
    //     if (cntxmenuitems) this.menuItem = cntxmenuitems;
    //   }
    // }

    // for (let i = 0; i < this.props._cmenuitems.length; i++) {
    // 	var mnuitem = this.props._cmenuitems[i];
    // 	this.menuItem.push({ text: mnuitem.$.text });
    // }
    // if (this.state._menuitems !== undefined) {
    //   let cntxmenu = Common.FindJsonByRelationFieldId(
    //     this.contextmenu.children,
    //     this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
    //     this.user
    //   ); //9150 = CntxMenu
    //   if (cntxmenu !== null) {
    //     let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
    //       cntxmenu.children,
    //       this._structure,
    //       this.user
    //     );
    //     cntxmenuitems = cntxmenuitems.filter(
    //       (x: any) => !x.text.includes('Re-Entry')
    //     );
    //     if (cntxmenuitems) this.menuItem = cntxmenuitems;
    //   }
    // }
    let _objectId = Common.FindJsonByObjectValue(
      this._fulljson,
      'LeaveCalendarContxMenu',
      this.user
    );

    if (_objectId !== null) {
      let menu = Common.FindJsonByObjectId(
        this._fulljson,
        _objectId.object,
        this.user
      );
      this.contextmenu = menu;
      let cntxmenu = Common.FindJsonByRelationFieldId(
        this.contextmenu.children,
        this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
        this.user
      ); //9150 = CntxMenu
      if (cntxmenu !== null) {
        let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
          cntxmenu.children,
          this._structure,
          this.user
        );
        cntxmenuitems = cntxmenuitems.filter(
          (x: any) => !x.text.includes('Re-Entry')
        );

        // let menu = Common.FindJsonByObjectId(
        //   this._fulljson,
        //   _objectId.object,
        //   this.user
        // );

        // if (menu !== undefined) {
        //   this.contextmenu = menu;
        // }

        // let cntxmenuitems: MenuItemModel[] | undefined = Common.GetContextMenu(
        //   (this.contextmenu as any).children,
        //   this._structure.MenuElements.CntxtMenuElements.CntxMenuObject,
        //   this.user
        // );

        for (let i = 0; i < cntxmenuitems.length; i++) {
          this.cntxitems[0].items?.splice(1, 0, cntxmenuitems[i]);
        }
        this.menuItem = this.cntxitems;
      } else {
        this.menuItem = this.cntxitems;
      }
    } else {
      this.menuItem = this.cntxitems;
    }
    this.menuItem.splice(
      this.menuItem.indexOf(
        this.menuItem.filter((x: any) => x.text == 'Delete')[0]
      ) - 1,
      this.menuItem.indexOf(
        this.menuItem.filter((x: any) => x.text == 'Delete')[0]
      )
    );
  }

  private ConvertToCSV(objArray: any) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  public MorningTimeEvt(args: IChangedEventArgs): void {
    let currentDate: Date = new Date(+args.start);
    if (document.getElementById('mstart') !== null) {
      document.getElementById('mstart')!.innerHTML = `${(
        '0' +
        (currentDate.getHours() % 24)
      ).slice(-2)}:${('0' + currentDate.getMinutes()).slice(-2)}`;
    }
    document.getElementById('mend')!.innerHTML = `${(
      '0' +
      (new Date(+args.end).getHours() % 24)
    ).slice(-2)}:${('0' + new Date(+args.end).getMinutes()).slice(-2)}`;
    this._enddatetime = new Date(+args.end);
    if (this._enddatetime !== undefined) {
      if (document.getElementById('astart') !== null) {
        if (
          parseInt(
            document.getElementById('astart')!.innerHTML.split(':')[0]
          ) <= this._enddatetime.getHours()
        ) {
          document.getElementById('astart')!.innerHTML = `${(
            '0' +
            (this._enddatetime.getHours() % 24)
          ).slice(-2)}:${('0' + this._enddatetime.getMinutes()).slice(-2)}`;
        }
      }
    }
  }

  public AfternoonTimeEvt(args: IChangedEventArgs): void {
    let currentDate: Date = new Date(+args.start);
    if (currentDate < this._enddatetime) {
      currentDate = this._enddatetime;
    }
    if (document.getElementById('astart') !== null) {
      document.getElementById('astart')!.innerHTML = `${(
        '0' +
        (currentDate.getHours() % 24)
      ).slice(-2)}:${('0' + currentDate.getMinutes()).slice(-2)}`;
    }
    document.getElementById('aend')!.innerHTML = `${(
      '0' +
      (new Date(+args.end).getHours() % 24)
    ).slice(-2)}:${('0' + new Date(+args.end).getMinutes()).slice(-2)}`;
  }

  btnHandleClick(args: any) {
    if (args.target.id.toString().toLowerCase() == 'btnaccept') {
      let _rows = this.state._clickedrow;
      var _data = (_rows as any).target.closest('tr');
      _data.cells.span1.innerHTML =
        document.getElementById('mstart')!.innerHTML;
      _data.cells.span2.innerHTML = document.getElementById('mend')!.innerHTML;
      _data.cells.span3.innerHTML =
        document.getElementById('astart')!.innerHTML;
      _data.cells.span4.innerHTML = document.getElementById('aend')!.innerHTML;
    }
    this.setState({ showDialog: false, _isEditing: false });
  }

  importdataedit(_data: any) {
    var _content: any = undefined;
    var _series1 = this.GenerateSeries(
      new Date(parseInt(_data.currentTarget.parentNode.id)),
      60,
      6,
      13
    );
    var _series2 = this.GenerateSeries(
      new Date(parseInt(_data.currentTarget.parentNode.id)),
      60,
      13,
      23
    );
    let _date = Common.formatDate(
      new Date(parseInt(_data.currentTarget.parentNode.id)),
      'yyyy-MM-dd'
    );

    _content = (
      <>
        <div className='row' style={{ fontSize: '17px' }}>
          {Common.formatDate(
            new Date(parseInt(_data.currentTarget.parentNode.id)),
            'EEEE dd, yyyy'
          )}
        </div>
        <div className='row' style={{ width: '100%', height: '40px' }}></div>
        <div className='row' style={{ width: '100%' }}>
          <div className='row'>
            <div
              className='row'
              style={{ fontSize: '16px', textAlign: 'center' }}>
              Morning
            </div>
            <div className='row' style={{ fontSize: '40px' }}>
              <div style={{ display: 'flex' }}>
                <div id='mstart'>
                  {_data.currentTarget.parentElement.cells.span1.textContent}
                </div>
                -
                <div id='mend'>
                  {_data.currentTarget.parentElement.cells.span2.textContent}
                </div>
              </div>
            </div>
            <div className='row'>
              <RangeNavigation
                _id='morning'
                _immediateRender={true}
                _interval={1}
                _allowIntervalData={true}
                _valueType='DateTime'
                _intervalType='Hours'
                _labelformat='HH:mm'
                _heigh='52'
                _navigatorstyle={{
                  thumb: {
                    type: 'Circle',
                  },
                }}
                _width='70%'
                _value={[
                  new Date(
                    _date +
                    ' ' +
                    _data.currentTarget.parentElement.cells.span1.textContent
                  ),
                  new Date(
                    _date +
                    ' ' +
                    _data.currentTarget.parentElement.cells.span2.textContent
                  ),
                ]}
                _datasourse={_series1}
                _xaxisname='x'
                _yaxisname='y'
                _changed={this.MorningTimeEvt.bind(this)}></RangeNavigation>
            </div>
          </div>
          <div className='row'>
            <div
              className='row'
              style={{ fontSize: '16px', textAlign: 'center' }}>
              Afternoon
            </div>
            <div className='row' style={{ fontSize: '40px' }}>
              <div style={{ display: 'flex' }}>
                <div id='astart'>
                  {_data.currentTarget.parentElement.cells.span3.textContent}
                </div>
                -
                <div id='aend'>
                  {_data.currentTarget.parentElement.cells.span4.textContent}
                </div>
              </div>
            </div>
            <div className='row'>
              <RangeNavigation
                _id='afternoon'
                _immediateRender={true}
                _interval={1}
                _allowIntervalData={true}
                _valueType='DateTime'
                _intervalType='Hours'
                _labelformat='HH:mm'
                _heigh='52'
                _navigatorstyle={{
                  thumb: {
                    type: 'Circle',
                  },
                }}
                _width='70%'
                _value={[
                  new Date(
                    _date +
                    ' ' +
                    _data.currentTarget.parentElement.cells.span3.textContent
                  ),
                  new Date(
                    _date +
                    ' ' +
                    _data.currentTarget.parentElement.cells.span4.textContent
                  ),
                ]}
                _datasourse={_series2}
                _xaxisname='x'
                _yaxisname='y'
                _changed={this.AfternoonTimeEvt.bind(this)}></RangeNavigation>
            </div>
            <div
              className='row'
              style={{ width: '100%', height: '40px' }}></div>
            <div
              className='row'
              style={{ textAlign: 'right', display: 'flex', float: 'right' }}>
              <div>
                <button
                  id='btnIAccept'
                  style={{
                    height: '26px',
                    width: '140px',
                    fontSize: '16px',
                    backgroundColor: 'transparent',
                    marginRight: '10px',
                  }}
                  onClick={this.btnHandleClick.bind(this)}>
                  Accept
                </button>
                <button
                  id='btnICancel'
                  style={{
                    height: '26px',
                    width: '140px',
                    fontSize: '16px',
                    backgroundColor: 'transparent',
                    paddingLeft: '10px',
                  }}
                  onClick={this.btnHandleClick.bind(this)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    this.setState({
      _showDialog2: true,
      _dialogContent2: _content,
      _clickedrow: _data,
    });
  }

  doubleclick(_data: any) {
    this.setState({
      _isEditing: true,
      _clickedrow: _data,
      showDialog: true,
      _dlgInvoker: 'rowclick',
      _dlgWidth: '50vw',
      _dlgHeight: '53vh',
    });
  }

  ProcessDialog(args: any) {
    let _content, _width, _height;
    if (args.target.id.toString().toLowerCase() == 'settings') {
      this.setState({
        _isEditing: false,
        _dlgInvoker: args.target.id.toString().toLowerCase(),
        showDialog: true,
        _dlgWidth: '70vw',
        _dlgHeight: '60vh',
      });
      // _content = '';
      // _width = '70vw';
      // _height = '60vh';
    } else if (args.target.id.toString().toLowerCase() == 'device') {
      this.setState({
        _isEditing: false,
        _dlgInvoker: args.target.id.toString().toLowerCase(),
        showDialog: true,
        _dlgWidth: '60vw',
        _dlgHeight: '60vh',
      });
      // _content = '';
      // _width = '60vw';
      // _height = '60vh';
    }

    // this.setState({
    //   showDialog: true,
    //   _dlgWidth: _width,
    //   _dlgHeight: _height,
    // });
  }

  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const csvText = e.target.result;
        // this.parseCSVtoJson(csvText);
        const data = btoa(csvText);
        this.Import_Attendance(data);
      };
      reader.readAsText(file);
    }
  };

  OpenPrinter = (evt: any) => {
    var html = '<!DOCTYPE HTML>';
    html += '<html lang="en-us">';
    html += '<head><style></style></head>';
    html += '<body>';

    var _period = document.getElementById('attendance-selector');
    var _details = document.getElementById('attDetails');
    var _summary = document.getElementById('attSummary');

    //check to see if they are null so "undefined" doesnt print on the page. <br>s optional, just to give space
    if (_period != null) html += (_period as any).value + '<br/><br/>';
    if (_details != null) html += _details.innerHTML + '<br/><br/>';
    if (_summary != null) html += _summary.innerHTML + '<br/><br/>';

    html += '</body>';
    html += '</html>';
    const w = window.open();
    w?.document.write(html);
    w?.window.print();
    w?.document.close();
  };

  handleChange = (e: any) => {
    this.fetchPayslipData(e.id, e.dateStart, e.dateEnd);
  };

  onUp(day: any, classes: any, _dragevt: boolean) {
    if (day.nativeEvent.button == 0) {
      this.dragevt = _dragevt;
      if (_dragevt == false) {
        let ts = day.target.closest('tr').id;
        const { selectingRange } = this.state;
        if (selectingRange) {
          // selectingRange[1] = hoveredDay;
          selectingRange[1] = ts;
          this.setState({
            selectingRange,
          });
        }
      }
    } else if (day.nativeEvent.button == 2) {
      day.preventDefault();
    }
  }

  onClick(day: any, classes: any, _dragevt: boolean) {
    if (day.nativeEvent.button == 0) {
      this.dragevt = _dragevt;
      this.dayClicked(day, classes);
    }
  }

  onHover(evt: any) {
    this.dayHovered(evt);
  }

  handleMenuClick = (e: any) => {
    if (this.isEmpty(this.state.selectedRange) === false) {
      let start = (this.state.selectingRange || this.state.selectedRange)[0];
      let end = (this.state.selectingRange || this.state.selectedRange)[1];
      var _start = start !== undefined ? start : '';
      var _end = end !== undefined ? end : '';
      if (_start !== '' && _end !== '') {
        this.AddLeave(_start, _end, e.element.id);
      }
    }
  };
  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  private rightClick(evt: any) {
    if (evt.nativeEvent.button == 2) {
      evt.preventDefault();

      if (this.cMenu) {
        // let ts = evt.target.closest('tr').id;

        // let obj1: HTMLElement = document.getElementById(ts) as HTMLElement;
        let ts = evt.target.closest('tr');

        let obj1: HTMLElement = ts as HTMLElement;
        if (obj1.classList.contains('range') == true) {
          let c_area: any = obj1.getBoundingClientRect();
          this.cMenu.open(c_area.top + c_area.height, c_area.left);
        }
      }
    }
  }

  private async AddLeave(
    dateStart: string,
    dateEnd: string,
    leaveAccountId: number
  ) {
    var payLoad: any = {
      //Employeeid: this.props._employeeId,
      employee: this.props.selectorData,
      accountid: leaveAccountId,
      period: {
        datestart: Common.formatDate(
          new Date(parseInt(dateStart)),
          'yyyy-MM-dd'
        ),
        dateend: Common.formatDate(new Date(parseInt(dateEnd)), 'yyyy-MM-dd'),
      },
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Dossier/Absences/Set`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        // var minYear = Math.min(...this.props._years);
        // var maxYear = Math.max(...this.props._years);
        // this.GetAbsences(
        //   minYear.toString() + '-01-01',
        //   maxYear.toString() + '-12-31'
        // );
        this.fetchPayslipData(
          this.state._periodId,
          this.state._startDate,
          this.state._endDate
        );
        // this.absences = resopnse.data;
        // this.setState({ _absences: this.absences });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private dayHovered(hDayevt: any) {
    let ts = hDayevt.target.closest('tr').id;
    if (this.dragevt == true) {
      if (!hDayevt) {
        // clicked on prev or next month
        return;
      }

      const { selectingRange } = this.state;

      if (selectingRange) {
        // selectingRange[1] = hoveredDay;
        selectingRange[1] = ts;
        this.setState({
          selectingRange,
        });
      }
    } else {
    }
  }

  private datePicked(date: any, classes: any) {
    this.setState({
      selectedDay: date,
      // selectedRange: [date, new Date(date.setDate(15))],
      selectedRange: [date, date],
    });
  }

  private rangePicked(start: any, end: any) {
    this.setState({
      selectedRange: [start, end],
      selectedDay: start,
    });
  }

  private dayClicked(date: any, classes: any) {
    if (date.nativeEvent.button == 0) {
      let ts = date.target.closest('tr').id;

      if (this.dragevt == true) {
        this.datePicked(new Date(parseInt(ts)), classes);

        let { selectingRange } = this.state;

        if (selectingRange !== undefined) {
          selectingRange = [,];
          var _end: Date = new Date(parseInt(ts));
          selectingRange = [ts, _end.getTime()];
        }

        if (date.target.closest('tr').classList.length > 0) {
          let _elem = date.target.closest('tr').classList;
          if (_elem !== undefined) {
            for (let c = 0; c < _elem.length; c++) {
              if (_elem[c].toLowerCase() === 'range') {
                this.setState({ selectedRange: [,], selectingRange: [,] });
              } else {
                this.setState({
                  selectingRange,
                });
              }
            }
          }
        }
      } else if (this.dragevt == false) {
        let { selectingRange } = this.state;
        if ((selectingRange as any)[0] == undefined) {
          (selectingRange as any)[0] = ts;
        }
        if ((selectingRange as any)[0] >= ts) {
          this.rangePicked(ts, (selectingRange as any)[0]);
        } else {
          this.rangePicked((selectingRange as any)[0], ts);
        }
      }
    } else if (date.nativeEvent.button == 2) {
      date.preventDefault();
    }
  }

  private fetchPayslipData(periodid: number, dateStart: any, dateEnd: any) {
    var payLoad: any = {
      employee: this.props.selectorData,
      period: {
        id: periodid,
      },
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_FOXSYSTEMSERVICE}/Payslip`,
      payLoad,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        return resopnse.data;
      })
      .then((resopnse: any) => {
        this.setState({
          Attendance: resopnse,
          _periodId: periodid,
          _startDate: dateStart,
          _endDate: dateEnd,
        });
        this.totalGross = 0;
        this.totalOvertime = 0;
        //this.GetAbsences();
        this.holidayData();
        this.updateDimensions();
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({
          Attendance: undefined,
          _periodId: periodid,
          _startDate: dateStart,
          _endDate: dateEnd,
        });
        this.totalGross = 0;
        this.totalOvertime = 0;
        //this.GetAbsences();
        this.holidayData();
      })
      .finally(() => { });
  }

  private parseCSVtoJson(csvText: any) {
    const lines = csvText.split('\n');
    const headers = lines[0].split(',');
    const parsedData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(',');
      if (currentLine.length === headers.length) {
        const row: any = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j].trim()] = currentLine[j].trim();
        }
        parsedData.push(row);
      }
    }
    if (parsedData.length > 0) {
      var fulldata = parsedData.filter(
        (x: any) =>
          x.MachineId ==
          (this.state.Attendance as any).employee.attendanceMachineId
      );
      if (fulldata !== undefined && fulldata !== null) {
        let final_data = fulldata.filter(
          (x: any) =>
            new Date(x.Date).getTime() >=
            new Date(this.state._startDate as any).getTime() &&
            new Date(x.Date).getTime() <=
            new Date(this.state._endDate as any).getTime()
        );
        if (final_data !== undefined && final_data !== null) {
          const json = this.ConvertToCSV(final_data);
          const data = btoa(json);
          this.Import_Attendance(data);
        }
      }
    }
  }

  // private setAbsense(date: any) {
  //   var _name: string = '';
  //   if (this.isEmpty(this.state._absences) === false) {
  //     var _accounts: any = [];
  //     _accounts = Object.values(this.state._absences).filter((x: any) =>
  //       x.Dates.find((y: any) => new Date(y).getTime() == date)
  //     );

  //     if (_accounts !== undefined && _accounts.length > 0) {
  //       _name = _accounts[0].Account.Name;
  //     }
  //   }

  //   return _name;
  // }

  private setAbsense(idate: any) {
    var _name: any;
    // if (this.state._absences !== undefined) {
    //   var _accounts: any = [];
    //   _accounts = Object.values(this.state._absences).filter(
    //     (x: any) => new Date(x.Date).getTime() == idate.getTime()
    //   );
    //   if (_accounts !== undefined && _accounts.length > 0) {
    //     _name = _accounts[0].Account.Name;
    //   }
    // }
    if (this.state.Attendance !== undefined && this.state.Attendance !== '') {
      if (
        (this.state.Attendance as any).attendance.leaves !== undefined &&
        (this.state.Attendance as any).attendance.leaves.length > 0
      ) {
        var _accounts: any = [];
        _accounts = (this.state.Attendance as any).attendance.leaves.filter(
          (x: any) => new Date(x.date).getTime() == idate
        );
        if (_accounts !== undefined && _accounts.length > 0) {
          _name = _accounts[0].account.name;
        }
      }
    }

    return _name;
  }

  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  private GetHoliday(_date: string) {
    var _name = '';
    if (this.isEmpty(this.state.Holidays) === false) {
      var _holiday: any = Object.entries(this.state.Holidays)[0][1];
      Object.values(_holiday).map((val: any) => {
        let date = new Date(val.toString());
        if (_date == date.getTime().toString()) {
          var _hname: any = Object.keys(_holiday)
            .filter((key) => _holiday[key] === val)
            .join(', ');
          _name = _hname;
        }
      });
    }
    return _name;
  }

  private IsHolidayPresent(_date: string) {
    var _iname = this.GetHoliday(_date);

    if (_iname !== undefined && _iname !== '') {
      return true;
    } else {
      return false;
    }
  }

  private IsAbsencesPresent(date: string) {
    var _iname = this.setAbsense(date);

    if (_iname !== undefined && _iname !== '') {
      return true;
    } else {
      return false;
    }
  }

  renderHolidays(date: any) {
    return <div>{this.GetHoliday(date)}</div>;
  }

  renderLeavedays(date: any) {
    return <div>{this.setAbsense(date)}</div>;
  }

  private CheckAllParameter(date: any) {
    var final = '';
    var Isweekend: Boolean = false;

    if (new Date(date).getDay() === 6 || new Date(date).getDay() === 0) {
      Isweekend = true;
    }

    var _HolidayName = this.GetHoliday(date);
    var _LeaveName = this.setAbsense(date);
    final = _HolidayName + ',' + _LeaveName;
    var _elements: any = [];

    if (Isweekend === false && final !== '') {
      _elements.push(
        <td
          style={{
            backgroundColor: `${new Date(date).getDay() === 6
                ? ' whitesmoke'
                : 'transparent' && new Date(date).getDay() === 0
                  ? ' Linen'
                  : 'transparent'
              }`,
          }}
          className='bodytd3'
          colSpan={4}>
          {final}
        </td>
      );
    } else if (Isweekend === false) {
      _elements.push(
        <td className='bodytd3' colSpan={4}>
          {final}
        </td>
      );
    } else if (Isweekend == false && final == '') {
      _elements.push(<td className='bodytd4'>{this.morningin(date)}</td>);
      _elements.push(<td className='bodytd4'>{this.morningout(date)}</td>);
      _elements.push(<td className='bodytd4'>{this.morningin1(date)}</td>);
      _elements.push(<td className='bodytd5'>{this.morningout1(date)}</td>);
    }

    _elements.push(<td className='bodytd6'></td>);
    _elements.push(<td className='bodytd7'></td>);
    _elements.push(<td className='bodytd8'></td>);
    _elements.push(<td className='bodytd9'></td>);
    _elements.push(<td className='bodytd10'></td>);
    _elements.push(<td></td>);

    return final;
  }

  dialogContent() {
    var _content: any, _width: any, _height: any;
    if (this.state._dlgInvoker == 'rowclick') {
      if (this.state._isEditing == true) {
        let _rows = this.state._clickedrow;
        var _data = (_rows as any).target.closest('tr');
        var _series1 = this.GenerateSeries(
          new Date(parseInt(_data.id)),
          60,
          6,
          15
        );
        var _series2 = this.GenerateSeries(
          new Date(parseInt(_data.id)),
          60,
          13,
          23
        );
        let _date = Common.formatDate(
          new Date(parseInt(_data.id)),
          'yyyy-MM-dd'
        );

        _content = (
          <>
            <div
              style={{
                fontSize: '25px',
                borderBottom: '1px solid steelblue',
                paddingBottom: '12px',
              }}>
              {Common.formatDate(new Date(parseInt(_data.id)), 'EEEE dd,yyyy')}
            </div>
            <div className='content-c-align'>
              <div style={{ width: '100%', height: '40px' }}></div>
              <div style={{ width: '100%' }}>
                <div>
                  <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                    Morning
                  </div>
                  <div style={{ fontSize: '40px' }}>
                    <div style={{ display: 'flex', marginLeft: '39%' }}>
                      <div id='mstart'>{_data.cells.span1.textContent}</div> -
                      <div id='mend'>{_data.cells.span2.textContent}</div>
                    </div>
                  </div>
                  <div>
                    <RangeNavigation
                      _id='morning'
                      _immediateRender={true}
                      _interval={1}
                      _allowIntervalData={true}
                      _valueType='DateTime'
                      _intervalType='Hours'
                      _labelformat='HH:mm'
                      _heigh='52'
                      _navigatorstyle={{
                        thumb: {
                          type: 'Circle',
                        },
                      }}
                      _width='100%'
                      _value={[
                        new Date(_date + ' ' + _data.cells.span1.textContent),
                        new Date(_date + ' ' + _data.cells.span2.textContent),
                      ]}
                      _datasourse={_series1}
                      _xaxisname='x'
                      _yaxisname='y'
                      _changed={this.MorningTimeEvt.bind(
                        this
                      )}></RangeNavigation>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontSize: '16px',
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}>
                    Afternoon
                  </div>
                  <div style={{ fontSize: '40px' }}>
                    <div style={{ display: 'flex', marginLeft: '39%' }}>
                      <div id='astart'>{_data.cells.span3.textContent}</div> -
                      <div id='aend'>{_data.cells.span4.textContent}</div>
                    </div>
                  </div>
                  <div>
                    <RangeNavigation
                      _id='afternoon'
                      _immediateRender={true}
                      _interval={1}
                      _allowIntervalData={true}
                      _valueType='DateTime'
                      _intervalType='Hours'
                      _labelformat='HH:mm'
                      _heigh='52'
                      _navigatorstyle={{
                        thumb: {
                          type: 'Circle',
                        },
                      }}
                      _width='100%'
                      _value={[
                        new Date(_date + ' ' + _data.cells.span3.textContent),
                        new Date(_date + ' ' + _data.cells.span4.textContent),
                      ]}
                      _datasourse={_series2}
                      _xaxisname='x'
                      _yaxisname='y'
                      _changed={this.AfternoonTimeEvt.bind(
                        this
                      )}></RangeNavigation>
                  </div>
                  <div
                    className='row'
                    style={{ width: '100%', height: '40px' }}></div>
                </div>
              </div>
            </div>
            <div
              style={{
                textAlign: 'right',
                display: 'flex',
                float: 'right',
              }}>
              <button
                className='flat-button'
                id='btnCancel'
                style={{
                  marginRight: '15px',
                }}
                onClick={this.btnHandleClick.bind(this)}>
                Cancel
              </button>
              <button
                className='flat-button'
                id='btnAccept'
                style={{
                  marginRight: '10px',
                }}
                onClick={this.btnHandleClick.bind(this)}>
                Accept
              </button>
            </div>
          </>
        );
      }
    } else if (this.state._dlgInvoker == 'settings') {
      _content = '';
    } else if (this.state._dlgInvoker == 'device') {
      _content = '';
    }

    return <>{_content}</>;
  }

  private GenerateSeries(
    _date: any,
    interval: any,
    startHour: any,
    endHour: any
  ) {
    var series1 = [];
    var point = {};

    var x = interval; //minutes interval
    var times = []; // time array
    var sthrs = 60 * startHour; // start time
    var enhrs = 60 * endHour; // end time
    for (var i = 0; sthrs < 24 * 60; i++) {
      if (sthrs > enhrs) {
        break;
      }
      var hh = Math.floor(sthrs / 60); // getting hours of day in 0-24 format
      var mm = sthrs % 60; // getting minutes of the hour in 0-55 format
      times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2);
      sthrs = sthrs + x;
      point = {
        x: new Date(new Date(_date).setHours(hh % 24)),
        y: ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2),
      };
      series1.push(point);
    }
    return series1;
  }

  public updateDimensions = () => {
    // const _element: HTMLElement = document.getElementById(
    //   'attDetails'
    // ) as HTMLElement;
    // if (!_element) return;
    // const gridBody: HTMLElement = _element?.querySelector(
    //   '.table-container'
    // ) as HTMLElement;
    // gridBody.style.height = 'calc(100vh - 270px);';
  };

  morningin = (props: any) => {
    const timeValue =
      props.spans &&
      props.spans[0].punchIn &&
      props.spans[0].punchIn.actual &&
      props.spans[0].punchIn.actual.substring(11, 16);
    return (
      <div
        id={
          timeValue && timeValue !== '00:00'
            ? props.spans[0].punchIn.actual
              ? props.spans[0].punchIn.actual
              : props.spans[0].punchIn.scheduled
            : ''
        }>
        {timeValue && timeValue !== '00:00' ? timeValue : ''}
      </div>
    );
  };

  morningout = (props: any) => {
    const timeValue =
      props.spans &&
      props.spans[0].punchOut &&
      props.spans[0].punchOut.actual &&
      props.spans[0].punchOut.actual.substring(11, 16);

    return (
      <div
        id={
          timeValue && timeValue !== '00:00'
            ? props.spans[0].punchOut.actual
              ? props.spans[0].punchOut.actual
              : props.spans[0].punchOut.scheduled
            : ''
        }>
        {timeValue && timeValue !== '00:00' ? timeValue : ''}
      </div>
    );
  };

  morningin1 = (props: any) => {
    const timeValue =
      props.spans &&
      props.spans[1].punchIn &&
      props.spans[1].punchIn.actual &&
      props.spans[1].punchIn.actual.substring(11, 16);

    return (
      <div
        id={
          timeValue && timeValue !== '00:00'
            ? props.spans[1].punchIn.actual
              ? props.spans[1].punchIn.actual
              : props.spans[1].punchIn.scheduled
            : ''
        }>
        {timeValue && timeValue !== '00:00' ? timeValue : ''}
      </div>
    );
  };

  morningout1 = (props: any) => {
    const timeValue =
      props.spans &&
      props.spans[1].punchOut &&
      props.spans[1].punchOut.actual &&
      props.spans[1].punchOut.actual.substring(11, 16);

    return (
      <div
        id={
          timeValue && timeValue !== '00:00'
            ? props.spans[1].punchOut.actual
              ? props.spans[1].punchOut.actual
              : props.spans[1].punchOut.scheduled
            : ''
        }>
        {timeValue && timeValue !== '00:00' ? timeValue : ''}
      </div>
    );
  };

  private process_day() {
    let _final: any = [];
    if (
      this.state._startDate !== undefined &&
      this.state._endDate !== undefined
    ) {
      const date = new Date(this.state._startDate as any);
      while (date <= new Date(this.state._endDate as any)) {
        let classes: any = [];
        classes.push('tr');
        classes.push('text-center');
        let _data: any = undefined;
        if (
          this.state.Attendance !== undefined &&
          this.state.Attendance !== ''
        ) {
          var data = (this.state.Attendance as any).attendance.timeSheet.filter(
            (x: any) => new Date(x.date).getTime() == new Date(date).getTime()
          );
        }
        if (data !== undefined) {
          _data = data[0];
        }

        var _elem = this.generate_Element(date, _data);

        if (this.isEmpty(this.state.selectedRange) === false) {
          let start = (this.state.selectingRange ||
            this.state.selectedRange)[0];
          let end = (this.state.selectingRange || this.state.selectedRange)[1];

          var _start =
            start !== undefined ? new Date(parseInt(start)) : undefined;
          var _end = end !== undefined ? new Date(parseInt(end)) : undefined;
          if (_end !== undefined && _start !== undefined && _end < _start) {
            [end, start] =
              this.state.selectingRange || this.state.selectedRange;
          }
          if (
            _end !== undefined &&
            _start !== undefined &&
            new Date(date).getTime() >= new Date(_start).getTime() &&
            new Date(date).getTime() <= new Date(_end).getTime()
          )
            if (
              (new Date(date).getDay() === 6 ||
                new Date(date).getDay() === 0) == false
            ) {
              classes.push('range');
            }
        }
        _final.push(
          <tr
            id={new Date(date).getTime().toString()}
            onMouseDown={(d) => this.onClick(d, classes.join(' '), true)}
            onMouseMove={(d) => this.onHover(d)}
            onMouseUp={(d) => this.onUp(d, classes.join(' '), false)}
            onContextMenu={(e) => this.rightClick(e)}
            className={classes.join(' ')}
            style={{
              backgroundColor: `${new Date(date).getDay() === 6
                  ? ' whitesmoke'
                  : 'transparent' && new Date(date).getDay() === 0
                    ? ' Linen'
                    : 'transparent'
                }`,
            }}>
            {_elem}
          </tr>
        );
        date.setDate(date.getDate() + 1);
      }
    }
    return _final;
  }

  private generate_Element(date: Date, data?: any) {
    let classes: any = [];
    classes.push('tr');
    classes.push('text-center');
    let element: any = [];
    if (data !== undefined) {
      element.push(
        <td
          className='bodytd1'
          style={{
            color: `${this.IsHolidayPresent(new Date(date).getTime().toString())
                ? 'red'
                : 'black'
              }`,
          }}>
          {date.getDate()}
        </td>
      );
      element.push(
        <td
          className='bodytd2'
          style={{
            color: `${this.IsHolidayPresent(new Date(date).getTime().toString())
                ? 'red'
                : 'black'
              }`,
          }}>
          {Common.formatDate(date, 'EEEE')}
        </td>
      );
      if (
        this.IsHolidayPresent(new Date(date).getTime().toString()) ||
        this.IsAbsencesPresent(new Date(date).getTime().toString())
      ) {
        element.push(
          <td
            style={{
              color: `${this.IsHolidayPresent(new Date(date).getTime().toString())
                  ? 'red'
                  : 'black'
                }`,
            }}
            className='bodytd3'
            colSpan={4}>
            {this.renderHolidays(new Date(date).getTime().toString())}
            {this.renderLeavedays(new Date(date).getTime().toString())}
          </td>
        );
      } else {
        element.push(
          <>
            <td
              className='bodytd4'
              id='span1'
              onDoubleClick={this.doubleclick.bind(this)}>
              {this.morningin(data)}
            </td>
            <td
              className='bodytd4'
              id='span2'
              onDoubleClick={this.doubleclick.bind(this)}>
              {this.morningout(data)}
            </td>

            <td
              className='bodytd4'
              id='span3'
              onDoubleClick={this.doubleclick.bind(this)}>
              {this.morningin1(data)}
            </td>
            <td
              className='bodytd5'
              id='span4'
              onDoubleClick={this.doubleclick.bind(this)}>
              {this.morningout1(data)}
            </td>
          </>
        );
      }
      if (new Date(date).getDay() == 6 || new Date(date).getDay() == 0) {
        element.push(<td className='.bodytd3' colSpan={6}></td>);
      } else {
        element.push(
          <>
            <td className='bodytd6'>{this.morningminutecount(data)}</td>
            <td className='bodytd7'>{this.afterminutecount(data)}</td>
            <td className='bodytd8'>{this.regular(data)}</td>
            <td className='bodytd9'>{this.overtime(data)}</td>
            <td className='bodytd10'>{this.gross(data, 2)}</td>
            <td>{this.overtimerate(data)}</td>
          </>
        );
      }
    } else {
      element.push(
        <td
          className='bodytd1'
          style={{
            color: `${this.IsHolidayPresent(new Date(date).getTime().toString())
                ? 'red'
                : 'black'
              }`,
          }}>
          {date.getDate()}
        </td>
      );
      element.push(
        <td
          className='bodytd2'
          style={{
            color: `${this.IsHolidayPresent(new Date(date).getTime().toString())
                ? 'red'
                : 'black'
              }`,
          }}>
          {Common.formatDate(date, 'EEEE')}
        </td>
      );
      if (
        this.IsHolidayPresent(date.getTime().toString()) == true ||
        this.IsAbsencesPresent(new Date(date).getTime().toString())
      ) {
        element.push(
          <td
            className='bodytd3'
            style={{
              color: `${this.IsHolidayPresent(new Date(date).getTime().toString())
                  ? 'red'
                  : 'black'
                }`,
            }}
            colSpan={4}>
            {this.GetHoliday(date.getTime().toString())}
            {this.renderLeavedays(new Date(date).getTime().toString())}
          </td>
        );
      } else {
        element.push(
          <td
            id='span1'
            className='bodytd4'
            onDoubleClick={this.doubleclick.bind(this)}></td>
        );
        element.push(
          <td
            id='span2'
            className='bodytd4'
            onDoubleClick={this.doubleclick.bind(this)}></td>
        );
        element.push(
          <td
            id='span3'
            className='bodytd4'
            onDoubleClick={this.doubleclick.bind(this)}></td>
        );
        element.push(
          <td
            id='span4'
            className='bodytd5'
            onDoubleClick={this.doubleclick.bind(this)}></td>
        );
      }
      element.push(<td className='bodytd6'></td>);
      element.push(<td className='bodytd7'></td>);
      element.push(<td className='bodytd8'></td>);
      element.push(<td className='bodytd9'></td>);
      element.push(<td className='bodytd10'></td>);
      element.push(<td></td>);
    }
    return element;
  }

  morningminutecount = (props: any) => {
    let timeValue =
      props.spans &&
      props.spans[0].punchIn &&
      props.spans[0].punchIn.actual &&
      props.spans[0].punchIn.actual.substring(11, 16);
    if (timeValue && timeValue == '00:00') {
      timeValue =
        props.spans &&
        props.spans[1].punchOut &&
        props.spans[1].punchOut.actual &&
        props.spans[1].punchOut.actual.substring(11, 16);
    }
    return (
      <div>
        {timeValue && timeValue !== '00:00'
          ? props.spans[0].actualMinutes > 0
            ? props.spans[0].actualMinutes
            : props.spans[0].expectedMinutes
          : ''}
        {/* {props.spans &&
          props.spans.length > 0 &&
          props.spans[0].expectedMinutes > 0
            ? props.spans[0].expectedMinutes
            : ''} */}
      </div>
    );
  };

  afterminutecount = (props: any) => {
    let timeValue =
      props.spans &&
      props.spans[0].punchIn &&
      props.spans[0].punchIn.actual &&
      props.spans[0].punchIn.actual.substring(11, 16);
    if (timeValue && timeValue == '00:00') {
      timeValue =
        props.spans &&
        props.spans[1].punchOut &&
        props.spans[1].punchOut.actual &&
        props.spans[1].punchOut.actual.substring(11, 16);
    }
    return (
      <div>
        {timeValue && timeValue !== '00:00'
          ? props.spans[1].actualMinutes > 0
            ? props.spans[1].actualMinutes
            : props.spans[1].expectedMinutes
          : ''}
        {/* {props.expectedMinutes ? props.spans[1].expectedMinutes : ''} */}
      </div>
    );
  };

  regular = (props: any) => {
    let timeValue =
      props.spans &&
      props.spans[0].punchIn &&
      props.spans[0].punchIn.actual &&
      props.spans[0].punchIn.actual.substring(11, 16);
    if (timeValue && timeValue == '00:00') {
      timeValue =
        props.spans &&
        props.spans[1].punchOut &&
        props.spans[1].punchOut.actual &&
        props.spans[1].punchOut.actual.substring(11, 16);
    }
    return (
      <div>
        {timeValue && timeValue !== '00:00'
          ? props.actualMinutes
            ? props.actualMinutes
            : props.expectedMinutes
          : ''}
      </div>
    );
  };

  overtime = (props: any) => {
    let timeValue =
      props.spans &&
      props.spans[0].punchIn &&
      props.spans[0].punchIn.actual &&
      props.spans[0].punchIn.actual.substring(11, 16);
    if (timeValue && timeValue == '00:00') {
      timeValue =
        props.spans &&
        props.spans[1].punchOut &&
        props.spans[1].punchOut.actual &&
        props.spans[1].punchOut.actual.substring(11, 16);
    }
    if (props.overtime.minutes > 0) {
      return (
        <div>
          {timeValue && timeValue !== '00:00'
            ? props.overtime
              ? props.overtime.minutes
              : null
            : ''}
        </div>
      );
    } else {
      return [];
    }
  };

  gross = (props: any, decimalplace: number) => {
    if (props.expectedMinutes > 0) {
      this.totalGross =
        this.totalGross +
        props.actualMinutes * (this.state.Attendance as any).salaryperMinute;
      return (
        <div style={{ textAlign: 'right' }}>
          {this.state.Attendance
            ? props.actualMinutes *
              (this.state.Attendance as any).salaryperMinute >
              0
              ? Common.FormatNumber(
                props.actualMinutes *
                (this.state.Attendance as any).salaryperMinute,
                this.user.settings.formats.Decimal
              )
              : null
            : null}
        </div>
      );
    } else {
      return [];
    }
  };

  overtimerate = (props: any) => {
    if (props.overtime.minutes > 0) {
      this.totalOvertime =
        this.totalOvertime +
        props.overtime.minutes *
        (this.state.Attendance as any).salaryperMinute *
        props.overtime.rate;
      return (
        <div style={{ textAlign: 'right' }}>
          {this.state.Attendance
            ? Common.FormatNumber(
              props.overtime.minutes *
              (this.state.Attendance as any).salaryperMinute *
              props.overtime.rate,
              this.user.settings.formats.Decimal
            )
            : null}
        </div>
      );
    } else {
      return [];
    }
  };

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * Avinash - 2023-07-18 - 85 -- Working on Attendance header part create shipmle HTML struture
  // * Avinash - 2023-07-21 - 230 -- Working on Attendance data and convert in to desire foramte
  render() {
    const formatData = (input: any) => {
      console.log(input);
      if (input > 9) {
        return input;
      } else return `0${input}`;
    };

    if (this.state._menuitems !== undefined && this.menuItem.length <= 0) {
      this.setContextMenuItems();
    }

    return (
      <>
        <div id='attMainTable' style={{ display: 'grid' }}>
          <div className='mt-3'>
            <div className='PeriodSelector'>
              <PeriodSelector
                handleInputChange={this.handleChange}
                id='attendance-selector'
                _jObject={this.props.selectorData}
                showEmployeePeriodsOnly={true}
                showLastPeriod={true}
              />
            </div>
            <div
              id='attIcons'
              className='settingicons'
              style={{ display: 'flex' }}>
              <div className='icon-spacing'>
                <i
                  id='settings'
                  className='fa-solid fa-sliders'
                  title='Settings'
                  onClick={this.ProcessDialog.bind(this)}></i>
              </div>
              <div className='icon-spacing'>
                <label htmlFor='fileUpload'>
                  <i
                    className='fa-regular fa-folder-open'
                    title='choose file'
                    style={{ cursor: 'pointer' }}></i>
                  <input
                    type='file'
                    id='fileUpload'
                    onChange={this.handleFileChange.bind(this)}
                    style={{ display: 'none' }}
                    accept='.csv'
                  />
                </label>
              </div>
              <div className='icon-spacing'>
                <i
                  id='device'
                  className='fa-solid fa-plug'
                  title='Connect Device'
                  onClick={this.ProcessDialog.bind(this)}></i>
              </div>
              <div className='icon-spacing'>
                {/* <i
                  className='fa-solid fa-print'
                  onClick={this.OpenPrinter.bind(this)}></i> */}
                <i className='fa-solid fa-print'></i>
              </div>
            </div>
          </div>

          <div>
            <div id='attDetails' className='table-container'>
              <table id='atttable' className='itable'>
                <thead className='thead'>
                  <tr>
                    <th colSpan={2} rowSpan={2} className='td1'></th>
                    <th colSpan={2} className='td2'>
                      Morning
                    </th>
                    <th colSpan={2} className='td3'>
                      Afternoon
                    </th>
                    <th colSpan={4} className='td4'>
                      Work Time (Minutes)
                    </th>
                    <th colSpan={2} className='td5'>
                      Salary
                    </th>
                  </tr>
                  <tr>
                    <th className='td6'>In</th>
                    <th className='td6'>Out</th>
                    <th className='td6'>In</th>
                    <th className='td7'>Out</th>
                    <th className='td8'>Morning</th>
                    <th className='td8'>Afternoon</th>
                    <th className='td8'>Regular</th>
                    <th className='td9'>Overtime</th>
                    <th className='td10'>Gross</th>
                    <th className='td11'>Overtime</th>
                  </tr>
                  <tr className='blank-space'></tr>
                </thead>
                <tbody className='tbody'>{this.process_day()}</tbody>
              </table>
            </div>
            <div id='attSummary' className='summary'>
              <table className='summary-table'>
                <tr>
                  <td className='summary-td1'></td>
                  <td className='summary-td2'></td>
                  <td className='summary-td3'></td>
                  <td className='summary-td4'></td>
                  <td className='summary-td5'></td>
                  <td className='summary-td6'></td>
                  <td className='summary-td7'></td>
                  <td className='summary-td10'>
                    {Common.FormatNumber(
                      this.totalGross,
                      this.user.settings.formats.Decimal
                    )}
                  </td>
                  <td className='summary-td10'>
                    {Common.FormatNumber(
                      this.totalOvertime,
                      this.user.settings.formats.Decimal
                    )}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div>
            {this.state.showDialog === true ? (
              <DialogComponent
                id='defaultDialog1'
                showCloseIcon={true}
                visible={this.state.showDialog}
                width={this.state._dlgWidth}
                height={this.state._dlgHeight}
                close={() => this.setState({ showDialog: false })}
                content={this.dialogContent.bind(this)}
                isModal={true}
                statelessTemplates={[]}></DialogComponent>
            ) : null}
          </div>
          <div>
            <ContextMenuComponent
              id={Math.random().toString() + 'contextmenu'}
              ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
              items={this.menuItem}
              select={this.handleMenuClick}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Attendance;
