import { fontColor } from '@syncfusion/ej2-react-spreadsheet';
import * as React from 'react';
import Common from '../../../Util/Common';
import ResetPassword from '../../ResetPassword';

import {
  DialogUtility,
  DialogComponent,
  Dialog,
} from '@syncfusion/ej2-react-popups';
import { ProgressButtonComponent } from '@syncfusion/ej2-react-splitbuttons';

const {
  REACT_APP_ENDPOINT_USERSERVICE,
  REACT_APP_ENDPOINT_TOKENS,
  REACT_APP_ENDPOINT_XMLPATH,
  REACT_APP_ENDPOINT_SIDEBARPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
  REACT_APP_VERSION,
} = process.env;

let username: any;
let password: any;
let message: any;
let protocol: any;
let domain: any;
let port: any;
let path: any;

interface ForgotProps {
  sendDataToLogin: (data: string) => void;
  showHeaderName?: any;
}

interface ForgotState {
  forgotData: string;
  showReset: boolean;
  isLoading: boolean;
}

class ForgotPassword extends React.Component<ForgotProps, ForgotState> {
  constructor(props: ForgotProps) {
    super(props);
    this.state = {
      forgotData: '',
      showReset: false,
      isLoading: false,
    };
  }

  handleDataFromReset = (resetData: string) => {
    this.setState({ forgotData: resetData });
    this.props.sendDataToLogin(this.state.forgotData);
  };

  private sendNotification() {
    protocol = window.location.protocol;
    domain = window.location.hostname;
    port = window.location.port;
    path = protocol + '//' + domain + ':' + port;

    if (
      document.getElementById('txtEmail') !== undefined &&
      (document.getElementById('txtEmail') as any).value == ''
    ) {
      //DialogUtility.alert('Please enter Email to continue.');
      alert('Please enter Email to continue.');
      return;
    }

    username = (document.getElementById('txtEmail') as any).value;
    password = '0';
    let requestdata: any = { email: username, password: password, path: path };

    this.setState({ isLoading: true, showReset: false });

    Common.ApiCallAsync(
      'post',
      `${REACT_APP_ENDPOINT_USERSERVICE}/gettenant`,
      requestdata
    )
      .then((response: any) => {
        let _tenant: any = response.data;
        if (_tenant == '') {
          _tenant = 'root';
        }

        Common.ApiCallAsync(
          'post',
          `${REACT_APP_ENDPOINT_USERSERVICE}/forgotpasswordemail`,
          requestdata,
          '',
          null,
          _tenant
        )
          .then((response: any) => {
            if (response.data === 'True') {
              // DialogUtility.alert(
              //   'A password reset token has been sent to your email address. If you do not receive the email within a few minutes, please check your spam folder or contact support.'
              // );
              alert(
                'A password reset token has been sent to your email address. If you do not receive the email within a few minutes, please check your spam folder or contact support.'
              );
              // this.setState({ showReset: true });
              this.props.showHeaderName('');
            } else if (response.data === 'False') {
              //DialogUtility.alert('Reset Password Link Email Sending Failure!');
              alert('Reset Password Link Email Sending Failure!');
            } else {
              //DialogUtility.alert(response.data);
              alert(response.data);
            }
          })
          .catch((error: any) => {
            console.error(error);
            this.setState({ isLoading: false });
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({ isLoading: false });
      })
      .finally(() => {});
  }
  render() {
    return (
      <>
        {this.state.showReset === false ? (
          <div className='row'>
            {/* <div className='col-12 py-2' style={{ textAlign: 'center' }}>
              <b>
                <label style={{ fontSize: '15px' }}>Forgot Password</label>
              </b>
            </div> */}
            <div className='col-12 py-2' style={{ textAlign: 'center' }}>
              <label style={{ fontSize: '15px' }}>
                Enter your email and we'll send you a link to reset your
                password.
              </label>
            </div>

            <div className='col-12 py-2 my-2'>
              <input
                className='input-text-border1 w-100 p-2'
                id='txtEmail'
                name='txtEmail'
                type='text'
                placeholder='Enter your email'
                autoComplete='off'
                style={{ fontSize: '15px' }}
                required={true}
              />
            </div>

            <div className='col-12 d-grid gap-2'>
              <ProgressButtonComponent
                id='btnforgotsubmit'
                content='Submit'
                spinSettings={{ position: 'Right' }}
                onClick={this.sendNotification.bind(this)}
                className='e-btn submitprogress'
                disabled={this.state.isLoading}
              ></ProgressButtonComponent>
            </div>
            <div className='col-12 py-2' style={{ textAlign: 'left' }}>
              <label
                id='lblMeessage'
                style={{ fontSize: '15px', color: 'red' }}
              >
                {message}
              </label>
            </div>
          </div>
        ) : (
          <>
            {
              <ResetPassword
                sendDataToForgot={this.handleDataFromReset}
              ></ResetPassword>
            }
          </>
        )}
      </>
    );
  }
}
export default ForgotPassword;
