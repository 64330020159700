import * as React from 'react';
import './CompanyList.css';
import Common from '../../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  Inject,
  Toolbar,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import SelectorSearch from '../../Layout/SelectorSearch';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import Company from './Company';
import DialogConfirm from '../../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE } = process.env;

interface Props {}

export default class CompanyList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  private objGrid: TreeGridComponent | any = null;
  public toolbarOptions: any = [
    'Add',
    'Edit',
    // 'Delete',
    // {
    //   text: 'Save',
    //   tooltipText: 'Save',
    //   prefixIcon: 'e-update',
    //   id: `companylist-treegrid_gridcontrol_update`,
    // },
    // 'Cancel',
  ];
  private editSettings: EditSettingsModel = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Row',
    newRowPosition: 'Below',
    allowEditOnDblClick: false,
    showDeleteConfirmDialog: true,
  };

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    jsonData: undefined,
    showDialog: false,
    isAdd: true,
    tenantGroupId: '',
    identifier: '',
    name: '',
    email: '',
    adminEmail: '',
    website: '',
    finStartDate: new Date(new Date().getFullYear(), 0, 1),
    finEndDate: new Date(new Date().getFullYear(), 11, 31),
    countryId: '',
    isActive: true,
  };

  componentDidMount(): void {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  public GetList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/tenants/tenantgroup/get`,
      { addNewOption: true },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          jsonData: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const onToolbarClick = (e: any) => {
      if (e.item.id === 'companylist-treegrid_gridcontrol_add') {
        e.cancel = true;
        this.setState({
          showDialog: true,
          isAdd: true,
          tenantGroupId: '',
          identifier: '',
          name: '',
          email: '',
          adminEmail: '',
          website: '',
          finStartDate: null,
          finEndDate: null,
          countryId: '',
          isActive: true,
        });
      } else if (e.item.id === 'companylist-treegrid_gridcontrol_edit') {
        e.cancel = true;
        if (recordEdit(e) === false) {
          return;
        }
      } else {
      }
    };

    const recordEdit = (e: any) => {
      if (e.cell) {
        this.objGrid.selectRow(e.rowIndex);
      }

      if (this.objGrid.getSelectedRows().length > 0) {
        if (this.objGrid.getSelectedRecords()[0].level === 0) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, You cannot edit company group.</p>',
          });

          return false;
        }
        this.setState({
          showDialog: true,
          isAdd: false,
          tenantGroupId: this.objGrid.getSelectedRecords()[0].tenantGroupId,
          identifier: this.objGrid.getSelectedRecords()[0].identifier,
          name: this.objGrid.getSelectedRecords()[0].name,
          email: this.objGrid.getSelectedRecords()[0].email,
          adminEmail: this.objGrid.getSelectedRecords()[0].adminEmail,
          website: this.objGrid.getSelectedRecords()[0].website,
          finStartDate: this.objGrid.getSelectedRecords()[0].finStartDate,
          finEndDate: this.objGrid.getSelectedRecords()[0].finEndDate,
          countryId: this.objGrid.getSelectedRecords()[0].countryId,
          isActive: this.objGrid.getSelectedRecords()[0].isActive,
        });
        //this.objGrid.endEdit();
        return true;
      } else {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">No record selected.</p>',
        });
        //this.objGrid.endEdit();
        return false;
      }
    };

    const dlgContent = (e: any) => {
      return (
        <Company
          isAdd={this.state.isAdd}
          tenantGroupId={this.state.tenantGroupId}
          identifier={this.state.identifier}
          name={this.state.name}
          email={this.state.email}
          adminEmail={this.state.adminEmail}
          website={this.state.website}
          finStartDate={this.state.finStartDate}
          finEndDate={this.state.finEndDate}
          countryId={this.state.countryId}
          isActive={this.state.isActive}
          jsonCompanyGroup={this.state.jsonData}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
        />
      );
    };

    const handleCancelClick = (e: any) => {
      this.setState({ showDialog: false, isAdd: true });
    };

    const handleSaveClick = (e: any) => {
      this.setState({
        isAdd: true,
        identifier: '',
        name: '',
        email: '',
        adminEmail: '',
        website: '',
        finStartDate: null,
        finEndDate: null,
        isActive: true,
        tenantGroupId: '',
        countryId: '',
        databaseName: '',
      });
      this.GetList();
    };

    return (
      <>
        <div id='divCompanyList' className='py-2'>
          <label
            className='pb-1'
            style={{ fontSize: '20px', fontWeight: '500' }}
          >
            Company
          </label>
          <div className='control-pane'>
            <div className='control-section'>
              <div id='divcompanylist-grid'>
                {this.state.jsonData && (
                  <>
                    <TreeGridComponent
                      id='companylist-treegrid'
                      className={`companylist-treegrid`}
                      height={`calc(100vh - 280px`}
                      dataSource={(this.state.jsonData as any).filter(
                        (x: any) => x.id !== Common.blankguid
                      )}
                      treeColumnIndex={0}
                      childMapping={'tenants'}
                      editSettings={this.editSettings}
                      toolbar={this.toolbarOptions}
                      toolbarClick={onToolbarClick}
                      recordDoubleClick={recordEdit}
                      ref={(obj: any) => (this.objGrid = obj)}
                    >
                      <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Group Name' />
                        <ColumnDirective
                          field='identifier'
                          headerText='Code'
                          width={'100px'}
                        />
                        <ColumnDirective
                          field='email'
                          headerText='Email'
                          width={'180px'}
                        />
                        <ColumnDirective
                          field='adminEmail'
                          headerText='Admin Email'
                          width={'180px'}
                        />
                        <ColumnDirective
                          field='website'
                          headerText='Website'
                          width={'200px'}
                        />
                        <ColumnDirective
                          field='validUpto'
                          headerText='Valid Upto'
                          width={'120px'}
                          type='datetime'
                          format={this.user.settings.formats.DateShort}
                        />
                        <ColumnDirective
                          field='country.name'
                          headerText='Country'
                          width={'100px'}
                        />
                        <ColumnDirective
                          field='isActive'
                          headerText='Active'
                          width={'55px'}
                          type='boolean'
                          displayAsCheckBox={true}
                          textAlign='Center'
                        />
                      </ColumnsDirective>
                      <Inject services={[Toolbar, Edit]} />
                    </TreeGridComponent>
                    <SelectorSearch
                      grid_id={'companylist-treegrid'}
                      showMoreOptions={false}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-companyhange'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={this.state.isAdd ? '50vw' : '25vw'}
            close={() => this.setState({ showDialog: false })}
            content={dlgContent}
            isModal={true}
            header={`${this.state.isAdd ? 'Add' : 'Edit'} Comoany`}
            //buttons={this.DialogButtonModel}
            statelessTemplates={[]}
            allowDragging={true}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
