import * as React from 'react';
import './LinkList.css';
import Common from '../../Util/Common';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface fields {
  id: string;
  text: string;
  iconCss: string;
}

interface Props {
  id: string;
  jsonDataViewer: any;
  dataSource: fields[] | any;
  fields: fields;
  isCustomReport?: boolean;
  onLinkItemClick?: any;
  linkItemOptionClick?: any;
}

export default class LinkList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  static defaultProps = {
    isCustomReport: false,
  };

  state = {};

  componentDidMount(): void {}

  render() {
    const onLinkItemClick = (e: any) => {
      // if (!this.props.isCustomReport) {
      //   return;
      // } else {
      //   this.props.onLinkItemClick({
      //     id: e.target.closest('.linkitem').dataset.id,
      //   });
      // }
      this.props.onLinkItemClick({
        id: e.target.closest('.linkitem').dataset.id,
      });
    };

    const onlinkitemOptionClick = (e: any) => {
      this.props.linkItemOptionClick(e);
    };

    return (
      <div key={Math.random()}>
        <div
          key={`divlinklist-${this.props.id}`}
          id={`divlinklist-${this.props.id}`}
          className='divlinklist row'
        >
          {this.props.dataSource &&
            this.props.dataSource.map((x: any) => {
              return (
                <div
                  key={Math.random()}
                  // className={`col-md-${this.props.isCustomReport ? '2' : '12'}`}
                  className={`col-md-12 ps-2`}
                >
                  <div
                    className='linkitem position-relative'
                    data-id={x.id ?? x.Id}
                  >
                    <div className='linkitemfav'>
                      <span
                        className={
                          x[this.props.fields.iconCss] ?? 'fa-regular fa-star'
                        }
                      ></span>
                    </div>
                    <div className='linkitemtext' onClick={onLinkItemClick}>
                      {x[this.props.fields.text]}
                    </div>
                    <div
                      className='linkitemoptions fa-solid fa-ellipsis'
                      onClick={onlinkitemOptionClick}
                      title='Click for more options'
                    ></div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
