// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SANDEEP - 2023-07-31 - 60 - Status Review Meeting.
// * SANDEEP - 2023-08-02 - 120 - Checking syncfusion gantt chart and getting accoquinted with it
// * SANDEEP - 2023-08-03 - 180 - Checking syncfusion gantt chart and getting accoquinted with it
// * SANDEEP - 2023-08-04 - 180 - Integrating gantt chart into aruti2.0 frontend and checking
// * SANDEEP - 2023-08-07 - 420 - Getting issues on gantt chart for events and rows so finding the cause
// * SANDEEP - 2023-08-08 - 420 - Tried with different version for gantt chart and checked for the issue

// * -----------------------------------------------------------------------------------------------------------------2.Initialize

import React, { ReactNode, startTransition, useEffect } from 'react';
import Common from '../Util/Common';
import '../App.css';
import {
	GanttComponent,
	DayMarkers,
	Inject,
	Selection,
	Toolbar,
	Edit,
	ColumnsDirective,
	ColumnDirective,
} from '@syncfusion/ej2-react-gantt';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TypePredicateKind } from 'typescript';

// * ------------------------------------------------------------------------------------------------------------------3.Style

/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
	explicitRoot: false,
	explicitArray: false,
});
const { REACT_APP_ENDPOINT_EMPLOYEESERVICE } = process.env;

class LeaveProcess extends React.Component<{}> {
	// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
	private user: any;
	//private ganttInstance: GanttComponent | any;

	constructor(props: any) {
		super(props);
		this.user = JSON.parse(Common.getUser() ?? '');
	}

	// * ------------------------------------------------------------------------------------------------------------------5.State
	state = {};

	// * ------------------------------------------------------------------------------------------------------------------Event Handler
	componentDidMount() {}

	// * ------------------------------------------------------------------------------------------------------------------6.Adapter

	private RemoveHeaderText() {}

	HierarchyData: Object[] = [
		{
			TaskID: 1,
			TaskName: 'Project Initiation',
			StartDate: new Date('04/02/2023'),
			EndDate: new Date('04/21/2023'),
			subtasks: [
				{
					TaskID: 2,
					TaskName: 'Identify Site location',
					StartDate: new Date('04/02/2023'),
					Duration: 4,
					Progress: 50,
					Remaining: 2,
				},
				{
					TaskID: 3,
					TaskName: 'Perform Soil test',
					StartDate: new Date('04/02/2023'),
					Duration: 4,
					Progress: 50,
					Remaining: 2,
				},
				{
					TaskID: 4,
					TaskName: 'Soil test approval',
					StartDate: new Date('04/02/2023'),
					Duration: 4,
					Progress: 50,
					Remaining: 2,
				},
			],
		},
		{
			TaskID: 5,
			TaskName: 'Project Estimation',
			StartDate: new Date('04/02/2023'),
			EndDate: new Date('04/21/2023'),
			subtasks: [
				{
					TaskID: 6,
					TaskName: 'Develop floor plan for estimation',
					StartDate: new Date('04/04/2023'),
					Duration: 3,
					Progress: 50,
					Remaining: 1.5,
				},
				{
					TaskID: 7,
					TaskName: 'List materials',
					StartDate: new Date('04/04/2023'),
					Duration: 3,
					Progress: 50,
					Remaining: 1.5,
				},
				{
					TaskID: 8,
					TaskName: 'Estimation approval',
					StartDate: new Date('04/04/2023'),
					Duration: 3,
					Progress: 50,
					Remaining: 1.5,
				},
			],
		},
	];

	taskFields: any = {
		id: 'TaskID',
		name: '',
		startDate: 'StartDate',
		duration: 'Duration',
		progress: 'Progress',
		child: 'subtasks',
		remaining: 'Remaining',
	};
	public labelSettings: any = {
		leftLabel: 'TaskName',
	};
	public projectStartDate: Date = new Date('01/01/2023');
	public projectEndDate: Date = new Date('12/31/2023');
	timelineSettings: any = {
		timelineUnitSize: 25,
		timelineViewMode: 'Month',
		topTier: {
			format: 'MMM yyyy',
			unit: 'Month',
		},
		bottomTier: {
			format: 'dd',
			unit: 'Day',
		},
	};
	public splitterSettings: any = {
		position: '25%',
		minimum: '25%',
	};
	public editSettings: any = {
		allowAdding: true,
		allowEditing: true,
		allowDeleting: true,
		allowTaskbarEditing: true,
		showDeleteConfirmDialog: true,
	};

	// * --------------------------------------------------------------------------------------------------------------------HTML

	render() {
		return (
			<>
				<div>
					<div
						id='drpleave'
						style={{
							width: '20%',
							position: 'fixed',
							top: '90px',
							zIndex: '1',
							marginLeft: '9px',
						}}>
						<DropDownListComponent
							id='dropdownelement'
							// popupHeight="200px"
							// popupWidth="250px"
							placeholder='Select Leave Type'
							name='leavetype'
						/>
					</div>
					<div id='gantt-data'>
						<GanttComponent
							id='TaskMode'
							dataSource={this.HierarchyData}
							treeColumnIndex={1}
							allowSelection={true}
							highlightWeekends={true}
							editSettings={this.editSettings}
							splitterSettings={this.splitterSettings}
							height='calc(100vh - 115px)'
							showInlineNotes={true}
							taskFields={this.taskFields}
							projectStartDate={this.projectStartDate}
							projectEndDate={this.projectEndDate}
							timelineSettings={this.timelineSettings}>
							<ColumnsDirective>
								<ColumnDirective
									field='TaskID'
									visible={false}
									headerText='  '></ColumnDirective>
								<ColumnDirective
									field='TaskName'
									headerText=' '></ColumnDirective>
								<ColumnDirective
									field='isManual'
									headerText=' '></ColumnDirective>
							</ColumnsDirective>
							<Inject services={[Edit, Selection, Toolbar, DayMarkers]} />
						</GanttComponent>
					</div>
				</div>
			</>
		);
	}
}

export default LeaveProcess;
