import React from 'react'

// * SUHAIL - 2023-02-23 - 0.10
import { Route, Navigate } from 'react-router-dom'
import Common from './Common'

// * SUHAIL - 2023-02-23 - 0.10
interface Props {
  component: React.ComponentType
  path?: string
}

// * SUHAIL - 2023-02-24 - 0.30
const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

// * SUHAIL - 2023-02-23 - 2.00
// * SUHAIL - 2023-02-24 - 0.30
export const PrivateRoute: React.FC<Props> = ({
  component: Component,
  ...rest
}) => {
  if (Common.getToken() && Common.getUser()) {
    const token: any = Common.getToken()
    const decodedJwt = parseJwt(token)

    if (decodedJwt.exp * 1000 < Date.now()) {
      Common.removeUserSession()
      return <Navigate to="/login" />
    } else {
      return <Component />
    }
  } else {
    return <Navigate to="/login" />
  }
}

export default PrivateRoute
