import * as React from 'react';
import Common from '../../Util/Common';
import './User.css';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE } = process.env;

interface datamodel {
  isAdd?: boolean;
  firstName?: string;
  lastName?: string;
  userName?: string;
  password?: string;
  confirmPassword?: string;
  email?: string;
  isManager?: boolean;
  jsonRole?: any;
  roleName?: string;
  roleId?: string;
}
interface Props {}

export default class User extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private ddlRole: DropDownListComponent | any;
  private userRoleId: string = '';
  private employeeRoleId: string = '';

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.state = Object.assign({}, props);
  }

  static defaultProps = {
    isManager: true,
  };

  componentDidMount(): void {
    let data: datamodel = this.state;

    this.employeeRoleId = data.jsonRole.filter(
      (x: any) => x.name === 'Employee'
    )[0].id;

    if (data.roleName) {
      this.userRoleId = data.jsonRole.filter(
        (x: any) => x.name === data.roleName
      )[0].id;
      this.setState({
        roleId: this.userRoleId,
      });
    }
  }

  public GetState() {
    return this.state;
  }

  onCheckboxChange(args: any): void {
    let data: datamodel = this.state;
    let ctrl: HTMLElement = args.event.target.parentElement;
    let ctrlid: any = ctrl.getElementsByTagName('input');
    if (!ctrlid) return;
    ctrlid = 'u_' + ctrlid[0].id;

    let chk: any = (document.getElementsByClassName(ctrlid) as any)[0]
      .ej2_instances[0];

    let key: string = chk.id;
    let value: boolean = chk.checked;
    (document.getElementById(ctrlid) as HTMLInputElement).value =
      value.toString();
    this.ddlRole.enabled = chk.checked;

    this.setState({
      [key]: value,
      roleId: chk.checked
        ? this.userRoleId
          ? this.userRoleId
          : null
        : this.employeeRoleId,
    });
    if (chk.checked && !this.userRoleId) {
      this.ddlRole.value = null;
    }
  }

  render() {
    let data: datamodel = this.state;

    const ontextChange = (e: any) => {
      let key: string = e.target.name;
      let value: string = e.value;

      this.setState({ [key]: value });
    };

    return (
      <>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              First Name
            </label>
            <TextBoxComponent
              className=''
              id='firstName'
              name='firstName'
              type='text'
              placeholder='Enter first name'
              autocomplete='off'
              value={data.firstName}
              onChange={ontextChange}
            />
          </div>
          <div className='col-6 py-2'>
            {' '}
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Last Name
            </label>
            <TextBoxComponent
              className=''
              id='lastName'
              name='lastName'
              type='text'
              placeholder='Enter last name'
              autocomplete='off'
              value={data.lastName}
              onChange={ontextChange}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              User Name
            </label>
            <TextBoxComponent
              className=''
              id='userName'
              name='userName'
              type='text'
              placeholder='Enter user name'
              autocomplete='off'
              value={data.userName}
              onChange={ontextChange}
              enabled={
                data.isAdd === undefined || data.isAdd === null || data.isAdd
              }
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Email
            </label>
            <TextBoxComponent
              className=''
              id='email'
              name='email'
              type='email'
              placeholder='Enter email'
              autocomplete='off'
              value={data.email}
              onChange={ontextChange}
            />
          </div>
        </div>
        {(data.isAdd === undefined || data.isAdd === null || data.isAdd) && (
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <span className='mandatoryinfo'>*</span>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Password
              </label>
              <TextBoxComponent
                className=''
                id='password'
                name='password'
                type='password'
                placeholder='Enter password'
                autocomplete='off'
                value={data.password}
                onChange={ontextChange}
              />
            </div>
            <div className='col-6 py-2'>
              <span className='mandatoryinfo'>*</span>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Confirm Password
              </label>
              <TextBoxComponent
                className=''
                id='confirmPassword'
                name='confirmPassword'
                type='password'
                placeholder='Enter confirm password'
                autocomplete='off'
                value={data.confirmPassword}
                onChange={ontextChange}
              />
            </div>
          </div>
        )}
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <CheckBoxComponent
              id='isManager'
              name='isManager'
              className='u_isManager'
              // checked={
              //   data.isAdd === undefined || data.isAdd === null || data.isAdd
              //     ? true
              //     : data.isManager
              // }
              checked={
                typeof data.isManager === 'boolean' ? data.isManager : true
              }
              label={'Manager'}
              change={this.onCheckboxChange.bind(this)}
              value=''
            />
            <input
              className='input-text-border'
              id='u_isManager'
              name='u_isManager'
              type='hidden'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              // value={
              //   data.isAdd === undefined || data.isAdd === null || data.isAdd
              //     ? 'true'
              //     : data.isManager!.toString()
              // }
              value={
                typeof data.isManager === 'boolean'
                  ? data.isManager!.toString()
                  : 'true'
              }
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              User Role
            </label>
            <DropDownListComponent
              id='roleId'
              name='roleId'
              dataSource={data.jsonRole}
              ref={(obj) => {
                this.ddlRole = obj;
              }}
              filterBarPlaceholder='Search Role'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Role'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
              value={data.roleId ? data.roleId : undefined}
              change={(e: any) => {
                this.setState({
                  roleId: e.itemData ? e.itemData.id : undefined,
                });
              }}
              enabled={data.isManager === false ? false : true}
            />
          </div>
        </div>
      </>
    );
  }
}
