// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import './AllocationList.css';
import Common from '../../Util/Common';
import {
  ContextMenuComponent,
  MenuItemModel,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';
import {
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import Allocation from './Allocation';
import {
  DropDownListComponent,
  DropDownTreeComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import AllocationDetail from './AllocationDetail';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  Filter,
  Inject,
  RowDD,
  Toolbar,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import SelectorSearch from '../Layout/SelectorSearch';
import DialogConfirm from '../Dialogbox/DialogConfirm';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  jsonDataViewer: any;
  underTabs?: boolean;
}

export default class AllocationList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private id: string = '';
  private allocation_id: string = '';
  private allocation_header: string = '';
  private allocation_child: string = '';
  private allocation_hasCode: boolean = false;
  private allocation_hasCustomCode: boolean = false;
  private allocation_hasReportingTo: boolean = false;
  private allocation_idfield: string = '';
  private allocation_endpointlist: string = '';
  private allocation_endpointadd: string = '';
  private allocation_endpointdelete: string = '';
  //public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private selectednode: any = null;
  private selectedmenuid: string = '';
  private editingnode: any = {};

  private treeSettings: TreeSettingsModel = { expandOn: 'Click' };
  private dblclickedctrlid: string = '';
  private ddlTree: DropDownTreeComponent | any = null;

  private child: Allocation | any;
  private isgroupselected: boolean = false;

  private isnodeselected: boolean = false;

  private editSettings: EditSettingsModel = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Row',
    newRowPosition: 'Below',
    allowEditOnDblClick: true,
    showDeleteConfirmDialog: true,
  };
  public toolbarOptions: any = ['Edit', 'Delete'];
  private grdAllocation: TreeGridComponent | any = null;
  private editDatasource: any = null;
  private editRowIdx: number = 0;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.InitializeData();

    this.child = React.createRef();
  }

  static defaultProps = {
    underTabs: false,
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonAllocation: undefined,
    showDialog: false,
    addnew: true,
    delkey: '',
    code: '',
    name: '',
    selectednode: undefined,
    jfieldsreportto: undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {
    if (!this.state.jsonAllocation) {
      this.GetAllocation();
    }
  }

  public InitializeData() {
    this.allocation_id = this.props.jsonDataViewer
      ? this.props.jsonDataViewer.id.toLowerCase()
      : '';

    this.id = `#allocationlisttree-${this.props.jsonDataViewer.id}`;

    this.allocation_header = this.props.jsonDataViewer
      ? this.props.jsonDataViewer.name
      : '';

    switch (this.allocation_id) {
      case 'branch':
        this.allocation_child = 'subBranchs';
        this.allocation_idfield = 'branchId';
        this.allocation_endpointlist = 'allocation/listbranch';
        this.allocation_endpointadd = 'allocation/addbranch';
        this.allocation_endpointdelete = 'allocation/deletebranch';
        break;

      case 'department':
        this.allocation_child = 'subDepartments';
        this.allocation_idfield = 'departmentId';
        this.allocation_endpointlist = 'allocation/listdepartment';
        this.allocation_endpointadd = 'allocation/adddepartment';
        this.allocation_endpointdelete = 'allocation/deletedepartment';
        break;

      case 'job':
      case 'position':
        this.allocation_hasCode = true;
        this.allocation_child = 'subJob';
        this.allocation_idfield = 'jobId';
        this.allocation_endpointlist = 'allocation/listjob';
        this.allocation_endpointadd = 'allocation/addjob';
        this.allocation_endpointdelete = 'allocation/deletejob';
        this.allocation_hasReportingTo = true;
        break;

      case 'grade':
      case 'level':
        this.allocation_hasCode = true;
        this.allocation_child = 'subGrades';
        this.allocation_idfield = 'gradeId';
        this.allocation_endpointlist = 'grades/listgrades';
        this.allocation_endpointadd = 'grades/add';
        this.allocation_endpointdelete = 'grades/deletegrade';
        break;

      case 'costcenter':
        this.allocation_hasCode = true;
        this.allocation_child = 'subCostcenters';
        this.allocation_idfield = 'costcenterId';
        this.allocation_endpointlist = 'costcenter/listcostcenters';
        this.allocation_endpointadd = 'costcenter/add';
        this.allocation_endpointdelete = 'costcenter/deletecostcenter';
        break;

      case 'class':
        this.allocation_child = 'subClasses';
        this.allocation_idfield = 'classId';
        this.allocation_endpointlist = 'allocation/listclass';
        this.allocation_endpointadd = 'allocation/addclass';
        this.allocation_endpointdelete = 'allocation/deleteclass';
        break;

      case 'section':
        this.allocation_child = 'subSections';
        this.allocation_idfield = 'sectionId';
        this.allocation_endpointlist = 'allocation/listsection';
        this.allocation_endpointadd = 'allocation/addsection';
        this.allocation_endpointdelete = 'allocation/deletesection';
        break;

      case 'unit':
        this.allocation_child = 'subUnits';
        this.allocation_idfield = 'unitId';
        this.allocation_endpointlist = 'allocation/listunit';
        this.allocation_endpointadd = 'allocation/addunit';
        this.allocation_endpointdelete = 'allocation/deleteunit';
        break;

      case 'team':
        this.allocation_child = 'subTeams';
        this.allocation_idfield = 'teamId';
        this.allocation_endpointlist = 'allocation/listteam';
        this.allocation_endpointadd = 'allocation/addteam';
        this.allocation_endpointdelete = 'allocation/deleteteam';
        break;

      default:
        this.allocation_endpointlist = '';
        this.allocation_endpointadd = '';
        this.allocation_endpointdelete = '';
        this.allocation_idfield = '';
        break;
    }
  }

  public GetAllocation() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/${this.allocation_endpointlist}`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonAllocation: response,

          jfieldsreportto: this.allocation_hasReportingTo
            ? {
                dataSource: response,
                value: 'id',
                text: 'name',
                child: 'subJob',
                selectable: 'selectable',
              }
            : false,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showDialog === true) {
          this.setState({ showDialog: false });
        } else {
          //this.setSelectedDropdownTreeValue.bind(this);
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        let data: any = this.child.current.GetState();

        if (this.allocation_hasCode && !data.alloccode) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter code</p>',
          });
          return;
        }

        if (!data.allocname) {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Please enter name<p>',
          });
          return;
        }

        // if (
        //   this.allocation_hasCustomCode &&
        //   this.selectednode.isgroup === false &&
        //   !data.alloccustomcode
        // ) {
        //  DialogConfirm.showDialog({
        //    content: '<p class="dialog-contain">Please enter custom code<p>',
        //  });
        //   return;
        // }

        let payload: any = null;
        payload = {
          id:
            this.selectedmenuid === 'changeitem'
              ? this.selectednode!['id']
              : null,
          code: data.alloccode,
          name: data.allocname,
          isgroup: this.editingnode.isgroup,
          customCode: data.alloccustomcode,
          [this.allocation_idfield]:
            this.grdAllocation.dataSource.length > 0 &&
            this.selectednode &&
            this.editingnode.addnew === true
              ? this.selectednode!['id']
              : this.selectednode && this.selectedmenuid === 'changeitem'
              ? this.selectednode![this.allocation_idfield]
              : null,
        };

        this.SaveAllocation(payload);
      },
    },
  ];

  public SaveAllocation(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/${this.allocation_endpointadd}`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let obj: any = response;

        if (this.selectednode) {
          if (
            this.selectedmenuid === 'newgroup' ||
            this.selectedmenuid === 'newitem'
          ) {
            if (!this.selectednode[this.allocation_child]) {
              this.selectednode[this.allocation_child] = [];
            }
            this.selectednode[this.allocation_child].push(obj);

            // if (obj.isgroup === false) {
            //   switch (this.allocation) {
            //     case 'branch':
            //       this.jsonJobBranch.push(obj);
            //       break;

            //     case 'department':
            //       this.jsonJobDepartment.push(obj);
            //       break;

            //     case 'job':
            //       this.jsonJobReportTo.push(obj);
            //       break;

            //     case 'grade':
            //       this.jsonJobGrade.push(obj);
            //       break;

            //     case 'costcenter':
            //       break;

            //     case 'section':
            //       this.jsonJobSection.push(obj);
            //       break;

            //     case 'unit':
            //       this.jsonJobUnit.push(obj);
            //       break;

            //     case 'class':
            //       this.jsonJobClass.push(obj);
            //       break;

            //     case 'team':
            //       this.jsonJobTeam.push(obj);
            //       break;

            //     default:
            //       return;
            //       break;
            //   }
          } else if (this.selectedmenuid === 'changeitem') {
            let jsn: any = Common.FindParentJsonByIdKeyChildren(
              this.editDatasource,
              this.selectednode.id,
              'id',
              this.allocation_child
            );
            if (jsn) {
              jsn[this.allocation_child][
                jsn[this.allocation_child].findIndex(
                  (x: any) => x.id === this.selectednode.id
                )
              ] = obj;
            } else {
              let jsn: any = Common.FindJsonByIdKeyChildren(
                this.editDatasource,
                this.selectednode.id,
                'id',
                this.allocation_child
              );
              if (jsn) {
                let a: any[] = this.editDatasource as [];
                a[a.findIndex((x: any) => x.id === jsn.id)] = obj;
              }
              // if (obj.isgroup === false) {
              //   let fltr: any = null;
              //   switch (this.allocation) {
              //     case 'branch':
              //       fltr = this.jsonJobBranch.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobBranch[this.jsonJobBranch.indexOf(fltr[0])] =
              //           obj;
              //         bfieldsjob.dataSource = this.jsonJobBranch;
              //       }
              //       break;
              //     case 'department':
              //       fltr = this.jsonJobDepartment.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobDepartment[
              //           this.jsonJobDepartment.indexOf(fltr[0])
              //         ] = obj;
              //         dfieldsjob.dataSource = this.jsonJobDepartment;
              //       }
              //       break;
              //     case 'job':
              //       fltr = this.jsonJobReportTo.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobReportTo[
              //           this.jsonJobReportTo.indexOf(fltr[0])
              //         ] = obj;
              //         jfreportto.dataSource = this.jsonJobReportTo;
              //       }
              //       break;
              //     case 'grade':
              //       fltr = this.jsonJobGrade.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobGrade[this.jsonJobGrade.indexOf(fltr[0])] =
              //           obj;
              //         gfieldsjob.dataSource = this.jsonJobGrade;
              //       }
              //       break;
              //     case 'costcenter':
              //       break;
              //     case 'section':
              //       fltr = this.jsonJobSection.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobSection[
              //           this.jsonJobSection.indexOf(fltr[0])
              //         ] = obj;
              //         sfieldsjob.dataSource = this.jsonJobSection;
              //       }
              //       break;
              //     case 'unit':
              //       fltr = this.jsonJobUnit.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobUnit[this.jsonJobUnit.indexOf(fltr[0])] = obj;
              //         ufieldsjob.dataSource = this.jsonJobUnit;
              //       }
              //       break;
              //     case 'class':
              //       fltr = this.jsonJobClass.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobClass[this.jsonJobClass.indexOf(fltr[0])] =
              //           obj;
              //         cfieldsjob.dataSource = this.jsonJobClass;
              //       }
              //       break;
              //     case 'team':
              //       fltr = this.jsonJobTeam.filter(
              //         (x: any) => x.id === this.selectednode.id
              //       );
              //       if (fltr.length > 0) {
              //         this.jsonJobTeam[this.jsonJobTeam.indexOf(fltr[0])] = obj;
              //         tfieldsjob.dataSource = this.jsonJobTeam;
              //       }
              //       break;
              //     default:
              //       return;
              //       break;
              //   }
              // }
            }
            this.grdAllocation.dataSource = this.editDatasource;
            this.grdAllocation.selectRow(this.editRowIdx);
          }
          this.setState({
            showDialog: false,
          });
        } else {
          let a: any[] = this.grdAllocation.dataSource as [];
          a.push(obj);

          // if (obj.isgroup === false) {
          //   switch (this.allocation) {
          //     case 'branch':
          //       this.jsonJobBranch.push(obj);
          //       bfieldsjob.dataSource = this.jsonJobBranch;
          //       break;

          //     case 'department':
          //       this.jsonJobDepartment.push(obj);
          //       dfieldsjob.dataSource = this.jsonJobDepartment;
          //       break;

          //     case 'job':
          //       this.jsonJobReportTo.push(obj);
          //       jfreportto.dataSource = this.jsonJobReportTo;
          //       break;

          //     case 'grade':
          //       this.jsonJobGrade.push(obj);
          //       gfieldsjob.dataSource = this.jsonJobGrade;
          //       break;

          //     case 'costcenter':
          //       break;

          //     case 'section':
          //       this.jsonJobSection.push(obj);
          //       sfieldsjob.dataSource = this.jsonJobSection;
          //       break;

          //     case 'unit':
          //       this.jsonJobUnit.push(obj);
          //       ufieldsjob.dataSource = this.jsonJobUnit;
          //       break;

          //     case 'class':
          //       this.jsonJobClass.push(obj);
          //       cfieldsjob.dataSource = this.jsonJobClass;
          //       break;

          //     case 'team':
          //       this.jsonJobTeam.push(obj);
          //       tfieldsjob.dataSource = this.jsonJobTeam;
          //       break;

          //     default:
          //       return;
          //       break;
          //   }
          // }

          this.setState({
            showDialog: false,
          });
        }

        // this.treeObj.ensureVisible(obj.id);
        // if (obj.isgroup === true) {
        //   this.treeObj.expandAll([obj.id]);
        // }
        this.selectednode = obj;
        let selIdx: any = this.grdAllocation.selectedRowIndex;

        this.grdAllocation.refresh();
        if (selIdx < 0) {
          selIdx = this.grdAllocation.getRows().length;
        }
        setTimeout(() => {
          this.grdAllocation.selectRow(selIdx);
          if (selIdx >= 0) {
            this.selectednode =
              this.grdAllocation.getSelectedRecords()[0].taskData;
          } else {
            this.selectednode = null;
          }
        }, 1000);

        return response;
      })
      .catch((error: any) => {
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        //  DialogConfirm.showDialog({
          //  content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
        //  });
        // }
      })
      .finally(() => {});
  }

  handleLabelDblClick = (e: any) => {
    if (e.target.id.toString().includes('_orgnl') === false) {
      return;
    }

    let nonlblelmnt: any = document.getElementById(
      e.target.id.toString().replace('_orgnl', '')
    );
    if (nonlblelmnt) {
      document.getElementById(e.target.id)!.classList.add('d-none');
      nonlblelmnt.parentElement.parentElement.classList.remove('d-none');
      this.dblclickedctrlid = nonlblelmnt.id;

      if (e.target.attributes['itemtype'].value == 'dropdowntree') {
        let obj: any = document.getElementById(nonlblelmnt.id) as Element;
        if (obj !== null) {
          let ddtree: DropDownTreeComponent = obj
            .ej2_instances[0] as DropDownTreeComponent;
          if (ddtree) {
            ddtree.showPopup();
            if (ddtree.value && ddtree.value.length > 0) {
              ddtree.ensureVisible(ddtree.value[0]);
            }
          }
        }
      } else if (e.target.attributes['itemtype'].value == 'dropdownlist') {
        let obj: any = document.getElementById(nonlblelmnt.id) as Element;
        if (obj !== null) {
          let ddlist: DropDownListComponent = obj
            .ej2_instances[0] as DropDownListComponent;
          if (ddlist) {
            ddlist.showPopup();
          }
        }
      } else if (e.target.attributes['itemtype'].value == 'datepicker') {
        let obj: any = document.getElementById(nonlblelmnt.id) as Element;
        if (obj !== null) {
          let dtpicker: DatePickerComponent = obj
            .ej2_instances[0] as DatePickerComponent;
          if (dtpicker) {
            dtpicker.show();
          }
        }
      }
    }
  };

  render() {
    let cnfDlg: Dialog;

    let menuItems: MenuItemModel[] = [
      { id: 'newgroup', text: `Add New ${this.allocation_header} Group` },
      { id: 'newitem', text: `Add New ${this.allocation_header}` },
      { id: 'newseparator', text: ' ', separator: true },
      {
        id: 'changeitem',
        text: `Change ${this.allocation_header} Group/${this.allocation_header}`,
      },
      { id: 'changeseparator', text: '  ', separator: true },
      { id: 'delete', text: 'Delete' },
    ];

    const confirmYesClick = () => {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/${this.allocation_endpointdelete}`,
        { id: this.selectednode.id },
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          // let jsn: any = Common.FindParentJsonByIdKeyChildren(
          //   this.grdAllocation.dataSource,
          //   this.selectednode.id,
          //   'id',
          //   this.allocation_child
          // );
          // if (jsn) {
          //   jsn[this.treeObj.fields.child].splice(
          //     jsn[this.treeObj.fields.child].indexOf(this.selectednode),
          //     1
          //   );
          // } else {
          //   let jsn: any = Common.FindJsonByIdKeyChildren(
          //     this.treeObj.fields!['dataSource'],
          //     this.selectednode.id,
          //     'id',
          //     this.treeObj.fields.child
          //   );
          //   if (jsn) {
          //     let a: any[] = this.treeObj.fields!['dataSource'] as [];
          //     a.splice(a.indexOf(jsn), 1);
          //   }
          // }
          //cnfDlg.hide();
          // this.setState({ delkey: this.selectednode.id });
          // clearParentclick(null);
          // setTimeout(() => {
          //   this.treeObj.expandAll();
          // }, 100);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const menuclick = (e: any) => {
      this.selectedmenuid = e.item.id;

      if (this.selectedmenuid === 'newgroup') {
        this.isgroupselected = true;

        this.editingnode['alloccode'] = '';
        this.editingnode['allocname'] = '';
        this.editingnode['alloccustomcode'] = '';
        this.editingnode['allocdesc'] = '';
        this.editingnode['addnew'] = true;
        this.editingnode['isgroup'] = this.isgroupselected;

        this.setState({
          showDialog: true,
          addnew: true,
        });
      } else if (this.selectedmenuid === 'newitem') {
        this.isgroupselected = false;

        this.editingnode['alloccode'] = '';
        this.editingnode['allocname'] = '';
        this.editingnode['alloccustomcode'] = '';
        this.editingnode['allocdesc'] = '';
        this.editingnode['addnew'] = true;
        this.editingnode['isgroup'] = this.isgroupselected;

        this.setState({
          showDialog: true,
          addnew: true,
        });
      } else if (this.selectedmenuid === 'changeitem' && this.selectednode) {
        this.editingnode['alloccode'] = this.allocation_hasCode
          ? this.selectednode.code
          : '';
        this.editingnode['allocname'] = this.selectednode.name;
        this.editingnode['alloccustomcode'] = this.selectednode.customCode;
        this.editingnode['allocdesc'] = '';
        this.editingnode['addnew'] = false;
        this.editingnode['isgroup'] = this.selectednode.isgroup;
        this.editingnode['reportToJob'] = this.selectednode;

        this.setState({
          showDialog: true,
          addnew: false,
        });
      } else if (this.selectedmenuid === 'delete') {
           DialogConfirm.showDialog({
             isConfirm: true,
             content: ` <p class="dialog-contain">Do you want to delete the ${
               this.allocation_header
             } ${this.selectednode.isgroup === true ? 'group' : ''} ${
               this.selectednode.name
             }? </p>`,
             okCaption: 'Yes',
             closeCaption: 'No',
             // dialogcssClass: 'deleteallocation',        //   animationSettings: { effect: 'SlideTop' },
             OkEvent: confirmYesClick,
          showCloseIcon: true,
          closeOnEscape: true,
        });

        return;
      }
    };

    const toolbarClick = (e: any) => {
      if (e.item.id === `allocationlist-treegrid-${this.allocation_id}_add`) {
        this.selectedmenuid = 'newitem';
        this.selectednode = this.grdAllocation.getSelectedRecords()[0];
        this.isgroupselected = false;

        this.editingnode['alloccode'] = '';
        this.editingnode['allocname'] = '';
        this.editingnode['alloccustomcode'] = '';
        this.editingnode['allocdesc'] = '';
        this.editingnode['addnew'] = true;
        this.editingnode['isgroup'] = this.isgroupselected;

        this.setState({
          showDialog: true,
          addnew: true,
        });
      } else if (
        e.item.id === `allocationlist-treegrid-${this.allocation_id}_addgrp`
      ) {
        this.selectedmenuid = 'newgroup';
        this.selectednode = this.grdAllocation.getSelectedRecords()[0];
        this.isgroupselected = true;

        this.editingnode['alloccode'] = '';
        this.editingnode['allocname'] = '';
        this.editingnode['alloccustomcode'] = '';
        this.editingnode['allocdesc'] = '';
        this.editingnode['addnew'] = true;
        this.editingnode['isgroup'] = this.isgroupselected;

        this.setState({
          showDialog: true,
          addnew: true,
        });
      } else if (
        e.item.id === `allocationlist-treegrid-${this.allocation_id}_clear`
      ) {
        this.grdAllocation.clearSelection();
        this.selectedmenuid = '';
        this.selectednode = null;
        resetToolbar();
      }
    };

    const rowSelected = (e: any) => {
      if (!e.data) return;

      if (e.data.isgroup === false) {
        this.grdAllocation.toolbarModule.enableItems(
          [
            `allocationlist-treegrid-${this.allocation_id}_add`,
            `allocationlist-treegrid-${this.allocation_id}_addgrp`,
          ],
          false
        );
      } else {
        resetToolbar();
      }

      if (this.allocation_hasReportingTo) {
        // setTimeout(() => {
        let f1: any = Common.FindJsonByIdKeyChildren(
          (this.state.jfieldsreportto as any).dataSource,
          e.data.id,
          'id',
          this.allocation_child
        );

        if (f1) {
          this.selectednode = f1;
          this.isnodeselected = true;
          this.setState({ selectednode: f1 });
          // setTimeout(() => {
          //   this.keepNodeSelected();
          // }, 500);
        }
        //  }, 0);
      }
    };

    const resetToolbar = () => {
      this.grdAllocation.toolbarModule.enableItems(
        [
          `allocationlist-treegrid-${this.allocation_id}_add`,
          `allocationlist-treegrid-${this.allocation_id}_addgrp`,
        ],
        true
      );
    };

    const actionBegin = (e: any) => {
      if (e.requestType === 'beginEdit') {
        this.selectedmenuid = 'changeitem';
        this.selectednode = e.rowData.taskData;
        this.editRowIdx = e.rowIndex;

        this.editDatasource = JSON.parse(
          JSON.stringify(this.grdAllocation.dataSource)
        );
      } else if (e.requestType === 'save' && e.action === 'edit') {
        let payload: any = null;
        payload = {
          id: e.data.id,
          code: this.allocation_hasCode ? e.data.code : null,
          name: e.data.name,
          isgroup: e.data.isgroup,
          customCode: this.allocation_hasCustomCode ? e.data.customcode : null,
          [this.allocation_idfield]: e.data[this.allocation_idfield],
        };

        this.SaveAllocation(payload);
      } else if (e.requestType === 'delete') {
        this.selectednode = e.data[0].taskData;
        confirmYesClick();
      }
    };

    const actionComplete = (e: any) => {};

    return (
      <>
        <div id='divAllocationList' className='py-2'>
          <label
            className='pb-1'
            style={{ fontSize: '20px', fontWeight: '500' }}
          >
            {this.allocation_header}
          </label>
          <div className='control-pane'>
            <div className='control-section'>
              <div className='control_wrapper'>
                <div
                  id={`allocationlisttree-${this.props.jsonDataViewer.id}`}
                  className='allocationlisttree'
                >
                  <div className='control-pane'>
                    <div className='control-section'>
                      <TreeGridComponent
                        id='allocationlist-treegrid'
                        className={`allocationlist-treegrid ${
                          this.props.underTabs ? 'undertabs' : ''
                        }`}
                        height={`calc(100vh - ${
                          this.props.underTabs ? '337' : '283'
                        }px`}
                        dataSource={this.state.jsonAllocation}
                        treeColumnIndex={0}
                        childMapping={this.allocation_child}
                        editSettings={this.editSettings}
                        //enablePersistence={true}
                        toolbar={[
                          {
                            text: '',
                            tooltipText: 'Add',
                            prefixIcon: 'e-add font-22',
                            id: `allocationlist-treegrid-${this.allocation_id}_add`,
                          },
                          {
                            text: '',
                            tooltipText: 'Add Group',
                            prefixIcon: 'e-add-above font-22',
                            id: `allocationlist-treegrid-${this.allocation_id}_addgrp`,
                          },
                          {
                            text: '',
                            tooltipText: 'Edit',
                            prefixIcon: 'e-edit font-22',
                            id: `allocationlist-treegrid_gridcontrol_edit`,
                          },
                          {
                            text: '',
                            tooltipText: 'Delete',
                            prefixIcon: 'e-delete font-22',
                            id: `allocationlist-treegrid_gridcontrol_delete`,
                          },
                          //...this.toolbarOptions,
                          {
                            text: '',
                            tooltipText: 'Clear Selection',
                            prefixIcon: 'e-error-treeview font-22',
                            id: `allocationlist-treegrid-${this.allocation_id}_clear`,
                          },
                        ]}
                        // toolbar={this.toolbarOptions}
                        toolbarClick={toolbarClick}
                        ref={(obj: any) => (this.grdAllocation = obj)}
                        dataBound={(e: any) => {
                          if (this.grdAllocation) {
                            this.grdAllocation.toolbarModule.overflowMode =
                              'MultiRow';
                          }
                        }}
                        rowSelected={rowSelected}
                        actionBegin={actionBegin}
                        actionComplete={actionComplete}
                      >
                        <ColumnsDirective>
                          <ColumnDirective
                            field='name'
                            headerText='Name'
                            textAlign='Left'
                            allowEditing={true}
                            validationRules={{ required: true }}
                          />

                          {this.allocation_hasCode && (
                            <ColumnDirective
                              field='code'
                              headerText='Code'
                              textAlign='Left'
                              allowEditing={true}
                              validationRules={{ required: true }}
                              width={'30%'}
                            ></ColumnDirective>
                          )}
                        </ColumnsDirective>
                        <Inject services={[Filter, Edit, Toolbar, RowDD]} />
                      </TreeGridComponent>
                      <SelectorSearch grid_id='allocationlist-treegrid' />
                    </div>
                  </div>
                </div>

                <div>
                  {this.props.jsonDataViewer && this.state.selectednode && (
                    <AllocationDetail
                      id={`allocationlistdetail-${
                        this.props.jsonDataViewer.id
                      }-${this.state.selectednode!['id']}`}
                      selectorData={this.props.jsonDataViewer}
                      allocationtData={this.state.selectednode}
                      jfieldsreportto={this.state.jfieldsreportto}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-allocationchange'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'25vw'}
            close={() => {
              this.setState({ showDialog: false });
            }}
            content={() => (
              <Allocation
                jsonDataViewer={this.props.jsonDataViewer}
                editingnode={this.editingnode}
                ref={this.child}
              />
            )}
            isModal={true}
            header={`${this.state.addnew === true ? 'Add' : 'Change'} ${
              this.allocation_header
            } ${
              this.editingnode && this.editingnode.isgroup === true
                ? 'Group'
                : ''
            }`}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
