// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import PeriodSelector from '../PeriodSelector/PeriodSelector';
import {
  ColumnsDirective,
  ColumnDirective,
  VirtualScroll,
  Toolbar,
  RowDD,
  EditSettingsModel,
} from '@syncfusion/ej2-react-grids';
import Common from '../../Util/Common';
import { indexFinder } from '@syncfusion/ej2-react-charts';
import DataGrid from '../DataGrid';
import { Grid } from 'react-ionicons';
import {
  DialogEditEventArgs,
  Edit,
  GridComponent,
  Inject,
} from '@syncfusion/ej2-react-grids';
import '../PayHeads/EmployeePayHeads.css';
import {
  ButtonPropsModel,
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  MenuItemModel,
  ContextMenuComponent,
  ClickEventArgs,
} from '@syncfusion/ej2-react-navigations';
import { emppayhead_contextmenu } from '../../dummydata/xml_data_employee';
import SelectorSearch from '../Layout/SelectorSearch';
import CreatePeriod from '../PeriodSelector/CreatePeriod';
import PayHeads from './PayHeads';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  IProgressValueEventArgs,
  ProgressBarComponent,
} from '@syncfusion/ej2-react-progressbar';
import * as signalR from '@microsoft/signalr';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import DialogConfirm from '../Dialogbox/DialogConfirm';
// * ------------------------------------------------------------------------------------------------------------------3.Style

interface Props {
  selectorData: any;
}
/*
UNCOMMENT IF XML2JS ARE REQUIRED
*/
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const {
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
  REACT_APP_ENDPOINT_API,
} = process.env;

export default class EmployeePayHeads extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private child: CreatePeriod | any;
  private buttons: ButtonPropsModel[];
  public connection: any;
  private DefaultHeadCalcType: any = [];
  private DefaultTypeOf: any = [];
  private grid: GridComponent | null;
  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.buttons = [{}];
    this.grid = null;
    // var _result: any;

    // parser.parseString(
    //   emppayhead_contextmenu,
    //   function (err: any, result: any) {
    //     _result = JSON.parse(JSON.stringify(result));
    //   }
    // );
    // this.cntxitems = Common.GetContextMenuFromXML(
    //   _result.ContextMenu.Item
    // ) as MenuItemModel[];

    this.child = React.createRef();
    this.DefaultHeadCalcType.push(
      this._structure.CalcType.NetPay.id,
      this._structure.CalcType.TotalEarning.id,
      this._structure.CalcType.TotalDeduction.id,
      this._structure.CalcType.Taxable_Earning_Total.id,
      this._structure.CalcType.Employer_Contribution_Payable.id,
      this._structure.CalcType.Rounding_Adjustment.id,
      this._structure.CalcType.Non_Taxable_Earning_Total.id,
      this._structure.CalcType.Non_Cash_Benefit_Total.id,
      this._structure.CalcType.Net_Pay_Rounding_Adjustment.id,
      this._structure.CalcType.Total_Taxable_Income_Reducer.id,
      this._structure.CalcType.Total_Tax_Relief.id
    );
    this.DefaultTypeOf.push(this._structure.TypeOf.Informational.id);
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    payheadlist: undefined,
    periodid: '',
    pstartdate: undefined,
    penddate: undefined,
    addingperiod: false,
    periodselectorkey: '',
    deldata: undefined,
    selecteddate: new Date(),
    showProgress: false,
    progress: 0,
    totalcount: 0,
    totalpct: 0,
    openperiod: undefined,
    allassignedhead: [],
    minDate: new Date(),
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {
    // if (this.state.payheadlist == undefined) {
    //   this.FetchAssignedHeads(new Date())
    // }

    if (this.state.openperiod == undefined) {
      this.getopenperiod();
    }

    let port = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}`.split('/')[0];
    let url = `${REACT_APP_ENDPOINT_API}${port}/progressHub?userId=${this.user.id}`;
    console.log(url);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(() => {
        console.log('Connected to SignalR hub');
        // this.connection.invoke('RequestProgress', this.user.id, 0, 0)
        //   .catch((err: any) => console.error('Error invoking RequestProgress:', err));
      })
      .catch((err: any) =>
        console.error('Error connecting to SignalR hub:', err)
      );

    // Handle the connection ID sent from the server
    this.connection.on('ReceiveConnectionId', (connectionId: any) => {
      console.log('Received connection ID:', connectionId);
      this.setState({ connectionId });
    });

    this.connection.on('ReceiveProgress', (cnt: any, totalcnt: any) => {
      console.log('Received progress update:', cnt, totalcnt);
      const pct = (cnt / totalcnt) * 100;
      this.setState({ progress: cnt, totalcount: totalcnt, totalpct: pct });
    });
  }

  componentWillUnmount() {
    if (this.connection) {
      this.connection.stop();
    }
  }

  handleChange = (e: any) => {
    if (e.id !== Common.blankguid) {
      this.FetchAssignedHeads(e.id, e.startDate, e.endDate);
      this.setState({ pstartdate: e.startDate, penddate: e.endDate });
    } else {
      this.setState({ addingperiod: true });
    }
  };

  handleCheckChanged = (e: any) => {
    if (e == false) {
      this.FetchAssignedHeads(undefined, undefined, undefined);
    } else if (e == true) {
      this.FetchAssignedHeads(
        undefined,
        this.state.pstartdate,
        this.state.penddate
      );
    }
  };

  handleCancelbtn = (e: any) => {
    this.setState({ addingperiod: false });
  };

  handleAcceptbtn = (data: any) => {
    localStorage.setItem('lastSelectedPeriod', JSON.stringify(data));
    this.setState({ addingperiod: false, periodselectorkey: Math.random() });
    this.FetchAssignedHeads(data.id, data.startDate, data.endDate);
  };
  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // hndlAsOnDateChange = (e: any) => {
  //   let _date = new Date(e.value);
  //   this.FetchAssignedHeads(_date)
  // }

  private getopenperiod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/firstperiod`,
      {
        statusId: Number(this._structure.PeriodStatusId.Open),
        lastPeriodOnNoOpenPeriod: false,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (response) {
          var dt = new Date(response.startDate);

          this.setState({
            openperiod: response,
            minDate: new Date(dt.setDate(dt.getDate() - 1)),
          });
        } else {
          this.getLastClosedPeriod();
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private getLastClosedPeriod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/lastperiod`,
      {
        statusId: Number(this._structure.PeriodStatusId.Close),
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          minDate: new Date(response.endDate),
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private FetchAssignedHeads(pid: any, pst: any, ped: any) {
    // if (asondate == undefined) {
    //   asondate = new Date();
    // }
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/listempheads`,
      {
        startDate:
          pst !== undefined && pst !== null
            ? Common.formatDate(new Date(pst), 'yyyy-MM-dd')
            : null,
        endDate:
          ped !== undefined && ped !== null
            ? Common.formatDate(new Date(ped), 'yyyy-MM-dd')
            : null,
        employeeId: this.props.selectorData.id,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        var filtered = response.filter(
          (x: any) => !this.DefaultHeadCalcType.includes(x.calcTypeId)
        );

        // filtered = filtered.filter((x: any) => !this.DefaultTypeOf.includes(x.typeOfId))
        // this._structure.HeadType.Informational.id
        // this._structure.TypeOf.Informational.id
        // this._structure.CalcType.ComputedValue.id

        let extraheads = filtered.filter(
          (x: any) =>
            x.headTypeId == this._structure.HeadType.Informational.id &&
            x.typeOfId == this._structure.TypeOf.Informational.id &&
            x.calcTypeId == this._structure.CalcType.ComputedValue.id &&
            x.payHead.isDefault === true
        );

        filtered = filtered.filter((x: any) => !extraheads.includes(x));

        this.setState({
          payheadlist: filtered,
          allassignedhead: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  groupOptions: any = {
    columns: ['headTypeName'],
    captionTemplate: '<div className="grouphead" >${key}</div>',
    showDropArea: false,
  };

  private actionComplete = (args: DialogEditEventArgs): void => {
    const dialog: Dialog = args.dialog as Dialog;
    // if (
    //   args.requestType === 'beginEdit' &&
    //   (args as any).rowData.calcTypeId !==
    //   this._structure.CalcType.FlatRateOthers.id
    // ) {

    // DialogConfirm.showDialog({
    //   content:
    //     '<p class="dialog-contain">Sorry, you can only edit the head which is of having claculation type is flat rate.</p>',
    // });
    //   dialog.hide();
    //   return;
    // } else {
    if (args.requestType === 'add') {
      dialog.header = 'Assign New Head';
      this.SetDialogCSS(dialog);
    } else if (args.requestType === 'beginEdit') {
      dialog.header = 'Edit Pay Head';
      this.SetDialogCSS(dialog);
    } else if (args.requestType === 'delete') {
    }
    //}
  };

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  private cellSave(e: any) {
    if (e.requestType === 'save') {
      if (e.data.id !== undefined) {
        if (e.data.amount == null) {
          e.requestType = 'add';
          e.cancel = true;
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Please enter amount to continue.</p>',
          });
          return;
        } else {
          if (isNaN(e.data.amount)) {
            e.requestType = 'add';
            e.cancel = true;
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Please enter valid amount to continue.</p>',
            });
            return;
          }
        }

        if (e.data.startDate == undefined || e.data.startDate == null) {
          e.requestType = 'add';
          e.cancel = true;
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Please set start date to continue.</p>',
          });
          return;
        }

        if (e.data.endDate !== undefined && e.data.endDate !== null) {
          if (
            new Date(e.data.endDate).getTime() <=
            new Date(e.data.startDate).getTime()
          ) {
            e.requestType = 'add';
            e.cancel = true;

            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, end date cannot be less or equal to start date.</p>',
            });
            return;
          }
        }

        //if (this.state.openperiod !== undefined) {
        if (e.data.endDate !== undefined && e.data.endDate !== null) {
          if (
            // new Date(e.data.endDate).getTime() <=
            // new Date((this.state.openperiod as any).startDate).getTime()
            Common.getDate(new Date(e.data.endDate)) <
            Common.getDate(new Date(this.state.minDate))
          ) {
            e.requestType = 'add';
            e.cancel = true;

            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, end date cannot be in between the closed period.</p>',
            });
            return;
          }
        }

        if (
          (e.data.amount !== e.previousData.amount ||
            Common.getDate(new Date(e.data.endDate)) ===
              Common.getDate(new Date(e.previousData.endDate))) &&
          Common.getDate(new Date(e.data.startDate)) <=
            Common.getDate(new Date(this.state.minDate))
        ) {
          e.requestType = 'add';
          e.cancel = true;

          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, start date cannot be in between the closed period.<BR><BR>In order to change amount</p> <p class="dialog-contain">Please change start date to any date of open period.</p>',
          });
          return;
        }
        //}

        if (this.state.payheadlist !== undefined) {
          let ihead = (this.state.payheadlist as any).filter(
            (x: any) => x.id == e.data.id
          );
          if (ihead !== undefined && ihead.length > 0) {
            ihead.sort((a: any, b: any) => {
              return (
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
              );
            });

            // if (ihead[0].endDate == null) {
            //   e.requestType = 'add';
            //   e.cancel = true;
            // DialogConfirm.showDialog({
            //   content: '<p class="dialog-contain">Sorry, end date cannot be less or equal to start date.</p>',
            // });
            //   return;
            // }

            if (ihead[0].endDate !== null) {
              if (
                new Date(ihead[0].endDate).getTime() <=
                new Date(e.data.startDate).getTime()
              ) {
                e.requestType = 'add';
                e.cancel = true;

                DialogConfirm.showDialog({
                  content:
                    '<p class="dialog-contain">Sorry, end date cannot be less or equal to start date.</p>',
                });
                return;
              }
            }
          }
        }

        let id: string | any = e.data.id;
        if (
          id &&
          Common.getDate(new Date(e.previousData.startDate)) !==
            Common.getDate(new Date(e.data.startDate)) &&
          Common.getDate(new Date(e.previousData.startDate)) <=
            Common.getDate(new Date(this.state.minDate))
        ) {
          id = null;
        }

        // let ihead = (this.state.allassignedhead as any).filter((x: any) => x.id == e.data.id)[0];

        // if ((new Date(ihead.startDate).getTime() !== new Date(e.data.startDate).getTime())) {
        //   e.requestType = 'add';
        //   e.cancel = true;

        //  DialogConfirm.showDialog({
        //    content:  '<p class="dialog-contain">Sorry, You cannot edit and change the selected head start date. If you wish to change the start date of this assigned head you need to first set the stop date and save. \n And you need to assign new head again with the selected start date.</p>'

        //  });
        //   return;
        // }

        Common.ShowSpinner();

        let payload: any = {
          id: id,
          amount: e.data.amount ? e.data.amount : 0,
          startDate: Common.formatDate(e.data.startDate, 'yyyy-MM-dd'),
          endDate:
            e.data.endDate !== null
              ? Common.formatDate(e.data.endDate, 'yyyy-MM-dd')
              : null,
          employeeId: this.props.selectorData.id,
        };
        if (id === null) {
          payload['payHeadId'] = e.data.payHeadId;
          payload['headTypeId'] = e.data.headTypeId;
          payload['typeOfId'] = e.data.typeOfId;
          payload['calcTypeId'] = e.data.calcTypeId;
          payload['formula'] = e.data.formula ?? '';
        }
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/update`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            let data = this.state.payheadlist;
            // if (data) { (data as any).push(response) }
            // data = (data as any).filter((x: any) => x.id !== undefined)
            this.FetchAssignedHeads(
              null,
              this.state.pstartdate,
              this.state.penddate
            );
            this.setState({ payheadlist: data });
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {
            Common.HideSpinner();
          });
      } else {
        if (e.data !== undefined) {
          if (
            e.data.payheadid == undefined ||
            e.data.payheadid == Common.blankguid
          ) {
            e.requestType = 'add';
            e.cancel = true;
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Please select pay head to continue.</p>',
            });
            return;
          }

          if (e.data.startDate == undefined || e.data.startDate == null) {
            e.requestType = 'add';
            e.cancel = true;
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Please set start date to continue.</p>',
            });
            return;
          }

          if (e.data.endDate !== undefined && e.data.endDate !== null) {
            if (
              new Date(e.data.endDate).getTime() <=
              new Date(e.data.startDate).getTime()
            ) {
              e.requestType = 'add';
              e.cancel = true;

              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, end date cannot be less or equal to start date.</p>',
              });
              return;
            }
          }

          if (this.state.openperiod !== undefined) {
            if (e.data.endDate !== undefined && e.data.endDate !== null) {
              if (
                new Date(e.data.endDate).getTime() <=
                new Date((this.state.openperiod as any).startDate).getTime()
              ) {
                e.requestType = 'add';
                e.cancel = true;

                DialogConfirm.showDialog({
                  content:
                    '<p class="dialog-contain">Sorry, end date cannot be in between the closed period.</p>',
                });
                return;
              }
            }

            if (
              new Date(e.data.startDate).getTime() <
              new Date((this.state.openperiod as any).startDate).getTime()
            ) {
              e.requestType = 'add';
              e.cancel = true;

              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, start date cannot be in between the closed period.</p>',
              });
              return;
            }
          }

          // if (
          //   (this.state.payheadlist as any).filter(
          //     (x: any) =>
          //       x.payHeadId == e.data.payheadid &&
          //       x.periodId == this.state.periodid
          //   ).length > 0
          // ) {
          //   e.requestType = 'add';
          //   e.cancel = true;

          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry, this head is already assigned to the selected employee and period.</p>',
          // });
          //   return;
          // }

          if (this.state.allassignedhead.length > 0) {
            let ihead = (this.state.allassignedhead as any).filter(
              (x: any) =>
                x.payHeadId == e.data.payheadid &&
                Common.getDate(new Date(x.startDate)) ===
                  Common.getDate(new Date(e.data.startDate))
            );
            if (ihead !== undefined && ihead.length > 0) {
              // ihead.sort((a: any, b: any) => {
              //   return (
              //     new Date(b.startDate).getTime() -
              //     new Date(a.startDate).getTime()
              //   );
              // });

              //if (ihead[0].endDate == null) {
              e.requestType = 'add';
              e.cancel = true;
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, You cannot assign the selected head as same head is already assigned and still active.</p>',
              });
              return;
              //}

              if (ihead[0].endDate !== null) {
                if (
                  new Date(ihead[0].endDate).getTime() <=
                  new Date(e.data.startDate).getTime()
                ) {
                  e.requestType = 'add';
                  e.cancel = true;
                  DialogConfirm.showDialog({
                    content:
                      '<p class="dialog-contain">Sorry, You cannot assign the selected head. Reason, End date is greater than start date set.</p>',
                  });
                  return;
                }
              }
            }
          }

          if (this.state.payheadlist !== undefined) {
            let ihead = (this.state.payheadlist as any).filter(
              (x: any) =>
                x.payHeadId == e.data.payheadid &&
                Common.getDate(new Date(x.startDate)) ===
                  Common.getDate(new Date(e.data.startDate))
            );
            if (ihead !== undefined && ihead.length > 0) {
              // ihead.sort((a: any, b: any) => {
              //   return (
              //     new Date(b.startDate).getTime() -
              //     new Date(a.startDate).getTime()
              //   );
              // });

              //if (ihead[0].endDate == null) {
              e.requestType = 'add';
              e.cancel = true;
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, You cannot assign the selected head as same head is already assigned and still active.</p>',
              });
              return;
              //W}

              if (ihead[0].endDate !== null) {
                if (
                  new Date(ihead[0].endDate).getTime() <=
                  new Date(e.data.startDate).getTime()
                ) {
                  e.requestType = 'add';
                  e.cancel = true;
                  DialogConfirm.showDialog({
                    content:
                      '<p class="dialog-contain">Sorry, You cannot assign the selected head. Reason, End date is greater than start date set.</p>',
                  });
                  return;
                }
              }
            }
          }

          if (
            Number(e.data.h_calctypeid) ==
            this._structure.CalcType.FlatRateOthers.id
          ) {
            if (e.data.amount == null) {
              e.requestType = 'add';
              e.cancel = true;
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Please enter amount to continue.</p>',
              });
              return;
            } else {
              if (isNaN(e.data.amount)) {
                e.requestType = 'add';
                e.cancel = true;
                DialogConfirm.showDialog({
                  content:
                    '<p class="dialog-contain">Please enter valid amount to continue.</p>',
                });
                return;
              }
            }
          }

          let ihead = (this.state.allassignedhead as any).filter(
            (x: any) => x.payHeadId == e.data.payheadid
          )[0];

          if (ihead && ihead.endDate == null) {
            e.requestType = 'add';
            e.cancel = true;
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, You cannot assign the head as this head is already assigned and active.</p>',
            });
            return;
          }

          Common.ShowSpinner();

          let payload: any = {
            employeeId: this.props.selectorData.id,
            payHeadId: e.data.payheadid,
            headTypeId: e.data.h_headtypeid,
            typeOfId: e.data.h_typeofid,
            calcTypeId: e.data.h_calctypeid,
            amount: e.data.amount ? e.data.amount : 0,
            formula: e.data.h_formula ? e.data.h_formula : '',
            startDate: Common.formatDate(e.data.startDate, 'yyyy-MM-dd'),
            endDate:
              e.data.endDate !== null
                ? Common.formatDate(e.data.endDate, 'yyyy-MM-dd')
                : null,
            //periodId: this.state.periodid,
          };
          Common.ApiCallAsync(
            'POST',
            `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/add`,
            payload,
            Common.getToken() || '',
            null,
            this.token.tenant
          )
            .then((response: any) => {
              return response.data;
            })
            .then((response: any) => {
              let data: any = this.state.payheadlist;
              let fltr: any = data.filter(
                (x: any) =>
                  x.employeeId == response.employeeId &&
                  x.payHeadId === response.payHeadId
              );
              if (data && fltr.length <= 0) {
                (data as any).push(response);
              } else if (fltr.length > 0) {
                data[
                  data.findIndex(
                    (x: any) =>
                      x.employeeId === fltr[0].employeeId &&
                      x.payHeadId === fltr[0].payHeadId
                  )
                ] = response;
              }

              data = (data as any).filter((x: any) => x.id !== undefined);
              this.FetchAssignedHeads(
                null,
                this.state.pstartdate,
                this.state.penddate
              );
              this.setState({ payheadlist: data });
            })
            .catch((error: any) => {
              console.error(error);
            })
            .finally(() => {
              Common.HideSpinner();
            });
        }
      }
    } else if (e.requestType === 'delete') {
      if (e.data !== undefined) {
        if (
          Common.getDate(new Date(e.data[0].startDate)) <
          Common.getDate(new Date(this.state.minDate))
        ) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, The Period for selected transaction is already closed.</p>',
          });
          e.cancel = true;
          return;
        }

        if (
          e.data[0].headTypeId ==
            this._structure.HeadType.EarningforEmployee.id &&
          e.data[0].typeOfId == this._structure.TypeOf.Salary.id &&
          e.data[0].calcTypeId == this._structure.CalcType.DefinedSalary.id
        ) {
          e.cancel = true;
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, you cannot delete this head as this head is as type of salary.</p>',
          });
          return;
        }
      }

      this.setState({ deldata: e.data[0] });
      e.data = [];
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove this assigned payhead?</p></div>',
        okCaption: 'Cancel',
        closeCaption: 'Accept',
        // btncssClass: 'flat-button',
        CloseEvent: () => {
          this.confirmAction.bind(this);
        },
      });
    } else if (e.requestType === 'beginEdit') {
      if (
        this.state.pstartdate &&
        Common.getDate(new Date(this.state.pstartdate!)) <
          Common.getDate(new Date(this.state.minDate))
      ) {
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Sorry, The Period selected on the top is already closed.</p>',
        });
        e.cancel = true;
        return;
      }
    }
  }

  confirmAction = () => {
    if (this.state.deldata !== undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/voidemphead`,
        { id: (this.state.deldata as any).id },
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            let data = this.state.payheadlist;
            data = (data as any).filter(
              (x: any) => x.id !== (this.state.deldata as any).id
            );
            this.setState({ payheadlist: data });
            this.FetchAssignedHeads(
              null,
              this.state.pstartdate,
              this.state.penddate
            );
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  onCheckboxChange(args: any): void {
    if (this.state.payheadlist !== undefined) {
      let ctrl: HTMLElement = args.event.target.parentElement;
      let ctrlid: any = ctrl.getElementsByTagName('input');
      if (!ctrlid) return;
      ctrlid = 'ck_' + ctrlid[0].id;

      let chk: any = (document.getElementsByClassName(ctrlid) as any)[0]
        .ej2_instances[0];
      // let value: string = chk.checked;

      if (chk.checked == false) {
        var filtered = this.state.allassignedhead.filter(
          (x: any) => !this.DefaultHeadCalcType.includes(x.calcTypeId)
        );

        let extraheads: any = filtered.filter(
          (x: any) =>
            x.headTypeId == this._structure.HeadType.Informational.id &&
            x.typeOfId == this._structure.TypeOf.Informational.id &&
            x.calcTypeId == this._structure.CalcType.ComputedValue.id &&
            x.payHead.isDefault === true
        );

        filtered = filtered.filter((x: any) => !extraheads.includes(x));

        this.setState({ payheadlist: filtered });
      } else {
        this.setState({ payheadlist: this.state.allassignedhead });
      }
    }
  }

  public extradialogbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.setState({
          addingperiod: false,
          periodselectorkey: Math.random(),
        });
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        //this.HeadAdd();
        this.child.current.SavePeriod().then((response: any) => {
          if (response) {
            localStorage.setItem(
              'lastSelectedPeriod',
              JSON.stringify(response)
            );
            this.setState({
              addingperiod: false,
              periodselectorkey: Math.random(),
            });
            this.FetchAssignedHeads(
              response.id,
              response.startDate,
              response.endDate
            );
          }
        });
      },
    },
  ];

  private showProgress() {
    return (
      <>
        <div>
          <ProgressBarComponent
            id='linear'
            type='Linear'
            showProgressValue={true}
            labelStyle={{ color: '#FFFFFF', fontWeight: 'bold' }}
            trackThickness={30}
            progressThickness={30}
            trackColor='transparent'
            progressCompleted={(args: IProgressValueEventArgs) => {
              args.value = 0;
              this.setState({
                totalcount: 0,
                showCloseDialog: false,
                totalpct: 0,
              });
            }}
            maximum={100}
            value={this.state.totalpct}
          ></ProgressBarComponent>
        </div>
      </>
    );
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    //let grid: GridComponent | null;

    const contextMenuClick = (e: any) => {
      if (this.state.payheadlist !== undefined) {
        if (e) {
          this.grid?.addRecord();
        }
      }
    };

    const dialogTemplate = (props: any) => {
      return (
        <>
          <DialogFormTemplate
            {...props}
            {...this.state}
            // periodid={this.state.periodid}
            // pstartdate={this.state.pstartdate}
            // penddate={this.state.penddate}
          />
        </>
      );
    };

    const editOptions: EditSettingsModel = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: true,
      template: dialogTemplate,
    };

    return (
      <>
        <div style={{ display: 'grid' }}>
          {/* <div className='row align-items-center'>
            <div className='col-2 py-2'>
              <label style={{ fontSize: '14px' }}>As On Date</label>
            </div>
            <div className='col-2 py-2'>
              <DatePickerComponent
                id='asondate'
                name='asondate'
                value={this.state.selecteddate}
                format={this.user.settings.formats.DateShort}
                showClearButton={false}
                //cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                onChange={this.hndlAsOnDateChange.bind(this)}
                //placeholder={this.user.settings.formats.DateShort}
                placeholder='Select Date'
                style={{ fontSize: '13px' }}
              />
            </div>
          </div> */}
          <div className='col-12' style={{ display: 'flex' }}>
            <div className='col-7'>
              <div className='PeriodSelector'>
                <PeriodSelector
                  key={this.state.periodselectorkey}
                  handleInputChange={this.handleChange}
                  id='attendance-selector'
                  _jObject={this.props.selectorData}
                  showEmployeePeriodsOnly={false}
                  showLastPeriod={true}
                  alttext={'No Period Defined.'}
                  showcheckbox={true}
                  checked={true}
                  handleCheckChange={this.handleCheckChanged}
                />
              </div>
            </div>
            <div className='col-4' style={{ marginTop: '10px' }}>
              <CheckBoxComponent
                id='chkshowinfohead'
                name='chkshowinfohead'
                className='ck_chkshowinfohead'
                label={'Show informational heads'}
                change={this.onCheckboxChange.bind(this)}
                cssClass='larger'
              />
            </div>
          </div>
          <div>
            <GridComponent
              id='grid-selector'
              dataSource={this.state.payheadlist}
              allowTextWrap={true}
              groupSettings={this.groupOptions}
              allowGrouping={true}
              showColumnChooser={false}
              actionBegin={this.cellSave.bind(this)}
              actionComplete={this.actionComplete.bind(this)}
              ref={(g) => (this.grid = g)}
              height='calc(100vh - 343px)'
              editSettings={editOptions}
              // contextMenuItems={this.cntxitems}
              // contextMenuClick={contextMenuClick}
              toolbar={this.toolbarOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field='payHead.code'
                  headerText='Code'
                  width='120'
                />
                <ColumnDirective
                  field='payHead.name'
                  headerText='Name'
                  width='290'
                />
                <ColumnDirective
                  field='typeOfName'
                  headerText='Type Of'
                  width='200'
                  visible={false}
                />
                <ColumnDirective
                  field='calcTypeName'
                  headerText='Calculation Type'
                  width='190'
                  visible={false}
                />
                <ColumnDirective
                  field='amount'
                  headerText='Amount'
                  width='170'
                  format={
                    this.user.settings.formats.Decimal
                      ? 'N' +
                        this.user.settings.formats.Decimal.split('.')[1].length
                      : 'N2'
                  }
                  textAlign='Right'
                />
                <ColumnDirective
                  field='formula'
                  headerText='Formula'
                  width='200'
                  visible={false}
                />
                <ColumnDirective
                  field='startDate'
                  headerText='Start Date'
                  format={this.user.settings.formats.DateShort}
                  type='datetime'
                  width='90'
                />
                <ColumnDirective
                  field='endDate'
                  headerText='Stop Date'
                  format={this.user.settings.formats.DateShort}
                  type='datetime'
                  width='90'
                />
                <ColumnDirective
                  field='headTypeName'
                  headerText='Head Type'
                  visible={false}
                />
                <ColumnDirective field='id' headerText='id' visible={false} />
                <ColumnDirective
                  field='payHead.id'
                  headerText='payheadid'
                  visible={false}
                />
                <ColumnDirective
                  field='headTypeId'
                  headerText='typeofid'
                  visible={false}
                />
                <ColumnDirective
                  field='typeOfId'
                  headerText='calctypeid'
                  visible={false}
                />
                <ColumnDirective
                  field='calcTypeId'
                  headerText='calctypeid'
                  visible={false}
                />
              </ColumnsDirective>
              <Inject services={[Toolbar, Edit]} />
            </GridComponent>
          </div>
          <SelectorSearch grid_id='grid-selector' />
        </div>
        <div>
          {this.state.addingperiod === true ? (
            <DialogComponent
              id='defaultDialog1'
              showCloseIcon={true}
              header={'Add New Period'}
              width={'30vw'}
              visible={this.state.addingperiod}
              close={() => this.setState({ addingperiod: false })}
              content={() => (
                <CreatePeriod
                  ref={this.child}
                  id={Math.random().toString()}
                  title=''
                  // handleAccept={this.handleAcceptbtn}
                  // handleCancel={this.handleCancelbtn}
                />
              )}
              isModal={true}
              buttons={this.extradialogbuttons}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
        {this.state.showProgress == true ? (
          <>
            <DialogComponent
              id='dlgProgress'
              showCloseIcon={false}
              visible={this.state.showProgress}
              width={'45vw'}
              height={'15vh'}
              content={this.showProgress.bind(this)}
              isModal={true}
              header={'Process Saving...'}
              statelessTemplates={[]}
              buttons={this.buttons}
            ></DialogComponent>
          </>
        ) : null}
      </>
    );
  }
}

export class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private headtype: any = [];
  private typeof: any = [];
  private calctype: any = [];
  private alltypeof: any = [];
  private allcalctype: any = [];
  private lstPayHead: DropDownListComponent | any;
  private lstHeadType: DropDownListComponent | any;
  private lstTypeOf: DropDownListComponent | any;
  private lstCalcType: DropDownListComponent | any;
  private allpayheads: any;
  private fitereddata: any;
  private iselcheadtypeid: number = 0;
  private iselctypeofid: number = 0;
  private iselccalctypeid: number = 0;
  private itemselect: any = {
    id: 0,
    text: 'Select',
  };
  private DefaultHeadCalcType: any = [];
  private PPACalcType: any = [];
  private NetPayRoundingAdjustmentCalcType: any = [];
  private LoanCalcType: any = [];

  private child: PayHeads | any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.fillCalcType(2);
    this.fillHeadType(0);
    this.fillTypeOf(0);
    this.state = Object.assign({}, props);
    this.DefaultHeadCalcType.push(
      this._structure.CalcType.NetPay.id,
      this._structure.CalcType.TotalEarning.id,
      this._structure.CalcType.TotalDeduction.id,
      this._structure.CalcType.Taxable_Earning_Total.id,
      this._structure.CalcType.Employer_Contribution_Payable.id,
      this._structure.CalcType.Rounding_Adjustment.id,
      this._structure.CalcType.Non_Taxable_Earning_Total.id,
      this._structure.CalcType.Non_Cash_Benefit_Total.id,
      this._structure.CalcType.Net_Pay_Rounding_Adjustment.id,
      this._structure.CalcType.Total_Taxable_Income_Reducer.id,
      this._structure.CalcType.Total_Tax_Relief.id
    );

    this.NetPayRoundingAdjustmentCalcType.push(
      this._structure.CalcType.Net_Pay_Rounding_Adjustment.id
    );

    this.child = React.createRef();
  }

  componentDidMount(): void {
    this.fillPayHeads();
  }

  onChange(args: any): void {
    let key: string = args.target.name;
    let value: string = args.target.value;
    this.setState({ [key]: value });
  }

  private fillHeadType(iheadtypeid: any) {
    let item: any;
    for (let i = 0; i < Object.entries(this._structure.HeadType).length; i++) {
      item = Object.entries(this._structure.HeadType)[i][1];
      if (item) {
        if (iheadtypeid > 0) {
          if (item.id == iheadtypeid) {
            this.headtype.push(item);
          }
        } else {
          this.headtype.push(item);
        }
      }
    }
    this.headtype.unshift(this.itemselect);
  }

  private fillTypeOf(itypeofid: any) {
    let item: any;
    for (let i = 0; i < Object.entries(this._structure.TypeOf).length; i++) {
      item = Object.entries(this._structure.TypeOf)[i][1];
      if (item) {
        if (itypeofid > 0) {
          if ((item.id = itypeofid)) {
            this.typeof.push(item);
          }
        } else {
          this.typeof.push(item);
        }
      }
    }
    this.typeof.unshift(this.itemselect);
    this.alltypeof = this.typeof;
  }

  private fillCalcType(icalctypeid: any) {
    let item: any;
    for (let i = 0; i < Object.entries(this._structure.CalcType).length; i++) {
      item = Object.entries(this._structure.CalcType)[i][1];
      if (item) {
        if (icalctypeid > 0) {
          if (item.OthId == icalctypeid) {
            this.calctype.push(item);
          }
        } else {
          this.calctype.push(item);
        }
      }
    }
    this.calctype.unshift(this.itemselect);
    this.allcalctype = this.calctype;
  }

  private filterpayhead() {
    if (this.fitereddata == undefined) {
      this.fitereddata = this.allpayheads;
    }

    if (this.iselcheadtypeid > 0) {
      this.fitereddata = this.allpayheads.filter(
        (x: any) =>
          x.headTypeId == this.iselcheadtypeid || x.id == Common.blankguid
      );
    } else {
      //this.fitereddata = this.allpayheads.filter((x: any) => x.headTypeId > this.iselcheadtypeid);
    }

    if (this.iselctypeofid > 0) {
      this.fitereddata = this.allpayheads.filter(
        (x: any) => x.typeOfId == this.iselctypeofid || x.id == Common.blankguid
      );
    } else {
      //this.fitereddata = this.allpayheads.filter((x: any) => x.typeOfId > this.iselctypeofid);
    }

    if (this.iselccalctypeid > 0) {
      this.fitereddata = this.allpayheads.filter(
        (x: any) =>
          x.calcTypeId == this.iselccalctypeid || x.id == Common.blankguid
      );
    } else {
      //this.fitereddata = this.allpayheads.filter((x: any) => x.calcTypeId > this.iselccalctypeid);
    }

    this.setState({ payheads: this.fitereddata });
  }

  private hdlpayheadchg(e: any) {
    if (e.itemData != null) {
      if (e.itemData.headTypeId == -99) {
        this.setState({ showDialog: true });
      } else {
        if (document.getElementById('h_headtypeid')) {
          (document.getElementById('h_headtypeid') as any).value =
            e.itemData.headTypeId;
        }
        if (document.getElementById('h_typeofid')) {
          (document.getElementById('h_typeofid') as any).value =
            e.itemData.typeOfId;
        }
        if (document.getElementById('h_calctypeid')) {
          (document.getElementById('h_calctypeid') as any).value =
            e.itemData.calcTypeId;
        }
        if (
          document.getElementById('h_formula') &&
          e.itemData.payHeadTran !== null &&
          e.itemData.payHeadTran.length > 0
        ) {
          (document.getElementById('h_formula') as any).value = e.itemData
            .payHeadTran[0].formula
            ? e.itemData.payHeadTran[0].formula
            : '';
        }
        if (
          document.getElementById('h_amount') //&& e.itemData.payHeadTran !== null && e.itemData.payHeadTran.length > 0
        ) {
          // (document.getElementById('h_amount') as any).value = e.itemData
          //   .payHeadTran[0].amount
          //   ? e.itemData.payHeadTran[0].amount
          //   : '';
          (document.getElementById('h_amount') as any).value =
            e.itemData.calcTypeId ==
              this._structure.CalcType.FlatRateOthers.id &&
            e.itemData.payHeadTran &&
            e.itemData.payHeadTran.length > 0 &&
            e.itemData.payHeadTran[0].amount
              ? e.itemData.payHeadTran[0].amount
              : '';
        }

        if (
          document.getElementById('amount') //&& e.itemData.payHeadTran !== null && e.itemData.payHeadTran.length > 0
        ) {
          (document.getElementById('amount') as any).ej2_instances[0].value =
            e.itemData.calcTypeId ==
              this._structure.CalcType.FlatRateOthers.id &&
            e.itemData.payHeadTran &&
            e.itemData.payHeadTran.length > 0 &&
            e.itemData.payHeadTran[0].amount
              ? e.itemData.payHeadTran[0].amount
              : '';
        }

        if (
          e.itemData.calcTypeId !== this._structure.CalcType.FlatRateOthers.id
        ) {
          if (document.getElementsByName('amount')) {
            (
              document.getElementById('amount') as any
            ).ej2_instances[0].enabled = false;
          }
        } else {
          if (document.getElementsByName('amount')) {
            (
              document.getElementById('amount') as any
            ).ej2_instances[0].enabled = true;
          }
        }

        this.setState({
          headtypeid: e.itemData.headTypeId,
          typeofid: e.itemData.typeOfId,
          calctypeid: e.itemData.calcTypeId,
        });
      }
    }
  }

  private hndlheadtypechg(e: any) {
    if (e.itemData != null) {
      if (e.itemData.id > 0) {
        this.iselcheadtypeid = e.itemData.id;
        if (e.element.id.toLowerCase() !== 'ph_htype') {
          this.filterpayhead();
        }
        if (e.itemData.id == this._structure.HeadType.Informational) {
          this.typeof = this.alltypeof.filter(
            (x: any) => x.OthId == e.itemData.id
          );
        } else {
          this.typeof = this.alltypeof.filter(
            (x: any) =>
              x.OthId == e.itemData.id ||
              x.id == this._structure.TypeOf.Salary.id
          );
        }
      } else {
        this.typeof = this.alltypeof;
      }
      this.lstTypeOf.dataSource = this.typeof;
      this.lstTypeOf.value = 0;
      this.lstTypeOf.text = '';
      this.lstTypeOf.refresh();
    } else {
      this.iselcheadtypeid = 0;
      this.typeof = this.alltypeof;
    }
  }

  private hndltypeofchg(e: any) {
    if (e) {
      if (e.itemData != null) {
        if (e.itemData.id > 0) {
          this.iselctypeofid = e.itemData.id;
          if (e.element.id.toLowerCase() !== 'ph_typof') {
            this.filterpayhead();
          }
          let _filter: any = [];
          if (e.itemData.id > 1) {
            // NOT Type of - Salary
            this.calctype = this.allcalctype.filter(
              (x: any) => x.OthId <= e.itemData.id
            );

            this.DefaultHeadCalcType.map((x: any) => {
              _filter.push(x);
            });
            this.PPACalcType.map((x: any) => {
              _filter.push(x);
            });
            this.NetPayRoundingAdjustmentCalcType.map((x: any) => {
              _filter.push(x);
            });
            this.LoanCalcType.map((x: any) => {
              _filter.push(x);
            });

            if (e.itemData.id == this._structure.TypeOf.Other_Earnings.id) {
              _filter.push(this._structure.CalcType.ShortHours.id);
            } else if (
              e.itemData.id == this._structure.TypeOf.Other_Deductions_Emp.id
            ) {
              _filter.push(this._structure.CalcType.OverTimeHours.id);
            } else if (
              e.itemData.id == this._structure.TypeOf.Informational.id
            ) {
              _filter.push(
                this._structure.CalcType.OverTimeHours.id,
                this._structure.CalcType.OverTimeHours.id
              );
            } else if (
              e.itemData.id ==
              this._structure.TypeOf.NET_PAY_ROUNDING_ADJUSTMENT.id
            ) {
              _filter.push(
                this._structure.CalcType.OverTimeHours.id,
                this._structure.CalcType.OverTimeHours.id
              );
            } else if (e.itemData.id == this._structure.TypeOf.LOAN.id) {
              _filter.push(
                this._structure.CalcType.OverTimeHours.id,
                this._structure.CalcType.OverTimeHours.id
              );
            } else {
              _filter.push(
                this._structure.CalcType.ShortHours.id,
                this._structure.CalcType.OverTimeHours.id
              );
            }

            this.calctype = this.calctype.filter(
              (x: any) => !_filter.includes(x.id)
            );
          } else {
            //Type of - Salary

            _filter.push(
              this._structure.TypeOf.Salary.id,
              this._structure.TypeOf.Allowance.id
            );

            this.calctype = this.allcalctype.filter((x: any) =>
              _filter.includes(x.OthId)
            );

            let _calid_noa: any = [
              this._structure.CalcType.DefinedSalary.id,
              this._structure.CalcType.OnAttendance.id,
            ];
            let _calid_nob: any = [
              this._structure.CalcType.ShortHours.id,
              this._structure.CalcType.OverTimeHours.id,
            ];

            this.DefaultHeadCalcType.map((x: any) => {
              _calid_nob.push(x);
            });
            this.PPACalcType.map((x: any) => {
              _calid_nob.push(x);
            });
            this.NetPayRoundingAdjustmentCalcType.map((x: any) => {
              _calid_nob.push(x);
            });
            this.LoanCalcType.map((x: any) => {
              _calid_nob.push(x);
            });

            if (
              this.iselcheadtypeid ==
                this._structure.HeadType.Informational.id &&
              e.itemData.id == this._structure.TypeOf.Salary.id
            ) {
              this.calctype = this.calctype.filter(
                (x: any) => x.id == this._structure.CalcType.FlatRateOthers.id
              );
            } else if (
              this.iselcheadtypeid !==
                this._structure.HeadType.Informational.id &&
              e.itemData.id == this._structure.TypeOf.Salary.id
            ) {
              this.calctype = this.calctype.filter(
                (x: any) =>
                  (x.OthId == this._structure.TypeOf.Salary.id &&
                    !_calid_noa.includes(x.id)) ||
                  (x.OthId == this._structure.TypeOf.Allowance.id &&
                    !_calid_nob.includes(x.id))
              );
            }
          }
        } else {
          this.calctype = this.allcalctype;
        }
        this.lstCalcType.dataSource = this.calctype;
        this.lstCalcType.value = 0;
        this.lstCalcType.text = '';
        this.lstCalcType.refresh();
      } else {
        this.iselctypeofid = 0;
        this.calctype = this.allcalctype;
      }
    }
  }

  private hndlcalctypechg(e: any) {
    if (e) {
      if (e.itemData != null) {
        this.iselccalctypeid = e.itemData.id;
        if (e.element.id.toLowerCase() !== 'ph_calcid') {
          this.filterpayhead();
        }
        if (e.element.id.toLowerCase() == 'ph_calcid') {
          switch (e.itemData.id) {
            case this._structure.CalcType.FlatRateOthers.id:
              if (document.getElementById('ph_formula') !== undefined) {
                (document.getElementById('ph_formula') as any).disabled = true;
                (document.getElementById('ph_formula') as any).value = '';
              }
              if (document.getElementById('ph_amount') !== undefined) {
                (document.getElementById('ph_amount') as any).disabled = false;
              }

              break;
            default:
              if (document.getElementById('ph_amount') !== undefined) {
                (document.getElementById('ph_amount') as any).disabled = true;
                (document.getElementById('ph_amount') as any).value = '';
              }
              if (document.getElementById('ph_formula') !== undefined) {
                (document.getElementById('ph_formula') as any).disabled = false;
              }
              break;
          }
        }
      } else {
        this.iselccalctypeid = 0;
      }
    }
  }

  private fillPayHeads() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/listheads`,
      {
        addNewOption: true,
        startDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
        endDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.allpayheads = response;
        this.setState({ payheads: response });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public extradialogbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.setState({ showDialog: false });
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        //this.HeadAdd();
        this.child.current.HeadAdd().then((response: any) => {
          if (response) {
            let svdata = this.allpayheads;
            if (svdata) {
              (svdata as any).push(response);
            }
            svdata = (svdata as any).filter((x: any) => x.id !== undefined);
            this.setState({ payheads: svdata, showDialog: false });

            this.lstPayHead.refresh();
            this.lstPayHead.value = response.id;
            this.lstPayHead.text = response.name;
            //this.lstPayHead.ensureVisible(response.id.toString());
          }
        });
      },
    },
  ];

  // private HeadAdd() {
  //   let iHeadTypeId: any = 0;
  //   let iTypeOfId: any = 0;
  //   let iCalcTypeId: any = 0;
  //   if (
  //     document.getElementById('ph_code') !== undefined &&
  //     (document.getElementById('ph_code') as any).value == ''
  //   ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please enter code to continue.</p>'
  //           });
  //     return;
  //   }
  //   if (
  //     document.getElementById('ph_name') !== undefined &&
  //     (document.getElementById('ph_code') as any).value == ''
  //   ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Pleaseenter name to continue.</p>'
  //           });
  //     return;
  //   }
  //   if (
  //     document.getElementById('ph_Htype') !== undefined &&
  //     (document.getElementById('ph_Htype') as any).value == ''
  //   ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please select head type tp continue.</p>'
  //           });
  //     return;
  //   }
  //   if (
  //     document.getElementById('ph_TypOf') !== undefined &&
  //     (document.getElementById('ph_TypOf') as any).value == ''
  //   ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please select type of to continue.</p>'
  //           });
  //     return;
  //   }
  //   if (
  //     document.getElementById('ph_calcid') !== undefined &&
  //     (document.getElementById('ph_calcid') as any).value == ''
  //   ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please select calculation type to continue.</p>'
  //           });
  //     return;
  //   }
  //   iHeadTypeId = (document.getElementById('ph_Htype') as any).ej2_instances[0]
  //     .itemData.id;
  //   iTypeOfId = (document.getElementById('ph_TypOf') as any).ej2_instances[0]
  //     .itemData.id;
  //   iCalcTypeId = (document.getElementById('ph_calcid') as any).ej2_instances[0]
  //     .itemData.id;

  //   switch (iCalcTypeId) {
  //     case this._structure.CalcType.FlatRateOthers.id:
  //       if (
  //         document.getElementById('ph_amount') !== undefined &&
  //         (document.getElementById('ph_amount') as any).value == ''
  //       ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please enter amount to continue.</p>'
  //           });
  //         return;
  //       }
  //       if (isNaN((document.getElementById('ph_amount') as any).value)) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please enter valid value to continue.</p>'
  //           });
  //         return;
  //       }
  //       break;
  //     default:
  //       if (
  //         document.getElementById('ph_formula') !== undefined &&
  //         (document.getElementById('ph_formula') as any).value == ''
  //       ) {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please enter formula to continue.</p>'
  //           });
  //         return;
  //       }

  //       let _formula = (document.getElementById('ph_formula') as any).value;
  //       let _pattern = /(#.*?#)/g;
  //       let new_formula = _formula.replace(_pattern, '1');
  //       try {
  //         eval(new_formula);
  //       } catch {
  //  DialogConfirm.showDialog({
  //             content: '<p class="dialog-contain">Please enter correct formula to continue.</p>'
  //           });
  //         return;
  //       }

  //       //let isvalid: boolean
  //       //isvalid = this.ValidateFormula((document.getElementById('ph_formula') as any).value)

  //       break;
  //   }

  //   let payload: any = {
  //     code: (document.getElementById('ph_code') as any).value,
  //     name: (document.getElementById('ph_name') as any).value,
  //     headTypeId: iHeadTypeId,
  //     typeOfId: iTypeOfId,
  //     calcTypeId: iCalcTypeId,
  //     isTaxable: false,
  //     isDefault: false,
  //     payHeadTran: [
  //       {
  //         effectiveDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
  //         amount:
  //           (document.getElementById('ph_amount') as any).value == ''
  //             ? 0
  //             : (document.getElementById('ph_amount') as any).value,
  //         formula: (document.getElementById('ph_formula') as any).value,
  //       },
  //     ],
  //   };

  //   Common.ApiCallAsync(
  //     'POST',
  //     `${REACT_APP_ENDPOINT_CORESERVICE}/payhead/add`,
  //     payload,
  //     Common.getToken() || '',
  //     this.user,
  //     this.token.tenant
  //   )
  //     .then((response: any) => {
  //       return response.data;
  //     })
  //     .then((response: any) => {
  //       let svdata = this.allpayheads;
  //       if (svdata) {
  //         (svdata as any).push(response);
  //       }
  //       svdata = (svdata as any).filter((x: any) => x.id !== undefined);
  //       this.setState({ payheads: svdata, showDialog: false });
  //       this.lstPayHead.refresh();
  //       this.lstPayHead.value = response.id;
  //       this.lstPayHead.text = response.name;
  //       this.lstPayHead.ensureVisible(response.id.toString());
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //     })
  //     .finally(() => {});
  // }

  private ValidateFormula(expression: string) {
    let isvalid: boolean = false;
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/evalformula`,
      { formula: expression },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        isvalid = true;
      })
      .catch((error: any) => {
        isvalid = true;
      })
      .finally(() => {});
    return isvalid;
  }

  public dlgContent() {
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-4 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Code
            </label>
            <input
              className='input-text-border'
              id='ph_code'
              name='ph_code'
              type='text'
              placeholder='Enter Code'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              required={true}
              maxLength={10}
            />
          </div>
          <div className='col-8 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Name
            </label>
            <input
              className='input-text-border'
              id='ph_name'
              name='ph_name'
              type='text'
              placeholder='Enter Name'
              autoComplete='off'
              style={{ fontSize: '15px' }}
              required={true}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Head Type
            </label>
            <DropDownListComponent
              id='ph_Htype'
              name='ph_Htype'
              dataSource={this.headtype}
              ref={(scope) => {
                this.lstHeadType = scope;
              }}
              filterBarPlaceholder='Search Head Type'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'text' }}
              change={this.hndlheadtypechg.bind(this)}
              placeholder='Select Head Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Type Of
            </label>
            <DropDownListComponent
              id='ph_TypOf'
              name='ph_TypOf'
              dataSource={this.typeof}
              ref={(scope) => {
                this.lstTypeOf = scope;
              }}
              filterBarPlaceholder='Search Type Of'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'text' }}
              change={this.hndltypeofchg.bind(this)}
              placeholder='Select Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Calculation Type
            </label>
            <DropDownListComponent
              id='ph_calcid'
              name='ph_calcid'
              dataSource={this.calctype}
              ref={(scope) => {
                this.lstCalcType = scope;
              }}
              filterBarPlaceholder='Search Calculation Type'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'text' }}
              change={this.hndlcalctypechg.bind(this)}
              placeholder='Select Calculation Type'
              popupHeight='220px'
              showClearButton={true}
              style={{ fontSize: '15px' }}
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Amount
            </label>
            <input
              className='input-text-border'
              id='ph_amount'
              name='ph_amount'
              type='number'
              placeholder='Enter Amount'
              autoComplete='off'
              //onChange={this.onpersonemp_namechange.bind(this)}
              style={{ fontSize: '15px', textAlign: 'right' }}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Formula
            </label>
            <input
              className='input-text-border'
              id='ph_formula'
              name='ph_formula'
              type='text'
              placeholder='Type Formula'
              autoComplete='off'
              //onChange={this.onpersonemp_namechange.bind(this)}
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
      </>
    );
    return <>{_content}</>;
  }

  render(): any {
    let data: employeepayheadmodel = this.state;

    let prdtext: any;
    if (document.getElementById('attendance-selector')) {
      prdtext = document.getElementById('attendance-selector');
    }
    return (
      <>
        {data.payheads !== undefined ? (
          <>
            <div style={{ width: '30vw', height: '100%', padding: '7px' }}>
              {/* <div className='row align-items-center'>
                <div className='col-12 py-2'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Head Type
                  </label>
                  <DropDownListComponent
                    id='headtypeid'
                    name='headtypeid'
                    dataSource={this.headtype}
                    ref={(scope) => {
                      this.lstHeadType = scope;
                    }}
                    filterBarPlaceholder='Search Head Type'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'text' }}
                    change={this.hndlheadtypechg.bind(this)}
                    placeholder='Select Head Type'
                    popupHeight='220px'
                    enabled={data.isAdd}
                    showClearButton={true}
                    value={data.headTypeId?.toString()}
                    text={data.headTypeName}
                    style={{ fontSize: '15px' }}
                  />
                </div>
              </div>
              <div className='row align-items-center'>
                <div className='col-12 py-2'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Type Of
                  </label>
                  <DropDownListComponent
                    id='typeofid'
                    name='typeofid'
                    dataSource={this.typeof}
                    ref={(scope) => {
                      this.lstTypeOf = scope;
                    }}
                    filterBarPlaceholder='Search Type Of'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'text' }}
                    change={this.hndltypeofchg.bind(this)}
                    placeholder='Select Type'
                    popupHeight='220px'
                    showClearButton={true}
                    enabled={data.isAdd}
                    value={data.typeOfId?.toString()}
                    text={data.typeOfName}
                    style={{ fontSize: '15px' }}
                  />
                </div>
              </div>
              <div className='row align-items-center'>
                <div className='col-12 py-2'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Calculation Type
                  </label>
                  <DropDownListComponent
                    id='calctypeid'
                    name='calctypeid'
                    dataSource={this.calctype}
                    ref={(scope) => {
                      this.lstCalcType = scope;
                    }}
                    filterBarPlaceholder='Search Calculation Type'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'text' }}
                    change={this.hndlcalctypechg.bind(this)}
                    placeholder='Select Calculation Type'
                    popupHeight='220px'
                    showClearButton={true}
                    enabled={data.isAdd}
                    value={data.calcTypeId?.toString()}
                    style={{ fontSize: '15px' }}
                    text={data.calcTypeName}
                  />
                </div>
              </div> */}
              <div className='row align-items-center'>
                <div className='col-12 py-2'>
                  <span className='mandatoryinfo'>*</span>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Pay Head
                  </label>
                  <DropDownListComponent
                    id='payheadid'
                    name='payheadid'
                    dataSource={data.payheads}
                    ref={(scope) => {
                      this.lstPayHead = scope;
                    }}
                    filterBarPlaceholder='Search Head'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={{ value: 'id', text: 'name' }}
                    placeholder='Select Head'
                    showClearButton={true}
                    value={data.payHeadId}
                    enabled={data.isAdd}
                    change={this.hdlpayheadchg.bind(this)}
                    text={data.payHead['name']}
                  />
                  <input type='hidden' id='h_headtypeid' name='h_headtypeid' />
                  <input type='hidden' id='h_typeofid' name='h_typeofid' />
                  <input type='hidden' id='h_calctypeid' name='h_calctypeid' />
                  <input type='hidden' id='h_formula' name='h_formula' />
                  <input type='hidden' id='h_amount' name='h_amount' />
                </div>
              </div>
              <div className='row align-items-center'>
                <div className='col-12 py-2'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Amount
                  </label>
                  <NumericTextBoxComponent
                    id='amount'
                    name='amount'
                    cssClass='text-right'
                    placeholder='Enter Amount'
                    value={data.amount?.toString() as any}
                    decimals={
                      this.user.settings.formats.Decimal.includes('.')
                        ? this.user.settings.formats.Decimal.split('.').pop()
                            .length
                        : null
                    }
                    // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
                    // format={`c${this.user.settings.formats.Decimal.includes('.')
                    //   ? this.user.settings.formats.Decimal.split('.').pop()
                    //     .length
                    //   : 2
                    //   }`}
                    format={`${this.user.currencySign} 0.${
                      this.user.settings.formats.Decimal.includes('.')
                        ? '0'.repeat(
                            this.user.settings.formats.Decimal.split('.').pop()
                              .length
                          )
                        : '0'.repeat(2)
                    }`}
                    showSpinButton={false}
                    onChange={this.onChange.bind(this)}
                    enabled={
                      data.calcTypeId ===
                      this._structure.CalcType.FlatRateOthers.id
                    }
                  />
                  {/* <input
                    className='input-text-border no-spinner'
                    id='amount'
                    name='amount'
                    type='number'
                    value={data.amount?.toString()}
                    placeholder='Enter Amount'
                    autoComplete='off'
                    onChange={this.onChange.bind(this)}
                    //onChange={this.onpersonemp_namechange.bind(this)}
                    style={{ fontSize: '15px', textAlign: 'right' }}
                  /> */}
                </div>
              </div>
              <div className='row align-items-center'>
                <div className='col-3 py-2'>
                  <span className='mandatoryinfo'>*</span>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    {/* {prdtext.value} */} Start Date
                  </label>
                </div>
                <div className='col-3 py-2'>
                  <DatePickerComponent
                    id='startDate'
                    name='startDate'
                    format={this.user.settings.formats.DateShort}
                    showClearButton={false}
                    cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    value={
                      data.startDate == undefined
                        ? (data as any).openperiod.startDate
                        : data.startDate
                    }
                    showTodayButton={false}
                    placeholder={this.user.settings.formats.DateShort}
                    style={{ fontSize: '13px' }}
                    min={
                      (data as any).openperiod
                        ? (data as any).openperiod.startDate
                        : null
                    }
                  />
                </div>
                <div className='col-3 py-2'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    {/* {prdtext.value} */} End Date
                  </label>
                </div>
                <div className='col-3 py-2'>
                  <DatePickerComponent
                    id='endDate'
                    name='endDate'
                    format={this.user.settings.formats.DateShort}
                    showClearButton={false}
                    cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    value={data.endDate}
                    showTodayButton={false}
                    //placeholder={this.user.settings.formats.DateShort}
                    placeholder='Select Date'
                    style={{ fontSize: '13px' }}
                    min={data.minDate}
                  />
                </div>
              </div>
              <div
                className='row align-items-center'
                style={{ display: 'none' }}
              >
                <div className='col-12 py-2'>
                  <span className='mandatoryinfo'>*</span>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Formula
                  </label>
                  <input
                    className='input-text-border'
                    id='formula'
                    name='formula'
                    type='text'
                    value={data.formula}
                    placeholder='Type Formula'
                    disabled={data.isAdd}
                    autoComplete='off'
                    //onChange={this.onpersonemp_namechange.bind(this)}
                    style={{ fontSize: '15px' }}
                  />
                </div>
              </div>
            </div>
            <div>
              {data.showDialog ? (
                <>
                  <div style={{ height: '100%', padding: '7px' }}>
                    <DialogComponent
                      id='dlgAddHead'
                      showCloseIcon={true}
                      width={'40vw'}
                      visible={data.showDialog}
                      close={() =>
                        this.setState({
                          showDialog: false,
                          showDialogCompany: false,
                        })
                      }
                      //content={this.dlgContent.bind(this)}
                      content={(props: any) => (
                        <PayHeads
                          {...props}
                          tableid='payhead'
                          ref={this.child}
                          jsonPayHead={data.payheads}
                        />
                      )}
                      isModal={true}
                      header='New Pay Head'
                      buttons={this.extradialogbuttons}
                      statelessTemplates={[]}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </>
    );
  }
}

export interface employeepayheadmodel {
  isAdd?: boolean;
  selectorService?: any;
  employeeid?: any;
  employeename?: string;
  payHeadId?: any;
  payHead?: any;
  headTypeId?: Number;
  typeOfId?: Number;
  calcTypeId?: Number;
  amount?: Number;
  formula?: string;
  h_headtypeid?: Number;
  h_typeofid?: Number;
  h_calctypeid?: Number;
  h_formula?: string;
  headTypeName?: string;
  typeOfName?: string;
  calcTypeName?: string;
  payheads?: any;
  showDialog?: boolean;
  id?: string;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
}
