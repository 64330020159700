import React from 'react';
import Common from '../../Util/Common';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  GridComponent,
  IEditCell,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import DialogConfirm from '../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface props {
  id: string;
  title: string;
  code?: any;
  name?: any;
  guid?: any;
  daysdata?: any;
  handleChange: any;
}

class WorkScheduleCreate extends React.Component<props> {
  private user: any;
  private token: any = null;
  private _structure: any;
  public validationRule: Object = { required: true };
  public inputarray: { [key: number]: any } = {};

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    shiftdays: [],
  };

  componentDidMount() {
    if (this.state.shiftdays.length <= 0) {
      this.GenerateData();
    }
  }

  private GenerateData() {
    const dates = [
      new Date(2023, 6, 2),
      new Date(2023, 6, 3),
      new Date(2023, 6, 4),
      new Date(2023, 6, 5),
      new Date(2023, 6, 6),
      new Date(2023, 6, 7),
      new Date(2023, 6, 8),
    ];

    let datarr: any = [];
    for (let i = 0; i < dates.length; i++) {
      const day = Common.formatDate(new Date(dates[i]), 'EEEE');
      var wkend: boolean = false;
      if (i == 0) {
        wkend = true;
      }

      var diff =
        (new Date(
          Common.formatDate(new Date(), 'yyyy-MM-dd') + 'T' + '18:00:00'
        ).getTime() -
          new Date(
            Common.formatDate(new Date(), 'yyyy-MM-dd') + 'T' + '09:00:00'
          ).getTime()) /
        1000;
      diff /= 60;
      let workingmins = Math.abs(Math.round(diff));
      workingmins = workingmins - 0;
      const hours = Math.floor(workingmins / 60);
      const minutes = workingmins % 60;

      let item: any = {
        id: null,
        shiftId: null,
        dayid: i,
        dayName: day,
        startTime:
          Common.formatDate(new Date(), 'yyyy-MM-dd') + 'T' + '09:00:00',
        endTime: Common.formatDate(new Date(), 'yyyy-MM-dd') + 'T' + '18:00:00',
        breakHour: 0,
        workingHour: workingmins,
        displayWorkingHour: hours.toString().concat('.', minutes.toString()),
        isWeekend: wkend,
      };
      datarr.push(item);
    }
    this.setState({ shiftdays: datarr });
  }

  public async SaveShift() {
    if (document.getElementById('sf_code')) {
      if ((document.getElementById('sf_code') as any).value == '') {
       
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain"><p class="dialog-contain">Please code is mandatory information. Please provide code to continue.</p>',
        });
        return;
      }
    }

    if (document.getElementById('sf_name')) {
      if ((document.getElementById('sf_name') as any).value == '') {
      
        DialogConfirm.showDialog({
          content : '<p class="dialog-contain"><p class="dialog-contain">Please name is mandatory information. Please provide name to continue.</p>'
        })
        return;
      }
    }

    let finalresponse: any = null;

    let payload: any = {
      code: (document.getElementById('sf_code') as any).value,
      name: (document.getElementById('sf_name') as any).value,
      ShiftTran: this.props.daysdata,
    };

    await Common.ApiCallAsync(
      'POST',
      //`${REACT_APP_ENDPOINT_CORESERVICE}/shift/add`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        finalresponse = response;
        return response;
      })
      .catch((error: any) => {
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        // DialogConfirm.showDialog({
        //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
        // });
        // }
      })
      .finally(() => {});
    if (finalresponse) {
      return finalresponse;
    }
  }

  public datetimepickerparams: IEditCell = {
    params: {
      showClearButton: false,
      format: 'HH:mm',
      step: 5,
      cssClass: 'e-hidedate-icon',
    },
  };

  private cellSave(e: any) {
    if (e.requestType === 'save') {
      if (e.data) {
        var diff =
          (e.data.endTime.getTime() - e.data.startTime.getTime()) / 1000;
        diff /= 60;
        let workingmins = Math.abs(Math.round(diff));
        workingmins = workingmins - e.data.breakHour;
        const hours = Math.floor(workingmins / 60);
        const minutes = workingmins % 60;
        let data: any = this.state.shiftdays;
        if (data.filter((x: any) => x.dayid == e.data.dayid).length > 0) {
          let mdata: any = data.filter((x: any) => x.dayid == e.data.dayid)[0];
          let newobj = { ...mdata };
          newobj.startTime =
            Common.formatDate(e.data.startTime, 'yyyy-MM-dd') +
            'T' +
            Common.formatDate(e.data.startTime, 'HH:mm:ss');
          newobj.endTime =
            Common.formatDate(e.data.endTime, 'yyyy-MM-dd') +
            'T' +
            Common.formatDate(e.data.endTime, 'HH:mm:ss');
          newobj.breakHour = e.data.breakHour;
          newobj.workingHour = workingmins;
          newobj.displayWorkingHour = hours
            .toString()
            .concat('.', minutes.toString());
          newobj.isWeekend = e.data.isWeekend;
          data = data.filter((x: any) => x.dayid !== e.data.dayid);
          if (document.getElementById('objDayId_' + e.data.dayid)) {
            (document.getElementById('objDayId_' + e.data.dayid) as any).value =
              JSON.stringify(newobj);
          }
          data.push(newobj);
          data.sort((a: any, b: any) => {
            return a.dayid - b.dayid;
          });
        }

        this.setState({ shiftdays: data });

        // let data = this.props.daysdata.map((obj: any) => {
        //   if (obj.dayid === e.data.dayid) {
        //     return {
        //       ...obj,
        //       startTime: Common.formatDate(e.data.startTime, 'yyyy-MM-dd') + 'T' + Common.formatDate(e.data.startTime, 'HH:mm:ss'),
        //       endTime: Common.formatDate(e.data.endTime, 'yyyy-MM-dd') + 'T' + Common.formatDate(e.data.endTime, 'HH:mm:ss'),
        //       breakHour: e.data.breakHour,
        //       workingHour: workingmins,
        //       displayWorkingHour: hours.toString().concat(".", minutes.toString()),
        //       isWeekend: e.data.isWeekend
        //     };
        //   }
        //   return obj;
        // });
        // this.props.handleChange(data);
      }
    } else if (e.requestType === 'delete') {
    }
  }

  render() {
    return (
      <>
        <div
          id={this.props.id}
          style={{ height: '100%', width: this.props.guid ? '20vw' : '45vw' }}
        >
          <div>
            {this.state.shiftdays.map((obj: any) => {
              return (
                <input
                  id={'objDayId_' + obj.dayid.toString()}
                  name={'objDayId_' + obj.dayid.toString()}
                  type='hidden'
                  defaultValue={JSON.stringify(obj)}
                />
              );
            })}
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <span className='mandatoryinfo'>*</span>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Code
              </label>
              <input
                className='input-text-border'
                id='sf_code'
                name='sf_code'
                type='text'
                placeholder='Enter Code'
                autoComplete='off'
                style={{ fontSize: '15px' }}
                defaultValue={this.props.code}
                maxLength={20}
              />
            </div>
            <div className='col-6 py-2'>
              <span className='mandatoryinfo'>*</span>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Name
              </label>
              <input
                className='input-text-border'
                id='sf_name'
                name='sf_name'
                type='text'
                placeholder='Enter Name'
                autoComplete='off'
                style={{ fontSize: '15px' }}
                defaultValue={this.props.name}
              />
            </div>
          </div>
          {this.props.guid == undefined ? (
            <div>
              <GridComponent
                id='grid-days'
                allowTextWrap={true}
                allowGrouping={false}
                showColumnChooser={false}
                dataSource={this.state.shiftdays}
                actionBegin={this.cellSave.bind(this)}
                editSettings={{
                  allowEditing: true,
                  allowAdding: false,
                  allowDeleting: false,
                  allowEditOnDblClick: false,
                }}
                toolbar={['Edit', 'Update', 'Cancel']}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field='dayName'
                    headerText='Day'
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field='startTime'
                    headerText='Start Time'
                    type='datetime'
                    format='HH:mm'
                    editType='datetimepickeredit'
                    validationRules={this.validationRule}
                    edit={this.datetimepickerparams}
                  />
                  <ColumnDirective
                    field='endTime'
                    headerText='End Time'
                    type='datetime'
                    format='HH:mm'
                    editType='datetimepickeredit'
                    validationRules={this.validationRule}
                    edit={this.datetimepickerparams}
                  />
                  <ColumnDirective field='breakHour' headerText='Break Time' />
                  <ColumnDirective
                    field='displayWorkingHour'
                    headerText='Working Hour'
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field='isWeekend'
                    headerText='Weekend'
                    displayAsCheckBox={true}
                    editType='booleanedit'
                  />
                </ColumnsDirective>
                <Inject services={[Toolbar, Edit]} />
              </GridComponent>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default WorkScheduleCreate;
