import React from 'react';
import './ResourceLinkCard.css';
import Common from '../../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  rootmenu: any;
  selectedmenu: any;
  updateJsonDataViewer?: any;
}

export default class ResourceLinkCard extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { jsonData: undefined };

  componentDidMount() {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  public GetList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/get`,
      { expiryDate: new Date(), includeBlankCategory: false },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          jsonData: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const titleTemplate = (props: any) => {
      return (
        <>
          {!props.isgroup ? (
            <>
              {!props.icon && (
                <div
                  className={!props.icon ? 'fa-solid fa-link me-1' : ''}
                  style={{ height: '20px', width: '20px' }}
                ></div>
              )}

              {props.icon && (
                <img
                  className='image'
                  src={`data:image/png;base64,${props.icon}`}
                  height={20}
                  width={20}
                />
              )}
              {props.description ? (
                <TooltipComponent
                  content={props.description}
                  className='d-inline'
                  position='TopCenter'
                >
                  <a href={props.url} target='_blank'>
                    {props.nameTitle}
                  </a>
                </TooltipComponent>
              ) : (
                <a href={props.url} target='_blank'>
                  {props.nameTitle}
                </a>
              )}
            </>
          ) : (
            props.nameTitle
          )}
        </>
      );
    };

    return (
      <div
        id='divResourcelinkCard'
        className='e-card mt-4 stickydiv fadeUp'
        style={{ marginTop: '20px', width: '100%' }}
      >
        <div className='e-card-header'>
          Resource Links<i className='ps-2 fa-solid fa-link'></i>
        </div>
        <div id='divresourcelinkcard-grid'>
          {this.state.jsonData && (
            <TreeGridComponent
              id='resourcelinkcardlist-treegrid'
              className={`resourcelinkcardlist-treegrid`}
              height={250}
              dataSource={this.state.jsonData}
              treeColumnIndex={0}
              childMapping={'subResourceLinks'}
              gridLines='None'
              allowSelection={false}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field='nameTitle'
                  headerText='Title'
                  textAlign='Left'
                  template={titleTemplate}
                />
              </ColumnsDirective>
            </TreeGridComponent>
          )}
        </div>
      </div>
    );
  }
}
