import * as React from 'react';
import Common from '../../Util/Common';
import './EmployeeFormat.css';
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import { Dialog, DialogUtility } from '@syncfusion/ej2-react-popups';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {}

export default class EmployeeFormat extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private changesDetected: boolean = false;
  private JsonLoaded: boolean = false;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    jsonTenantSetting: undefined,
  };
  componentDidMount() {
    if (this.state.jsonTenantSetting == undefined) {
      this.GetTenantSetting();
    }
  }

  private GetTenantSetting() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/MultiTenant/TenantSetting/Get`,
      { groupId: this._structure.TenantSetting.Employee.Id },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        (
          document.getElementById('employeecodeprefix') as HTMLInputElement
        ).value = response
          ? (
              (response as any).filter(
                (x: any) => x.formatName === 'EmployeeCode'
              )[0]['formatValue'] as string
            ).split(':')[0]
          : 'E';

        (document.getElementById('txtMaxDigit') as HTMLInputElement).value =
          response
            ? (response as any)
                .filter((x: any) => x.formatName === 'EmployeeCode')[0]
                ['formatValue'].slice(
                  (response as any)
                    .filter((x: any) => x.formatName === 'EmployeeCode')[0]
                    ['formatValue'].indexOf('[') + 1,
                  (response as any)
                    .filter((x: any) => x.formatName === 'EmployeeCode')[0]
                    ['formatValue'].indexOf(']')
                ).length
            : 5;

        (document.getElementById('txtStartFrom') as HTMLInputElement).value =
          response
            ? (response as any).filter(
                (x: any) => x.formatName === 'EmployeeCode'
              )[0]['startFrom']
            : 1;

        this.setState({
          jsonTenantSetting: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.JsonLoaded = true;
      });
  }

  public SaveData(payload: any, action: string = 'add') {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/MultiTenant/TenantSetting/Set`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const onEmployeeCodeFormatChange = (e: any) => {
      let pfx: string = (
        document.getElementById('employeecodeprefix') as HTMLInputElement
      ).value;

      let maxdigit: string = (
        document.getElementById('txtMaxDigit') as HTMLInputElement
      ).value;

      let startfrom: string = (
        document.getElementById('txtStartFrom') as HTMLInputElement
      ).value;

      let jsn: any = (this.state.jsonTenantSetting as any).filter(
        (x: any) => x.formatName === 'EmployeeCode'
      );

      jsn[0]['formatValue'] = pfx + ':[' + '0'.repeat(Number(maxdigit)) + ']';
      jsn[0]['startFrom'] = Number(startfrom);

      if (pfx && this.JsonLoaded) {
        this.changesDetected = true;
      }
      //   ((this.state.jsonTenantSetting as any).filter(
      //     (x: any) => x.formatName === 'EmployeeCode'
      //   )[0]['formatValue'] as string) =
      //     pfx + ':[' + '0'.repeat(Number(maxdigit)) + ']';

      //   (this.state.jsonTenantSetting as any).filter(
      //     (x: any) => x.formatName === 'EmployeeCode'
      //   )[0]['startFrom'] = Number(startfrom);
      this.setState({ jsonTenantSetting: this.state.jsonTenantSetting });
    };

    const onEployeeCodeFormatBlur = (e: any) => {
      if (
        !(document.getElementById('employeecodeprefix') as HTMLInputElement)
          .value
      ) {
        let cnf: Dialog = DialogUtility.alert('Please enter Prefix.');
        //  DialogConfirm.showDialog({
        //    content: '<p class="dialog-contain">Please enter Prefix.</p>',
        //  })
        (
          document.getElementById('employeecodeprefix') as HTMLInputElement
        ).value = 'E';

        cnf.close = (e: any) => {
          (
            document.getElementById('employeecodeprefix') as HTMLInputElement
          ).value = 'E';
          setTimeout(() => {
            (
              document.getElementById('employeecodeprefix') as HTMLInputElement
            ).focus();
          }, 0);
        };
        return;
      }

      if (this.changesDetected === true) {
        this.changesDetected = false;
        let payload: any = (this.state.jsonTenantSetting as any).filter(
          (x: any) => x.formatName === 'EmployeeCode'
        )[0];

        this.SaveData(payload);
      }
    };

    return (
      <div id='employeeformat' className='employeeformat'>
        <div className='row pt-1'>
          <div className='col-md-12'>
            <label
              style={{
                fontSize: '13px',
                paddingBottom: '7px',
                fontWeight: 'bold',
              }}
            >
              Employee code
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-1'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Prefix
                </label>
                <TextBoxComponent
                  className='textbox-noborder '
                  id='employeecodeprefix'
                  name='employeecodeprefix'
                  type='text'
                  placeholder='Enter Prefix'
                  //autoComplete='off'
                  style={{ fontSize: '15px' }}
                  //required={true}
                  //maxLength={10}
                  autocomplete='off'
                  value={
                    this.state.jsonTenantSetting
                      ? (
                          (this.state.jsonTenantSetting as any).filter(
                            (x: any) => x.formatName === 'EmployeeCode'
                          )[0]['formatValue'] as string
                        ).split(':')[0]
                      : 'E'
                  }
                  onChange={onEmployeeCodeFormatChange}
                  onBlur={onEployeeCodeFormatBlur}
                />
              </div>
              <div className='col-md-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Maximum Digit
                </label>
                <NumericTextBoxComponent
                  id='txtMaxDigit'
                  value={
                    this.state.jsonTenantSetting
                      ? (this.state.jsonTenantSetting as any)
                          .filter(
                            (x: any) => x.formatName === 'EmployeeCode'
                          )[0]
                          ['formatValue'].slice(
                            (this.state.jsonTenantSetting as any)
                              .filter(
                                (x: any) => x.formatName === 'EmployeeCode'
                              )[0]
                              ['formatValue'].indexOf('[') + 1,
                            (this.state.jsonTenantSetting as any)
                              .filter(
                                (x: any) => x.formatName === 'EmployeeCode'
                              )[0]
                              ['formatValue'].indexOf(']')
                          ).length
                      : 5
                  }
                  decimals={0}
                  format='n0'
                  min={2}
                  max={7}
                  showSpinButton={false}
                  cssClass='textbox-noborder'
                  change={onEmployeeCodeFormatChange}
                  onBlur={onEployeeCodeFormatBlur}
                />
              </div>
              <div className='col-md-1'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Start From
                </label>
                <NumericTextBoxComponent
                  id='txtStartFrom'
                  value={
                    this.state.jsonTenantSetting
                      ? Number(
                          (this.state.jsonTenantSetting as any).filter(
                            (x: any) => x.formatName === 'EmployeeCode'
                          )[0]['startFrom']
                        )
                      : 1
                  }
                  decimals={0}
                  format='n0'
                  min={1}
                  max={99999}
                  showSpinButton={false}
                  cssClass='textbox-noborder'
                  onChange={onEmployeeCodeFormatChange}
                  onBlur={onEployeeCodeFormatBlur}
                />
              </div>
              <div className='col-md-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Format
                </label>
                <input
                  className='textbox-noborder e-control-wrapper e-input-group'
                  id='txtEmpCodeFormat'
                  name='txtEmpCodeFormat'
                  type='text'
                  placeholder='Enter Prefix'
                  autoComplete='off'
                  style={{ fontSize: '15px', fontWeight: 'bold' }}
                  readOnly={true}
                  maxLength={5}
                  value={
                    this.state.jsonTenantSetting
                      ? ((this.state.jsonTenantSetting as any).filter(
                          (x: any) => x.formatName === 'EmployeeCode'
                        )[0]['formatValue'] as string)
                      : 'E000001'
                  }

                  //onChange={this.onChange.bind(this)}
                />
              </div>
              <div className='d-none'></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
