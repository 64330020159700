// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import './EmployeeBankList.css';
import Common from '../../Util/Common';
import {
  ContextMenuComponent,
  MenuItemModel,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';
import {
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';

import { DataManager, Query } from '@syncfusion/ej2-data';
import EmployeeBank from './EmployeeBank';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  TreeGridComponent,
  Inject,
  Toolbar,
  RowDD,
  Selection,
  ITreeData,
  Filter,
} from '@syncfusion/ej2-react-treegrid';
import {
  DropDownListComponent,
  DropDownTreeComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { showDialog } from '@syncfusion/ej2-react-spreadsheet';
import SelectorSearch from '../Layout/SelectorSearch';
import DialogConfirm from '../Dialogbox/DialogConfirm';

// * ------------------------------------------------------------------------------------------------------------------3.Style

const { REACT_APP_ENDPOINT_PAYROLLSERVICE } = process.env;

interface Props {
  jsonDataViewer: any;
}

export default class EmployeeBankList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private grdBank: TreeGridComponent | any = undefined;
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  public jsonEmployee: any = undefined;
  public jsonPaymentMode: any = undefined;
  public jsonPaymentType: any = undefined;
  public selectedData: any = null;
  private selectedBankGroupId: any = null;
  private selectedBankBranchId: any = null;
  private selectedAccountTypeId: any = null;
  private rowDragStartParentIdx: number = 0;
  private rowDragStartParentChildCount: number = 0;
  private jsonOnRowDrop: any = [];

  private treemenuObj: ContextMenuComponent | any = null;
  private child: EmployeeBank | any;

  private editSettings: EditSettingsModel = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Row',
    newRowPosition: 'Below',
    allowEditOnDblClick: true,
    showDeleteConfirmDialog: true,
  };

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    let emp: any = document.querySelector('#treegrid-selector');
    if (emp) {
      this.jsonEmployee = emp.ej2_instances[0].dataSource;
    }

    this.jsonPaymentMode = Common.GetListFromStructure(
      this._structure.Bank.Mode
    );

    this.jsonPaymentType = Common.GetListFromStructure(
      this._structure.Bank.PaymentType
    );

    this.child = React.createRef();
  }

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonEmpBank: undefined,
    jsonBankGroup: undefined,
    jsonBankBranch: undefined,
    jsonAccountType: undefined,
    fields: undefined,
    showDialog: false,
    openperiod: undefined,
    periodfetched: false,
    bankfetched: false,
    accounttypefetched: false,
    employeebankfetched: false,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount(): void {
    /* Open contextmenu on three vertical dots */
    document
      .querySelector('#bank-moreoptions')
      ?.removeEventListener('click', (e: any) =>
        this.treemenuObj.open(e.y, e.x)
      );
    document
      .querySelector('#bank-moreoptions')
      ?.addEventListener('click', (e: any) => this.treemenuObj.open(e.y, e.x));

    if (this.state.openperiod == undefined) {
      this.getopenperiod();
    }

    if (!this.state.jsonBankGroup) {
      this.GetBank();
    }

    if (!this.state.jsonAccountType) {
      this.GetAccountType();
    }

    // if (!this.state.jsonEmpBank) {
    //   this.GetEmployeeBank();
    // }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.state.periodfetched &&
      this.state.bankfetched &&
      this.state.accounttypefetched &&
      !this.state.employeebankfetched
    ) {
      this.GetEmployeeBank();
    }
  }

  private getopenperiod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/firstperiod`,
      {
        statusId: Number(this._structure.PeriodStatusId.Open),
        lastPeriodOnNoOpenPeriod: false,
      },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          openperiod: response,
          periodfetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({
          periodfetched: true,
        });
      })
      .finally(() => {});
  }

  public GetBank() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/list`,
      { showBothGroupAndChild: true },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonBankGroup: response.filter((x: any) => x.isgroup === true),
          jsonBankBranch: response.filter((x: any) => x.isgroup === false),
          fields: {
            dataSource: response.filter((x: any) => x.bankId === null),
            value: 'id',
            text: 'name',
            child: 'subBank',
            selectable: 'selectable',
          },
          bankfetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({ bankfetched: true });
      })
      .finally(() => {});
  }

  public GetAccountType() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/accounttype/list`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          jsonAccountType: response,
          accounttypefetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({ accounttypefetched: true });
      })
      .finally(() => {});
  }

  public GetEmployeeBank() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/list${
        !this.user.isManager ? '/ess' : ''
      }`,
      { employeeId: this.props.jsonDataViewer.id ?? null },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        response.forEach((x: any) => {
          x.distribution.forEach((d: any) => {
            d['modeId'] = d.bank.bankId;
            d['modeName'] = d.bank.groupName;
            d['paymentTypeId'] = d.bank.id;
            d['paymentTypeName'] = d.bank.name;
          });

          // x['startDate'] = new Date(
          //   Common.formatDate(
          //     new Date(x['startDate']),
          //     this.user.settings.formats.DateShort
          //   )
          // );
          // x['endDate'] = new Date(
          //   Common.formatDate(
          //     new Date(x['endDate']),
          //     this.user.settings.formats.DateShort
          //   )
          // );
          // x['customgroupname'] =
          //   x['employeeName'] +
          //   ' | ' +
          //   Common.formatDate(
          //     new Date(x['startDate']),
          //     this.user.settings.formats.DateShort
          //   ) +
          //   ' | Mode : ' +
          //   x['modeName'] +
          //   ' | Payment Type : ' +
          //   x['paymentTypeName'];
        });

        this.setState({
          jsonEmpBank: response,
          employeebankfetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
        this.setState({ employeebankfetched: true });
      })
      .finally(() => {});
  }

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showDialog === true) {
          this.setState({ showDialog: false });
        } else {
          //this.setSelectedDropdownTreeValue.bind(this);
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        let data: any = this.child.current.GetState();

        if (!data.employeeId) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select employee.</p>',
            });
          return;
        }

        if (!data.modeId || data.modeId <= 0) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select mode.</p>',
            });
          return;
        }

        if (!data.paymentTypeId || data.paymentTypeId <= 0) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select payment type.</p>',
            });
          return;
        }

        if (!data.startDate) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select start date.</p>',
            });
          return;
        }

        if (!data.bankId || data.bankId.length <= 0) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select bank.</p>',
            });
          return;
        }

        if (!data.accountTypeId) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select account type.</p>',
            });
          return;
        }

        if (
          data.paymentTypeId ===
            this._structure.Bank.PaymentType.BankTransfer.Id &&
          !data.accountNo
        ) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select account no.</p>',
            });
          return;
        }

        if (
          data.paymentTypeId ===
            this._structure.Bank.PaymentType.MobileTransfer.Id &&
          !data.mobileNo
        ) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select mobile no.</p>',
            });
          return;
        }

        if (
          data.modeId === this._structure.Bank.Mode.Percentage.Id &&
          !data.percentage
        ) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please enter percentage.</p>',
            });
          return;
        }

        if (
          data.modeId === this._structure.Bank.Mode.Amount.Id &&
          !data.amount
        ) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please enter amount.</p>',
            });
          return;
        }

        if (this.grdBank.dataSource.length <= 0) {
          this.grdBank.dataSource = [...this.jsonOnRowDrop];
        }

        let d: any = {
          employeeBankId:
            this.grdBank.getSelectedRecords().length > 0
              ? this.grdBank.getSelectedRecords()[0].parentItem
                ? this.grdBank.getSelectedRecords()[0].parentItem.id
                : this.grdBank.getSelectedRecords()[0].id
              : null,
          bankId: data.bankId[0],
          accountTypeId: data.accountTypeId,
          accountNo: data.accountNo,
          mobileNo: data.mobileNo,
          percentage: data.percentage,
          amount: data.amount,
        };

        let distrib: any[] = [];
        // if (this.grdBank.getSelectedRecords().length > 0) {
        //   if (this.grdBank.getSelectedRecords()[0].parentItem) {
        //     distrib = [
        //       ...(this.grdBank.dataSource as any)[
        //         (this.grdBank.dataSource as any).findIndex(
        //           (x: any) =>
        //             x.id === this.grdBank.getSelectedRecords()[0].parentItem.id
        //         )
        //       ].distribution,
        //     ];
        //   } else {
        //     distrib = [...this.grdBank.getSelectedRecords()[0].distribution];
        //   }
        // }

        distrib.push(d);

        if (distrib.length > 0) {
          let i: number = 0;
          distrib.forEach((x: any) => {
            x.priority = ++i;
          });
        }

        let payload: any = {
          // id:
          //   this.grdBank.getSelectedRecords().length > 0
          //     ? this.grdBank.getSelectedRecords()[0].parentItem
          //       ? this.grdBank.getSelectedRecords()[0].parentItem.id
          //       : this.grdBank.getSelectedRecords()[0].id
          //     : null,
          id: null,
          employeeId: data.employeeId,
          startDate: Common.RemoveTimezoneOffsetFromDate(
            new Date(data.startDate)
          ),
          endDate: data.endDate
            ? Common.RemoveTimezoneOffsetFromDate(new Date(data.endDate))
            : null,
          removeEndDateIfNull: true,
          modeId: data.modeId,
          paymentTypeId: data.paymentTypeId,
          distribution: distrib,
        };

        this.SaveData(
          payload,
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/add${
            !this.user.isManager ? '/ess' : ''
          }`
        )
          .then((response: any) => {
            if (!response.response) {
              let f1: any = this.grdBank.dataSource.filter(
                (x: any) => x.id === response.id
              );
              if (f1.length <= 0) {
                (this.grdBank.dataSource as any).unshift(response);
              } else {
                (this.grdBank.dataSource as any)[
                  this.grdBank.dataSource.findIndex(
                    (x: any) => x.id === response.id
                  )
                ] = response;
              }
              // if (this.grdBank.getSelectedRecords().length <= 0) {
              //   (this.grdBank.dataSource as any).push(response);
              // } else {
              //   (this.grdBank.dataSource as any)[
              //     this.grdBank.dataSource.findIndex(
              //       (x: any) => x.id === response.id
              //     )
              //   ] = response;
              // }
              this.grdBank.refresh();
              this.setState({ showDialog: false });
            } else {
            }
          })
          .catch((error: any) => {
            // if (!error.response) {
            //   (this.state.jsonEmpBank as any).splice(0, 1);
            //   this.grdBank.refresh();
            // }

            return;
          });
      },
    },
  ];

  private async SaveData(payload: any, url: string, action: string = 'add') {
    let finalresponse: any = null;
    let ds: any = [...this.grdBank.dataSource];

    await Common.ApiCallAsync(
      'POST',
      url,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        finalresponse = response;
      })
      .catch((error: any) => {
        if (action === 'delete') {
          this.grdBank.dataSource = ds;
          this.grdBank.refresh();
        }
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        // DialogConfirm.showDialog({
        //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
        // });
        //   finalresponse = error;
        // }
        finalresponse = error;
      })
      .finally(() => {});

    if (finalresponse) {
      return finalresponse;
    }
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  render() {
    let cnfDlg: Dialog;

    const amountTemplate = (props: any) => {
      switch (props.column.field) {
        case 'percentage':
          return (
            <>
              {props.parentItem &&
              props.parentItem['modeId'] ===
                this._structure.Bank.Mode.Percentage.Id
                ? Common.FormatNumber(
                    props[props.column.field] ? props[props.column.field] : 0,
                    this.user.settings.formats.Decimal
                  )
                : ''}
            </>
          );
          break;

        case 'amount':
          return (
            <>
              {props.parentItem &&
              props.parentItem['modeId'] === this._structure.Bank.Mode.Amount.Id
                ? Common.FormatNumber(
                    props[props.column.field] ? props[props.column.field] : 0,
                    this.user.settings.formats.Decimal
                  )
                : ''}
            </>
          );
          break;

        default:
          break;
      }
    };

    const editAmount = (e: any) => {
      let enbl: boolean = true;
      let crncyfrmt: string = `${this.user.currencySign} 0.${
        this.user.settings.formats.Decimal.includes('.')
          ? '0'.repeat(
              this.user.settings.formats.Decimal.split('.').pop().length
            )
          : '0'.repeat(2)
      }`;
      if (!e.startDate) {
        switch (e.column.field) {
          case 'percentage':
            if (
              e.parentItem.modeId !== this._structure.Bank.Mode.Percentage.Id
            ) {
              enbl = false;
            }
            crncyfrmt = '0.00';
            break;

          case 'amount':
            if (e.parentItem.modeId !== this._structure.Bank.Mode.Amount.Id) {
              enbl = false;
            }
            break;

          default:
            break;
        }
        return (
          <NumericTextBoxComponent
            id={e.column.field}
            name={e.column.field}
            cssClass='text-right'
            placeholder={`Enter ${e.column.field}`}
            value={!e[e.column.field] ? 0 : e[e.column.field]}
            decimals={
              this.user.settings.formats.Decimal.includes('.')
                ? this.user.settings.formats.Decimal.split('.').pop().length
                : null
            }
            // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
            // format={`c${
            //   this.user.settings.formats.Decimal.includes('.')
            //     ? this.user.settings.formats.Decimal.split('.').pop().length
            //     : 2
            // }`}
            format={crncyfrmt}
            showSpinButton={false}
            enabled={enbl}
          />
        );
      } else {
        return <></>;
      }
    };

    const templateDropDown = (e: any) => {
      let itemValue: string = '';
      let fltr: any;
      switch (e.column.field) {
        case 'modeName':
          if (!e.parentItem) {
            return '';
            fltr = (this.jsonPaymentMode! as []).filter(
              (x: any) => x.Id == e.modeId
            );

            if (fltr.length > 0) {
              itemValue = fltr[0].Name;
            }
          } else if (e.bank) {
            fltr = (this.state.jsonBankGroup! as []).filter(
              (x: any) => x.id == e.bank.bankId
            );

            if (fltr && fltr.length > 0) {
              itemValue = fltr[0].name;
            }
          }
          break;

        case 'paymentTypeName':
          if (!e.parentItem) {
            return '';
            fltr = (this.jsonPaymentType! as []).filter(
              (x: any) => x.Id == e.paymentTypeId
            );

            if (fltr.length > 0) {
              itemValue = fltr[0].Name;
            }
          } else if (e.bank) {
            fltr = (this.state.jsonBankBranch! as []).filter(
              (x: any) => x.id == e.bank.id
            );

            if (fltr && fltr.length > 0) {
              itemValue = fltr[0].name;
            }
          }

          break;

        // case 'bank.bankId':
        //   if (e.bank) {
        //     fltr = (this.state.jsonBankGroup! as []).filter(
        //       (x: any) => x.id == e.bank.bankId
        //     );

        //     if (fltr && fltr.length > 0) {
        //       itemValue = fltr[0].name;
        //     }
        //   }
        //   break;

        // case 'bank.id':
        //   if (e.bank) {
        //     fltr = (this.state.jsonBankBranch! as []).filter(
        //       (x: any) => x.id == e.bank.id
        //     );

        //     if (fltr && fltr.length > 0)
        //       itemValue = fltr[0].name;
        //     }
        //   }
        //   break;

        case 'accountTypeName':
          if (e.bank) {
            fltr = (this.state.jsonAccountType! as []).filter(
              (x: any) => x.id == e.accountTypeId
            );
            if (fltr && fltr.length > 0) {
              itemValue = fltr[0].name;
            }
          }
          break;

        default:
          break;
      }

      return <>{itemValue}</>;
    };

    const editDropDown = (e: any) => {
      let fld: any = null;
      let flds: any = { value: 'Id', text: 'Name', selectable: 'selectable' };
      let fldname: string = e.column.field;

      if (e.column.field.split('.').length === 1) {
        switch (e.column.field) {
          case 'modeName':
            if (!e.parentItem) {
              fld = e['modeId'];
            } else {
              fld = e['bank']['bankId'];
            }

            break;

          case 'paymentTypeName':
            if (!e.parentItem) {
              fld = e['paymentTypeId'];
            } else {
              fld = e['bank']['id'];
            }

            break;

          case 'accountTypeName':
            fld = e['accountTypeId'];
            break;

          default:
            fld = e[e.column.field];
            break;
        }
      } else if (e.column.field.split('.').length === 2) {
        if (e[e.column.field.split('.')[0]]) {
          fld = e[e.column.field.split('.')[0]][e.column.field.split('.')[1]];
        }
      }

      if (fld) {
        let ds: any = undefined;
        switch (e.column.field) {
          case 'modeName':
            if (!e.parentItem) {
              ds = this.jsonPaymentMode;
            } else if (e.bank) {
              fldname = 'bank.bankId';
              ds = this.state.jsonBankGroup as any;
              flds = { value: 'id', text: 'name' };
            }

            break;

          case 'paymentTypeName':
            if (!e.parentItem) {
              ds = this.jsonPaymentType;
            } else if (e.bank) {
              fldname = 'bank.id';
              ds = (this.state.jsonBankBranch as any).filter(
                (x: any) => x.bankId === e['bank']['bankId']
              );
              flds = { value: 'id', text: 'name' };
            }

            break;

          // case 'bank.bankId':
          //   ds = this.state.jsonBankGroup as any;
          //   flds = { value: 'id', text: 'name' };
          //   break;

          // case 'bank.id':
          //   ds = (this.state.jsonBankBranch as any).filter(
          //     (x: any) => x.bankId === e['bank']['bankId']
          //   );
          //   flds = { value: 'id', text: 'name' };
          //   break;

          case 'accountTypeName':
            ds = this.state.jsonAccountType;
            flds = { value: 'id', text: 'name' };
            break;

          default:
            break;
        }

        const onDropDownChange = (e: any) => {
          if (e.element.ej2_instances[0].id === 'bank.bankId') {
            let ds: any = this.state.jsonBankBranch;
            let o: any = document.getElementById('bank.id');

            let dd: DropDownListComponent = o.ej2_instances[0];

            ds.filter((x: any) => x.id === e.value);

            dd.text = '';
            dd.value = '';
            this.selectedBankBranchId = null;

            dd.dataSource = ds.filter((x: any) => x.bankId === e.value);
            dd.refresh();
          }

          switch (e.element.ej2_instances[0].id) {
            case 'bank.bankId':
              this.selectedBankGroupId = e.itemData ? e.itemData.id : null;
              break;

            case 'bank.id':
              this.selectedBankBranchId = e.itemData ? e.itemData.id : null;
              break;

            case 'accountTypeId':
              this.selectedAccountTypeId = e.itemData ? e.itemData.id : null;
              break;

            default:
              break;
          }
        };

        return (
          <DropDownListComponent
            id={fldname}
            name={fldname}
            dataSource={ds}
            value={!fld ? undefined : fld}
            filterBarPlaceholder='Search'
            allowFiltering={true}
            filterType='Contains'
            fields={flds}
            change={onDropDownChange}
            placeholder='Select'
            popupHeight='220px'
            popupWidth={'200px'}
            showClearButton={true}
            style={{ fontSize: '15px' }}
            focus={dropdownOnFocus}
            enabled={fldname !== 'modeName' && fldname !== 'paymentTypeName'}
          />
        );
      } else {
        return <></>;
      }
    };

    const dropdownOnFocus = (e: any) => {
      if (e.event.type === 'focus' && e.event.sourceCapabilities !== null) {
        (document.getElementById(
          e.event.target.firstChild.id.replace('_hidden', '')
        ) as any)!.ej2_instances[0].showPopup();
      }
    };

    const editDate = (e: any) => {
      if (e[e.column.field]) {
        let date: any = e.startDate ? e.startDate : new Date();
        // if (!e.startDate) {
        //   if (e.column.edit.parent.dataSource.length > 0) {
        //     date = new Date(e.column.edit.parent.dataSource[0].startDate);
        //   }
        // }
        return (
          <DatePickerComponent
            id={e.column.field}
            name={e.column.field}
            format={this.user.settings.formats.DateShort}
            value={date}
            showClearButton={false}
            cssClass='e-hidedate-icon'
            openOnFocus={true}
            showTodayButton={false}
            style={{ fontSize: '15px' }}
          />
        );
      } else {
        return <></>;
      }
    };

    const editAccountNo = (e: any) => {
      let enbl: boolean = true;
      if (!e.startDate) {
        switch (e.column.field) {
          case 'accountNo':
            if (
              e.parentItem.paymentTypeId !==
              this._structure.Bank.PaymentType.BankTransfer.Id
            ) {
              enbl = false;
            }
            break;

          case 'mobileNo':
            if (
              e.parentItem.paymentTypeId !==
              this._structure.Bank.PaymentType.MobileTransfer.Id
            ) {
              enbl = false;
            }
            break;
        }
        return (
          <TextBoxComponent
            id={e.column.field}
            name={e.column.field}
            value={e[e.column.field]}
            onBlur={onAccountNoChange}
            enabled={enbl}
          ></TextBoxComponent>
        );
      } else {
        return <></>;
      }
    };

    const onAccountNoChange = (e: any) => {};

    const editDropDownTree = (e: any) => {
      let fld: any = null;
      if (e.column.field.split.length === 1) {
        fld = e[e.column.field];
      } else if (e.column.field.split.length === 2) {
        fld = e[e.column.field.split('.')[0]][e.column.field.split('.')[1]];
      }

      let treeSettings: TreeSettingsModel = { expandOn: 'Click' };
      if (fld) {
        return (
          <DropDownTreeComponent
            id={e.column.field}
            name={e.column.field}
            value={[fld]}
            //filterBarPlaceholder='Search'
            allowFiltering={true}
            filterType='Contains'
            fields={this.state.fields}
            //treeSettings={treeSettings}
            // change={(e: any) =>
            //   (this.selectedSlabType = e.itemData ? e.itemData.id : null)
            // }
            placeholder='Select'
            popupHeight='220px'
            showClearButton={true}
            style={{ fontSize: '15px' }}
            //focus={dropdownOnFocus}
          />
        );
      } else {
        return <></>;
      }
    };

    const onToolbarClick = (e: any) => {
      if (e.item.id.slice(-3) === 'add') {
        this.setState({ showDialog: true });
      } else if (e.item.id.slice(-5) === 'clear') {
        this.grdBank.clearSelection();
      } else if (e.item.id.slice(-10) === 'addnewdate') {
        this.grdBank.clearSelection();
        this.setState({ showDialog: true });
      }
    };

    const onRowDragStart = (e: any) => {
      if (!e.data[0].parentItem) {
        e.cancel = true;
        return;
      }
      if (
        new Date(e.data[0].parentItem.startDate).getTime() <
        new Date((this.state.openperiod as any).startDate).getTime()
      ) {
        e.cancel = true;
        return;
      }
      this.rowDragStartParentIdx = e.data[0].parentItem.index;
      this.rowDragStartParentChildCount =
        e.data[0].parentItem.taskData.distribution.length;
    };

    const onRowDrag = (e: any) => {
      let rowEle: Element = e.target ? e.target.closest('tr') : null;
      let rowIdx: number = rowEle
        ? (rowEle as HTMLTableRowElement).rowIndex
        : -1;
      let currentData: ITreeData = this.grdBank.getCurrentViewRecords()[rowIdx];

      if (currentData) {
        // e.data[0].priority = (currentData as any).priority;

        if (this.rowDragStartParentIdx !== currentData.parentItem?.index) {
          this.grdBank.rowDragAndDropModule.addErrorElem();
        }
      }
    };

    const onRowDrop = (e: any) => {
      let ds: any = [...this.grdBank.dataSource];
      let reorder: boolean = false;
      if (e.dropPosition == 'middleSegment') {
        e.cancel = true;
        this.grdBank.reorderRows([e.fromIndex], e.dropIndex, 'below');
        reorder = true;
        this.grdBank.dataSource = ds;
        //return;
      }
      if (
        e.dropIndex >
          this.rowDragStartParentIdx + this.rowDragStartParentChildCount ||
        e.dropIndex < this.rowDragStartParentIdx
      ) {
        e.cancel = true;
        return;
      }

      let i: number = 0;
      let dropindex: number = e.dropIndex;
      if (
        e.dropPosition == 'middleSegment' ||
        e.dropPosition == 'bottomSegment'
      ) {
        dropindex = e.dropIndex + 1;
      }

      let idx: number = this.grdBank.dataSource.findIndex(
        (x: any) => x.id === e.data[0].parentItem.id
      );

      this.grdBank.dataSource[idx].distribution.forEach((x: any) => {
        if (reorder === true) {
          x.priority = i + 1;
        } else if (e.fromIndex < dropindex && i + 1 === e.fromIndex) {
          x.priority = dropindex - e.data[0].parentItem.index;
        } else if (
          e.fromIndex > dropindex &&
          i + 1 === e.fromIndex - e.data[0].parentItem.index
        ) {
          x.priority = dropindex - e.data[0].parentItem.index;
        } else if (
          e.fromIndex < dropindex &&
          i + 1 > e.fromIndex - e.data[0].parentItem.index &&
          i + 1 <= dropindex - e.data[0].parentItem.index
        ) {
          x.priority = i;
        } else if (
          e.fromIndex > dropindex &&
          i + 1 >= dropindex - e.data[0].parentItem.index &&
          i + 1 <= e.fromIndex - e.data[0].parentItem.index
        ) {
          x.priority = i + 2;
        } else {
          x.priority = i + 1;
        }

        i = i + 1;
      });

      this.grdBank.dataSource[idx].distribution =
        e.data[0].parentItem.taskData.distribution;

      this.jsonOnRowDrop = [...this.grdBank.dataSource];

      this.SaveData(
        e.data[0].parentItem.taskData,
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/add${
          !this.user.isManager ? '/ess' : ''
        }`,
        e.action
      )
        .then((response: any) => {})
        .catch((error: any) => {});
    };

    const onRowDataBound = (e: any) => {
      if (e.data.priority) {
        e.data.priority = e.data.index - e.data.parentItem.index;
        e.row.cells[e.row.cells.length - 1].textContent = e.data.priority;
      }
    };

    const actionBegin: any = (e: any) => {
      let payload: any = null;
      if (this.grdBank.dataSource.length <= 0) {
        this.grdBank.dataSource = [...this.jsonOnRowDrop];
      }

      if (e.requestType === 'beginEdit') {
        if (e.rowData.parentItem) {
          if (
            new Date(e.rowData.parentItem.startDate).getTime() <
            new Date((this.state.openperiod as any).startDate).getTime()
          ) {
            e.cancel = true;
           
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, You cannot edit the record as period is already closed.</p>',
            });
            return;
          }
          this.selectedBankGroupId = e.rowData.bank.bankId.toString();
          this.selectedBankBranchId = e.rowData.bank.id.toString();
          this.selectedAccountTypeId = e.rowData.accountTypeId.toString();
        } else {
          if (
            new Date(e.rowData.startDate).getTime() <
            new Date((this.state.openperiod as any).startDate).getTime()
          ) {
            e.cancel = true;
           
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, You cannot edit the record as period is already closed.</p>',
            });
            return;
          }
        }
      } else if (
        e.requestType === 'save' &&
        (e.action === 'add' || e.action === 'edit')
      ) {
        if (e.data.parentItem) {
          let fld: string = 'percentage';
          if (
            e.data.parentItem.modeId === this._structure.Bank.Mode.Amount.Id
          ) {
            fld = 'amount';
          }
          let objlastinput: any = document.getElementById(fld);
          if (objlastinput) {
            if (!isNaN(objlastinput.value)) {
              e.data[fld] = Number(objlastinput.value);
            }
          }

          e.data.bank.bankId = this.selectedBankGroupId;
          e.data.bank.id = this.selectedBankBranchId;
          e.data.bankId = this.selectedBankBranchId;
          e.data.accountTypeId = this.selectedAccountTypeId;

          if (!e.data.bank.bankId) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select bank group.</p>',
            });
            e.cancel = true;
            return;
          }

          if (!e.data.bank.id) {
             DialogConfirm.showDialog({
               content: '<p class="dialog-contain">Please select bank branch.</p>',
             });
            e.cancel = true;
            return;
          }

          if (!e.data.accountTypeId) {
             DialogConfirm.showDialog({
               content: '<p class="dialog-contain">Please select account type.</p>',
             });
            e.cancel = true;
            return;
          }

          if (!e.data.accountNo) {
             DialogConfirm.showDialog({
               content: '<p class="dialog-contain">Please enter account no.</p>',
             });
            e.cancel = true;
            return;
          }

          if (
            e.data.parentItem.modeId ===
              this._structure.Bank.Mode.Percentage.Id &&
            !e.data.percentage
          ) {
              DialogConfirm.showDialog({
                content: '<p class="dialog-contain">Please enter percentage.</p>',
              });
            e.cancel = true;
            return;
          }

          if (
            e.data.parentItem.modeId ===
              this._structure.Bank.Mode.Percentage.Id &&
            e.data.percentage > 100
          ) {
           
              DialogConfirm.showDialog({
                content: 'Sorry, Percentage should not be greater than 100.</p>',
              });
            e.cancel = true;
            return;
          }

          if (
            e.data.parentItem.modeId === this._structure.Bank.Mode.Amount.Id &&
            !e.data.amount
          ) {
              DialogConfirm.showDialog({
                content: '<p class="dialog-contain">Please enter percentage.</p>',
              });
            e.cancel = true;
            return;
          }

          let distrib: any = [...e.data.parentItem.taskData.distribution];

          if (distrib.length > 0) {
            distrib[distrib.findIndex((x: any) => x.id === e.data.id)] = e.data;
          }

          payload = {
            id: e.data.parentItem.id,
            employeeId: e.data.parentItem.employeeId,
            startDate: Common.RemoveTimezoneOffsetFromDate(
              new Date(e.data.parentItem.startDate)
            ),
            endDate: e.data.parentItem.endDate
              ? Common.RemoveTimezoneOffsetFromDate(
                  new Date(e.data.parentItem.endDate)
                )
              : null,
            removeEndDateIfNull: true,
            modeId: e.data.parentItem.modeId,
            paymentTypeId: e.data.parentItem.paymentTypeId,
            distribution: distrib.length > 0 ? distrib : [],
          };
        } else {
          if (!e.data.startDate) {
            DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select start date.</p>',
            });
            e.cancel = true;
            return;
          }

          if (!e.data.modeId) {
             DialogConfirm.showDialog({
               content: '<p class="dialog-contain">Please select payment mode.</p>',
             });
            e.cancel = true;
            return;
          }

          if (!e.data.employeeId) {
             DialogConfirm.showDialog({
               content: '<p class="dialog-contain">Please select employee.</p>',
             });
            e.cancel = true;
            return;
          }

          if (!e.data.paymentTypeId) {
             DialogConfirm.showDialog({
              content: '<p class="dialog-contain">Please select payment type.</p>',
            });
            e.cancel = true;
            return;
          }

          payload = {
            id: e.data.id,
            employeeId: e.data.employeeId,
            startDate: Common.RemoveTimezoneOffsetFromDate(
              new Date(e.data.startDate)
            ),
            endDate: e.data.endDate
              ? Common.RemoveTimezoneOffsetFromDate(new Date(e.data.endDate))
              : null,
            removeEndDateIfNull: true,
            modeId: e.data.modeId,
            paymentTypeId: e.data.paymentTypeId,
            distribution: e.data.distribution,
          };
        }

        //this.selectedData = e.data.taskData;

        e.cancel = true;

        this.SaveData(
          payload,
          `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/add${
            !this.user.isManager ? '/ess' : ''
          }`,
          e.action
        )
          .then((response: any) => {
            if (!response.response) {
              e.cancel = false;

              if (e.action === 'add') {
                (this.grdBank.dataSource as any).push(response);
              } else if (e.action === 'edit') {
                (this.grdBank.dataSource as any)[
                  this.grdBank.dataSource.findIndex(
                    (x: any) => x.id === response.id
                  )
                ] = response;
              }
              this.grdBank.refresh();
            } else {
            }
          })
          .catch((error: any) => {
            if (~error.response && !error.response.config.data.id) {
              e.cancel = true;
              (this.state.jsonEmpBank as any).splice(0, 1);
              this.grdBank.refresh();
            }

            return;
          });
      } else if (e.requestType === 'delete') {
        let ds: any = [...this.grdBank.dataSource];
        if (e.data[0].parentItem) {
          if (
            new Date(e.data[0].parentItem.startDate).getTime() <
            new Date((this.state.openperiod as any).startDate).getTime()
          ) {
            e.cancel = true;
          
             DialogConfirm.showDialog({
               content:
                 '<p class="dialog-contain">Sorry, You cannot delete the record as period is already closed.</p>',
             });
            return;
          }
        } else {
          if (
            new Date(e.data[0].startDate).getTime() <
            new Date((this.state.openperiod as any).startDate).getTime()
          ) {
            e.cancel = true;
          
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, You cannot delete the record as period is already closed.</p>',
            });
            return;
          }
        }

        if (e.data[0].parentItem) {
          let ds: any = [...this.grdBank.dataSource];
          this.SaveData(
            {
              id: e.data[0].id,
              EmployeeBankId: e.data[0].parentItem.id,
            },
            `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebankdistribution/delete`,
            e.requestType
          )
            .then((response: any) => {
              if (e.data[0].parentItem.taskData.distribution.length == 1) {
                ds.splice(
                  ds.findIndex((x: any) => x.id === e.data[0].parentItem.id),
                  1
                );
                this.grdBank.dataSource = ds;
                this.grdBank.refresh();
                // this.grdBank.selectedRowIndex = this.grdBank.selectedRowIndex - 1;
                // this.grdBank.editSettings.showDeleteConfirmDialog = false;
                // this.grdBank.deleteRecord();
                // this.grdBank.editSettings.showDeleteConfirmDialog = true;
                // this.grdBank.deleteRow(
                //   this.grdBank.getRowByIndex(this.grdBank.selectedRowIndex)
                // );
              } else {
                let pidx: number = ds.findIndex(
                  (x: any) => x.id === e.data[0].parentItem.id
                );
                let cidx: number = ds[pidx].distribution.findIndex(
                  (x: any) => x.id === e.data[0].id
                );
                ds[pidx].distribution.splice(cidx, 1);
                let i: number = 0;

                ds[pidx].distribution.forEach((x: any, y: any) => {
                  i++;
                  x.priority = i;
                  // if (x.id === e.data[0].id) {
                  //   //i--;
                  // }
                  // x.priority = i;
                  // if (
                  //   this.grdBank.getRowByIndex(
                  //     e.data[0].parentItem.index + y + 1
                  //   )
                  // ) {
                  //   if (
                  //     this.grdBank.getRowByIndex(
                  //       e.data[0].parentItem.index + y + 1
                  //     ).cells[12].textContent != x.priority
                  //   ) {
                  //     this.grdBank.getRowByIndex(
                  //       e.data[0].parentItem.index + y + 1
                  //     ).cells[12].textContent = x.priority;
                  //   }
                  // }
                });
                this.grdBank.dataSource = ds;
                this.grdBank.refresh();
              }
              // if (d.length > 0) {
              //   if (d[0].distribution.length == 0) {
              //     this.grdBank.dataSource.splice(
              //       this.grdBank.selectedRowIndex - 1,
              //       1
              //     );
              //     this.grdBank.refresh();
              //   }
              // }
            })
            .catch((error: any) => {
              this.grdBank.dataSource = ds;
              this.grdBank.refresh();
              console.error(error);
              // if (
              //   error.response &&
              //   error.response.data &&
              //   error.response.data.messages &&
              //   error.response.data.messages.length > 0
              // ) {
              // DialogConfirm.showDialog({
              //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
              // });
              // }
            })
            .finally(() => {});
        } else {
          this.SaveData(
            {
              id: e.data[0].parentItem ? e.data[0].parentItem.id : e.data[0].id,
            },
            `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/bank/employeebank/delete`,
            e.requestType
          )
            .then((response: any) => {
              if (response) {
                let pidx: number = ds.findIndex(
                  (x: any) => x.id === e.data[0].id
                );
                ds.splice(pidx, 1);
                this.grdBank.dataSource = ds;
                this.grdBank.refresh();
              } else {
                this.grdBank.dataSource.splice(
                  this.grdBank.selectedRowIndex,
                  1
                );
                this.grdBank.refresh();
              }
            })
            .catch((error: any) => {
              this.grdBank.dataSource = ds;
              this.grdBank.refresh();
              console.error(error);
              // if (
              //   error.response &&
              //   error.response.data &&
              //   error.response.data.messages &&
              //   error.response.data.messages.length > 0
              // ) {
              // DialogConfirm.showDialog({
              //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
              // });
              // }
            })
            .finally(() => {});
        }
      }
    };

    const actionComplete: any = (e: any) => {
      if (e.requestType === 'beginEdit' || e.requestType === 'add') {
        const dialog: Dialog = e.dialog as Dialog;
        if (e.requestType === 'add') {
          dialog.header = 'Add Employee Bank';
        }
        if (e.requestType === 'beginEdit') {
          dialog.header = 'Edit Employee Bank';
        }
        this.SetDialogCSS(dialog);
      }
    };

    return (
      <>
        <div id='divBank' className='py-2'>
          <div className='control-pane'>
            <div className='control-section'>
              <div className='control_wrapper'>
                <div id='empbanktree' className='empbanktree'>
                  <TreeGridComponent
                    id={'grid-employeebanklist'}
                    dataSource={this.state.jsonEmpBank}
                    height='calc(100vh - 340px)'
                    treeColumnIndex={1}
                    childMapping='distribution'
                    editSettings={this.editSettings}
                    actionBegin={actionBegin}
                    // selectionSettings={{
                    //   type: 'Single',
                    //   persistSelection: true,
                    // }}
                    ref={(obj: any) => (this.grdBank = obj)}
                    toolbar={[
                      {
                        text: 'Add',
                        tooltipText: 'Add',
                        prefixIcon: 'e-add font-18',
                        id: `grid-employeebanklist-gridcontrol_add`,
                      },
                      // {
                      //   text: 'Add New Date',
                      //   tooltipText: 'Add New Effective Date',
                      //   prefixIcon: 'e-add-above font-18',
                      //   id: `grid-employeebanklist-gridcontrol_addnewdate`,
                      // },
                      {
                        text: 'Edit',
                        tooltipText: 'Edit',
                        prefixIcon: 'e-edit font-18',
                        id: `grid-employeebanklist_gridcontrol_edit`,
                      },
                      {
                        text: 'Delete',
                        tooltipText: 'Delete',
                        prefixIcon: 'e-delete font-18',
                        id: `grid-employeebanklist_gridcontrol_delete`,
                      },
                      //...this.toolbarOptions,
                      {
                        text: 'Cancel',
                        tooltipText: 'Cancel',
                        prefixIcon: 'e-cancel font-18',
                        id: `grid-employeebanklist_gridcontrol_cancel`,
                      },
                      // {
                      //   text: '',
                      //   tooltipText: 'Clear Selection',
                      //   prefixIcon: 'e-error-treeview font-18',
                      //   id: `grid-employeebanklist_gridcontrol_clear`,
                      // },
                    ]}
                    toolbarClick={onToolbarClick}
                    allowRowDragAndDrop={true}
                    rowDragStartHelper={onRowDragStart}
                    rowDrag={onRowDrag}
                    rowDrop={onRowDrop}
                    //rowDataBound={onRowDataBound}
                    // allowGrouping={true}
                    // groupSettings={this.groupOptions}
                    // allowSorting={true}
                    // sortSettings={{
                    //   columns: [
                    //     { field: 'startDate', direction: 'Descending' },
                    //   ],
                    // }}
                  >
                    <ColumnsDirective>
                      <ColumnDirective field='employeeId' visible={false} />
                      {/* <ColumnDirective
                        field='customgroupname'
                        headerText='Employee'
                      /> */}
                      <ColumnDirective
                        field='startDate'
                        headerText='Start Date'
                        width='70'
                        type='datetime'
                        format={this.user.settings.formats.DateShort}
                        //allowEditing={false}
                        editType='datepickeredit'
                        editTemplate={editDate}
                        //validationRules={{ required: true }}
                      />
                      <ColumnDirective
                        field='employeeName'
                        headerText='Account Name'
                        width='100'
                        allowEditing={false}
                        //editTemplate={templateEmployee}
                      />
                      {/* <ColumnDirective
                        field='endDate'
                        headerText='End Date'
                        width='100'
                        type='datetime'
                        format={this.user.settings.formats.DateShort}
                        editType='datepickeredit'
                        //editTemplate={editTemplateDate}
                      /> */}
                      <ColumnDirective field='modeId' visible={false} />
                      <ColumnDirective
                        field='modeName'
                        headerText='Bank'
                        width='70'
                        editType='dropdownedit'
                        template={templateDropDown}
                        editTemplate={editDropDown}
                      />
                      <ColumnDirective field='paymentTypeId' visible={false} />
                      <ColumnDirective
                        field='paymentTypeName'
                        headerText='Branch'
                        width='100'
                        editType='dropdownedit'
                        template={templateDropDown}
                        editTemplate={editDropDown}
                      />
                      {/* <ColumnDirective
                        field='bank.bankId'
                        headerText='Bank'
                        width='100'
                        template={templateDropDown}
                        editTemplate={editDropDown}
                      />
                      <ColumnDirective
                        field='bank.id'
                        headerText='Branch'
                        width='100'
                        editType='dropdownedit'
                        template={templateDropDown}
                        editTemplate={editDropDown}
                      /> */}
                      <ColumnDirective
                        field='accountTypeName'
                        headerText='Account Type'
                        width='70'
                        template={templateDropDown}
                        editType='dropdownedit'
                        editTemplate={editDropDown}
                      />
                      <ColumnDirective
                        field='accountNo'
                        headerText='Account No.'
                        width='90'
                        editType='stringedit'
                        editTemplate={editAccountNo}
                      />
                      <ColumnDirective
                        field='mobileNo'
                        headerText='Mobile No.'
                        width='60'
                        editType='stringedit'
                        editTemplate={editAccountNo}
                      />
                      <ColumnDirective
                        field='percentage'
                        headerText='Percentage'
                        width='55'
                        template={amountTemplate}
                        editType='numericedit'
                        editTemplate={editAmount}
                        textAlign='Right'
                      />
                      <ColumnDirective
                        field='amount'
                        headerText='Amount'
                        width='70'
                        template={amountTemplate}
                        editType='numericedit'
                        editTemplate={editAmount}
                        textAlign='Right'
                      />
                      <ColumnDirective
                        field='priority'
                        headerText='Priority'
                        width='40'
                        allowEditing={false}
                        textAlign='Center'
                      />
                    </ColumnsDirective>
                    <Inject
                      services={[Toolbar, Edit, RowDD, Selection, Filter]}
                    />
                  </TreeGridComponent>
                  <SelectorSearch
                    grid_id={'grid-employeebanklist'}
                    showMoreOptions={false}
                  />
                  <div className='p-1'>
                    <i>
                      <b>* Note: &nbsp;</b>Bank account with the lowest priority
                      rank takes all the remaining amount
                    </i>
                  </div>
                  {/* <GridComponent
                    id={'grid-employeebanklist'}
                    dataSource={this.state.jsonEmpBank}
                    height='calc(100vh - 272px)'
                    childGrid={childGrid}
                    detailDataBound={detailDataBound}
                    editSettings={editOptions}
                    toolbar={[
                      ...this.toolbarOptions,
                      {
                        text: 'Save',
                        tooltipText: 'Save',
                        prefixIcon: 'e-update',
                        id: `grid-employeebanklist_update`,
                      },
                      'Cancel',
                    ]}
                    actionBegin={actionBegin}
                    actionComplete={actionComplete}
                    ref={(obj: any) => (this.grdBank = obj)}
                  >
                    <ColumnsDirective>
                      <ColumnDirective field='employeeId' visible={false} />
                      <ColumnDirective
                        field='employeeName'
                        headerText='Employee'
                        width='100'
                        //editTemplate={templateEmployee}
                      />
                      <ColumnDirective
                        field='startDate'
                        headerText='Start Date'
                        width='100'
                        type='datetime'
                        format={this.user.settings.formats.DateShort}
                        editType='datepickeredit'
                        //editTemplate={editTemplateDate}
                        validationRules={{ required: true }}
                      />
                      <ColumnDirective
                        field='endDate'
                        headerText='End Date'
                        width='100'
                        type='datetime'
                        format={this.user.settings.formats.DateShort}
                        editType='datepickeredit'
                        //editTemplate={editTemplateDate}
                      />
                      <ColumnDirective field='modeId' visible={false} />
                      <ColumnDirective
                        field='modeName'
                        headerText='Mode'
                        width='100'
                        //editTemplate={templateBankPayMode}
                      />
                      <ColumnDirective field='paymentTypeId' visible={false} />
                      <ColumnDirective
                        field='paymentTypeName'
                        headerText='Payment Type'
                        width='100'
                        //editTemplate={templatePaymentType}
                      />
                      <ColumnDirective field='distribution' visible={false} />
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Edit, DetailRow]} />
                  </GridComponent> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-employeebankadd'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'25vw'}
            allowDragging={true}
            close={() => {
              this.setState({ showDialog: false });
            }}
            content={() => (
              <EmployeeBank
                //{...props}
                jsonDataViewer={this.props.jsonDataViewer}
                jsonEmployee={this.jsonEmployee}
                jsonPaymentMode={this.jsonPaymentMode}
                jsonPaymentType={this.jsonPaymentType}
                jsonBank={this.state.jsonBankBranch}
                jsonAccountType={this.state.jsonAccountType}
                //jsonDistribution={props.distribution}
                defaultEmployeeId={this.props.jsonDataViewer.id}
                bfields={this.state.fields}
                ref={this.child}
                employeeBankId={
                  this.grdBank.getSelectedRecords().length > 0
                    ? this.grdBank.getSelectedRecords()[0].parentItem
                      ? this.grdBank.getSelectedRecords()[0].parentItem.id
                      : this.grdBank.getSelectedRecords()[0].id
                    : undefined
                }
                // startDate={
                //   this.grdBank.getSelectedRecords().length > 0
                //     ? this.grdBank.getSelectedRecords()[0].parentItem
                //       ? this.grdBank.getSelectedRecords()[0].parentItem
                //           .startDate
                //       : this.grdBank.getSelectedRecords()[0].startDate
                //     : undefined
                // }
                startDate={undefined}
                openperiod={this.state.openperiod}
                modeId={
                  this.grdBank.getSelectedRecords().length > 0
                    ? this.grdBank.getSelectedRecords()[0].parentItem
                      ? this.grdBank.getSelectedRecords()[0].parentItem.modeId
                      : this.grdBank.getSelectedRecords()[0].modeId
                    : undefined
                }
                paymentTypeId={
                  this.grdBank.getSelectedRecords().length > 0
                    ? this.grdBank.getSelectedRecords()[0].parentItem
                      ? this.grdBank.getSelectedRecords()[0].parentItem
                          .paymentTypeId
                      : this.grdBank.getSelectedRecords()[0].paymentTypeId
                    : undefined
                }
              />
            )}
            isModal={true}
            header={`Add Employee Bank`}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
