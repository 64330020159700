import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import React from 'react';
import Common from '../../Util/Common';
import DataGrid from '../DataGrid';
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  DialogEditEventArgs,
  Edit,
  EditEventArgs,
  EditSettingsModel,
  GridComponent,
  GridModel,
  IEditCell,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import SelectorSearch from '../Layout/SelectorSearch';
import { Dialog } from '@syncfusion/ej2-react-popups';
import { DataManager, Query } from '@syncfusion/ej2-data';
import WorkScheduleCreate from './WorkScheduleCreate';
import { cp } from 'fs';
import DialogConfirm from '../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

// * ------------------------------------------------------------------------------------------------------------------3.Style
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// -------------------------------------------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
export default class WorkScheduleList extends React.Component<{}, {}> {
  private user: any;
  private token: any = null;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private GridInstance: GridComponent | any;
  public toolbarOptions: any = ['Add', 'Edit', 'Delete'];
  private _structure: any;
  public validationRule: Object = { required: true };

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
  }

  state = {
    shiftlist: [],
    shifttrans: [],
    showDialog: false,
    _menuitems: undefined,
    deldata: undefined,
  };

  // * ------------------------------------------------------------------------------------------------------------------Event Handler

  componentDidMount() {
    if (this.state.shiftlist.length <= 0) {
      this.fetchShifts();
    }
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  private fetchShifts() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/shift/listshift`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let childdata: any = [];
        if (response.length > 0) {
          for (let i = 0; i < response.length; i++) {
            childdata.push(response[i]['shiftTran']);
          }
        }

        const finalarr = childdata.flat();

        this.setState({
          shiftlist: response,
          shifttrans: finalarr,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private SetDialogCSS(dialog: Dialog) {
    for (let i = 0; i < dialog.buttons.length; i++) {
      let _primary = dialog.buttons[i].buttonModel?.isPrimary;

      let _model = {
        content: Common.toTitleCase(
          dialog.buttons[i].buttonModel?.content?.toLowerCase() == 'save'
            ? 'Accept'
            : `${dialog.buttons[i].buttonModel?.content}`
        ),
        cssClass: 'flat-button',
        isPrimary: _primary,
      };
      dialog.buttons[i].buttonModel = _model;
    }
    dialog.buttons.reverse();
  }

  private cellSave(e: any) {
    if (e.requestType === 'save') {
      let isedit: boolean = false;
      if (e) {
        if (e.data.id !== undefined && e.data.id !== null) {
          isedit = true;
        }

        if (e.data.sf_code == null) {
          e.requestType = 'add';
          e.cancel = true;
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Please enter code to continue.</p>',
          });
          return;
        }
        if (e.data.sf_name == null) {
          e.requestType = 'add';
          e.cancel = true;
             DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Please enter name to continue.</p>',
             });
          return;
        }

        let tran: any = [];
        if (isedit == false) {
          for (let i = 0; i < 7; i++) {
            if (e.data['objDayId_' + i.toString()]) {
              tran.push(JSON.parse(e.data['objDayId_' + i.toString()]));
            }
          }
        }

        let payload: any = {
          id: isedit == true ? e.data.id : null,
          code: e.data.sf_code,
          name: e.data.sf_name,
          ShiftTran: tran,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/shift/add`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            let sdata: any = this.state.shiftlist;
            let stran: any = this.state.shifttrans;
            if (isedit == false) {
              sdata = (sdata as any).filter(
                (x: any) => x.id !== response.id && x.id !== undefined
              );
              sdata.push(response);
              response.shiftTran.map((obj: any) => {
                stran.push(obj);
              });
            } else {
              sdata = sdata.map((obj: any) => {
                if (obj.id === response.id) {
                  return {
                    ...obj,
                    code: response.code,
                    displayWeekHour: response.displayWeekHour,
                    name: response.name,
                    shiftTran: response.shiftTran,
                  };
                }
                return obj;
              });
            }

            if (isedit == false) {
              this.setState({
                shiftlist: sdata,
                shifttrans: stran,
              });
            } else {
              this.setState({
                shiftlist: sdata,
              });
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    } else if (e.requestType === 'delete') {
      this.setState({ deldata: e.data[0] });
      e.data = [];
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove selected shift?</p></div>',
        okCaption: 'Yes',
        closeCaption: 'No',
        OkEvent: this.confirmAction.bind(this),
      });
    }
  }

  confirmAction = () => {
    if (this.state.deldata !== undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/shift/voidshift`,
        { id: (this.state.deldata as any).id },
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            let data = this.state.shiftlist;
            let tran = this.state.shifttrans;
            data = (data as any).filter(
              (x: any) => x.id !== (this.state.deldata as any).id
            );
            tran = (tran as any).filter(
              (x: any) => x.shiftId !== (this.state.deldata as any).id
            );
            this.setState({ shiftlist: data, shifttrans: tran });
          }
        })
        .catch((error: any) => {
          console.error(error);
          // if (
          //   error.response &&
          //   error.response.data &&
          //   error.response.data.messages &&
          //   error.response.data.messages.length > 0
          // ) {
          // DialogConfirm.showDialog({
          //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,

          //    });
          // }
        })
        .finally(() => {});
    }
  };

  private actionComplete = (args: DialogEditEventArgs): void => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog: Dialog = args.dialog as Dialog;
      if (args.requestType === 'add') {
        dialog.header = 'New Shift';
      }
      if (args.requestType === 'beginEdit') {
        dialog.header = 'Edit Shift';
      }
      this.SetDialogCSS(dialog);
    }
  };

  public formatData(date: any) {
    let adate = Common.formatDate(date, 'yyyy-MM-dd');
    let atime = Common.formatDate(date, 'HH:mm:ss');
    let result = adate.concat('T', atime);
    return result;
  }

  // * --------------------------------------------------------------------------------------------------------------------HTML
  render() {
    const dialogTemplate = (props: any) => {
      return (
        <>
          <DialogFormTemplate {...props} {...this.state} />
        </>
      );
    };

    const editSettings: EditSettingsModel = {
      allowEditing: true,
      allowAdding: true,
      allowDeleting: true,
      mode: 'Dialog',
      allowEditOnDblClick: false,
      template: dialogTemplate,
    };

    const datetimepickerparams: IEditCell = {
      params: {
        showClearButton: false,
        format: 'HH:mm',
        step: 5,
        cssClass: 'e-hidedate-icon',
      },
    };

    const UpdateTranData = (data: any) => {
      if (data) {
        let editguid = data.id;

        var diff = (data.endTime.getTime() - data.startTime.getTime()) / 1000;
        diff /= 60;
        let workingmins = Math.abs(Math.round(diff));
        workingmins = workingmins - data.breakHour;

        let payload: any = {
          id: data.id,
          shiftId: data.shiftId,
          dayid: data.dayid,
          startTime: this.formatData(data.startTime),
          endTime: this.formatData(data.endTime),
          breakHour: data.breakHour,
          workingHour: workingmins,
          isWeekend: data.isWeekend,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/shift/updatetran`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            if (response !== '') {
              let tdata: any = this.state.shifttrans;
              if (this.state.shifttrans.length > 0) {
                tdata = tdata.filter((x: any) => x.id !== data.id);
                tdata.push(
                  response.shiftTran.filter((x: any) => x.id == data.id)[0]
                );
              }
              tdata.sort((a: any, b: any) => {
                return a.dayid - b.dayid;
              });

              let sdata: any = this.state.shiftlist;
              if (this.state.shiftlist.length > 0) {
                let fdata: any = sdata.filter((x: any) => x.id == response.id);
                if (fdata) {
                  let finalsft: any = fdata[0].shiftTran.filter(
                    (x: any) => x.id !== data.id
                  );
                  finalsft.push(
                    response.shiftTran.filter((x: any) => x.id == data.id)[0]
                  );
                  finalsft.sort((a: any, b: any) => {
                    return a.dayid - b.dayid;
                  });
                  sdata = sdata.map((obj: any) => {
                    if (obj.id === response.id) {
                      return {
                        ...obj,
                        code: response.code,
                        displayWeekHour: response.displayWeekHour,
                        name: response.name,
                        shiftTran: finalsft,
                      };
                    }
                    return obj;
                  });
                }
              }

              this.setState({
                shiftlist: sdata,
                shifttrans: tdata,
              });
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    };

    const childGrid: GridModel = {
      actionBegin(args: any) {
        if (args.requestType === 'save') {
          if (args.data.endTime <= args.data.startTime) {
            args.requestType = 'add';
            args.cancel = true;
              DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Please set correct end time to continue.</p>',
              });
            return;
          }
          if (args.data.breakHour == null) {
            args.data.breakHour = 0;
          }

          UpdateTranData(args.data);
        }
      },
      columns: [
        { field: 'dayName', headerText: 'Day', allowEditing: false },
        {
          field: 'startTime',
          headerText: 'Start Time',
          type: 'datetime',
          format: 'HH:mm',
          editType: 'datetimepickeredit',
          validationRules: this.validationRule,
          edit: datetimepickerparams,
        },
        {
          field: 'endTime',
          headerText: 'End Time',
          type: 'datetime',
          format: 'HH:mm',
          editType: 'datetimepickeredit',
          validationRules: this.validationRule,
          edit: datetimepickerparams,
        },
        {
          field: 'breakHour',
          headerText: 'Break Time (In Minutes)',
          textAlign: 'Right',
        },
        {
          field: 'displayWorkingHour',
          headerText: 'Working Hour',
          allowEditing: false,
          format: '00.00',
        },
        {
          field: 'isWeekend',
          headerText: 'Weekend',
          displayAsCheckBox: true,
          editType: 'booleanedit',
        },
      ],
      editSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        allowEditOnDblClick: false,
      },
      toolbar: ['Edit', 'Update', 'Cancel'],
    };

    const detailDataBound = (args: any) => {
      let orderData = this.state.shifttrans;
      let empIdValue = args.childGrid.parentDetails.parentRowData.id;
      let matchedData = new DataManager(orderData).executeLocal(
        new Query().where('shiftId', 'equal', empIdValue, true)
      );
      args.childGrid.query = new Query();
      args.childGrid.dataSource = matchedData;

      if (args.data.isDefault) {
        VisibleDeleteButton(true);
      } else {
        VisibleDeleteButton(false);
      }
    };

    const GridRowSelected = (args: any) => {
      if (args.data.isDefault) {
        VisibleDeleteButton(true);
      } else {
        VisibleDeleteButton(false);
      }
    };

    const VisibleDeleteButton = (disabled: any) => {
      let gridRef: any = document.querySelector(`#grid-shift`);
      const gridObj = gridRef.ej2_instances[0];

      if (gridObj) {
        const toolbar = gridObj.toolbarModule.getToolbar();
        const deleteButton = toolbar.querySelector(
          'button#' + gridObj.element.id + '_delete'
        );

        if (deleteButton) {
          deleteButton.disabled = disabled;
          if (disabled) {
            deleteButton.classList.add('e-disabled');
          } else {
            deleteButton.classList.remove('e-disabled');
          }
        }
      }
    };

    return (
      <>
        <div style={{ height: '10px' }}>&nbsp;</div>
        <div className='control-pane'>
          <div className='control-section'>
            <GridComponent
              id='grid-shift'
              dataSource={this.state.shiftlist}
              childGrid={childGrid}
              allowTextWrap={true}
              allowGrouping={false}
              showColumnChooser={false}
              ref={(g) => (this.GridInstance = g)}
              editSettings={editSettings}
              toolbar={this.toolbarOptions}
              height='calc(100vh - 314px)'
              detailDataBound={detailDataBound}
              actionBegin={this.cellSave.bind(this)}
              actionComplete={this.actionComplete.bind(this)}
              rowSelected={GridRowSelected}
            >
              <ColumnsDirective>
                <ColumnDirective field='code' headerText='Code' />
                <ColumnDirective field='name' headerText='Name' />
                <ColumnDirective
                  field='displayWeekHour'
                  headerText='Weekly Hour'
                  format='00.00'
                />
              </ColumnsDirective>
              <Inject services={[Toolbar, Edit, DetailRow]} />
            </GridComponent>
            <SelectorSearch grid_id='grid-shift' />
          </div>
        </div>
      </>
    );
  }
}

export class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private child: WorkScheduleCreate | any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.state = Object.assign({}, props);
    this.child = React.createRef();
  }

  handleChange = (data: any) => {
    if (data) {
    }
  };
  render(): any {
    let data: shiftdatamodel = this.state;

    // data._newdata = (this.state as any)['_newdata'];

    return (
      <>
        <WorkScheduleCreate
          ref={this.child}
          id={Math.random().toString()}
          title=''
          code={data.code}
          name={data.name}
          guid={data.id}
          handleChange={this.handleChange.bind(this)}
        ></WorkScheduleCreate>
      </>
    );
  }
}

export interface shiftdatamodel {
  isAdd?: boolean;
  code?: string;
  name?: string;
  id?: string;
  _newdata?: [];
}
