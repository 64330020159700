import * as React from 'react';
import './ResourceLinkCategory.css';
import Common from '../../../Util/Common';
import DialogConfirm from '../../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  nameTitle?: string;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}

export default class ResourceLinkCategory extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { nameTitle: this.props.nameTitle ?? '' };

  componentDidMount(): void {}

  render() {
    const onCancelClick = (e: any) => {
      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };
    const onSaveClick = (e: any) => {
      if (IsValid() === false) return false;

      let payload: any = {
        nameTitle: this.state.nameTitle,
        isgroup: true,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/set`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          //DialogUtility.alert('Record saved successfully!');
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Record saved successfully!</p>',
          });
          this.setState({
            nameTitle: '',
          });

          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const IsValid = (): boolean => {
      if (!this.state.nameTitle.trim()) {
        //DialogUtility.alert(`Please enter Category.`);
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter Category.</p>',
        });
        return false;
      }

      return true;
    };
    return (
      <>
        <div id='divResourceLinkCategory' className='py-2'>
          <div className='row'>
            <div className='col-12'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Category
              </label>
              <span className='mandatoryinfo'>*</span>
              <input
                className='input-text-border'
                id='nameTitle'
                name='nameTitle'
                type='text'
                placeholder={'Category'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                //autoFocus={true}
                //ref={(obj: any) => (this.txtName = obj)}
                value={this.state.nameTitle}
                onChange={(e: any) => {
                  this.setState({ nameTitle: e.target.value });
                }}
              />
            </div>
          </div>
          <div className='e-footer-content'>
            <div>
              <button
                id='btnCancel'
                className='e-control e-btn e-lib flat-button e-flat'
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                id='btnAccept'
                className='e-control e-btn e-lib flat-button e-primary e-flat'
                onClick={onSaveClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
