import * as React from 'react';
import './CompanyGroup.css';
import Common from '../../../Util/Common';
import DialogConfirm from '../../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

interface Props {
  code?: string;
  name?: string;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}

export default class CompanyGroup extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { code: '', name: '' };

  componentDidMount(): void {}

  render() {
    const onCancelClick = (e: any) => {
      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };

    const onSaveClick = (e: any) => {
      if (IsValid() === false) return false;

      let payload: any = {
        code: this.state.code,
        name: this.state.name,
        totalCompany: 1,
        totalEmployee: 1,
        email: '',
        website: '',
        issuer: '',
        isActive: true,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/tenants/tenantgroup/set`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Record saved successfully!</p>',
          });
          this.setState({
            code: '',
            name: '',
          });

          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const IsValid = (): boolean => {
      if (!this.state.code.trim()) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter Code.</p>',
        });
        return false;
      } else if (!this.state.name.trim()) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter Name.</p>',
        });
        return false;
      }

      return true;
    };

    return (
      <>
        <div id='divCompanyGroup' className='py-2'>
          <div className='row'>
            <div className='col-12'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Code
              </label>
              <span className='mandatoryinfo'>*</span>
              <input
                className='input-text-border'
                id='code'
                name='code'
                type='text'
                placeholder={'Code'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                //autoFocus={true}
                //ref={(obj: any) => (this.txtName = obj)}
                value={this.state.code}
                onChange={(e: any) => {
                  this.setState({ code: e.target.value });
                }}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Name
              </label>
              <span className='mandatoryinfo'>*</span>
              <input
                className='input-text-border'
                id='name'
                name='name'
                type='text'
                placeholder={'Name'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                //autoFocus={true}
                //ref={(obj: any) => (this.txtName = obj)}
                value={this.state.name}
                onChange={(e: any) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </div>
          </div>
          <div className='e-footer-content'>
            <div>
              <button
                id='btnCancel'
                className='e-control e-btn e-lib flat-button e-flat'
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                id='btnAccept'
                className='e-control e-btn e-lib flat-button e-primary e-flat'
                onClick={onSaveClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
