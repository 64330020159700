import { type } from 'os';
import React, { Dispatch } from 'react';

export const IDENTITY = 'identity';
export const USER_DETAIL = 'useretail';
export const CONFIRM = 'confirm';
export const MESSAGE = 'message';

export interface RegistrationState {
  /*step 1 */
  username: string;
  password: string;
  cpassword: string;
  error: string;
  step_no: number;
  /*step 2 */
  employeeid: string;
  fullname: string;
  birthdate: string;
  phoneno: string;
  /*step 2 */
  companyregistered: boolean;
  companycode: string;
  accesscode: string;
}

export const initialStateRegistration: RegistrationState = {
  /*step 1 */
  username: '',
  password: '',
  cpassword: '',
  error: '',
  step_no: 0,
  /*step 2 */
  employeeid: '',
  fullname: '',
  birthdate: '',
  phoneno: '',
  /*step 3 */
  companyregistered: true,
  companycode: '',
  accesscode: '',
};

export type RegistrationAction =
  | { type: 'registration-activestep'; stepno: number }
  | { type: 'registration-error'; message?: string }
  | {
      type: 'registration-field';
      fieldName: string;
      payload: string | boolean;
    };

export const registrationReducer = (
  state: RegistrationState,
  action: RegistrationAction
): RegistrationState => {
  switch (action.type) {
    case 'registration-activestep': {
      return {
        ...state,
        step_no: action.stepno,
        error: '',
      };
    }

    case 'registration-field': {
      return {
        ...state,
        error: '',
        [action.fieldName]: action.payload,
      };
    }

    case 'registration-error': {
      return {
        ...state,
        //username: '',
        //password: '',
        //cpassword: '',
        error:
          action.message && action.message !== '' ? action.message : 'Invalid',
        // step_no:
        //   action.setstep && action.setstep !== ''
        //     ? action.setstep
        //     : state.step_no,
      };
    }

    default:
      return state;
  }
};
