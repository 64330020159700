import React, { useState, ChangeEvent, FormEvent } from 'react';
import Common from '../Util/Common';

// import {
//   DialogComponent,
//   Dialog,
// } from '@syncfusion/ej2-react-popups';
import Email from './Email/Email';
import DialogConfirm from './Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_USERSERVICE } = process.env;

interface ResetProps {
  sendDataToForgot: (data: string) => void;
}

interface ResetState {
  resetData: string;
  message: undefined;
  token: undefined;
  username: undefined;
  newpassword: undefined;
  confirmpassword: undefined;
}

export default class ResetPassword extends React.Component<
  ResetProps,
  ResetState
> {
  constructor(props: ResetProps) {
    super(props);
    this.state = {
      resetData: '',
      message: undefined,
      token: undefined,
      username: undefined,
      newpassword: undefined,
      confirmpassword: undefined,
    };
  }

  componentDidMount(): void {}

  private handleResetPassword() {
    // if (
    //   document.getElementById('txtToken') !== undefined &&
    //   (document.getElementById('txtToken') as any).value == ''
    // ) {
    // DialogConfirm.showDialog({
    //   content: '<p class="dialog-contain">Please enter Token to continue.</p>',
    // });
    //   return;
    // }

    if (
      document.getElementById('txtEmail') !== undefined &&
      (document.getElementById('txtEmail') as any).value == ''
    ) {
      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Please enter Email to continue.</p>',
      // });
      alert('Please enter Email to continue.');
      return;
    }

    if (
      document.getElementById('txtNewPassword') !== undefined &&
      (document.getElementById('txtNewPassword') as any).value == ''
    ) {
      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Please enter Nw Password to continue.</p>',
      // });
      alert('Please enter New Password to continue.');
      return;
    }

    if (
      document.getElementById('txtConfirmPassword') !== undefined &&
      (document.getElementById('txtConfirmPassword') as any).value == ''
    ) {
      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Please enter Confirm Password to continue.</p>',
      // });
      alert('Please enter Confirm Password to continue.');
      return;
    }

    if (
      (document.getElementById('txtNewPassword') as any).value !==
      (document.getElementById('txtConfirmPassword') as any).value
    ) {
      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Sorry, New password and confirmation password do not match.</p>',
      // });
      alert('Sorry, New password and confirmation password do not match.');
      return;
    }

    // this.state.confirmpassword = (
    //   document.getElementById('txtConfirmPassword') as any
    // ).value;

    let qparam: any = new URLSearchParams(window.location.search);
    if (qparam.size <= 0 || !qparam.get('Token')) {
      // DialogConfirm.showDialog({
      //   content:  '<p class="dialog-contain">Invalid Token. <br><br> Please check your email to reset your password.</p>',
      // });
      alert(
        'Invalid Token. \n\n Please check your email to reset your password.'
      );
      return;
    }

    let requestdata: any = {
      Email: (document.getElementById('txtEmail') as any).value,
      Password: (document.getElementById('txtNewPassword') as any).value,
      //Token: (document.getElementById('txtToken') as any).value,
      Token: qparam.get('Token'),
    };

    Common.ApiCallAsync(
      'post',
      `${REACT_APP_ENDPOINT_USERSERVICE}/gettenant`,
      requestdata
    ).then((response: any) => {
      let _tenant: any = response.data;
      if (_tenant == '') {
        _tenant = 'root';
      }

      Common.ApiCallAsync(
        'post',
        `${REACT_APP_ENDPOINT_USERSERVICE}/reset-password`,
        requestdata,
        '',
        null,
        _tenant
      ).then((response: any) => {
        if (response.data === 'Succeeded') {
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Your password has been successfully reset. You will be redirected to the login page to access your account with your new password.</p>',
          // });
          alert(
            'Your password has been successfully reset. You will be redirected to the login page to access your account with your new password.'
          );
          //(document.getElementById('txtToken') as any).value = '';
          (document.getElementById('txtEmail') as any).value = '';
          (document.getElementById('txtNewPassword') as any).value = '';
          (document.getElementById('txtConfirmPassword') as any).value = '';
          this.setState({
            token: undefined,
            username: undefined,
            newpassword: undefined,
            confirmpassword: undefined,
            message: undefined,
            resetData: 'success',
          });
          this.props.sendDataToForgot(this.state.resetData);
        } else {
          this.setState({
            message: response.data,
          });
        }
      });
    });
  }
  render() {
    return (
      <>
        <div className='col-12 py-2' style={{ textAlign: 'center' }}>
          <b>
            <label style={{ fontSize: '15px' }}>Reset Password</label>
          </b>
        </div>
        {/* <div className='col-12 py-2'>
          <input
            className='input-text-border'
            id='txtToken'
            name='txtToken'
            type='text'
            placeholder='Enter Token'
            autoComplete='off'
            style={{ fontSize: '15px' }}
            required={true}
            defaultValue={this.state.token}
          />
        </div> */}

        <div className='col-12 py-2'>
          <input
            className='input-text-border1 w-100 p-2'
            id='txtEmail'
            name='txtEmail'
            type='text'
            placeholder='Enter Email or Username'
            autoComplete='off'
            style={{ fontSize: '15px' }}
            required={true}
            defaultValue={this.state.username}
          />
        </div>

        <div className='col-12 py-2'>
          <input
            className='input-text-border1 w-100 p-2'
            id='txtNewPassword'
            name='txtNewPassword'
            type='password'
            placeholder='Enter New Password'
            autoComplete='off'
            style={{ fontSize: '15px' }}
            required={true}
            defaultValue={this.state.newpassword}
          />
        </div>

        <div className='col-12 py-2'>
          <input
            className='input-text-border1 w-100 p-2'
            id='txtConfirmPassword'
            name='txtConfirmPassword'
            type='password'
            placeholder='Confirm Password'
            autoComplete='off'
            style={{ fontSize: '15px' }}
            required={true}
            defaultValue={this.state.confirmpassword}
          />
        </div>

        <div className='col-12 d-grid gap-2'>
          <button
            className='btn submit'
            onClick={this.handleResetPassword.bind(this)}
          >
            Submit
          </button>
        </div>
        <div className='col-12 py-2' style={{ textAlign: 'left' }}>
          <label id='lblMeessage' style={{ fontSize: '15px', color: 'red' }}>
            {this.state.message}
          </label>
        </div>
      </>
    );
  }
}
