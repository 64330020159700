import React, { ReactNode, startTransition, useEffect } from 'react';
import Common from '../../Util/Common';
import './YearlyCalendar.css';
import {
  ContextMenuComponent,
  MenuItemModel,
  MenuEventArgs,
} from '@syncfusion/ej2-react-navigations';
import { leave_contextmenu } from '../../dummydata/xml_data_employee';
import axios from 'axios';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import { DateTime } from '@syncfusion/ej2-react-charts';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import LeaveApplication from '../Leave/LeaveApplication';

const { REACT_APP_ENDPOINT_LEAVESERVICE, REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface _timeline {
  _years: number[];
  _employee: any;
  _firstDayOfWeek: number;
  _showWeekSeparators: boolean;
  _forceFullWeeks: boolean;
  _cmenuitems: any;
  _reversemonthorder?: boolean;
  _fromdate?: any;
  _todate?: any;
  _applicationid?: any;
  _leaverange?: any;
  _leavecolor?: any;
  onDayClick?: (response: any) => void;
}

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

export default class LeaveTimeLine extends React.Component<_timeline> {
  private absences: any = undefined;
  private user: any;
  private _structure: any;
  private _fulljson: any;

  private dragevt: any = false;
  private cMenu: ContextMenuComponent | null = null;
  private menuItem: MenuItemModel[] = [];
  private contextmenu: any = undefined;
  private cntxitems: MenuItemModel[] = [];
  private _objectId: any;
  private token: any = null;
  private _curryear: number = 0;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    var _result: any;
    parser.parseString(leave_contextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];

    if (this.props._years.length == 1) {
      this._curryear = this.props._years[0];
    }
    else {
      this._curryear = this.props._years.filter((x: any) => x == new Date().getFullYear())[0];
    }
  }

  state = {
    selectedDay: new Date(),
    selectedRange: [,],
    selectRange: false,
    selectingRange: [,],
    _holiday: [],
    _absences: undefined,
    _menuitems: undefined,
    leavetypes: [],
    showDialog: false,
    showApplicationDialog: false,
    year: 0,
    _leaverange: [],
  };

  componentDidMount() {
    if (this.state.year <= 0) {
      this.setState({ year: this._curryear })
      this.fetchHoliday(new Date().getFullYear())
    }

    if (this.state._absences == undefined) {
      this.GetIssuedLeaves();
    }

    if (this.state._leaverange.length <= 0) {
      if (this.props._leaverange) {
        let _items: any = [];
        this.props._leaverange.forEach((x: any) => {
          _items.push(x);
        });

        this.setState({ _leaverange: _items });
      }
    }
  }

  private GetIssuedLeaves() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getissued${!this.user.isManager ? '/ess' : ''
      }`,
      {
        employeeId: this.props._employee.id,
        leaveApplicationId: this.props._applicationid ? this.props._applicationid : null,
        excludeCancelledDays: this.props._applicationid == undefined ? true : false
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((resopnse: any) => {
        this.absences = resopnse;
        this.setState({
          _absences: this.absences,
          selectedRange: [,],
          selectingRange: [,],
        });
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          DialogConfirm.showDialog({
            content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,
          });
        }
      })
      .finally(() => { });
  }

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        if (response.data.length > 0) {
          this.setState({ _holiday: response.data })
        }
        else {
          this.googleholiday(startDate, endDate);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private async googleholiday(startDate: any, endDate: any) {
    let hcode = this.user.holidayIsoCode;
    let gurl = `https://www.googleapis.com/calendar/v3/calendars/en.${hcode}%23holiday%40group.v.calendar.google.com/events?timeMin=${startDate}T00:00:00Z&timeMax=${endDate}T00:00:00Z&key=AIzaSyAt9uQQ6EdGZ_RmfzF9siG7QtghkPVi91I`;
    console.log(gurl);
    const res = await axios({
      method: 'get',
      url: gurl,
    });

    if (res) {
      let holidays = res.data.items.map((item: any) => ({
        "name": item?.summary,
        "date": item?.start?.date + 'T00:00:00Z'
      }));
      this.setState({ _holiday: holidays })
    }

    return res;
  }

  private onPrevYear() {
    const currentIndex = this.props._years.indexOf(this.state.year);
    if (currentIndex > 0) {
      this.setState({ year: this.props._years[currentIndex - 1], _holiday: [] })
      this.fetchHoliday(this.props._years[currentIndex - 1]);
    }
  }

  private onNextYear() {
    const currentIndex = this.props._years.indexOf(this.state.year);
    if (currentIndex < this.props._years.length - 1) {
      this.setState({ year: this.props._years[currentIndex + 1], _holiday: [] })
      this.fetchHoliday(this.props._years[currentIndex + 1])
    }
  }

  private DaysOfWeek(
    firstDayOfWeek: number,
    forceFullWeeks: boolean,
    showWeekSeparators: boolean,
    _year: number
  ) {
    var cday = firstDayOfWeek;
    const dates = [
      new Date(2023, 6, 2),
      new Date(2023, 6, 3),
      new Date(2023, 6, 4),
      new Date(2023, 6, 5),
      new Date(2023, 6, 6),
      new Date(2023, 6, 7),
      new Date(2023, 6, 8),
    ];
    var fday = dates.length - 1;
    const totalDays = forceFullWeeks ? 42 : 37;
    const days: any = [];
    this.range(firstDayOfWeek, totalDays + firstDayOfWeek).forEach((i) => {
      if (cday > fday) {
        cday = 0;
      }
      const day = Common.formatDate(new Date(dates[cday]), 'EEE').charAt(0);
      cday++;
      if (showWeekSeparators) {
        if (i % 7 === firstDayOfWeek && days.length) {
          // push week separator
          days.push(<th className='week-separator' key={`seperator-${i}`} />);
        }
      }
      days.push(<th key={`weekday-${i}`}><span style={{ fontSize: '11pt' }}>{day}</span></th>);
    });
    return (
      <tr>
        <th style={{ textAlign: 'right' }}>
          {/* <span style={{ fontSize: '13pt' }}>{_year}</span> */}
        </th>
        {days}
      </tr>
    );
  }

  private processMonthDays(
    year: number,
    firstDayOfWeek: number,
    forceFullWeeks: boolean,
    showWeekSeparators: boolean
  ) {
    var _holiday: any; //this.getYearlyHoliday(year);

    var _months: any = [];
    if (this.props._fromdate !== undefined && this.props._todate !== undefined) {
      let start = new Date(this.props._fromdate);
      while (start <= this.props._todate) {
        _months.push(start.getMonth());
        start.setMonth(start.getMonth() + 1);
      }
    }
    else {
      _months = this.range(0, 12);
    }


    if (
      this.props._reversemonthorder !== undefined &&
      this.props._reversemonthorder === true
    ) {
      _months.reverse();
    }
    var _jsxElement: any[] = [];
    for (var i = 0; i < _months.length; i++) {
      _jsxElement.push(
        <>
          <tr>
            <td className='month-name'>
              {Common.formatDate(new Date(year, _months[i], 1), 'MMMM')}
            </td>
            {this.MonthDays(
              year,
              _months[i],
              firstDayOfWeek,
              forceFullWeeks,
              showWeekSeparators,
              _holiday
            )}
          </tr>
        </>
      );
    }
    return _jsxElement;
  }

  private daysInYear(year: number) {
    return this.isLeapYear(year) ? 366 : 365;
  }

  private weekdaynumber(date: Date) {
    return date.getDay();
  }

  private range(start: any, stop: any, step?: any) {
    if (stop == null) {
      stop = start || 0;
      start = 0;
    }
    step = step || 1;

    const length = Math.max(Math.ceil((stop - start) / step), 0);
    const range = Array(length);

    for (let idx = 0; idx < length; idx++, start += step) {
      range[idx] = start;
    }

    return range;
  }

  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  onUp(day: any, classes: any, timestamp: any, _dragevt: boolean) {
    if (day.nativeEvent.button == 0) {
      if (this.props._applicationid == undefined) {
        this.dragevt = _dragevt;
        // this.dayClicked1(day, classes, timestamp);
        if (_dragevt == false) {
          let { selectingRange } = this.state;

          if (selectingRange) {
            // selectingRange[1] = hoveredDay;
            selectingRange[1] = timestamp;
            this.setState({
              selectingRange
            });
          }
          if (classes.includes('weekendcolor') == false) {
            this.setState({ showApplicationDialog: true })
          }
        }
      }
      else {
        if (this.state._leaverange.filter((x: any) => x == day.currentTarget.id).length > 0) {
          if (day.currentTarget.style.background !== '') {
            day.currentTarget.style.background = '';
            day.currentTarget.childNodes[0].style.color = 'black'
            this.props.onDayClick && this.props.onDayClick(day.currentTarget.id + '|R');
          }
          else {
            day.currentTarget.style.background = this.props._leavecolor;
            day.currentTarget.childNodes[0].style.color = 'white'
            this.props.onDayClick && this.props.onDayClick(day.currentTarget.id + '|A');
          }
        }
        else {
          day.preventDefault();
        }
      }
    } else if (day.nativeEvent.button == 2) {
      day.preventDefault();
    }
  }

  onClick(day: any, classes: any, timestamp: any, _dragevt: boolean) {
    if (day.nativeEvent.button == 0) {
      this.dragevt = _dragevt;
      this.dayClicked1(day, classes, timestamp);
    }
  }

  onHover(day: any, timestamp: any) {
    this.dayHovered(day, timestamp);
  }

  private rightClick(evt: any, id: string) {
    if (evt.nativeEvent.button == 2) {
      evt.preventDefault();

      if (this.cMenu) {
        let obj1: HTMLElement = document.getElementById(id) as HTMLElement;
        if (obj1.classList.contains('range') == true) {
          let c_area: any = obj1.getBoundingClientRect();
          this.cMenu.open(c_area.top + c_area.height, c_area.left);
        }
      }
    }
  }

  private dayClicked1(date: any, classes: any, timestamp: any) {
    if (date.nativeEvent.button == 0) {
      if (this.dragevt == true) {
        this.datePicked(new Date(timestamp), classes);

        let { selectingRange } = this.state;

        if (selectingRange !== undefined) {
          selectingRange = [,];
          var _end: Date = new Date(timestamp);
          selectingRange = [timestamp, _end.getTime()];
        }

        this.setState({
          selectingRange,
        });
      } else if (this.dragevt == false) {
        let { selectingRange } = this.state;
        if ((selectingRange as any)[0] == undefined) {
          (selectingRange as any)[0] = timestamp;
        }
        if ((selectingRange as any)[0] >= timestamp) {
          this.rangePicked(timestamp, (selectingRange as any)[0]);
        } else {
          this.rangePicked((selectingRange as any)[0], timestamp);
        }
      }
    } else if (date.nativeEvent.button == 2) {
      date.preventDefault();
    }
  }

  private dayClicked(date: any, classes: any, timestamp: any) {
    if (!date) {
      // clicked on prev or next month
      return;
    }

    let { selectingRange } = this.state;

    if (!this.state.selectRange) {
      this.datePicked(new Date(timestamp), classes);
      //return;
    }

    if (!selectingRange) {
      var _end: Date = new Date(timestamp);
      selectingRange = [timestamp, _end.getTime()];
    } else {
      if ((selectingRange as any)[0] == undefined) {
        (selectingRange as any)[0] = timestamp;
      }
      if ((selectingRange as any)[0] >= timestamp) {
        this.rangePicked(timestamp, (selectingRange as any)[0]);
      } else {
        this.rangePicked((selectingRange as any)[0], timestamp);
      }
      //selectingRange = [,];
    }

    this.setState({
      selectingRange,
    });
  }

  private datePicked(date: any, classes: any) {
    this.setState({
      selectedDay: date,
      // selectedRange: [date, new Date(date.setDate(15))],
      selectedRange: [date, date],
    });
  }

  private rangePicked(start: any, end: any) {
    this.setState({
      selectedRange: [start, end],
      selectedDay: start,
    });
  }

  private dayHovered(hoveredDay: any, timestamp: any) {
    if (this.dragevt == true) {
      if (!hoveredDay) {
        // clicked on prev or next month
        return;
      }

      const { selectingRange } = this.state;

      if (selectingRange) {
        // selectingRange[1] = hoveredDay;
        selectingRange[1] = timestamp;
        this.setState({
          selectingRange,
        });
      }
    } else {
    }
  }

  private MonthDays(
    year: number,
    month: number,
    firstDayOfWeek: number,
    forceFullWeeks: boolean,
    showWeekSeparators: boolean,
    holidayrange: any[]
  ) {
    const monthStart = new Date(year, month, 1); // current day
    let prevMonthDaysCount = this.weekdaynumber(monthStart);
    while (prevMonthDaysCount < firstDayOfWeek) {
      prevMonthDaysCount += 7;
    }
    const numberOfDays = this.daysInMonth(year, month);
    const totalDays = forceFullWeeks ? 42 : 37;

    // day-generating loop
    const days: any = [];
    this.range(firstDayOfWeek + 1, totalDays + firstDayOfWeek + 1).forEach(
      (i) => {
        var day = new Date(year, month, i - prevMonthDaysCount);

        // pick appropriate classes
        const classes: any = [];
        const title = day ? day : undefined;

        if (day.getTime() < new Date().getTime()) {
          //classes.push('prev-day');
        }

        if ((i - 7) % 7 === 0) {
          // Saturday
          classes.push('saturdaycolor');
        }

        if ((i - 1) % 7 === 0) {
          // sunday
          classes.push('weekendcolor');
        }

        if (i <= prevMonthDaysCount) {
          classes.push('prev-month');
        } else if (i > numberOfDays + prevMonthDaysCount) {
          classes.push('next-month');
        } else {
          if (this.isEmpty(this.state.selectedRange) === false) {
            if (
              this.state.selectedRange[0] !== undefined &&
              day.getFullYear() ===
              new Date(this.state.selectedRange[0]).getFullYear()
            ) {
              // selectingRange is used while user is selecting a range
              // (has clicked on start day, and is hovering end day - but not yet clicked)
              let start = (this.state.selectingRange ||
                this.state.selectedRange)[0];
              let end = (this.state.selectingRange ||
                this.state.selectedRange)[1];

              var _start = start !== undefined ? new Date(start) : undefined;
              var _end = end !== undefined ? new Date(end) : undefined;

              // validate range
              if (_end !== undefined && _start !== undefined && _end < _start) {
                [end, start] =
                  this.state.selectingRange || this.state.selectedRange;
              }

              if (
                _end !== undefined &&
                _start !== undefined &&
                day.getTime() >= new Date(_start).getTime() &&
                day.getTime() <= new Date(_end).getTime()
              )
                if (
                  (classes.includes('saturdaycolor') ||
                    classes.includes('weekendcolor')) == false
                ) {
                  classes.push('range');
                }
            }
          }

          // if (day.isSame(start, "day")) {
          if (_start !== undefined && day == _start) {
            classes.push('range-left');
          }

          // if (day.isSame(end, "day")) {
          if (_end !== undefined && day == _end) {
            classes.push('range-right');
          }
        }

        if (year === new Date().getFullYear()) {
          if (
            Common.formatDate(day, 'yyyyMMdd') ===
            Common.formatDate(new Date(), 'yyyyMMdd')
          ) {
            if (
              (classes.includes('next-month') ||
                classes.includes('prev-month')) == false
            ) {
              classes.push('selected');
            }
          }
        }

        if (day.getTime() > new Date().getTime()) {
          classes.push('gray-day');
        }

        if (showWeekSeparators) {
          if ((i - 1) % 7 === firstDayOfWeek && days.length) {
            // push week separator
            days.push(<td className='week-separator' key={`seperator-${i}`} />);
          }
        }

        var _jsxElement: any[] = [];
        var _absent = this.setAbsense(day, classes);
        var _holiday = this.setHoliday(day);

        let _eleid = '';

        if (
          (classes.includes('next-month') || classes.includes('prev-month')) ==
          false
        ) {
          _eleid = day.getTime().toString();
        }

        if (
          _absent !== undefined &&
          _absent !== '' &&
          _holiday !== undefined &&
          _holiday !== ''
        ) {
          _jsxElement.push(
            <>
              <td
                style={{ background: _absent.split('|')[0], }}
                className={classes.join(' ')}
                title={_absent.split('|')[1] + '\n' + _holiday}
                id={_eleid}
              >
                {classes.includes('next-month') ||
                  classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span
                    className='holiday-number'
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }
        if (
          _absent !== undefined &&
          _absent !== '' &&
          (_holiday == undefined || _holiday == '')
        ) {
          _jsxElement.push(
            <>
              <td
                style={{ background: _absent.split('|')[0], }}
                className={classes.join(' ')}
                title={_absent.split('|')[1]}
                id={_eleid}
                onMouseUp={(d) => this.props._applicationid ? this.onUp(d, classes.join(' '), day.getTime(), false) : undefined}
              >
                {classes.includes('next-month') ||
                  classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span style={{ color: 'white' }}>
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }

        if (
          _holiday !== undefined &&
          _holiday !== '' &&
          (_absent == undefined || _absent == '')
        ) {
          _jsxElement.push(
            <>
              <td className={classes.join(' ')} title={_holiday} id={_eleid} style={{}}>
                {classes.includes('next-month') ||
                  classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span
                    className='holiday-number'
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }
        if (
          (_absent == undefined || _absent == '') &&
          (_holiday == undefined || _holiday == '')
        ) {
          _jsxElement.push(
            <>
              <td
                onMouseDown={(d) => this.props._applicationid == undefined ? this.onClick(d, classes.join(' '), day.getTime(), true) : null}
                onMouseMove={(d) => this.props._applicationid == undefined ? this.onHover(d, day.getTime()) : undefined}
                onMouseUp={(d) => this.props._applicationid == undefined ? this.onUp(d, classes.join(' '), day.getTime(), false) : undefined}

                // onMouseMove={(d) => this.onHover(d, day.getTime())}
                // onMouseUp={(d) => this.onUp(d, classes.join(' '), day.getTime(), false)}
                onContextMenu={(e) => this.rightClick(e, day.getTime().toString())}
                className={classes.join(' ')}
                style={{}}
                id={_eleid}
              >
                {classes.includes('next-month') ||
                  classes.includes('prev-month') == true ? (
                  <span></span>
                ) : (
                  <span className='day-number'>
                    {day === null ? '' : day.getDate()}
                  </span>
                )}
              </td>
            </>
          );
        }
        days.push(_jsxElement[0]);
      }
    );

    return days;
  }

  private setHoliday(idate: Date) {
    var _cvalue = '';
    const holidaysOnDate = this.state._holiday.filter((e: any) => new Date(e.date).getFullYear() === idate.getFullYear()
      && new Date(e.date).getMonth() === idate.getMonth()
      && new Date(e.date).getDate() === idate.getDate());
    if (holidaysOnDate.length > 0) {
      _cvalue = holidaysOnDate.map((e: any) => e.name).join('\n');
    }
    return _cvalue;
  }

  private daysInMonth(year: number, month: number) {
    if (isNaN(year) || isNaN(month)) {
      return NaN;
    }
    var modMonth: number = this.mod(month, 12);
    year += (month - modMonth) / 12;
    return modMonth === 1
      ? this.isLeapYear(year)
        ? 29
        : 28
      : 31 - ((modMonth % 7) % 2);
  }

  private mod(num1: number, num2: number) {
    return ((num1 % num2) + num2) % num2;
  }

  private isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  private processCaptions(_year: number) {
    var _caption: any = [];

    var _absent: any;
    _absent = this.state._absences;

    if (_absent !== undefined && _absent.length > 0) {
      const leaveTypesMap: Map<string, number> = new Map();
      const processedNames: Set<string> = new Set();

      _absent[0].leavesIssued.forEach((leave: any) => {
        let data = leave.appliedDays.filter(
          (x: any) => new Date(x.appliedDate).getFullYear() === _year
        );
        if (!processedNames.has(leave.name + '|' + leave.color)) {
          let totalDays = data.length;
          if (totalDays > 0) {
            leaveTypesMap.set(leave.name + '|' + leave.color, totalDays);
            processedNames.add(leave.name + '|' + leave.color);
          }
        }
      });
      const leaveTypesTotalDays = Array.from(leaveTypesMap.entries()).map(
        ([name, totalDays]) => ({ name, totalDays })
      );

      leaveTypesTotalDays.map((leaveType, index) =>
        _caption.push(
          <div
            style={{
              color: leaveType.name.split('|')[1],
              marginRight: '5px',
              fontSize: '14px',
            }}
          >
            {leaveType.name.split('|')[0]} : {leaveType.totalDays}
          </div>
        )
      );

      return _caption;
    }
  }

  private setAbsense(idate: any, classes: any) {
    var _absent: any;
    _absent = this.state._absences;

    var _cvalue: any;
    if (_absent !== undefined && _absent.length > 0) {
      var _accounts: any = [];

      _absent[0].leavesIssued.forEach((leave: any) => {
        const appliedDay = leave.appliedDays.find(
          (appliedDay: { appliedDate: any }) =>
            new Date(appliedDay.appliedDate).getTime() === idate.getTime()
        );
        if (appliedDay) {
          if (this.props._leaverange && this.props._leaverange.filter((x: any) => idate.getTime() == x).length > 0) {
            if (
              (classes.includes('next-month') ||
                classes.includes('prev-month')) == false
            ) {
              _cvalue = leave.color + '|' + leave.name;
            }
          }
          else {
            if (
              (classes.includes('next-month') ||
                classes.includes('prev-month')) == false
            ) {
              if (this.props._applicationid) {
                _cvalue = '#B2BEB5' + '|' + leave.name;
              } else {
                _cvalue = leave.color + '|' + leave.name;
              }
            }
          }
        }
      });

    }
    return _cvalue;
  }

  handleSave = (data: any) => {
    if (data !== undefined) {
      let strMsg: any = '';
      strMsg = 'Leave request (' + data.leaveType.name + ') for ' +
        Common.formatDate(new Date(data.startDate), 'MMM dd, yyyy') +
        ' to ' +
        Common.formatDate(new Date(data.endDate), 'MMM dd, yyyy') +
        ' has been successfully submitted. You will be notified once your request is approved.';
      DialogConfirm.showDialog({
        content:
          `<p class="dialog-contain">${strMsg}</p>`,
      });
      this.setState({ showDialog: false, istabloaded: false })
    }
  };

  render() {
    return (
      <>
        <div className="calendar-controls">
          <div className="control" onClick={() => this.onPrevYear()}>
            &laquo;
          </div>
          <div className="current-year">{this.state.year}</div>
          <div className="control" onClick={() => this.onNextYear()}>
            &raquo;
          </div>
        </div>
        <div>
          <table className='calendar'>
            <thead className='day-headers'>
              {this.DaysOfWeek(
                this.props._firstDayOfWeek,
                this.props._forceFullWeeks,
                this.props._showWeekSeparators,
                this.state.year
              )}
            </thead>
            <tbody>
              {this.processMonthDays(
                this.state.year,
                this.props._firstDayOfWeek,
                this.props._forceFullWeeks,
                this.props._showWeekSeparators
              )}
              <tr
                style={{
                  height: '40px',
                  fontWeight: 'bold',
                }}
              >
                <td
                  colSpan={this.props._forceFullWeeks ? 42 : 37}
                  style={{ textAlign: 'left', whiteSpace: 'nowrap' }}
                >
                  <div style={{ display: 'flex' }}>
                    {this.props._applicationid === undefined ? this.processCaptions(this.state.year) : null}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          {
            this.state.showApplicationDialog == true ? (
              <DialogComponent
                id='dlgLeaveApplication'
                showCloseIcon={true}
                header={'Leave Application'}
                width={'40vw'}
                visible={this.state.showApplicationDialog}
                close={() => this.setState({ showApplicationDialog: false })}
                content={() => (
                  <LeaveApplication
                    employeeID={this.props._employee.id}
                    leaveobj={undefined}
                    isFromHome={true}
                    onSave={this.handleSave}
                    onCancel={() => this.setState({ showApplicationDialog: false })}
                    startdate={new Date((this.state.selectingRange[0] as any))}
                    enddate={new Date((this.state.selectingRange[1] as any))}
                  ></LeaveApplication>
                )
                }
                isModal={true}
                buttons={[{}]}
                statelessTemplates={[]}
              ></DialogComponent>
            ) : null
          }
        </div>
      </>
    );
  }

}