// * -----------------------------------------------------------------------------------------------------------------1.Aknowlege

// * -----------------------------------------------------------------------------------------------------------------2.Initialize
import * as React from 'react';
import Common from '../../Util/Common';
import PeriodSelector from '../PeriodSelector/PeriodSelector';
import {
  Column,
  ColumnDirective,
  ColumnModel,
  ColumnsDirective,
  Edit,
  ExcelExportProperties,
  GridComponent,
  PdfExportProperties,
  Toolbar as GToolbar,
  Inject as GInject,
} from '@syncfusion/ej2-react-grids';
import './Payroll.css';
import SelectorSearch from '../Layout/SelectorSearch';
import {
  PaneDirective,
  PanePropertiesModel,
  PanesDirective,
  SplitterComponent,
} from '@syncfusion/ej2-react-layouts';
import {
  ContextMenuComponent,
  MenuItemModel,
  Tab,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations';
import {
  ButtonPropsModel,
  Dialog,
  DialogComponent,
} from '@syncfusion/ej2-react-popups';
import {
  PivotViewComponent,
  IDataOptions,
  Inject,
  FieldList,
  CalculatedField,
  Toolbar,
  PDFExport,
  ExcelExport,
} from '@syncfusion/ej2-react-pivotview';
import CreatePeriod from '../PeriodSelector/CreatePeriod';
import {
  paysummary_contextmenu,
  paysummary_empcontextmenu,
} from '../../dummydata/xml_data_employee';
import {
  IProgressValueEventArgs,
  ProgressBarComponent,
} from '@syncfusion/ej2-react-progressbar';
import * as signalR from '@microsoft/signalr';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {
  ButtonComponent,
  CheckBoxComponent,
  RadioButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { cssClass } from '@syncfusion/ej2-react-lists';
import { text } from 'stream/consumers';
import { AnyARecord } from 'dns';

import NewPayslip from '../NewPayslip';
import { Border, tooltipRender } from '@syncfusion/ej2-react-charts';
import FileUpload from '../FileUpload';
import { escapeLeadingUnderscores } from 'typescript';
import DialogConfirm from '../Dialogbox/DialogConfirm';

// * ------------------------------------------------------------------------------------------------------------------3.Style
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const {
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_PAYROLLSERVICE,
  REACT_APP_ENDPOINT_API,
} = process.env;

interface Props {
  jsonDataViewer: any;
}

export default class PayheadList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  private colsEmp: ColumnModel[];
  private grdEmp: GridComponent | any = null;
  private grdBulkEmp: GridComponent | any = null;
  private grdPayroll: GridComponent | any = null;
  private colsPayroll: ColumnModel[];
  private splitter: PaneDirective | any = null;
  private pivotObj: PivotViewComponent | any = null;
  private child: CreatePeriod | any;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  private buttons: ButtonPropsModel[];
  public connection: any;
  public allemployees: any = [];
  public filteredemps: any = [];
  public radOwExist: RadioButtonComponent | any = null;
  public radDNOwrts: RadioButtonComponent | any = null;
  public nutext: NumericTextBoxComponent | any = null;
  public selectedpayheadid: undefined;
  public selectedcalctypeid: number = 0;
  public menuItems: MenuItemModel[] = [];
  public payslipdialogheader: string = '';
  private grdExclusion: GridComponent | any = null;
  private tabdatatotal: any = { attributes: {} };
  private skipemployee: boolean = false;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.child = React.createRef();
    this.colsEmp = [
      {
        type: 'checkbox',
        width: '40',
      },
      {
        field: 'employeecode',
        headerText: 'Code',
        width: '80',
      },
      {
        field: 'personName',
        headerText: 'Name',
      },
    ];

    this.colsPayroll = [
      {
        field: 'period.name',
        headerText: 'Period',
        width: '90',
      },
      // {
      //   field: 'processedDate',
      //   headerText: 'Process Date',
      //   type: 'date',
      //   format: `${this.user.settings.formats.DateShort}`,
      //   width: '100',
      // },
      {
        field: 'employee.personName',
        headerText: 'Employee',
        width: '100',
      },
      {
        field: 'employeePayHeadTran.payHead.name',
        headerText: 'Pay Head',
        width: '150',
      },
      {
        field: 'employeePayHeadTran.formula',
        headerText: 'Formula',
        width: '200',
      },
      {
        field: 'amountPaidCurrency',
        headerText: 'Amount',
        width: '120',
        format: `${this.user.settings.formats.Decimal
          ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
          : 'N2'
          }`,
        textAlign: 'Right',
      },
    ];

    var _result: any;
    parser.parseString(
      paysummary_contextmenu,
      function (err: any, result: any) {
        _result = JSON.parse(JSON.stringify(result));
      }
    );

    let MenuId = 6; // 6 For Statutory Reports
    let _report: any = [];
    _result.ContextMenu.Item[MenuId].Item.forEach((x: any) => {
      let obj = x.$;
      if (
        obj.Country === this.user.countryName ||
        obj.Country === '' ||
        obj.Country === undefined
      ) {
        _report.push(obj);
      }
    });

    _result.ContextMenu.Item[MenuId].Item.length = _report.length;

    let id = 0;
    _report.forEach((x: any) => {
      _result.ContextMenu.Item[MenuId].Item[id].$.id = x.id;
      _result.ContextMenu.Item[MenuId].Item[id].$.text = x.text;
      _result.ContextMenu.Item[MenuId].Item[id].$.Country = x.Country;
      id = id + 1;
    });

    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];

    this.buttons = [{}];

    parser.parseString(
      paysummary_empcontextmenu,
      function (err: any, result: any) {
        _result = JSON.parse(JSON.stringify(result));
      }
    );

    this.menuItems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];
  }

  static defaultProps = {};

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {
    jsonEmployee: undefined,
    jsonPayroll: undefined,
    jsonPayrollFlat: undefined,
    periodid: '',
    periodselectorkey: '',
    addingperiod: false,
    periodlist: [],
    showCloseDialog: false,
    closepid: undefined,
    openpid: undefined,
    progress: 0,
    totalcount: 0,
    totalpct: 0,
    assigininghead: false,
    assignempid: undefined,
    payHeadId: undefined,
    headTypeId: 0,
    typeOfId: 0,
    calcTypeId: 0,
    bulkassignment: false,
    payheadlist: [],
    pstartDate: undefined,
    pendDate: undefined,
    headertext: '',
    showProgress: true,
    employeeIds: [],
    flatamount: 0,
    startDate: null,
    endDate: null,
    operationTypeId: false,
    empobject: undefined,
    showpayslip: false,
    defamout: 0,
    basic_salaryPayhead_name: '',
    uploadpayhead: false,
    selectedmodeid: 0,
    exclusion: [],
    selectedtabid: -1,
    deldata: undefined,
  };

  componentDidMount(): void {
    // if (!this.state.jsonEmployee) {
    //   this.GetEmployee();
    // }

    let port = `${REACT_APP_ENDPOINT_PAYROLLSERVICE}`.split('/')[0];
    let url = `${REACT_APP_ENDPOINT_API}${port}/progressHub?userId=${this.user.id}`;
    console.log(url);

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(() => {
        console.log('Connected to SignalR hub');
        // this.connection.invoke('RequestProgress', this.user.id, 0, 0)
        //   .catch((err: any) => console.error('Error invoking RequestProgress:', err));
      })
      .catch((err: any) =>
        console.error('Error connecting to SignalR hub:', err)
      );

    // Handle the connection ID sent from the server
    this.connection.on('ReceiveConnectionId', (connectionId: any) => {
      console.log('Received connection ID:', connectionId);
      this.setState({ connectionId });
    });

    this.connection.on('ReceiveProgress', (cnt: any, totalcnt: any) => {
      console.log('Received progress update:', cnt, totalcnt);
      const pct = (cnt / totalcnt) * 100;
      this.setState({ progress: cnt, totalcount: totalcnt, totalpct: pct });
    });

    document
      .querySelector('#moreoptions')
      ?.closest('div')
      ?.addEventListener('click', (e: any) => this.cMenu?.open(e.y, e.x));

    if (this.state.periodlist.length <= 0) {
      this.FetchPeriod();
    }

    if (this.state.payheadlist.length <= 0) {
      this.fetchheads();
    }
  }

  componentWillUnmount() {
    if (this.connection) {
      this.connection.stop();
    }
  }

  private FetchAssignedHeads(pst: any, ped: any, payheadid: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/listempheads`,
      {
        startDate:
          pst !== undefined && pst !== null
            ? Common.formatDate(new Date(pst), 'yyyy-MM-dd')
            : null,
        endDate:
          ped !== undefined && ped !== null
            ? Common.formatDate(new Date(ped), 'yyyy-MM-dd')
            : null,
        payHeadId: payheadid,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        const employeeIds: any = response.map((obj: any) => obj.employeeId);

        this.allemployees = this.allemployees.sort(
          (a: any, b: any) => a['employeecode'] - b['employeecode']
        );

        if (this.state.selectedmodeid === 1) {
          this.filteredemps = this.allemployees.filter(
            (x: any) => !employeeIds.includes(x.id)
          );
        } else if (this.state.selectedmodeid === 2) {
          this.filteredemps = this.allemployees.filter((x: any) =>
            employeeIds.includes(x.id)
          );
        }

        this.filteredemps = this.filteredemps.sort(
          (a: any, b: any) => a['employeecode'] - b['employeecode']
        );

        this.grdBulkEmp.dataSource = this.filteredemps;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private OnCheckChange(e: any) {
    if (e.target.id !== undefined && e.target.id === 'chkskipemployee') {
      this.skipemployee = e.target.element.ej2_instances[0].checked;
    } else {
      if (e.target.element.ej2_instances[0].checked === true) {
        this.grdBulkEmp.dataSource = this.allemployees;
        this.radOwExist.disabled = false;
      } else if (e.target.element.ej2_instances[0].checked === false) {
        this.grdBulkEmp.dataSource = this.filteredemps;
        this.radOwExist.disabled = true;
      }
    }
  }

  private OnGridCheckChange(e: any) {
    if (document.getElementById('lblSelectedEmpCount')) {
      (document.getElementById('lblSelectedEmpCount') as any).value =
        'Total Selected Employee : ' +
        this.grdBulkEmp.getSelectedRowIndexes().length;
    }
  }

  private handleContextMenu(args: any) {
    const target = args.event.target;
    if (
      target.closest('.e-frozencontent.e-frozen-left-content') === null ||
      target.closest('.e-gtot') ||
      target.closest('.e-lastrowcell')
    ) {
      args.cancel = true;
    } else {
      this.payslipdialogheader = '';
      this.payslipdialogheader = target.textContent;

      let empid = (this.state.jsonPayrollFlat as any).filter(
        (x: any) => x.employeecode_name === target.textContent
      )[0].employeeId;

      let empobjdata = (this.state.jsonPayroll as any).filter(
        (x: any) => x.employeeId === empid
      )[0].employee;

      this.setState({ empobject: empobjdata });
    }
  }

  //
  private handleContextMenuClick(e: any) {
    if (this.state.empobject !== undefined) {
      if (e.element.id === '100') {
        this.setState({ showpayslip: true });
      } else if (e.element.id === '200') {
        if (this.state.periodlist.length > 0) {
          let pItem = this.state.periodlist.filter(
            (x: any) => x.id == this.state.periodid
          )[0];
          if (pItem) {
            if (
              (pItem as any).statusId == this._structure.PeriodStatusId.Close
            ) {
              DialogConfirm.showDialog({
                content:
                  '<p class="dialog-contain">Sorry, you cannot perform payroll exclusion operation as selected period as it is already closed.</p>',
              });
              return;
            }
          }
        }

        DialogConfirm.showDialog({
          isConfirm: true,
          content:
            '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to exclude this employee for the selected period?</p></div>',
          okCaption: 'Yes',
          closeCaption: 'No',
          OkEvent: this.AddPayrollExclusion.bind(this),
        });
      }
    }
  }

  private VoidPayrollExclusion() {
    let payload: any = {
      ids: (this.state.deldata as any).map((x: any) => x.id),
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/voidexclusion`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.FetchPayrollData(this.state.periodid);
        this.FetchExclusion(this.state.periodid);
        this.setState({ deldata: undefined });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private AddPayrollExclusion() {
    let payload: any = {
      periodId: this.state.periodid,
      employeeId: (this.state.empobject as any).id,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/addexclusion`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.FetchPayrollData(this.state.periodid);
        this.FetchExclusion(this.state.periodid);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  public fetchheads() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payhead/listheads`,
      {
        addNewOption: false,
        startDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
        endDate: Common.formatDate(new Date(), 'yyyy-MM-dd'),
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        // let flatheads = response.filter(
        //   (x: any) =>
        //     x.calcTypeId === this._structure.CalcType.FlatRateOthers.id
        // );

        this.setState({ payheadlist: response });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  public FetchPeriod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/listperiods`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.setState({
          periodlist: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  public GetEmployee() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/employee/search`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.allemployees = response;
        // this.setState({
        //   jsonEmployee: response,
        // });
        // this.grdEmp.dataSource = response;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private handleChange = (e: any) => {
    if (e.id !== Common.blankguid) {
      this.GetEmployee();
      this.FetchPayrollData(e.id);
      this.FetchExclusion(e.id);

      this.setState({
        periodid: e.id,
        pstartDate: e.startDate,
        pendDate: e.endDate,
      });
    } else {
      this.setState({ addingperiod: true });
    }
  };

  handleMenuClick = (e: any) => {
    if (parseInt(e.element.id) === 100) {
      if (this.state.periodlist.length > 0) {
        let pItem = this.state.periodlist.filter(
          (x: any) => x.id == this.state.periodid
        )[0];
        if (pItem) {
          if ((pItem as any).statusId == this._structure.PeriodStatusId.Close) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, you cannot close the selected period as it is already closed.</p>',
            });
            return;
          }

          let time = new Date((pItem as any).startDate).getTime();
          if (
            this.state.periodlist.filter(
              (x: any) =>
                new Date(x.startDate).getTime() < time &&
                x.statusId == this._structure.PeriodStatusId.Open
            ).length > 0
          ) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, you cannot close the selected period as previous period is already open.</p>',
            });
            return;
          }
        }

        let oItem: any;
        let time = new Date((pItem as any).startDate).getTime();
        if (
          this.state.periodlist.filter(
            (x: any) =>
              new Date(x.startDate).getTime() > time &&
              x.statusId == this._structure.PeriodStatusId.Open
          ).length > 0
        ) {
          const index = this.state.periodlist.indexOf(pItem);
          try {
            oItem = this.state.periodlist[index + 1];
          } catch {
            oItem = this.state.periodlist[this.state.periodlist.length - 1];
          }
        }

        if (pItem && oItem) {
          this.setState({ closepid: (pItem as any).id, openpid: oItem.id });
          DialogConfirm.showDialog({
            isConfirm: true,
            content:
              '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to close selected period?</p></div>',
            okCaption: 'Yes',
            closeCaption: 'No',
            OkEvent: this.confimClosePeriod.bind(this)
          });
        }
      }
    } else if (parseInt(e.element.id) === 201) {
      let pdfExportProperties: PdfExportProperties = {
        header: {
          fromTop: 0,
          height: 130,
          contents: [
            {
              type: 'Text',
              value: 'Payroll Summary',
              position: { x: 0, y: 50 },
              style: {
                textBrushColor: '#000000',
                fontSize: 13,
                dashStyle: 'Solid',
                hAlign: 'Center',
              },
            },
          ],
        },
      };
      this.pivotObj.pdfExport(pdfExportProperties);
    } else if (parseInt(e.element.id) === 202) {
      let periodname = (document.getElementById('payroll-selector') as any)
        .value;
      let excelExportProperties: ExcelExportProperties = {
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: this.pivotObj.pivotColumns.length,
                  value: 'Payroll Data',
                  style: {
                    fontColor: '#C67878',
                    fontSize: 20,
                    hAlign: 'Center',
                    bold: true,
                    underline: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: this.pivotObj.pivotColumns.length,
                  value: periodname,
                  style: {
                    fontColor: '#C67878',
                    fontSize: 20,
                    hAlign: 'Center',
                    bold: true,
                    underline: true,
                  },
                },
              ],
            },
          ],
        },
      };

      //this.pivotObj.dataSourceSettings.expandAll = true;

      this.pivotObj.grid.excelExport(excelExportProperties);
    } else if (parseInt(e.element.id) === 203) {
      //this.pivotObj.csvExport();
    } else if (parseInt(e.element.id) === 300) {
      if (this.state.periodlist.length > 0) {
        let pItem = this.state.periodlist.filter(
          (x: any) => x.id == this.state.periodid
        )[0];
        if ((pItem as any).statusId == this._structure.PeriodStatusId.Close) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, you cannot do bulk assignment operation as selected period is already closed.</p>',
          });
          return;
        }
      }

      this.selectedcalctypeid = 0;
      this.selectedpayheadid = undefined;
      this.setState({ bulkassignment: true, selectedmodeid: 1 });
    } else if (parseInt(e.element.id) === 401) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/bankeft`,
        { periodId: this.state.periodid, EFTId: 1, FileFormatType: 1 },
        Common.getToken() || '',
        null,
        this.token.tenant
        // ,'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'CRDB_' + date + month + year + hh + mm + ss + '.csv'
        );
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 402) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/bankeft`,
        { periodId: this.state.periodid, EFTId: 2, FileFormatType: 2 },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'BOA_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 500) {
      this.setState({ uploadpayhead: true });
    } else if (parseInt(e.element.id) === 501) {
      if (this.state.periodlist.length > 0) {
        let pItem = this.state.periodlist.filter(
          (x: any) => x.id == this.state.periodid
        )[0];
        if (pItem) {
          if ((pItem as any).statusId == this._structure.PeriodStatusId.Close) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, you cannot perform bulk delete operation as selected period as it is already closed.</p>',
            });
            return;
          }

          let time = new Date((pItem as any).startDate).getTime();
          if (
            this.state.periodlist.filter(
              (x: any) =>
                new Date(x.startDate).getTime() < time &&
                x.statusId == this._structure.PeriodStatusId.Open
            ).length > 0
          ) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, you cannot perform bulk delete operation as previous period is already open.</p>',
            });
            return;
          }
        }

        this.selectedcalctypeid = 0;
        this.selectedpayheadid = undefined;
        this.setState({ bulkassignment: true, selectedmodeid: 2 });
      }
    } else if (parseInt(e.element.id) === 603) {
      // Tax Report
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/taxreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'TAX_' + date + month + year + hh + mm + ss + '.xlsm'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 601) {
      // NSSF Report Tanzania
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nssfreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NSSF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 602) {
      // NHIF Report Tanzania
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nhifreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NHIF_' + date + month + year + hh + mm + ss + '.csv'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 604) {
      // WCF Report Tanzania
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/wcfreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'WCF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 605) {
      // Housing Levy Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/housinglevyreport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'HOUSING_LEVY_' + date + month + year + hh + mm + ss + '.csv'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 606) {
      // NITA Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nitareport`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {});

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NITA_' + date + month + year + hh + mm + ss + '.csv'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 608) {
      // NHIF Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nhifreportkenya`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NHIF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    } else if (parseInt(e.element.id) === 607) {
      // NSSF Report Kenya
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/nssfreportkenya`,
        { periodId: this.state.periodid },
        Common.getToken() || '',
        null,
        this.token.tenant,
        'blob'
      ).then((response: any) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const hh = today.getHours();
        const mm = today.getMinutes();
        const ss = today.getSeconds();

        // Create a new Blob object using the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.href = URL.createObjectURL(blob);
        // link.download = 'Sample.xlsx';
        link.setAttribute(
          'download',
          'NSSF_' + date + month + year + hh + mm + ss + '.xlsx'
        );

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        // link.parentNode.removeChild(link);
        link.remove();

        return response.data;
      });
    }
  };

  confimClosePeriod = () => {
    this.setState({ showCloseDialog: true, headertext: 'Closing Period...' });
    this.ClosePeriod(this.state.closepid, this.state.openpid);
  };

  private ClosePeriod(closepid: any, openpid: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/closeperiod`,
      {
        closePeriodId: closepid,
        openPeriodId: openpid,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.FetchPeriod();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });

    // let url = `${REACT_APP_ENDPOINT_API}${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/closeperiod`;
    // this.socket = new WebSocket(url.replace("https:", "ws:").replace("http:", "ws:"));
    // this.socket.onopen = () => {
    //   console.log('WebSocket connected');
    // };

    // this.socket.onmessage = (event: any) => {
    //   const data = JSON.parse(event.data);
    //   this.setState({ progress: data.progress });
    // };
  }

  private SelectedTab(e: any) {
    if (e) {
      let elem = document.getElementById('moption');
      if (elem) {
        if (e.selectedIndex === 0) {
          (elem as HTMLElement).style.display = 'block';
        } else if (e.selectedIndex === 1) {
          (elem as HTMLElement).style.display = 'none';
        }
      }
      this.FetchPayrollData(this.state.periodid);
      this.FetchExclusion(this.state.periodid);

      // this.setState({ selectedtabid: e.selectedIndex });

      // if (e.selectedIndex === 0) {
      //   Common.SetStatusBar(`Total Records :  ${this.tabdatatotal.attributes.summary}`)
      // }
      // else if (e.selectedIndex === 1) {
      //   Common.SetStatusBar(`Total Records :  ${this.tabdatatotal.attributes.exclusion}`)
      // }
    }
  }

  private showProgress() {
    return (
      <>
        <div>
          <ProgressBarComponent
            id='linear'
            type='Linear'
            showProgressValue={true}
            labelStyle={{ color: '#FFFFFF', fontWeight: 'bold' }}
            trackThickness={30}
            progressThickness={30}
            trackColor='transparent'
            progressCompleted={(args: IProgressValueEventArgs) => {
              args.value = 0;
              this.setState({
                totalcount: 0,
                showCloseDialog: false,
                totalpct: 0,
              });
            }}
            maximum={100}
            value={this.state.totalpct}
          ></ProgressBarComponent>
        </div>
      </>
    );
  }

  private hdlpayheadchg(e: any) {
    if (e.itemData != null) {
      this.FetchAssignedHeads(
        this.state.pstartDate,
        this.state.pendDate,
        e.itemData.id
      );

      if (this.nutext !== null) {
        if (
          e.itemData.calcTypeId === this._structure.CalcType.FlatRateOthers.id
        ) {
          this.nutext.enabled = true;
        } else {
          this.nutext.enabled = false;
        }
      }

      this.selectedcalctypeid = e.itemData.calcTypeId;
      this.selectedpayheadid = e.itemData.id;
    }
  }

  handleCancelbtn = (e: any) => {
    this.setState({ addingperiod: false });
  };

  handleAcceptbtn = (data: any) => {
    localStorage.setItem('lastSelectedPeriod', JSON.stringify(data));
    this.setState({ addingperiod: false, periodselectorkey: Math.random() });
    this.FetchPayrollData(data.id);
    this.setState({ periodid: data.id });
  };

  private FetchExclusion(pid: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/getexclusion`,
      { periodId: pid },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.tabdatatotal.attributes['exclusion'] = response.length;

        // if (localStorage.getItem('tabtabpayrolllist') !== undefined) {
        //   var idx = Object.keys(
        //     JSON.parse(
        //       localStorage.getItem('tabtabpayrolllist')!.toString()
        //     )
        //   ).indexOf('selectedItem');
        //   if (idx === 1) {
        //     Common.SetStatusBar(`Total Records :  ${response.length}`)
        //   }
        // }

        this.setState({
          exclusion: response,
        });
        this.grdExclusion.dataSource = response;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  private FetchPayrollData(pid: any) {
    Common.ShowSpinner();

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/listpayrolldata`,
      { periodId: pid, employeeId: null },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        let mapObj = new Map(
          response.data.map((obj: any) => {
            return [JSON.stringify(obj['employeeId']), obj];
          })
        );
        const distinctValues = Array.from(mapObj);
        this.tabdatatotal.attributes['summary'] = distinctValues.length;

        // if (localStorage.getItem('tabtabpayrolllist') !== undefined) {
        //   var idx = Object.keys(
        //     JSON.parse(
        //       localStorage.getItem('tabtabpayrolllist')!.toString()
        //     )
        //   ).indexOf('selectedItem');
        //   if (idx === 0) {
        //     Common.SetStatusBar(`Total Records :  ${distinctValues.length}`)
        //   }
        // }

        return response.data;
      })
      .then((response: any) => {
        let jsonPayrollFlat: any = Common.flattenJSON(
          response,
          {},
          '',
          null,
          [],
          [],
          '_'
        );

        if (Object.keys(jsonPayrollFlat).length > 0) {
          // jsonPayrollFlat = jsonPayrollFlat.sort(
          //   (a: any, b: any) =>
          //     a['employeePayHeadTran_payHead_headTypeId'] -
          //     b['employeePayHeadTran_payHead_headTypeId']
          // );
          jsonPayrollFlat = jsonPayrollFlat.sort(
            (a: any, b: any) =>
              a['employeePayHeadTran_payHead_headTypeId'] -
              b['employeePayHeadTran_payHead_headTypeId'] ||
              a['employeePayHeadTran_payHead_typeOfId'] -
              b['employeePayHeadTran_payHead_typeOfId'] ||
              a['employeePayHeadTran_payHead_calcTypeId'] -
              b['employeePayHeadTran_payHead_calcTypeId']
          );
          jsonPayrollFlat = jsonPayrollFlat.map((x: any) => ({
            employeecode_name:
              x.employee_employeecode + ' - ' + x.employee_personName,
            ...x,
          }));
        }

        this.setState({
          jsonPayroll: response,
          jsonPayrollFlat: jsonPayrollFlat,
          basic_salaryPayhead_name:
            response !== undefined && response.length > 0
              ? jsonPayrollFlat[0].employeePayHeadTran_payHead_name
              : '',
        });
        //this.grdPayroll.dataSource = response;

        this.pivotObj.engineModule.fieldList = {};

        const excludedKeys = [
          'employeecode_name',
          'employeePayHeadTran_headTypeName',
          'employeePayHeadTran_payHead_name',
          'amountPaidCurrency',
        ];

        let filteredItem: any = [];
        Object.values(jsonPayrollFlat).map((item: any) => {
          if (filteredItem.length <= 0) {
            filteredItem.push(
              Object.keys(item).filter((key) => !excludedKeys.includes(key))
            );
            return;
          }
        });

        this.pivotObj.dataSourceSettings.dataSource = jsonPayrollFlat;
        this.pivotObj.dataSourceSettings.excludeFields = filteredItem[0];
        this.pivotObj.refresh();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  public splitterResize() {
    if (this.splitter) {
      if (this.splitter.allPanes[0].clientWidth < 300) {
        this.grdEmp.element.classList.add('minwidth');
      } else {
        this.grdEmp.element.classList.remove('minwidth');
      }
    }
  }

  public extrabulkassignbutton: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => this.setState({ bulkassignment: false, selectedmodeid: 0 }),
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        this.state.selectedmodeid === 1
          ? this.AddBulkAssignHeads()
          : this.VoidBulkAssignHeads();
      },
    },
  ];

  public extraaddheadbutton: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => this.setState({ assigininghead: false }),
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        this.AssignHead();
      },
    },
  ];

  public uploadpayheadbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => this.setState({ uploadpayhead: false }),
    },
  ];

  private AssignHead() {
    let elem = document.getElementById('amount');
    if (elem && (elem as any).ej2_instances[0].value == null) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please enter amount to continue.</p>',
      });
      return;
    }

    let payload: any = {
      employeeId: this.state.assignempid,
      payHeadId: this.state.payHeadId,
      headTypeId: this.state.headTypeId,
      typeOfId: this.state.typeOfId,
      calcTypeId: this.state.calcTypeId,
      amount: (elem as any).ej2_instances[0].value,
      formula: '',
      startDate: (
        this.state.periodlist.filter(
          (x: any) => x.id == this.state.periodid
        )[0] as any
      ).startDate,
      endDate: null,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/add`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.FetchPayrollData(this.state.periodid);
        this.setState({
          assignempid: undefined,
          payHeadId: undefined,
          headTypeId: 0,
          typeOfId: 0,
          calcTypeId: 0,
          assigininghead: false,
          defamout: 0,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private VoidBulkAssignHeads() {
    if (this.selectedpayheadid == undefined) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select payhead to continue.</p>',
      });
      return;
    }

    if (this.grdBulkEmp.getSelectedRowIndexes().length <= 0) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select atleast one employee.</p>',
      });
      return;
    }
    DialogConfirm.showDialog({
      isConfirm: true,
      content:
        '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to remove the selected pay head from the checked employee(s)?</p></div>',

      okCaption: 'Yes',
      closeCaption: 'No',
      OkEvent: this.confirmUnAssignment.bind(this)
    });
  }
  private AddBulkAssignHeads() {
    if (this.selectedpayheadid == undefined) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select payhead to continue.</p>',
      });
      return;
    }

    if (this.grdBulkEmp.getSelectedRowIndexes().length <= 0) {
      DialogConfirm.showDialog({
        content: '<p class="dialog-contain">Please select atleast one employee.</p>',
      });
      return;
    }

    if (
      this.selectedcalctypeid === this._structure.CalcType.FlatRateOthers.id
    ) {
      let elem = document.getElementById('flatamount');
      if (elem && (elem as any).ej2_instances[0].value == null) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter amount to continue.</p>',
        });
        return;
      }
    }

    if (this.radOwExist.disabled == false) {
      if (
        this.radDNOwrts.checked == false &&
        this.radOwExist.checked == false
      ) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please set atleast one operation to continue.</p>',
        });
        return;
      }
    }

    if (this.state.periodlist.length > 0) {
      let pItem = this.state.periodlist.filter(
        (x: any) => x.id == this.state.periodid
      )[0];
      let time = new Date((pItem as any).startDate).getTime();
      if (
        this.state.periodlist.filter(
          (x: any) =>
            new Date(x.startDate).getTime() < time &&
            x.statusId == this._structure.PeriodStatusId.Open
        ).length > 0
      ) {
        this.setState({ showProgress: false });
      }
    }
    DialogConfirm.showDialog({
      isConfirm: true,
      content:
        '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to assigned the selected head to the checked employee(s)?</p></div>',
      okCaption: 'Yes',
      closeCaption: 'No',
      OkEvent: this.confirmAssignment.bind(this)

    });
  }

  private PerformUnAssignment() {
    let payload: any = {
      periodId: this.state.periodid,
      payHeadId: this.selectedpayheadid,
      employeeIds: this.state.employeeIds,
      skipExlcudedEmployee: this.skipemployee,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/bulkunassignheads`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (response.length > 0) {
          this.FetchPayrollData(this.state.periodid);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private PerformAssignment() {
    let payload: any = {
      periodId: this.state.periodid,
      payHeadId: this.selectedpayheadid,
      employeeIds: this.state.employeeIds,
      amount: this.state.flatamount,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      overwriteExisting: this.state.operationTypeId,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/bulkassignheads`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (response.length > 0) {
          this.FetchPayrollData(this.state.periodid);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private confirmUnAssignment() {
    let employeeids = this.grdBulkEmp
      .getSelectedRecords()
      .map((x: any) => x.id);
    if (this.state.showProgress) {
      this.setState({
        showCloseDialog: true,
        headertext: 'Processing...',
        bulkassignment: false,
        selectedmodeid: 0,
        employeeIds: employeeids,
      });
    }
    this.PerformUnAssignment();
  }

  private confirmAssignment() {
    let elem = document.getElementById('flatamount');
    let employeeids = this.grdBulkEmp
      .getSelectedRecords()
      .map((x: any) => x.id);
    let flag = this.radOwExist.checked;
    if (this.state.showProgress) {
      this.setState({
        showCloseDialog: true,
        headertext: 'Processing...',
        bulkassignment: false,
        selectedmodeid: 0,
        employeeIds: employeeids,
        flatamount: (elem as any).ej2_instances[0].value,
        operationTypeId: flag,
      });
    }
    this.PerformAssignment();
  }

  private generatePayslip() {
    var content: any;
    content = (
      <>
        <NewPayslip selectorData={this.state.empobject}></NewPayslip>
      </>
    );
    return content;
  }

  public generatebulkcontent() {
    var content: any;
    if (this.state.selectedmodeid === 1) {
      content = (
        <>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Payhead
              </label>
              <DropDownListComponent
                id='payheadid'
                name='payheadid'
                dataSource={this.state.payheadlist}
                filterBarPlaceholder='Search Head'
                allowFiltering={true}
                filterType='Contains'
                fields={{ value: 'id', text: 'name' }}
                placeholder='Select Head'
                showClearButton={true}
                change={this.hdlpayheadchg.bind(this)}
              //value={this.state.selectedheadid}
              />
            </div>
            <div className='col-6 py-2'>
              <RadioButtonComponent
                id='radOwExist'
                ref={(c: any) => (this.radOwExist = c)}
                label='Overwrite Existing'
                name='radblukopr'
                value='1'
                disabled={true}
              ></RadioButtonComponent>
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Amount
              </label>
              <NumericTextBoxComponent
                id='flatamount'
                name='flatamount'
                cssClass='text-right'
                placeholder='Enter Amount'
                ref={(c: any) => (this.nutext = c)}
                decimals={
                  this.user.settings.formats.Decimal.includes('.')
                    ? this.user.settings.formats.Decimal.split('.').pop().length
                    : null
                }
                // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
                // format={`c${this.user.settings.formats.Decimal.includes('.')
                //   ? this.user.settings.formats.Decimal.split('.').pop()
                //     .length
                //   : 2
                //   }`}
                format={`${this.user.currencySign} 0.${this.user.settings.formats.Decimal.includes('.')
                  ? '0'.repeat(
                    this.user.settings.formats.Decimal.split('.').pop()
                      .length
                  )
                  : '0'.repeat(2)
                  }`}
                showSpinButton={false}
              />
            </div>
            <div className='col-6 py-2'>
              <RadioButtonComponent
                id='radDNOwrts'
                ref={(c: any) => (this.radDNOwrts = c)}
                label='Do not Overwrite'
                name='radblukopr'
                value='2'
              ></RadioButtonComponent>
            </div>
          </div>
          {/* <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Start Date
            </label>
            <DatePickerComponent
              id='startDate'
              name='startDate'
              format={this.user.settings.formats.DateShort}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              value={this.state.pstartDate}
              showTodayButton={false}
              placeholder={this.user.settings.formats.DateShort}
              style={{ fontSize: '13px' }}
              max={this.state.pstartDate}
              min={this.state.pstartDate}            
            />
          </div>
          <div className='col-6 py-2'>
            <span className='mandatoryinfo'>*</span>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              End Date
            </label>
            <DatePickerComponent
              id='endDate'
              name='endDate'
              format={this.user.settings.formats.DateShort}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              placeholder={this.user.settings.formats.DateShort}
              style={{ fontSize: '13px' }}
            />
          </div>
        </div> */}
          <div className='row align-items-center'>
            <div className='col-7 py-2' style={{ marginLeft: '10px' }}>
              <CheckBoxComponent
                checked={false}
                label={'Include assigned employee(s)'}
                onChange={this.OnCheckChange.bind(this)}
              ></CheckBoxComponent>
            </div>
            <div className='col-4 py-2'>
              <input
                type='label'
                id='lblSelectedEmpCount'
                value={'Total Selected Employee : 0'}
                style={{
                  border: '0px',
                  cursor: 'none',
                  width: '95%',
                  backgroundColor: 'transparent',
                }}
                disabled
              ></input>
            </div>
            <div className='col-12 py-2'>
              <GridComponent
                id='payrollemployeelist-grid'
                height={'calc(100vh - 500px)'}
                width={'100%'}
                dataSource={this.state.jsonEmployee}
                columns={this.colsEmp}
                ref={(obj: any) => (this.grdBulkEmp = obj)}
                checkBoxChange={this.OnGridCheckChange.bind(this)}
                rowSelected={this.OnGridCheckChange.bind(this)}
                rowDeselected={this.OnGridCheckChange.bind(this)}
              />
              <SelectorSearch
                grid_id={'payrollemployeelist-grid'}
                showMoreOptions={false}
              />
            </div>
          </div>
        </>
      );
    } else if (this.state.selectedmodeid === 2) {
      let flatheads = this.state.payheadlist.filter(
        (x: any) =>
          x.calcTypeId !== this._structure.CalcType.DefinedSalary.id &&
          x.typeOf !== this._structure.TypeOf.Salary.id
      );

      content = (
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Payhead
            </label>
            <DropDownListComponent
              id='payheadid'
              name='payheadid'
              dataSource={flatheads}
              filterBarPlaceholder='Search Head'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Head'
              showClearButton={true}
              change={this.hdlpayheadchg.bind(this)}
            //value={this.state.selectedheadid}
            />
          </div>
          <div className='col-6 py-2' style={{ marginLeft: '10px' }}>
            <CheckBoxComponent
              id='chkskipemployee'
              checked={false}
              label={'Skip Excluded employee(s) from Payroll'}
              onChange={this.OnCheckChange.bind(this)}
            ></CheckBoxComponent>
          </div>
          <div className='col-4 py-2'>
            <input
              type='label'
              id='lblSelectedEmpCount'
              value={'Total Selected Employee : 0'}
              style={{
                border: '0px',
                cursor: 'none',
                width: '95%',
                backgroundColor: 'transparent',
              }}
              disabled
            ></input>
          </div>
          <div className='col-12 py-2'>
            <GridComponent
              id='payrollemployeelist-grid'
              height={'calc(100vh - 500px)'}
              width={'100%'}
              dataSource={this.state.jsonEmployee}
              columns={this.colsEmp}
              ref={(obj: any) => (this.grdBulkEmp = obj)}
              checkBoxChange={this.OnGridCheckChange.bind(this)}
              rowSelected={this.OnGridCheckChange.bind(this)}
              rowDeselected={this.OnGridCheckChange.bind(this)}
            />
            <SelectorSearch
              grid_id={'payrollemployeelist-grid'}
              showMoreOptions={false}
            />
          </div>
        </div>
      );
    }

    return content;
  }

  public generatcontent() {
    var content: any;
    content = (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Amount
            </label>
            <NumericTextBoxComponent
              id='amount'
              name='amount'
              cssClass='text-right'
              placeholder='Enter Amount'
              decimals={
                this.user.settings.formats.Decimal.includes('.')
                  ? this.user.settings.formats.Decimal.split('.').pop().length
                  : null
              }
              // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
              // format={`c${
              //   this.user.settings.formats.Decimal.includes('.')
              //     ? this.user.settings.formats.Decimal.split('.').pop().length
              //     : 2
              // }`}
              format={`${this.user.currencySign} 0.${this.user.settings.formats.Decimal.includes('.')
                ? '0'.repeat(
                  this.user.settings.formats.Decimal.split('.').pop().length
                )
                : '0'.repeat(2)
                }`}
              value={this.state.defamout}
              showSpinButton={false}
            />
          </div>
        </div>
      </>
    );
    return content;
  }

  public generateuploadpayheadcontent() {
    var content: any;
    content = (
      <>
        <div className='row align-items-center'>
          <FileUpload uploadtypeid={this._structure.UploadType.PayHeads} />
        </div>
      </>
    );
    return content;
  }

  public extradialogbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.setState({ addingperiod: false });
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        //this.HeadAdd();
        this.child.current.SavePeriod().then((response: any) => {
          if (response) {
            localStorage.setItem(
              'lastSelectedPeriod',
              JSON.stringify(response)
            );
            this.setState({
              addingperiod: false,
              periodselectorkey: Math.random(),
            });
            this.FetchPayrollData(response.id);
          }
        });
      },
    },
  ];

  public ValidOperation() {
    let flag: boolean = true;
    let itm = this.state.periodlist.filter(
      (x: any) => x.id == this.state.periodid
    )[0];
    if (itm) {
      if ((itm as any).statusId == this._structure.PeriodStatusId.Close) {
        flag = false;
      }
    }
    return flag;
  }

  private drillThrough(args: any): void {
    if (args.rawData.length > 1) {
      args.cancel = true;
    } else if (args.rawData.length == 1) {
      if (
        args.rawData[0].employeePayHeadTran_calcTypeId !==
        this._structure.CalcType.FlatRateOthers.id
      ) {
        args.cancel = true;
      } else {
        if (this.ValidOperation() == false) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, you cannot edit amount or assign head on closed period.</p>',
          });
          return;
        }
      }
    } else if (args.rawData.length <= 0) {
      args.cancel = true;
      let empid = (this.state.jsonPayrollFlat as any).filter(
        (x: any) => x.employeecode_name === args.currentCell.rowHeaders
      )[0].employeeId;
      let hdata = (this.state.jsonPayroll as any).filter(
        (x: any) =>
          x.employeePayHeadTran !== null &&
          x.employeePayHeadTran.payHead.name ==
          args.currentCell.columnHeaders.split('.')[1]
      )[0];

      if (
        hdata.employeePayHeadTran.payHead.calcTypeId !==
        this._structure.CalcType.FlatRateOthers.id
      ) {
        args.cancel = true;
      } else {
        if (this.ValidOperation() == false) {
          DialogConfirm.showDialog({
            content:
              '<p class="dialog-contain">Sorry, you cannot edit amount or assign head on closed period.</p>',
          });
          return;
        }

        this.setState({
          assigininghead: true,
          assignempid: empid,
          payHeadId: hdata.employeePayHeadTran.payHead.id,
          headTypeId: hdata.employeePayHeadTran.payHead.headTypeId,
          typeOfId: hdata.employeePayHeadTran.payHead.typeOfId,
          calcTypeId: hdata.employeePayHeadTran.payHead.calcTypeId,
          defamout: hdata.employeePayHeadTran.payHead.payHeadTran[0].amount,
        });
      }

      // if (this.state.jsonPayroll && (this.state.jsonPayroll as any).filter((x: any) => x.employeePayHeadTran.payHead.name == args.currentCell.columnHeaders.split('.')[1])[0].employeePayHeadTran.calcTypeId !== this._structure.CalcType.FlatRateOthers.id) {
      //   args.cancel = true;
      // }
    }
  }

  private actionBegin(e: any) {
    if (e.requestType === 'delete') {
      if (this.state.periodlist.length > 0) {
        let pItem = this.state.periodlist.filter(
          (x: any) => x.id == this.state.periodid
        )[0];
        if (pItem) {
          if ((pItem as any).statusId == this._structure.PeriodStatusId.Close) {
            DialogConfirm.showDialog({
              content:
                '<p class="dialog-contain">Sorry, you cannot perform delete operation as selected period as it is already closed.</p>',
            });
            return;
          }
        }
      }

      this.setState({ deldata: e.data });
      e.data = [];

      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to include checked employee(s) for the selected period?</p></div>',
        okCaption: 'Yes',
        closeCaption: 'No',
        OkEvent: this.VoidPayrollExclusion.bind(this)
      });
    }
  }

  private enginePopulated(args: any) {
    for (var i = 0; i < args.pivotValues.length; i++) {
      for (
        var j = 0;
        args.pivotValues[i] != null && j < args.pivotValues[i].length;
        j++
      ) {
        if (
          args.pivotValues[i][j] != null &&
          args.pivotValues[i][j].axis == 'value'
        ) {
          if (args.pivotValues[i][j].actualValue === undefined) {
            args.pivotValues[i][j].actualValue = 0.0;
            args.pivotValues[i][j].value = 0.0;
          }
        }
      }
    }
  }

  private actionComplete(args: any) {
    if (args.actionName.toLowerCase() == 'record edited') {
      let payload: any = {
        id: args.actionInfo.editInfo.data[0].employeePayHeadTranId,
        amount:
          args.actionInfo.editInfo.data[0].amountPaidCurrency == null
            ? 0
            : args.actionInfo.editInfo.data[0].amountPaidCurrency,
        startDate:
          args.actionInfo.editInfo.data[0].employeePayHeadTran_startDate,
        employeeId: args.actionInfo.editInfo.data[0].employeeId,
        payHeadId:
          args.actionInfo.editInfo.data[0].employeePayHeadTran_payHeadId,
        headTypeId:
          args.actionInfo.editInfo.data[0]
            .employeePayHeadTran_payHead_headTypeId,
        typeOfId:
          args.actionInfo.editInfo.data[0].employeePayHeadTran_payHead_typeOfId,
        calcTypeId:
          args.actionInfo.editInfo.data[0]
            .employeePayHeadTran_payHead_calcTypeId,
        formula: '',
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/employeepayheads/update`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response) {
            if (args.actionInfo.editInfo.data[0].amountPaidCurrency == null) {
              this.pivotObj.dataSourceSettings.dataSource
                .filter(
                  (x: any) =>
                    x.employee_employeecode ==
                    args.actionInfo.editInfo.data[0].employee_employeecode
                )
                .filter(
                  (y: any) =>
                    y.employeePayHeadTran_payHead_name ==
                    args.actionInfo.editInfo.data[0]
                      .employeePayHeadTran_payHead_name
                )[0].amountPaidCurrency = 0;

              this.pivotObj.refresh();
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => { });
    }
  }

  private beforeToolbarRender(args: any): void {
    if (Object.keys(this.pivotObj.dataSourceSettings.dataSource).length <= 0)
      return;

    if (args.customToolbar.length > 0) {
      if (args.customToolbar[0].prefixIcon === 'e-toolbar-fieldlist e-icons') {
        args.customToolbar[0].cssClass = 'close-toolbar-ico';
        args.customToolbar[0].tooltipText = 'Show FieldList';
      }
    }

    args.customToolbar.splice(0, 0, {
      //prefixIcon: 'e-btn-icon e-toolbar-fieldlist e-icons e-icons',
      prefixIcon: 'e-btn-icon e-expand e-icons e-icons',
      tooltipText: 'Expand',
      cssClass: 'close-toolbar-ico',
      click: this.toolbarClicked.bind(this),
    });
  }

  private toolbarClicked(args: any) {
    this.pivotObj.dataSourceSettings.expandAll =
      !this.pivotObj.dataSourceSettings.expandAll;

    if (args.item.prefixIcon === 'e-btn-icon e-expand e-icons e-icons') {
      args.item.prefixIcon = 'e-btn-icon e-collapse e-icons e-icons';
      args.item.tooltipText = 'Collapse';
    } else {
      args.item.prefixIcon = 'e-btn-icon e-expand e-icons e-icons';
      args.item.tooltipText = 'Expand';
    }
  }

  private onHeadersSort(args: any) {
    if (args.fieldName == 'employeePayHeadTran_payHead_name') {
      if (this.state.jsonPayrollFlat !== undefined) {
        args.members = [this.state.basic_salaryPayhead_name];
        args.IsOrderChanged = true;
      }
    }
  }

  // private expandAll() {
  //   this.pivotObj.dataSourceSettings.expandAll = true;
  // }
  // private collapseAll() {
  //   this.pivotObj.dataSourceSettings.expandAll = false;
  // }

  render() {
    const leftPane = () => {
      return (
        <>
          <div id='divpayrollemployee'>
            <GridComponent
              id='payrollemployeelist-grid'
              height={'calc(100vh - 271px)'}
              width={'100%'}
              dataSource={this.state.jsonEmployee}
              columns={this.colsEmp}
              ref={(obj: any) => (this.grdEmp = obj)}
            />
            <SelectorSearch
              grid_id={'payrollemployeelist-grid'}
              showMoreOptions={false}
            />
          </div>
        </>
      );
    };

    const rightPane = () => {
      const tabSummary = () => {
        let ExcludeFieldsList = [
          'employeePayHeadTran_inculdeScale',
          'employeePayHeadTran_endDate',
          'employeePayHeadTran_startDate',
          'employeePayHeadTran_currencyId',
          'employeePayHeadTran_payHead_showLatestOnly',
          'employeePayHeadTran_payHead_addNewOption',
          'employeePayHeadTran_payHead_endDate',
          'employeePayHeadTran_payHead_startDate',
          'employeePayHeadTran_payHead_inexcessSlabTran',
          'employeePayHeadTran_payHead_simpleSlabTran',
          'employeePayHeadTran_payHead_payHeadTran',
          'employeePayHeadTran_payHead_isRecurrent',
          'employeePayHeadTran_payHead_isDefault',
          'employeePayHeadTran_payHead_isProrated',
          'employeePayHeadTran_payHead_isTaxableIncomeReducer',
          'employeePayHeadTran_payHead_isNonCashBenefit',
          'employeePayHeadTran_payHead_isTaxRelief',
          'employeePayHeadTran_payHead_isTaxable',
          'employeePayHeadTran_payHead_calcTypeName',
          'employeePayHeadTran_payHead_calcTypeId',
          'employeePayHeadTran_payHead_typeOfName',
          'employeePayHeadTran_payHead_typeOfId',
          'employeePayHeadTran_payHead_headTypeId',
          'employeePayHeadTran_payHead_code',
          'employeePayHeadTran_formula',
          'employeePayHeadTran_amount',
          'employeePayHeadTran_calcTypeName',
          'employeePayHeadTran_calcTypeId',
          'employeePayHeadTran_typeOfName',
          'employeePayHeadTran_typeOfId',
          'employeePayHeadTran_headTypeId',
          'employeePayHeadTran_payHeadId',
          'employeePayHeadTran_employeeId',
          'employeePayHeadTran_id',
          'period_periodStatus',
          'period_addNewOption',
          'period_closeUserId',
          'period_statusId',
          'period_totalDays',
          'period_endDate',
          'period_startDate',
          'period_name',
          'period_code',
          'period_id',
          'periodId',
          'addDeductId',
          'processedDate',
          'costcenterId',
          'exchangeRate',
          'currencyId',
          'employee_showLatestContractOnly',
          'employee_asOnDate',
          'employee_personName',
          'employee_isActive',
          'employee_isApproved',
          'employee_personId',
          'employee_employeecode',
          'employee_iDs',
          'employee_id',
          'employeeId',
          'employeePayHeadTranId',
          'id',
          'amount',
          'employeePayHeadTran_payHead_id',
          'employeePayHeadTran_payHead_headTypeName',
          'employeePayHeadTran_payHead_simpleSlabTran_0',
          'employeePayHeadTran_payHead_payHeadTran_0',
          'employee_contract_0_salary',
          'employee_contract_0_name',
          'employee_contract_0_removeDateEndIfNull',
          'employee_contract_0_dateEnd',
          'employee_contract_0_dateStart',
          'employee_contract_0_employeeId',
          'employee_contract_0_id',
        ];

        let dataSourceSettings: IDataOptions = {
          dataSource: this.state.jsonPayrollFlat,
          showColumnGrandTotals: false,
          enableSorting: true,
          emptyCellsTextContent: Common.FormatNumber(
            0,
            this.user.settings.formats.Decimal ?? '2'
          ),
          rows: [{ name: 'employeecode_name', caption: 'employee' }],
          columns: [
            {
              name: 'employeePayHeadTran_headTypeName',
              caption: 'head type',
            },
            { name: 'employeePayHeadTran_payHead_name', caption: 'payhead' },
          ],
          values: [{ name: 'amountPaidCurrency', caption: 'Amount' }],
          formatSettings: [
            {
              name: 'amountPaidCurrency',
              format: this.user.settings.formats.Decimal
                ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
                : 'N2',
            },
          ],
          allowLabelFilter: true,
          allowValueFilter: true,
          filters: [
            { name: 'employeecode_name', caption: 'Employee' },
            { name: 'employeePayHeadTran_payHead_name', caption: 'Pay Head' },
            { name: 'employeePayHeadTran_headTypeName', caption: 'Head Type' },
          ],
          sortSettings: [
            { name: 'employeePayHeadTran_headTypeName', order: 'None' },
          ],
          excludeFields: ExcludeFieldsList,
        };

        let dataSourceSettingsExpanded: IDataOptions = {
          dataSource: this.state.jsonPayrollFlat,
          showColumnGrandTotals: false,
          enableSorting: true,
          expandAll: true,
          emptyCellsTextContent: Common.FormatNumber(
            0,
            this.user.settings.formats.Decimal ?? '2'
          ),
          rows: [{ name: 'employeecode_name', caption: 'employee' }],
          columns: [
            {
              name: 'employeePayHeadTran_headTypeName',
              caption: 'head type',
            },
            { name: 'employeePayHeadTran_payHead_name', caption: 'payhead' },
          ],
          values: [{ name: 'amountPaidCurrency', caption: 'Amount' }],
          formatSettings: [
            {
              name: 'amountPaidCurrency',
              format: this.user.settings.formats.Decimal
                ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
                : 'N2',
            },
          ],
          allowLabelFilter: true,
          allowValueFilter: true,
          filters: [
            { name: 'employeecode_name', caption: 'Employee' },
            { name: 'employeePayHeadTran_payHead_name', caption: 'Pay Head' },
            { name: 'employeePayHeadTran_headTypeName', caption: 'Head Type' },
          ],
          sortSettings: [
            { name: 'employeePayHeadTran_headTypeName', order: 'None' },
          ],
          excludeFields: ExcludeFieldsList,
        };

        //let toolbarOptions: any = ['Expand/Collapse'];
        let toolbarOptions: any = ['FieldList'];

        return (
          <>
            <div className='row'>
              <div className='col'>
                <div className='payrollsummary'>
                  {/* <GridComponent
                    id='payrolldatalist-grid'
                    height={'calc(100vh - 465px)'} //265
                    width={'100%'}
                    dataSource={this.state.jsonPayroll}
                    columns={this.colsPayroll}
                    ref={(obj: any) => (this.grdPayroll = obj)}
                  /> */}

                  <PivotViewComponent
                    key={Math.random()}
                    id='PivotView'
                    ref={(pivotview: any) => {
                      this.pivotObj = pivotview;
                    }}
                    // load={onLoad.bind(this)}
                    dataSourceSettings={dataSourceSettings}
                    width={'100%'}
                    height={'94%'}
                    // height={'calc(100vh - 400px)'} //265
                    gridSettings={{
                      columnWidth: 120,
                      allowSelection: true,
                      selectionSettings: {
                        mode: 'Cell',
                        type: 'Single',
                        cellSelectionMode: 'BoxWithBorder',
                      },
                    }}
                    enginePopulated={this.enginePopulated.bind(this)}
                    allowDrillThrough={true}
                    allowExcelExport={true}
                    editSettings={{
                      allowEditing: true,
                      allowInlineEditing: true,
                      allowEditOnDblClick: false,
                      mode: 'Batch',
                    }}
                    drillThrough={this.drillThrough.bind(this)}
                    actionBegin={this.actionBegin.bind(this)}
                    actionComplete={this.actionComplete.bind(this)}
                    allowPdfExport={true}
                    showToolbar={true}
                    toolbar={toolbarOptions}
                    displayOption={{ view: 'Both' }}
                    toolbarRender={this.beforeToolbarRender.bind(this)}
                    showFieldList={true}
                    onHeadersSort={this.onHeadersSort.bind(this)}
                  >
                    <Inject
                      services={[
                        FieldList,
                        CalculatedField,
                        Toolbar,
                        PDFExport,
                        ExcelExport,
                      ]}
                    />
                  </PivotViewComponent>
                  <ContextMenuComponent
                    id={Math.random().toString() + 'contextmenu'}
                    ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
                    items={this.menuItems}
                    target='#PivotView'
                    beforeOpen={this.handleContextMenu.bind(this)}
                    select={this.handleContextMenuClick.bind(this)}
                  />
                </div>
              </div>
            </div>
          </>
        );
      };

      const tabPayrollExclusion = () => {
        const toolbarOptions: any = ['Add', 'Edit', 'Delete'];
        return (
          <>
            <div className='col-12 py-2'>
              <GridComponent
                id='payrollexclusion-grid'
                height={'calc(100vh - 320px)'}
                width={'100%'}
                dataSource={this.state.exclusion}
                ref={(obj: any) => (this.grdExclusion = obj)}
                toolbar={['Delete']}
                editSettings={{
                  allowAdding: false,
                  allowEditing: false,
                  allowEditOnDblClick: false,
                  allowDeleting: true,
                }}
                actionBegin={this.actionBegin.bind(this)}
              >
                <ColumnsDirective>
                  <ColumnDirective type='checkbox' width='5'></ColumnDirective>
                  <ColumnDirective
                    field='employee.employeecode'
                    headerText='Code'
                    width='30'
                  />
                  <ColumnDirective
                    field='employee.personName'
                    headerText='Name'
                    width='200'
                  />
                </ColumnsDirective>
                <GInject services={[GToolbar]} />
              </GridComponent>
              <SelectorSearch
                grid_id={'payrollexclusion-grid'}
                showMoreOptions={false}
              />
            </div>
          </>
        );
      };

      return (
        <>
          <TabComponent
            id='tabpayrolllist'
            heightAdjustMode='Auto'
            enablePersistence={true}
            selected={this.SelectedTab.bind(this)}
          >
            <TabItemsDirective>
              <TabItemDirective
                headerText={'Summary'}
                content={tabSummary}
                tabIndex={0}
              />
              <TabItemDirective
                headerText={'Payroll Exclusion'}
                content={tabPayrollExclusion}
                tabIndex={1}
              />
            </TabItemsDirective>
          </TabComponent>
        </>
      );
    };

    const splitterCreated = (e: any) => {
      let leftpane: PanePropertiesModel | any = {
        size: '25%',
        min: '10%',
        content: leftPane,
        collapsible: true,
      };

      let rightpane: PanePropertiesModel | any = {
        size: '75%',
        min: '10%',
        content: rightPane,
        collapsible: true,
      };

      // this.splitter.addPane(leftpane, 0);
      // this.splitter.addPane(rightpane, 1);
      this.splitter.addPane(rightpane, 0);
    };

    const handleProcessClick = (e: any) => {
      if (this.grdEmp.getSelectedRowIndexes().length <= 0) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please select atleast one employee.</p>',
        });
        return;
      } else {
        DialogConfirm.showDialog({
          isConfirm: true,
          // animationSettings: { effect: 'SlideTop' },
          showCloseIcon: true,
          closeOnEscape: true,
          title: ` Process Payroll `,
          content:
            '<p class="dialog-contain">Are you sure want to process payroll for selected employee(s)?</p>',
          okCaption: 'Yes',
          closeCaption: 'No',
          OkEvent: confirmYesClick,
        });
        return;
      }
    };

    const confirmYesClick = () => {


      let payload: any = {
        periodId: this.state.periodid,
        employeeIds: this.grdEmp.getSelectedRecords().map((x: any) => x.id),
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/listpayrolldata`,
        //`${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/process`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          let jsonPayrollFlat: any = Common.flattenJSON(
            response,
            {},
            '',
            null,
            [],
            [],
            '_'
          );

          // jsonPayrollFlat = jsonPayrollFlat.sort(
          //   (a: any, b: any) =>
          //     a['employeePayHeadTran_payHead_headTypeId'] - b['employeePayHeadTran_payHead_headTypeId']
          // );
          jsonPayrollFlat = jsonPayrollFlat.sort(
            (a: any, b: any) =>
              a['employeePayHeadTran_payHead_headTypeId'] -
              b['employeePayHeadTran_payHead_headTypeId'] ||
              a['employeePayHeadTran_payHead_typeOfId'] -
              b['employeePayHeadTran_payHead_typeOfId']
          );
          this.setState({
            jsonPayroll: response,
            jsonPayrollFlat: jsonPayrollFlat,
            basic_salaryPayhead_name:
              response !== undefined && response.length > 0
                ? jsonPayrollFlat[0].employeePayHeadTran_payHead_name
                : '',
          });
          //this.grdPayroll.dataSource = response;

          this.pivotObj.dataSourceSettings.dataSource = jsonPayrollFlat;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => { });
    };

    let tabc = document.getElementsByClassName(
      'e-toolbar-item e-template e-active'
    );
    if (tabc !== undefined && tabc.length > 0) {
      if ((tabc[0] as any).dataset['id'].toLowerCase() === 'tabitem_0') {
        Common.SetStatusBar(
          `Total Records :  ${this.tabdatatotal.attributes.summary}`
        );
      } else if ((tabc[0] as any).dataset['id'].toLowerCase() === 'tabitem_1') {
        Common.SetStatusBar(
          `Total Records :  ${this.tabdatatotal.attributes.exclusion}`
        );
      }
    }

    return (
      <>
        <div id='divpayroll' className='h-100'>
          <label style={{ fontSize: '20px', fontWeight: '500' }}>
            {this.props.jsonDataViewer
              ? Common.toTitleCase(
                this.props.jsonDataViewer.componentName.replaceAll('_', '')
              )
              : ''}
          </label>
          <div className='row'>
            <div
              className='col-12'
              style={{ display: 'flex', position: 'relative' }}
            >
              <div className='col-11' style={{ width: '99%' }}>
                <PeriodSelector
                  key={this.state.periodselectorkey}
                  handleInputChange={this.handleChange}
                  id='payroll-selector'
                  _jObject={this.props.jsonDataViewer}
                  showEmployeePeriodsOnly={false}
                  showLastPeriod={true}
                  alttext={'No Period Defined.'}
                />
              </div>
              <div
                style={{ position: 'absolute', right: '15px', top: '12px' }}
                id='moption'
              >
                <i
                  id='moreoptions'
                  className='fas fa-ellipsis-v'
                  style={{ fontSize: '20px', color: 'gray', cursor: 'pointer' }}
                ></i>
              </div>
            </div>
            <ContextMenuComponent
              id={Math.random().toString() + 'contextmenu'}
              ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
              // items={this.state._menuitems}
              items={this.cntxitems}
              select={this.handleMenuClick}
              target='#moreoptions'
            />
            {this.state.showCloseDialog == true ? (
              <>
                <DialogComponent
                  id='defaultDialog1'
                  showCloseIcon={false}
                  visible={this.state.showCloseDialog}
                  width={'45vw'}
                  height={'15vh'}
                  content={this.showProgress.bind(this)}
                  isModal={true}
                  header={this.state.headertext}
                  statelessTemplates={[]}
                  buttons={this.buttons}
                ></DialogComponent>
              </>
            ) : null}
            {/*<div className='col-md-6 text-end'>
              <input
                type='button'
                className='flat-button'
                value={'Process Payroll'}
                onClick={handleProcessClick}
              ></input>
              </div>*/}
          </div>
          {/* <div className='row'>
            <div className='col-md-6'>
              <div className='PeriodSelector'>
                <PeriodSelector
                  key={this.state.periodselectorkey}
                  handleInputChange={this.handleChange}
                  id='payroll-selector'
                  _jObject={this.props.jsonDataViewer}
                  showEmployeePeriodsOnly={false}
                  showLastPeriod={true}
                  alttext={'No Period Defined.'}
                />
              </div>
            </div>
            <div className='col-md-6 text-end'>
              <input
                type='button'
                className='flat-button'
                value={'Process Payroll'}
              ></input>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='payrollsummary'>
                <GridComponent
                  id='payrolldatalist-grid'
                  height={'calc(100vh - 226px)'}
                  width={'100%'}
                  dataSource={this.state.jsonPayroll}
                  columns={this.colsPayroll}
                  ref={(obj: any) => (this.grdEmp = obj)}
                />
              </div>
            </div>
          </div> */}
          <SplitterComponent
            id='payrolllist-splitter'
            orientation='Horizontal'
            delayUpdate={true}
            separatorSize={7}
            resizing={this.splitterResize.bind(this)}
            ref={(obj: any) => (this.splitter = obj)}
            created={splitterCreated}
          >
            <PanesDirective>
              {/*   <PaneDirective
                size='25%'
                min='10%'
                content={leftPane}
                collapsible={true}
              />
              <PaneDirective
                size='75%'
                min='10%'
                content={rightPane}
                collapsible={true}
              />*/}
            </PanesDirective>
          </SplitterComponent>
        </div>
        <div>
          {this.state.addingperiod === true ? (
            <DialogComponent
              id='addperiod'
              showCloseIcon={true}
              header={'Add New Period'}
              width={'30vw'}
              visible={this.state.addingperiod}
              close={() => this.setState({ addingperiod: false })}
              content={() => (
                <CreatePeriod
                  ref={this.child}
                  id={Math.random().toString()}
                  title=''
                // handleAccept={this.handleAcceptbtn}
                // handleCancel={this.handleCancelbtn}
                />
              )}
              isModal={true}
              buttons={this.extradialogbuttons}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
        <div>
          {this.state.assigininghead === true ? (
            <DialogComponent
              id='dlgAddHead'
              showCloseIcon={true}
              header={'Assign Amount'}
              width={'30vw'}
              visible={this.state.assigininghead}
              close={() => this.setState({ assigininghead: false })}
              content={this.generatcontent.bind(this)}
              isModal={true}
              buttons={this.extraaddheadbutton}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
        <div>
          <DialogComponent
            id='dlgBulkAssign'
            showCloseIcon={true}
            visible={this.state.bulkassignment}
            close={() =>
              this.setState({ bulkassignment: false, selectedmodeid: 0 })
            }
            header={
              this.state.selectedmodeid === 1
                ? 'Bulk Assignment'
                : 'Bulk Delete'
            }
            content={this.generatebulkcontent.bind(this)}
            width={'40vw'}
            isModal={true}
            buttons={this.extrabulkassignbutton}
            statelessTemplates={[]}
          ></DialogComponent>
        </div>
        <div>
          {this.state.showpayslip == true ? (
            <DialogComponent
              id='dlgPayslip'
              showCloseIcon={true}
              visible={this.state.showpayslip}
              close={() =>
                this.setState({ empobject: undefined, showpayslip: false })
              }
              header={'Payslip - (' + this.payslipdialogheader + ')'}
              content={this.generatePayslip.bind(this)}
              width={'60vw'}
              height={'60vh'}
              isModal={true}
              //buttons={this.extrabulkassignbutton}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
        <div>
          <DialogComponent
            id='dlgUploadPayHead'
            showCloseIcon={true}
            visible={this.state.uploadpayhead}
            close={() => this.setState({ uploadpayhead: false })}
            header={'Upload Pay Head'}
            content={this.generateuploadpayheadcontent.bind(this)}
            width={'40vw'}
            isModal={true}
            buttons={this.uploadpayheadbuttons}
            statelessTemplates={[]}
          ></DialogComponent>
        </div>
      </>
    );
  }
}
