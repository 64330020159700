import React from 'react';
import './CenterPanel.css';
import Common from '../../../Util/Common';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

interface Props {
  rootmenu: any;
  selectedmenu: any;
}

export default class CenterPanel extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {};

  componentDidMount() {}

  render() {
    return (
      <>
        <div id='dashboardcenterpanel' className='control-pane fadeUp'>
          <div className='sample_container card_sample control-section input-content-wrapper'>
            <div
              className='e-card e-custom-card'
              style={{ border: '1px solid #d3d3d3ab' }}
            >
              <div className='e-card-header'>
                <div className='e-card-header-caption center'>
                  <div className='e-card-header-title name'></div>
                  <div className='e-card-sub-title'></div>
                </div>
              </div>
              <div className='e-card-content-searchbar'>
                <div className='row'>
                  <div className='col-12'>
                    <TextBoxComponent
                      id='post'
                      cssClass='e-outline e-corner text-border-radius'
                      placeholder='Create a post'
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className='e-card e-custom-card  arutiintro'>
              <div className='e-card-header'>
                <div className='e-card-header-caption'>
                  <div className='e-card-header-title name'>
                    <img
                      src={'images/ArutiLogoProd.png'}
                      className='arutilogo'
                    />
                  </div>
                  <div className='e-card-sub-title'>
                    We are pleased to introduce you to Aruti 2.x
                  </div>
                </div>
              </div>
              <div className='e-card-content'>
                <div className='row'>
                  <div className='col-12'>
                    <video className='' controls>
                      <source
                        src='images/ArutiIntroPost.webm'
                        type='video/webm'
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
