import React from 'react';

class Splash extends React.Component {
  private intrvl: any;
  private splashInterval = 0;
  private maxInterval = 4; //seconds

  constructor(props: any) {
    super(props);
  }

  state = {
    showLoading: false,
  };

  componentDidMount() {
    this.splashInterval = 0;
    this.intrvl = setInterval(() => {
      this.splashInterval += 1;
      if (this.splashInterval > this.maxInterval) {
        this.setState({ showLoading: true });
        clearInterval(this.intrvl);
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intrvl);
  }

  render() {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <div>
          <video className='h-100' autoPlay>
            <source src='images/aruti.webm' type='video/webm' />
            Your browser does not support the video tag.
          </video>
          {/* <img src={'images/aruti.gif'} /> */}
        </div>
        {this.state.showLoading && (
          <div
            className='position-absolute text-center border-0 font-face-montserrat'
            style={{
              // fontFamily: 'Montserrat Light',
              fontSize: '40px',
              bottom: '10%',
            }}
          >
            {/* <i className='fa-solid fa-spinner fa-spin'> </i> */}
            Loading&nbsp;
            <i
              className='fa-solid fa-ellipsis fa-flip'
              style={{ fontSize: 'smaller', verticalAlign: 'bottom' }}
            ></i>
          </div>
        )}
      </div>
    );
  }
}

export default Splash;
