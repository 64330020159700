import * as React from 'react';
import './ResourceLink.css';
import Common from '../../../Util/Common';
import { DateTime } from '@syncfusion/ej2-react-charts';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ResourceLinkCategory from './ResourceLinkCategory';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ImageEditorNew from './ImageEditorNew';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  id?: string;
  resourceLinkId?: string;
  nameTitle?: string;
  isGroup?: boolean;
  url?: string;
  expiryDate?: Date;
  description?: string;
  icon?: string;
  iconCss?: string;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}

export default class ResourceLink extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    jsonData: undefined,
    showDialog: false,
    showImageDialog: false,
    id: this.props.id ?? '',
    resourceLinkId: this.props.resourceLinkId ?? '',
    nameTitle: this.props.nameTitle ?? '',
    isGroup: this.props.isGroup,
    url: this.props.url ?? '',
    expiryDate: this.props.expiryDate
      ? new Date(this.props.expiryDate)
      : undefined,
    description: this.props.description ?? '',
    icon: this.props.icon ?? '',
    iconCss: this.props.iconCss
      ? this.props.iconCss
      : this.props.icon
      ? ''
      : 'fa-solid fa-link',
  };

  componentDidMount(): void {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  public GetList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/get`,
      { addNewOption: true },
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          jsonData: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const onCancelClick = (e: any) => {
      this.setState({ showDialog: false });

      if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };

    const onSaveClick = (e: any) => {
      if (IsValid() === false) return false;

      let payload: any = {
        nameTitle: this.state.nameTitle,
        url: this.state.url,
        isgroup: false,
        resourceLinkId: this.state.resourceLinkId,
        description: this.state.description,
        expiryDate: this.state.expiryDate,
        icon: this.state.icon
          ? this.state.icon.replace('data:', '').replace(/^.+,/, '')
          : null,
        iconCss: this.state.iconCss,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/set`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          //DialogUtility.alert('Record saved successfully!');
          DialogConfirm.showDialog({
            content: '<p class="dialog-contain">Record saved successfully!</p>',
          });
          this.setState({
            nameTitle: '',
            url: '',
            isgroup: false,
            resourceLinkId: null,
            description: '',
            expiryDate: null,
            icon: null,
          });

          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    const IsValid = (): boolean => {
      if (
        !this.state.resourceLinkId ||
        this.state.resourceLinkId === Common.blankguid
      ) {
        //DialogUtility.alert(`Please select category.`);
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please select category.</p>',
        });
        return false;
      }
      if (!this.state.nameTitle.trim()) {
        //DialogUtility.alert(`Please enter Resource Link Title.`);
        DialogConfirm.showDialog({
          content:
            '<p class="dialog-contain">Please enter Resource Link Title.</p>',
        });
        return false;
      } else if (!this.state.isGroup && !this.state.url.trim()) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please enter URL.</p>',
        });
        return false;
      }

      return true;
    };

    const onCategoryChange = (e: any) => {
      if (e.value === Common.blankguid) {
        this.setState({ showDialog: true, resourceLinkId: e.value });
      } else {
        this.setState({ resourceLinkId: e.value });
      }
    };

    const onDescrChange = (e: any) => {
      this.setState({ description: e.value });
    };

    const dlgContent = (e: any) => {
      const handleSaveClick = (data: any) => {
        this.GetList();
        setTimeout(() => {
          let drp: any = document.getElementById('category');
          if (drp) {
            drp.ej2_instances[0].value = data.id;
          }
        }, 2000);
      };

      return (
        <ResourceLinkCategory
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
        />
      );
    };

    const handleCancelClick = (e: any) => {
      this.setState({ showDialog: false });
      let drp: any = document.getElementById('category');
      if (drp) {
        drp.ej2_instances[0].value = null;
      }
    };

    const onChangeIconClick = (e: any) => {
      this.setState({ showImageDialog: true });
    };

    const dlgIconContent = (e: any) => {
      return (
        <ImageEditorNew
          onCancelClick={handleImageCancelClick}
          onSaveClick={handleImageSaveClick}
          image={this.state.icon}
        />
      );
    };

    const handleImageCancelClick = (e: any) => {
      this.setState({ showImageDialog: false });
    };

    const handleImageSaveClick = (data: any) => {
      this.setState({ icon: data, showImageDialog: false });
    };

    return (
      <>
        <div id='divResourceLink' className='py-2'>
          {!this.state.isGroup && (
            <div className='row'>
              <div className='col-12'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Category
                </label>
                <span className='mandatoryinfo'>*</span>
                <DropDownListComponent
                  id='category'
                  dataSource={this.state.jsonData}
                  filterBarPlaceholder='Search a Category'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={{ value: 'id', text: 'nameTitle' }}
                  placeholder='Select Category'
                  popupHeight='220px'
                  style={{ fontSize: '15px' }}
                  value={this.state.resourceLinkId}
                  //   enabled={this.user.isManager && this.props.leaveobj == undefined ? true : false}
                  onChange={onCategoryChange}
                />
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-12'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                {this.state.isGroup ? 'Category' : 'Title'}
              </label>
              <span className='mandatoryinfo'>*</span>
              <input
                className='input-text-border'
                id='nameTitle'
                name='nameTitle'
                type='text'
                placeholder={this.state.isGroup ? 'Category' : 'Title'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                //autoFocus={true}
                //ref={(obj: any) => (this.txtName = obj)}
                value={this.state.nameTitle}
                onChange={(e: any) => {
                  this.setState({ nameTitle: e.target.value });
                  setTimeout(() => {
                    // this.txtName.focus();
                  }, 100);
                }}
              />
            </div>
          </div>
          {!this.state.isGroup && (
            <>
              <div className='row'>
                <div className='col-12'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    URL
                  </label>
                  <span className='mandatoryinfo'>*</span>
                  <input
                    className='input-text-border'
                    id='url'
                    name='url'
                    type='text'
                    placeholder={'URL'}
                    autoComplete='off'
                    style={{ fontSize: '15px' }}
                    //autoFocus={true}
                    //ref={(obj: any) => (this.txtName = obj)}
                    value={this.state.url}
                    onChange={(e: any) => {
                      this.setState({ url: e.target.value });
                      setTimeout(() => {
                        // this.txtName.focus();
                      }, 100);
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Expiry Date
                  </label>
                  <DatePickerComponent
                    id='startDate'
                    name='startDate'
                    format={this.user.settings.formats.DateShort}
                    showClearButton={true}
                    cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    value={
                      this.state.expiryDate == undefined
                        ? undefined
                        : this.state.expiryDate
                    }
                    showTodayButton={false}
                    placeholder={this.user.settings.formats.DateShort}
                    style={{ fontSize: '13px' }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Description
                  </label>
                  <TextBoxComponent
                    id='description'
                    multiline={true}
                    placeholder='Description'
                    value={this.state.description}
                    onChange={onDescrChange}
                  ></TextBoxComponent>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                    Image
                  </label>
                  <div className='d-flex'>
                    <div className='icn'>
                      {!this.state.icon ? (
                        <div className={this.state.iconCss}></div>
                      ) : (
                        <img src={this.state.icon} height={28} width={28} />
                      )}
                    </div>
                    <div>
                      <ButtonComponent
                        className='flat-button e-flat ms-3'
                        onClick={onChangeIconClick}
                      >
                        Change Image
                      </ButtonComponent>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='e-footer-content'>
            <div>
              <button
                id='btnCancel'
                className='e-control e-btn e-lib flat-button e-flat'
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                id='btnAccept'
                className='e-control e-btn e-lib flat-button e-primary e-flat'
                onClick={onSaveClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defaultDialog-resourcelinkchange'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'25vw'}
            close={handleCancelClick}
            content={dlgContent}
            isModal={true}
            header={`Add Resource Link Category`}
            //buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.showImageDialog === true ? (
          <DialogComponent
            id='defaultDialog-resourcelinkiconchange'
            showCloseIcon={true}
            visible={this.state.showImageDialog}
            width={'25vw'}
            close={handleImageCancelClick}
            content={dlgIconContent}
            isModal={true}
            header={`Select / Change Image`}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
