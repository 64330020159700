import * as React from 'react';
import './ResourceLinkList.css';
import Common from '../../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  Filter,
  Inject,
  Toolbar,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import SelectorSearch from '../../Layout/SelectorSearch';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import ResourceLink from './ResourceLink';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import ImageEditorNew from './ImageEditorNew';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {}

export default class ResourceLinkList extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private grd: TreeGridComponent | any = null;

  private editSettings: EditSettingsModel = {
    allowAdding: true,
    allowEditing: true,
    allowDeleting: true,
    mode: 'Row',
    newRowPosition: 'Below',
    allowEditOnDblClick: true,
    showDeleteConfirmDialog: true,
  };

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    jsonData: undefined,
    showAddEdit: false,
    showImageDialog: false,
    addNew: false,
    isGrp: false,
    icon: '',
    changeIconPayload: null,
  };

  componentDidMount(): void {
    if (!this.state.jsonData) {
      this.GetList();
    }
  }

  public GetList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/get`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          jsonData: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  render() {
    const toolbarClick = (e: any) => {
      if (e.item.id === `resourcelinklist-treegrid_gridcontrol_add`) {
        e.cancel = true;
        this.setState({ showAddEdit: true, addNew: true, isGrp: false });
      } else if (e.item.id === `resourcelinklist-treegrid_gridcontrol_update`) {
      }
    };

    const dlgContent = (e: any) => {
      return (
        <ResourceLink
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
        />
      );
    };

    const handleCancelClick = (e: any) => {
      this.setState({ showAddEdit: false });
    };

    const handleSaveClick = (e: any) => {
      this.GetList();
    };

    const editTemplateDate = (e: any) => {
      let date: any = e.expiryDate ? e.expiryDate : undefined;

      return (
        <DatePickerComponent
          id='dtpExpiryDate'
          name='expiryDate'
          format={this.user.settings.formats.DateShort}
          value={date}
          showClearButton={false}
          cssClass='e-hidedate-icon'
          openOnFocus={true}
          showTodayButton={false}
          style={{ fontSize: '15px' }}
        />
      );
    };

    const actionBegin = (e: any) => {
      if (e.requestType === 'beginEdit') {
        if (e.rowData.isgroup === true) {
          let grd: any = document.getElementById('resourcelinklist-treegrid');
          if (grd) {
            if (e.form) {
              let url: any = e.form.querySelector('input[name="url"]');
              url.disabled = true;
              url.readOnly = true;

              let expiryDate: any = e.form.querySelector(
                'input[name="expiryDate"]'
              );
              expiryDate.disabled = true;
              expiryDate.readOnly = true;

              let description: any = e.form.querySelector(
                'input[name="description"]'
              );
              description.disabled = true;
              description.readOnly = true;
            }
          }
        }
      } else if (e.action === 'edit' && e.requestType === 'save') {
        let payload: any = {
          id: e.data.id,
          nameTitle: e.data.nameTitle,
          url: e.data.url,
          isgroup: e.data.isgroup,
          resourceLinkId: e.data.resourceLinkId,
          description: e.data.description,
          expiryDate: e.data.expiryDate,
          icon: e.data.icon,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/set`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            //DialogUtility.alert('Record saved successfully!');
          })
          .catch((error: any) => {
            this.grd.selectRow(e.rowIndex);

            let url: any = document.querySelector(
              '.resourcelinklist-treegrid input[name="url"]'
            );
            if (url) {
              url.value = e.previousData.url;
            }
            e.data.url = e.previousData.url;

            let nameTitle: any = document.querySelector(
              '.resourcelinklist-treegrid input[name="nameTitle"]'
            );
            if (nameTitle) {
              nameTitle.value = e.previousData.nameTitle;
            }
            e.data.nameTitle = e.previousData.nameTitle;

            this.grd.startEdit();
            console.error(error);
          })
          .finally(() => {});
      } else if (e.requestType === 'delete') {
        let payload: any = {
          id: e.data[0].id,
        };

        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/delete`,
          payload,
          Common.getToken() || '',
          this.user,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {})
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => {});
      }
    };

    const iconTemplate = (props: any) => {
      const onIconTemplateClick = (e: any) => {
        let payload: any = {
          id: props.id,
          nameTitle: props.nameTitle,
          url: props.url,
          isgroup: false,
          resourceLinkId: props.resourceLinkId,
          description: props.description,
          expiryDate: props.expiryDate,
          icon: props.icon
            ? props.icon.replace('data:', '').replace(/^.+,/, '')
            : null,
          iconCss: props.iconCss,
        };

        this.setState({
          showImageDialog: true,
          changeIconPayload: payload,
          icon: props.icon ?? null,
        });
      };

      return (
        <div
          className={`${!props.isgroup ? 'cursor-pointer' : ''} `}
          onClick={onIconTemplateClick}
        >
          {!props.icon ? (
            <div
              className={
                !props.isgroup
                  ? props.iconCss
                    ? `ps-1 ${props.iconCss}`
                    : 'ps-1 fa-solid fa-link'
                  : ''
              }
            ></div>
          ) : (
            <img
              src={
                !props.icon.includes('data')
                  ? `data:image/png;base64,${props.icon}`
                  : props.icon
              }
              height={24}
              width={24}
            />
          )}
        </div>
      );
    };

    const iconEditTemplate = (props: any) => {
      return (
        <div>
          {!props.icon ? (
            <div
              className={
                !props.isgroup
                  ? props.iconCss
                    ? `ps-1 ${props.iconCss}`
                    : 'ps-1 fa-solid fa-link'
                  : ''
              }
            ></div>
          ) : (
            <img
              src={
                !props.icon.includes('data')
                  ? `data:image/png;base64,${props.icon}`
                  : props.icon
              }
              height={24}
              width={24}
            />
          )}
        </div>
      );
    };

    const dlgIconContent = (e: any) => {
      return (
        <ImageEditorNew
          onCancelClick={handleImageCancelClick}
          onSaveClick={handleImageSaveClick}
          image={this.state.icon}
        />
      );
    };

    const handleImageCancelClick = (e: any) => {
      this.setState({
        icon: null,
        changeIconPayload: null,
        showImageDialog: false,
      });
    };

    const handleImageSaveClick = (data: any) => {
      let payload: any = this.state.changeIconPayload;

      payload.icon = data
        ? data.replace('data:', '').replace(/^.+,/, '')
        : null;
      this.setState({
        changeIconPayload: payload,
        icon: data ? data.replace('data:', '').replace(/^.+,/, '') : null,
        showImageDialog: false,
      });

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/resourcelink/set`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          this.GetList();
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    };

    return (
      <>
        <div id='divResourceLinkList' className='py-2'>
          {/* <label
            className='pb-1'
            style={{ fontSize: '20px', fontWeight: '500' }}
          >
            Resource Links
          </label> */}
          <div className='control-pane'>
            <div className='control-section'>
              <div id='divresourcelink-grid'>
                <TreeGridComponent
                  id='resourcelinklist-treegrid'
                  className={`resourcelinklist-treegrid`}
                  height={`calc(100vh - 312px`}
                  dataSource={this.state.jsonData}
                  treeColumnIndex={0}
                  childMapping={'subResourceLinks'}
                  editSettings={this.editSettings}
                  toolbar={[
                    {
                      text: 'Add',
                      tooltipText: 'Add',
                      prefixIcon: 'e-add',
                      id: `resourcelinklist-treegrid_gridcontrol_add`,
                    },
                    // {
                    //   text: '',
                    //   tooltipText: 'Add Category',
                    //   prefixIcon: 'e-add-above font-22',
                    //   id: `resourcelinklist-treegrid_gridcontrol_addgrp`,
                    // },
                    {
                      text: 'Edit',
                      tooltipText: 'Edit',
                      prefixIcon: 'e-edit',
                      id: `resourcelinklist-treegrid_gridcontrol_edit`,
                    },
                    {
                      text: 'Delete',
                      tooltipText: 'Delete',
                      prefixIcon: 'e-delete',
                      id: `resourcelinklist-treegrid_gridcontrol_delete`,
                    },
                    {
                      text: 'Save',
                      tooltipText: 'Save',
                      prefixIcon: 'e-update',
                      id: `resourcelinklist-treegrid_gridcontrol_update`,
                    },
                    {
                      text: 'Cancel',
                      tooltipText: 'Cancel',
                      prefixIcon: 'e-cancel',
                      id: `resourcelinklist-treegrid_gridcontrol_cancel`,
                    },
                  ]}
                  toolbarClick={toolbarClick}
                  ref={(obj: any) => (this.grd = obj)}
                  // dataBound={(e: any) => {
                  //   if (this.grdAllocation) {
                  //     this.grdAllocation.toolbarModule.overflowMode = 'MultiRow';
                  //   }
                  // }}
                  // rowSelected={rowSelected}
                  actionBegin={actionBegin}
                  // actionComplete={actionComplete}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field='nameTitle'
                      headerText='Title'
                      textAlign='Left'
                      allowEditing={true}
                      validationRules={{ required: true }}
                    />

                    <ColumnDirective
                      field='url'
                      headerText='URL'
                      textAlign='Left'
                      allowEditing={true}
                      width={'20%'}
                    ></ColumnDirective>

                    <ColumnDirective
                      field='icon'
                      headerText='Icon'
                      textAlign='Center'
                      allowEditing={false}
                      template={iconTemplate}
                      editTemplate={iconEditTemplate}
                      width={50}
                    />

                    <ColumnDirective
                      field='expiryDate'
                      headerText='Expiry Date'
                      textAlign='Left'
                      width={'10%'}
                      allowEditing={true}
                      type='datetime'
                      format={this.user.settings.formats.DateShort}
                      editType='datepickeredit'
                      editTemplate={editTemplateDate}
                    />

                    <ColumnDirective
                      field='description'
                      headerText='Description'
                      textAlign='Left'
                      allowEditing={true}
                      width={'40%'}
                    />
                  </ColumnsDirective>
                  <Inject services={[Filter, Edit, Toolbar]} />
                </TreeGridComponent>
                <SelectorSearch grid_id='resourcelinklist-treegrid' />
              </div>
            </div>
          </div>
        </div>
        {this.state.showAddEdit === true ? (
          <DialogComponent
            id='defaultDialog-resourcelinkchange'
            showCloseIcon={true}
            visible={this.state.showAddEdit}
            width={'25vw'}
            close={handleCancelClick}
            content={dlgContent}
            isModal={true}
            header={`${
              this.state.addNew === true ? 'Add' : 'Change'
            } Resource Link`}
            //buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.showImageDialog === true ? (
          <DialogComponent
            id='defaultDialog-resourcelinkiconchange'
            showCloseIcon={true}
            visible={this.state.showImageDialog}
            width={'25vw'}
            close={handleImageCancelClick}
            content={dlgIconContent}
            isModal={true}
            header={`Select / Change Image`}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
