import * as React from 'react';
import Common from '../../../Util/Common';
import './PayrollVarianceReport.css';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  Group,
  Sort,
  Filter,
  Aggregate,
  ExcelQueryCellInfoEventArgs,
  PdfQueryCellInfoEventArgs,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregatesDirective,
  AggregateDirective,
} from '@syncfusion/ej2-react-grids';
import PeriodSelector from '../../PeriodSelector/PeriodSelector';
import {
  PivotViewComponent,
  IDataOptions,
  FieldList,
  Toolbar as PivotToolbar,
  ExcelExport as PivotExcelExport,
  PDFExport as PivotPDFExport,
  PivotChart,
  IDataSet,
} from '@syncfusion/ej2-react-pivotview';

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

interface Props {}
export default class PayrollVarianceReport extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private pivotObj: PivotViewComponent | any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { dataFlat: undefined, fromMonth: undefined, toMonth: undefined };

  private chartOnLoad(args: any) {
    let selectedTheme = window.location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, 'Dark')
      .replace(/contrast/i, 'Contrast')
      .replace(/-highContrast/i, 'HighContrast');
  }

  private GenerateReport(fromperiodid: string, toperiodid: string) {
    Common.ShowSpinner();

    let payload: any = {
      FromPeriodId: fromperiodid,
      ToPeriodId: toperiodid,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrollvariancereport/generate`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let jdataFlat: any = undefined;
        jdataFlat = Common.flattenJSON(data, {}, '', null, [], [], '_');
        if (this.pivotObj) {
          this.pivotObj.dataSourceSettings.dataSource = jdataFlat;
          this.pivotObj.refresh();
        } else {
          this.setState({ dataFlat: jdataFlat });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  render() {
    const handleCheckChange = (checked: any) => {};
    const handlePeriodChange = (e: any) => {
      let fromperiod: any = document.getElementById(
        'frompayrollvariancereportperiod-selector'
      );
      let toperiod: any = document.getElementById(
        'topayrollvariancereportperiod-selector'
      );

      if (fromperiod == undefined) {
        return;
      }

      if (toperiod == undefined) {
        return;
      }

      this.setState({
        fromMonth: Common.formatDate(
          new Date(fromperiod.dataset.startdate),
          'MMM yy'
        ),
        toMonth: Common.formatDate(
          new Date(toperiod.dataset.startdate),
          'MMM yy'
        ),
      });
      console.log(this.state.fromMonth, this.state.toMonth);
      this.GenerateReport(
        fromperiod.dataset.periodid,
        toperiod.dataset.periodid
      );
    };
    const handleAsOnDateChange = (e: any) => {};
    const handleDtpCheckChange = (e: any) => {};

    const dataSourceSettings: IDataOptions = {
      enableSorting: true,
      columns: [{ name: 'HeadName' }],
      valueSortSettings: { headerDelimiter: ' - ' },
      values: [
        { name: 'FromPeriodAmount', caption: this.state.fromMonth },
        { name: 'ToPeriodAmount', caption: this.state.toMonth },
        { name: 'Variance', caption: 'Variance' },
      ],
      dataSource: this.state.dataFlat,
      rows: [{ name: 'EmployeeName' }],
      formatSettings: [{ name: 'Amount', format: 'C0' }],
      expandAll: false,
      filters: [],
      showColumnGrandTotals: true,
    };

    return (
      <>
        <PivotViewComponent
          id='pivotpayrollvariancereport'
          className='position-relative'
          dataSourceSettings={dataSourceSettings}
          width={'100%'}
          height={'700'}
          showToolbar={true}
          // showFieldList={true}
          // displayOption={{ view: 'Both' }}
          toolbar={[
            'Grid',
            'Chart',
            'Export',
            { template: '#frompayrollvariancereport-periodctrls' },
            { template: '#topayrollvariancereport-periodctrls' },
          ]}
          allowExcelExport={true}
          allowPdfExport={true}
          allowDrillThrough={true}
          gridSettings={{ columnWidth: 140 }}
          chartSettings={{
            title: 'this.reportName',
            load: this.chartOnLoad.bind(this),
          }}
          ref={(pivotview: any) => {
            this.pivotObj = pivotview;
          }}
        >
          <Inject
            services={[
              FieldList,
              //CalculatedField,
              PivotToolbar,
              PivotPDFExport,
              PivotExcelExport,
              //ConditionalFormatting,
              //NumberFormatting,
              PivotChart,
            ]}
          />
        </PivotViewComponent>
        <div>
          <div id='frompayrollvariancereport-periodctrls'>
            <PeriodSelector
              handleInputChange={handlePeriodChange}
              id='frompayrollvariancereportperiod-selector'
              _jObject={[]}
              showEmployeePeriodsOnly={false}
              showLastPeriod={true}
              alttext={'No Period Defined.'}
              showNewPeriodOption={false}
              showAsOnDateIfNoPeriod={true}
              showcheckbox={true}
              checked={true}
              handleCheckChange={handleCheckChange}
              handleAsOnDateChange={handleAsOnDateChange}
              handleDtpCheckChange={handleDtpCheckChange}
            />
          </div>
        </div>
        <div>
          <div id='topayrollvariancereport-periodctrls'>
            <PeriodSelector
              handleInputChange={handlePeriodChange}
              id='topayrollvariancereportperiod-selector'
              _jObject={[]}
              showEmployeePeriodsOnly={false}
              showLastPeriod={true}
              alttext={'No Period Defined.'}
              showNewPeriodOption={false}
              showAsOnDateIfNoPeriod={true}
              showcheckbox={true}
              checked={true}
              handleCheckChange={handleCheckChange}
              handleAsOnDateChange={handleAsOnDateChange}
              handleDtpCheckChange={handleDtpCheckChange}
            />
          </div>
        </div>
      </>
    );
  }
}
