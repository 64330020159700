import * as React from 'react';
import './CustomReportPreview.css';
import Common from '../../Util/Common';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
  ExcelExport,
  PdfExport,
  Group,
  Sort,
  Filter,
  Aggregate,
  ExcelQueryCellInfoEventArgs,
  PdfQueryCellInfoEventArgs,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregatesDirective,
  AggregateDirective,
} from '@syncfusion/ej2-react-grids';
import PeriodSelector from '../PeriodSelector/PeriodSelector';
import {
  PivotViewComponent,
  IDataOptions,
  FieldList,
  Toolbar as PivotToolbar,
  ExcelExport as PivotExcelExport,
  PDFExport as PivotPDFExport,
  PivotChart,
} from '@syncfusion/ej2-react-pivotview';

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

interface Props {
  periodId: string;
  asOnStartDate: Date;
  asOnEndDate: Date;
  reportId?: string;
  reportType?: string;
  reportName?: string;
  reportCategory?: any;
  columns?: any;
  orientation?: string;
}

export default class CustomReportPreview extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private gridInstance: GridComponent | any;
  private pivotObj: PivotViewComponent | any = null;
  public toolbarOptions: any = ['ExcelExport', 'PdfExport'];
  private portraitwidth: number = 690;
  private landscapewidth: number = 1020;
  private pagewidth: number = 690;
  private reportType: string = '';
  private reportCategory?: any = undefined;
  private reportName: string = '';
  private columns: any = null;
  private pivotrows: any = null;
  private pivotvals: any = null;
  private orientation: string = '';
  private sortcolumns: any = undefined;
  private groupedcolumns: any = undefined;
  private pageloaded: boolean = false;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  static defaultProps = {
    orientation: 'Portrait',
  };

  state = { data: undefined, dataFlat: undefined };

  componentDidMount(): void {
    if (!this.props.reportId) {
      this.reportType = this.props.reportType!;
      this.reportCategory = this.props.reportCategory!;
      this.reportName = this.props.reportName!;
      this.columns = this.props.columns!;
      this.pivotrows = this.columns.filter((x: any) => x.isPivoted);
      this.pivotrows = this.columns.filter((x: any) => x.isPivotedVal);
      this.orientation = this.props.orientation!;

      this.pagewidth =
        this.orientation.toLowerCase() === 'landscape'
          ? this.landscapewidth
          : this.portraitwidth;

      let sortcols: any = this.props.columns.filter(
        (x: any) => x.isSorted !== ''
      );

      if (sortcols.length > 0) {
        this.sortcolumns = sortcols.map((x: any) => {
          return {
            field: x.columnId.split('|')[1],
            direction: x.isSorted === 'Z' ? 'Descending' : 'Ascending',
          };
        });
      }

      let groupedcols: any = this.props.columns.filter(
        (x: any) => x.isGrouped === true
      );

      if (groupedcols.length > 0) {
        this.groupedcolumns = groupedcols.map((x: any) => {
          return x.columnId.split('|')[1];
        });
      }

      this.GenerateReport(
        this.props.periodId,
        this.props.asOnStartDate,
        this.props.asOnEndDate,
        true
      );
    }
  }

  private GenerateReport(
    periodid: string,
    startDate: Date,
    endDate: Date,
    showActiveOnly: boolean
  ) {
    Common.ShowSpinner();
    let cols: any = [];
    if (this.reportType === 'pivottable') {
      cols = this.columns.filter(
        (x: any) =>
          x.isSelected === true ||
          x.isFiltered === true ||
          x.isPivoted === true ||
          x.isPivotedVal === true
      );
    } else {
      cols = this.columns.filter(
        (x: any) => x.isSelected === true || x.isFiltered === true
      );
    }

    let payload: any = {
      showOnlyActive: showActiveOnly,
      periodId: periodid,
      asOnStartDate: Common.RemoveTimezoneOffsetFromDate(new Date(startDate)),
      asOnEndDate: Common.RemoveTimezoneOffsetFromDate(new Date(endDate)),
      reportCategory: this.reportCategory,
      columns: cols,
    };

    let servicename: any = REACT_APP_ENDPOINT_CORESERVICE;
    // if (this.reportCategory.findIndex((x: any) => x.id === 'payroll') >= 0) {
    //   servicename = REACT_APP_ENDPOINT_PAYROLLSERVICE;
    // }

    Common.ApiCallAsync(
      'POST',
      `${servicename}/customreport/generate`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.forEach((x: any) => {
          this.columns.forEach((c: any) => {
            if (c.isDate) {
              if (x[c.columnId.split('|')[1]] === null) {
                x[c.columnId.split('|')[1]] = null;
              } else if (
                Common.formatDate(
                  new Date(x[c.columnId.split('|')[1]]),
                  'yyyyMMdd'
                ) !== Common.formatDate(new Date('0001-01-01'), 'yyyyMMdd')
              ) {
                x[c.columnId.split('|')[1]] = new Date(
                  x[c.columnId.split('|')[1]]
                );
              } else {
                x[c.columnId.split('|')[1]] = null;
              }
            }
          });
        });

        let jdataFlat: any = undefined;
        if (this.reportType === 'pivottable') {
          jdataFlat = Common.flattenJSON(data, {}, '', null, [], [], '_');
          if (this.pivotObj) {
            this.pivotObj.dataSourceSettings.dataSource = jdataFlat;
            this.pivotObj.refresh();
          } else {
            this.setState({ dataFlat: jdataFlat });
          }
        } else {
          this.setState({ data: data });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  public toolbarClick(args: any): void {
    switch (args.currentTarget.id) {
      case 'gridcustrepopreview_pdfexport':
        const exportProperties = {
          // multipleExport: {
          //   type: 'AppendToPage',
          //   blankRows: 1,
          // },
          header: {
            contents: [
              {
                position: { x: 0, y: 0 },
                style: {
                  textBrushColor: '#000000',
                  fontSize: 20,
                  hAlign: 'Center',
                  dashStyle: 'Solid',
                },
                type: 'Text',
                value: this.reportName,
                size: {
                  height: 30,
                  width: this.pagewidth,
                },
              },
            ],
            fromTop: 0,
            height: 30,
          },
          fileName: `${this.reportName}.pdf`,
          pageOrientation: this.orientation,
        };

        this.gridInstance.pdfExport(exportProperties);
        // .then((response: any) => {
        //   console.log(response);
        // });
        break;
      case 'gridcustrepopreview_excelexport':
        let cols: any = this.columns.map((x: any, idx: number) => {
          return {
            index: idx + 1,
            value: '',
            width: 150,
            style: { fontColor: '#c68e78', fontSize: 18, bold: true },
          };
        });
        const excelExportProperties = {
          // multipleExport: {
          //   type: 'AppendToSheet',
          //   blankRows: 2,
          // },
          header: {
            width: 500,
            headerRows: 2,
            rows: [
              {
                cells: [
                  {
                    colSpan: this.columns.length,
                    value: this.reportName,
                    //width: 200,
                    style: {
                      fontColor: '#c68e78',
                      fontSize: 20,
                      hAlign: 'Center',
                      bold: true,
                      underline: true,
                    },
                  },
                ],
              },
              {
                cells: cols,
              },
            ],
          },
          fileName: `${this.reportName}.xlsx`,
        };

        this.gridInstance.excelExport(excelExportProperties);
        break;
      case 'gridcustrepopreview_csvexport':
        this.gridInstance.csvExport();
        break;
    }
  }

  public exportQueryCellInfo(
    args: ExcelQueryCellInfoEventArgs | PdfQueryCellInfoEventArgs
  ): void {
    // if (args.column!.headerText === 'Employee Image') {
    //   if ((args as any).name === 'excelQueryCellInfo') {
    //     args.image = {
    //       height: 75,
    //       base64: args.data['EmployeeImage'],
    //       width: 75,
    //     };
    //   } else {
    //     args.image = { base64: args.data['EmployeeImage'] };
    //   }
    // }
    // if (args.column.headerText === 'Email ID') {
    //   args.hyperLink = {
    //     target: 'mailto:' + args.data['EmailID'],
    //     displayText: args.data['EmailID'],
    //   };
    // }
  }

  public pdfHeadertQueryCellInfo(args: any) {
    args.cell.row.pdfGrid.repeatHeader = true;
  }

  private chartOnLoad(args: any) {
    let selectedTheme = window.location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, 'Dark')
      .replace(/contrast/i, 'Contrast')
      .replace(/-highContrast/i, 'HighContrast');
  }

  render() {
    const handleCheckChange = (checked: any) => {
      let period: any = document.getElementById(
        'customreportpreviewperiod-selector'
      );
      if (period) {
        this.GenerateReport(
          period.dataset.periodid,
          period.dataset.startdate,
          period.dataset.enddate,
          checked
        );
      }
    };

    const handlePeriodChange = (e: any) => {
      if (this.pageloaded) {
        if (e.id !== Common.blankguid) {
          let period: any = document.getElementById(
            'customreportpreviewperiod-selector'
          );
          if (period) {
            this.GenerateReport(
              period.dataset.periodid,
              period.dataset.startdate,
              period.dataset.enddate,
              true
            );
          }
        }
      } else {
        this.pageloaded = true;
      }
    };

    const handleAsOnDateChange = (e: any) => {
      this.GenerateReport(
        Common.blankguid,
        e.target.value,
        e.target.value,
        true
      );
    };

    const handleDtpCheckChange = (e: any) => {
      let period: any = document.getElementById(
        'customreportpreviewperiod-selector'
      );
      if (period) {
        this.GenerateReport(
          period.dataset.periodid,
          period.dataset.startdate,
          period.dataset.enddate,
          e.target.checked
        );
      }
    };

    const toolbarTemplate = () => {
      return (
        <div
          id='toolbartemplate'
          className='e-control e-toolbar e-lib e-keyboard'
        >
          <div className='e-toolbar-items'>
            <div className='e-toolbar-item' title='Excel Export'>
              <button
                className='e-tbar-btn e-tbtn-txt e-control e-btn e-lib'
                type='button'
                id='gridcustrepopreview_excelexport'
                data-ripple='true'
                tabIndex={0}
                data-tabindex='-1'
                aria-label='Excel Export'
                aria-disabled='false'
                style={{ width: 'auto' }}
                onClick={this.toolbarClick.bind(this)}
              >
                <span className='e-btn-icon e-excelexport e-icons e-icon-left'></span>
                <span className='e-tbar-btn-text'>Excel Export</span>
              </button>
            </div>
            <div className='e-toolbar-item' title='PDF Export'>
              <button
                className='e-tbar-btn e-tbtn-txt e-control e-btn e-lib'
                type='button'
                id='gridcustrepopreview_pdfexport'
                data-ripple='true'
                tabIndex={-1}
                data-tabindex='-1'
                aria-label='PDF Export'
                aria-disabled='false'
                style={{ width: 'auto' }}
                onClick={this.toolbarClick.bind(this)}
              >
                <span className='e-btn-icon e-pdfexport e-icons e-icon-left'></span>
                <span className='e-tbar-btn-text'>PDF Export</span>
              </button>
            </div>
            <div className='e-toolbar-item' title='Select Period'>
              <PeriodSelector
                handleInputChange={handlePeriodChange}
                id='customreportpreviewperiod-selector'
                _jObject={[]}
                showEmployeePeriodsOnly={false}
                showLastPeriod={true}
                alttext={'No Period Defined.'}
                showNewPeriodOption={false}
                showAsOnDateIfNoPeriod={true}
                showcheckbox={true}
                checked={true}
                handleCheckChange={handleCheckChange}
                handleAsOnDateChange={handleAsOnDateChange}
                handleDtpCheckChange={handleDtpCheckChange}
              />
            </div>
          </div>
        </div>
      );
    };

    const templateGroupCount = (e: any) => {
      return <span>Sub Total : {e.Count}</span>;
    };

    const templateCount = (e: any) => {
      return <span>Total : {e.Count}</span>;
    };

    const templateGroupSum = (e: any) => {
      return <span>{e.Sum}</span>;
    };

    const templateSum = (e: any) => {
      return <span>{e.Sum}</span>;
    };

    const dataSourceSettings: IDataOptions = {
      dataSource: this.state.dataFlat,
      showColumnGrandTotals: false,
      showHeaderWhenEmpty: false,
      enableSorting: true,
      emptyCellsTextContent: Common.FormatNumber(
        0,
        this.user.settings.formats.Decimal ?? '2'
      ),
      rows:
        this.columns &&
        this.columns
          .filter((x: any) => x.isPivoted)
          .map((x: any) => {
            return {
              name: x.columnId.split('|')[1],
              caption: x.text,
            };
          }),
      //rows: [{ name: 'employeecode_name', caption: 'employee' }],
      columns:
        this.columns &&
        this.columns
          .filter((x: any) => x.isSelected)
          .map((x: any) => {
            return {
              name: x.columnId.split('|')[1],
              caption: x.text,
            };
          }),
      // columns: [
      //   {
      //     name: 'employeePayHeadTran_headTypeName',
      //     caption: 'head type',
      //   },
      //   { name: 'employeePayHeadTran_payHead_name', caption: 'payhead' },
      // ],
      //values: [{ name: 'amountPaidCurrency', caption: 'Amount' }],
      values:
        this.columns &&
        this.columns
          .filter((x: any) => x.isPivotedVal)
          .map((x: any) => {
            return {
              name: x.columnId.split('|')[1],
              caption: x.text,
            };
          }),
      // formatSettings: [
      //   {
      //     name: 'amountPaidCurrency',
      //     format: this.user.settings.formats.Decimal
      //       ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
      //       : 'N2',
      //   },
      // ],
      formatSettings:
        this.columns &&
        this.columns
          .filter((x: any) => x.isPivotedVal && x.isNumber === true)
          .map((x: any) => {
            return {
              name: x.columnId.split('|')[1],
              format: this.user.settings.formats.Decimal
                ? 'N' + this.user.settings.formats.Decimal.split('.')[1].length
                : 'N2',
            };
          }),
      allowLabelFilter: true,
      allowValueFilter: true,
      // filters: [
      //   { name: 'employeecode_name', caption: 'Employee' },
      //   { name: 'employeePayHeadTran_payHead_name', caption: 'Pay Head' },
      //   { name: 'employeePayHeadTran_headTypeName', caption: 'Head Type' },
      // ],
      filters:
        this.columns &&
        this.columns
          .filter((x: any) => x.isSelected || x.isPivoted)
          .map((x: any) => {
            return {
              name: x.columnId.split('|')[1],
              caption: x.text,
            };
          }),
      // sortSettings: [
      //   { name: 'employeePayHeadTran_headTypeName', order: 'None' },
      // ],
      sortSettings:
        this.columns &&
        this.columns
          .filter((x: any) => x.isSelected)
          .map((x: any) => {
            return {
              name: x.columnId.split('|')[1],
              order: 'None',
            };
          }),
    };

    return (
      <>
        <div id='custrepopreview' className='control-pane h-100'>
          <div className='control-section'>
            {this.reportType === 'list' && this.state.data && (
              <GridComponent
                id='gridcustrepopreview'
                className='custrepopreviewgrid'
                height={'calc(100vh - 350px)'}
                dataSource={this.state.data}
                allowFiltering={true}
                filterSettings={{ mode: 'Immediate', type: 'Excel' }}
                allowSorting={true}
                sortSettings={{
                  columns: this.sortcolumns,
                }}
                allowGrouping={true}
                groupSettings={{
                  showGroupedColumn: false,
                  showDropArea: false,
                  columns: this.groupedcolumns,
                  captionTemplate: '<span class="groupItems"> ${key} </span>',
                }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbarClick={this.toolbarClick.bind(this)}
                excelQueryCellInfo={this.exportQueryCellInfo.bind(this)}
                pdfQueryCellInfo={this.exportQueryCellInfo.bind(this)}
                pdfHeaderQueryCellInfo={this.pdfHeadertQueryCellInfo.bind(this)}
                toolbar={this.toolbarOptions}
                toolbarTemplate={toolbarTemplate}
                ref={(grid: any) => (this.gridInstance = grid)}
              >
                <ColumnsDirective>
                  {this.columns &&
                    this.columns
                      .filter((x: any) => x.isSelected === true)
                      .map((x: any) => {
                        return (
                          <ColumnDirective
                            key={x.columnId}
                            field={x.columnId.split('|')[1]}
                            headerText={
                              !x.text.includes('(')
                                ? x.text
                                : x.text.split('(')[0].trim()
                            }
                            type={
                              x.isNumber
                                ? 'number'
                                : x.isDate
                                ? 'datetime'
                                : 'string'
                            }
                            format={
                              x.isNumber
                                ? 'N2'
                                : x.isDate
                                ? this.user.settings.formats.DateShort
                                : ''
                            }
                            //headerTextAlign='Center'
                            textAlign={x.isNumber ? 'Right' : undefined}
                          />
                        );
                      })}
                </ColumnsDirective>
                <AggregatesDirective>
                  <AggregateDirective>
                    <AggregateColumnsDirective>
                      {this.columns &&
                        this.columns.filter((x: any) => x.isGrouped === false)
                          .length > 0 && (
                          <AggregateColumnDirective
                            key={
                              this.columns.filter(
                                (x: any) => x.isGrouped === false
                              )[0].columnId
                            }
                            field={
                              this.columns
                                .filter((x: any) => x.isGrouped === false)[0]
                                .columnId.split('|')[1]
                            }
                            type='Count'
                            format={'N0'}
                            groupFooterTemplate={templateGroupCount}
                            footerTemplate={templateCount}
                          />
                        )}
                      {this.columns &&
                        this.columns
                          .filter((x: any) => x.isSummed === true)
                          .map((x: any) => {
                            return (
                              <AggregateColumnDirective
                                key={x.columnId}
                                field={x.columnId.split('|')[1]}
                                type='Sum'
                                format={'N2'}
                                groupFooterTemplate={templateGroupSum}
                                footerTemplate={templateSum}
                              />
                            );
                          })}
                    </AggregateColumnsDirective>
                  </AggregateDirective>
                </AggregatesDirective>
                <Inject
                  services={[
                    Toolbar,
                    ExcelExport,
                    PdfExport,
                    Group,
                    Sort,
                    Filter,
                    Aggregate,
                  ]}
                />
              </GridComponent>
            )}
            {this.reportType === 'pivottable' && (
              <>
                <PivotViewComponent
                  id='pivotcustrepopreview'
                  className='position-relative'
                  dataSourceSettings={dataSourceSettings}
                  height={'100%'}
                  showToolbar={true}
                  toolbar={[
                    'Grid',
                    'Chart',
                    'Export',
                    { template: '#customreport-periodctrls' },
                  ]}
                  //toolbarTemplate={'#toolbartemplate'}
                  allowExcelExport={true}
                  allowPdfExport={true}
                  allowDrillThrough={true}
                  showFieldList={true}
                  displayOption={{ view: 'Both' }}
                  gridSettings={{
                    columnWidth: 120,
                    allowSelection: true,
                    selectionSettings: {
                      mode: 'Cell',
                      type: 'Single',
                      cellSelectionMode: 'BoxWithBorder',
                    },
                  }}
                  chartSettings={{
                    title: this.reportName,
                    load: this.chartOnLoad.bind(this),
                  }}
                  ref={(pivotview: any) => {
                    this.pivotObj = pivotview;
                  }}
                >
                  <Inject
                    services={[
                      FieldList,
                      //CalculatedField,
                      PivotToolbar,
                      PivotPDFExport,
                      PivotExcelExport,
                      //ConditionalFormatting,
                      //NumberFormatting,
                      PivotChart,
                    ]}
                  />
                </PivotViewComponent>
                <div>
                  <div id='customreport-periodctrls'>
                    <PeriodSelector
                      handleInputChange={handlePeriodChange}
                      id='customreportpreviewperiod-selector'
                      _jObject={[]}
                      showEmployeePeriodsOnly={false}
                      showLastPeriod={true}
                      alttext={'No Period Defined.'}
                      showNewPeriodOption={false}
                      showAsOnDateIfNoPeriod={true}
                      showcheckbox={true}
                      checked={true}
                      handleCheckChange={handleCheckChange}
                      handleAsOnDateChange={handleAsOnDateChange}
                      handleDtpCheckChange={handleDtpCheckChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
