import * as React from 'react';
import './ImageEditorNew.css';
import Common from '../../../Util/Common';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SliderComponent } from '@syncfusion/ej2-react-inputs';
import DialogConfirm from '../../Dialogbox/DialogConfirm';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  onCancelClick: (e: any) => void;
  onSaveClick: (e: any) => void;
  image?: any;
}

export default class ImageEditorNew extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private slide: SliderComponent | any = null;
  private imageRatio: number = 0;
  private zoomRatio: number = 1;
  private origClientX: number = 0;
  private origClientY: number = 0;
  private origoffsetLeft: number = 0;
  private origoffsetTop: number = 0;
  private origWidth: number = 0;
  private origHeight: number = 0;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {};

  componentDidMount(): void {}

  private fileChanged(e: any) {
    const file = e.target.files[0];

    const URL = window.URL;
    const url = URL.createObjectURL((e.target as any).files[0]);

    // let canvas: HTMLCanvasElement = document.querySelector('#img-canvas')!;
    let icont: any = document.querySelector(
      '#divResourceLinkIcon .image-container'
    )!;

    const img1: HTMLImageElement = document.querySelector('#custom-img')!;
    img1.src = url.toString();

    // const newimg = new Image();
    // newimg.src = img1.src;

    // img1.onload = () => {
    //   this.imageRatio = img1.height / img1.width;

    //   //this.setImageWidth(icont.offsetWidth);
    //   const img: HTMLImageElement = document.querySelector('#custom-img')!;
    //   let imgcont: any = document.querySelector(
    //     '#divResourceLinkIcon .image-container'
    //   )!;

    //   this.origWidth = img.width;
    //   this.origHeight = img.height;

    //   const containerWidth = imgcont.offsetWidth;
    //   const containerHeight = imgcont.offsetHeight;

    //   const imgNaturalWidth = img.naturalWidth;
    //   const imgNaturalHeight = img.naturalHeight;

    //   const widthScale = containerWidth / imgNaturalWidth;
    //   const heightScale = containerHeight / imgNaturalHeight;

    //   this.zoomRatio = Math.min(widthScale, heightScale);

    //   const offsetX = (containerWidth - imgNaturalWidth * this.zoomRatio) / 2;
    //   const offsetY = (containerHeight - imgNaturalHeight * this.zoomRatio) / 2;

    //   img.style.transform = `scale(${this.zoomRatio})`;
    //   img.style.left = `${offsetX}px`;
    //   img.style.top = `${offsetY}px`;

    //   // img.width = icont.offsetWidth;
    //   // img.height = icont.offsetWidth * this.imageRatio;
    //   // img.style.marginLeft = `-${(img.width - imgcont.offsetWidth) / 2}px`;
    //   // img.style.marginTop = `${(imgcont.offsetHeight - img.height) / 2}px`;
    //   img.style.left = `-${(img.width - imgcont.offsetWidth) / 2}px`;
    //   img.style.top = `${(imgcont.offsetHeight - img.height) / 2}px`;

    //   if (newimg.width >= newimg.height) {
    //     // img.width = icont.offsetWidth;
    //     // img.height = icont.offsetWidth * this.imageRatio;

    //     // img.style.marginLeft = `-${(img.width - icont.offsetWidth) / 2}px`;
    //     // img.style.marginTop = `${(icont.offsetHeight - img.height) / 2}px`;

    //     // this.slide.min = icont.offsetWidth ;
    //     this.slide.min = this.zoomRatio * 100;
    //     // this.slide.max = newimg.width;
    //     this.slide.max = 1 * 100;
    //     //this.slide.value = img.width;
    //     //this.slide.value = icont.offsetWidth;
    //     this.slide.value = this.zoomRatio * 100;
    //   } else {
    //     // img.height = icont.offsetHeight;
    //     // img.width = icont.offsetHeight * this.imageRatio;

    //     // img.style.marginLeft = `${(icont.offsetWidth - img.width) / 2}px`;
    //     // img.style.marginTop = `-${(img.height - icont.offsetHeight) / 2}px`;

    //     this.slide.min = icont.offsetHeight + 150;
    //     this.slide.max = newimg.height;
    //     //this.slide.value = img.height;
    //     this.slide.value = icont.offsetHeight;
    //   }
    // };
  }

  private onImageLoad() {
    //this.setImageWidth(icont.offsetWidth);
    const img: HTMLImageElement = document.querySelector('#custom-img')!;
    let imgcont: any = document.querySelector(
      '#divResourceLinkIcon .image-container'
    )!;

    this.imageRatio = img.height / img.width;

    this.origWidth = img.width;
    this.origHeight = img.height;

    const containerWidth = imgcont.offsetWidth;
    const containerHeight = imgcont.offsetHeight;

    const imgNaturalWidth = img.naturalWidth;
    const imgNaturalHeight = img.naturalHeight;

    const widthScale = containerWidth / imgNaturalWidth;
    const heightScale = containerHeight / imgNaturalHeight;

    this.zoomRatio = Math.min(widthScale, heightScale);

    const offsetX = (containerWidth - imgNaturalWidth * this.zoomRatio) / 2;
    const offsetY = (containerHeight - imgNaturalHeight * this.zoomRatio) / 2;

    img.style.transform = `scale(${this.zoomRatio})`;
    img.style.left = `${offsetX}px`;
    img.style.top = `${offsetY}px`;

    // img.width = icont.offsetWidth;
    // img.height = icont.offsetWidth * this.imageRatio;
    // img.style.marginLeft = `-${(img.width - imgcont.offsetWidth) / 2}px`;
    // img.style.marginTop = `${(imgcont.offsetHeight - img.height) / 2}px`;
    img.style.left = `-${(img.width - imgcont.offsetWidth) / 2}px`;
    img.style.top = `${(imgcont.offsetHeight - img.height) / 2}px`;

    if (img.width >= img.height) {
      // img.width = icont.offsetWidth;
      // img.height = icont.offsetWidth * this.imageRatio;

      // img.style.marginLeft = `-${(img.width - icont.offsetWidth) / 2}px`;
      // img.style.marginTop = `${(icont.offsetHeight - img.height) / 2}px`;

      // this.slide.min = icont.offsetWidth ;
      this.slide.min = this.zoomRatio * 100;

      // this.slide.max = newimg.width;
      this.slide.max = 1 * 100;
      //this.slide.value = img.width;
      //this.slide.value = icont.offsetWidth;
      this.slide.value = this.zoomRatio * 100;
    } else {
      // img.height = icont.offsetHeight;
      // img.width = icont.offsetHeight * this.imageRatio;

      // img.style.marginLeft = `${(icont.offsetWidth - img.width) / 2}px`;
      // img.style.marginTop = `-${(img.height - icont.offsetHeight) / 2}px`;

      this.slide.min = this.zoomRatio * 100;
      this.slide.max = 1 * 100;
      //this.slide.value = img.height;
      //this.slide.value = imgcont.offsetHeight;
      this.slide.value = this.zoomRatio * 100;
    }
    if (this.slide.min === 100) {
      this.slide.max = 2 * 100;
    }
  }
  private setImageWidth(width: number) {
    const img: HTMLImageElement = document.querySelector('#custom-img')!;
    let imgcont: any = document.querySelector(
      '#divResourceLinkIcon .image-container'
    )!;
    // this.zoomRatio =
    //   (width - imgcont.offsetWidth) / (this.origWidth - imgcont.offsetWidth);
    this.zoomRatio = width / 100;

    if (this.imageRatio < 1) {
      // img.width = width;
      // img.height = width * this.imageRatio;
      // img.style.marginLeft = `-${(img.width - imgcont.offsetWidth) / 2}px`;
      // img.style.marginTop = `${(imgcont.offsetHeight - img.height) / 2}px`;
    } else {
      // img.width = width;
      // img.height = width * this.imageRatio;
      // img.style.marginLeft = `${(imgcont.offsetWidth - img.width) / 2}px`;
      // img.style.marginTop = `-${(img.height - imgcont.offsetHeight) / 2}px`;
    }
    img.style.transform = `scale(${this.zoomRatio})`;

    const imgdrag: HTMLImageElement = document.querySelector('#img-drag')!;
    imgdrag.style.width = img.width + 'px';
    imgdrag.style.height = img.height + 'px';
    imgdrag.style.left = img.style.left;
    imgdrag.style.top = img.style.top;
    imgdrag.style.transform = `scale(${this.zoomRatio})`;
  }

  render() {
    const onOpenImageClick = (e: any) => {
      document.getElementById('img-icon-upload')!.click();
    };

    const onSliderChange = (e: any) => {
      const img: HTMLImageElement = document.querySelector('#custom-img')!;
      if (img) {
        this.setImageWidth(e.value);
      }
    };

    const onImgDrag = (e: any) => {
      if (e.clientX > 0) {
        const img: HTMLImageElement = document.querySelector('#custom-img')!;
        // img.style.left = `${
        //   this.origoffsetLeft + (e.clientX - this.origClientX)
        // }px`;
        // img.style.top = `${
        //   this.origoffsetTop + (e.clientY - this.origClientY)
        // }px`;
        img.style.left = `${
          this.origoffsetLeft + (e.clientX - this.origClientX)
        }px`;
        img.style.top = `${
          this.origoffsetTop + (e.clientY - this.origClientY)
        }px`;

        const imgdrag: HTMLImageElement = document.querySelector('#img-drag')!;
        imgdrag.style.width = img.width + 'px';
        imgdrag.style.height = img.height + 'px';
        imgdrag.style.left = img.style.left;
        imgdrag.style.top = img.style.top;
      }
    };

    const onImgMouseDown = (e: any) => {
      const img: HTMLImageElement = document.querySelector('#custom-img')!;
      this.origClientX = e.clientX;
      this.origClientY = e.clientY;
      this.origoffsetLeft = Number(img.style.left.replace('px', ''));
      this.origoffsetTop = Number(img.style.top.replace('px', ''));
    };

    const onImgMouseUp = (e: any) => {};

    const onSaveClick = (e: any) => {
      const img: HTMLImageElement = document.querySelector('#custom-img')!;
      const cont: HTMLDivElement = document.querySelector('.image-container')!;
      const canvas: HTMLCanvasElement = document.querySelector('#img-canvas')!;

      if (this.imageRatio === 0 && !this.props.image) {
        DialogConfirm.showDialog({
          content: '<p class="dialog-contain">Please select an image!</p>',
        });
        return;
      }

      const imgRect = img.getBoundingClientRect();
      const contRect = cont.getBoundingClientRect();

      const containerWidth = contRect.width;
      const containerHeight = contRect.height;

      const offsetX = (contRect.left - imgRect.left) / this.zoomRatio;
      const offsetY = (contRect.top - imgRect.top) / this.zoomRatio;

      canvas.width = Math.min(containerWidth, imgRect.width);
      canvas.height = Math.min(containerHeight, imgRect.height);
      let ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

      ctx.drawImage(
        img,
        Math.max(offsetX, 0), //offsetX
        Math.max(offsetY, 0), //offsetY
        canvas.width / this.zoomRatio, //containerWidth / this.zoomRatio
        canvas.height / this.zoomRatio, //containerHeight / this.zoomRatio
        0,
        0,
        canvas.width, //containerWidth
        canvas.height //containerHeight
      );

      const dataURL = canvas.toDataURL('image/png');
      if (dataURL === 'data:,') {
        this.props.onSaveClick(null);
      } else {
        this.props.onSaveClick(dataURL);
      }
    };

    const onCancelClick = (e: any) => {
      this.props.onCancelClick(e);
    };

    const onDeletelClick = (e: any) => {
      const img: HTMLImageElement = document.querySelector('#custom-img')!;
      img.src = '';
    };

    return (
      <>
        <div id='divResourceLinkIcon' className='py-2'>
          <div className='row'>
            <div className='col-12 imagecol'>
              <canvas id='img-canvas'></canvas>
              <div className='image-container'>
                <img
                  id='custom-img'
                  src={
                    this.props.image
                      ? !this.props.image.includes('data')
                        ? `data:image/png;base64,${this.props.image}`
                        : this.props.image
                      : ''
                  }
                  alt=''
                  onLoad={this.onImageLoad.bind(this)}
                />
                <div
                  id='img-drag'
                  draggable={true}
                  onMouseDown={onImgMouseDown}
                  onDrag={onImgDrag}
                ></div>
              </div>
              <div className='image-control'>
                <input
                  type='file'
                  id='img-icon-upload'
                  className='e-custom-file'
                  onChange={this.fileChanged.bind(this)}
                  accept='image/png, image/gif, image/jpeg'
                />
                <ButtonComponent
                  className='flat-button e-flat'
                  onClick={onOpenImageClick}
                >
                  Select an Image
                </ButtonComponent>
                <div className='sliderwrap'>
                  {/* Set the type to render MinRange slider */}
                  <SliderComponent
                    value={0}
                    type='MinRange'
                    change={onSliderChange}
                    ref={(obj: any) => (this.slide = obj)}
                  />
                </div>
              </div>
              <div className='w-100 d-flex'>
                <ButtonComponent
                  className='flat-button e-flat me-auto'
                  onClick={onDeletelClick}
                >
                  Delete Image
                </ButtonComponent>
                <ButtonComponent
                  className='flat-button e-flat'
                  onClick={onCancelClick}
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  className='flat-button e-flat'
                  onClick={onSaveClick}
                >
                  Save
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
